import React, { useState } from 'react';
import Cookies from 'js-cookie';
import {
  Col,
  Row,
} from '../../../../components/styled_components/grid/Grid.style';
import {
  AccountingPageLayout,
  PageHeading,
} from '../../../../theme/GlobalStyles';
import {
  DateInput,
  TransactionBankTransferInputWrap,
  TransactionBankTransferDisplayButton,
  TransactionBankTransferDisplayButtonIcon,
  TotalAmtDiv,
  Total,
  NoData,
} from './BankTransfer.style';
import arrowRightIcon from '../../../../assets/icons/arrow-right.svg';
import AccountingMenu from '../../../../components/accounting/menu';
import Table from '../../../../components/common/table';
import { fetcher } from '../../../../hooks/useFetch';
import { ACCT_API } from '../../../../constants';
import Breadcrumbs from '../../../../components/breadcrumbs';
import Loader from '../../../../components/styled_components/loader/loader.style';
import ScrollDownButton from '../../../../utils/ScrollDownButton';
import ScrollUpButton from '../../../../utils/ScrollUpButton';

const TransactionsBankTransfer = () => {
  const breadcrumbs = ['Accounting', 'Transactions', 'Bank Transfer'];
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [data, setData] = useState();
  const [total, setTotalAmt] = useState(0);
  const [loading, setLoading] = useState(false);
  const minDate = Cookies.get('accountant_year');

  const handleView = async () => {
    setLoading(true);
    const res = await fetcher({
      url: `${ACCT_API.GET_TRANSACTION_BANK_TRANSFER}?startDate=${startDate}&endDate=${endDate}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    setData(res.response);
    setLoading(false);
    let amt = 0;
    res.response.forEach((item) => {
      amt += item.amount;
    });
    setTotalAmt(amt);
  };
  const tableLookup = [
    {
      heading: 'Date',
      field_name: 'date',
      type: 'date',
    },
    {
      heading: 'From Account',
      field_name: 'fromAccount',
      type: 'string',
    },
    {
      heading: 'To Account',
      field_name: 'toAccount',
      type: 'string',
    },
    {
      heading: 'Description',
      field_name: 'description',
      type: 'string',
    },
    {
      heading: 'Amount',
      field_name: 'amount',
      type: 'amount',
    },
  ];
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return (
    <>
      <AccountingMenu />
      {loading ? <Loader /> : null}
      <ScrollUpButton />
      <ScrollDownButton />
      <AccountingPageLayout>
        <Row>
          <Col>
            <PageHeading>Bank Transfer</PageHeading>
          </Col>
        </Row>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <TransactionBankTransferInputWrap>
          <Row>
            <Col col={4}>
              <DateInput
                type="date"
                min={`${minDate}-01-01`}
                max={`${minDate}-12-31`}
                placeholder="Start Date"
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Col>
            <Col col={4}>
              <DateInput
                type="date"
                min={`${minDate}-01-01`}
                max={`${minDate}-12-31`}
                placeholder="End Date"
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Col>
            <Col col={4} textAlign="right">
              <TransactionBankTransferDisplayButton
                type="button"
                onClick={() => handleView()}
                disabled={!(startDate && endDate)}
              >
                Display
                <TransactionBankTransferDisplayButtonIcon>
                  <img src={arrowRightIcon} alt=">" height="16px" />
                </TransactionBankTransferDisplayButtonIcon>
              </TransactionBankTransferDisplayButton>
            </Col>
          </Row>
        </TransactionBankTransferInputWrap>
        {
          data && data.length > 0
            ? (
              <>
                <Table data={data || []} tableLookup={tableLookup} />
                <TotalAmtDiv>
                  <Total>
                    {`$ ${formatter.format(total)}`}
                  </Total>
                </TotalAmtDiv>
              </>
            ) : data && data.length === 0 ? (
              <NoData>
                No Data
              </NoData>
            ) : ''
        }
      </AccountingPageLayout>
    </>
  );
};

export default TransactionsBankTransfer;

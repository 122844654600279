import styled from 'styled-components';

export const TableDiv = styled.div`
  overflow-x: auto;
  table {
    width: 100%;
    border-spacing: 0 8px;
    border-collapse: separate;
    tr {
      background-color: ${({ theme }) => theme.colors.white};
      color: yellow;
    }
  }
`;

export const TableHead = styled.th`
  background-color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  font-size: 18px;
  text-align: left;
  padding: 18px;
  color: ${({ theme }) => theme.colors.primary_blue};
  :first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  :last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

export const TableData = styled.td`
  text-align: ${(props) => props.textAlign || 'left'};
  text-decoration: ${(props) => props.lineThrough};
  font-size: 16px;
  white-space: nowrap;
  padding: 18px;
  white-space: break-spaces;
  color: ${({ theme }) => theme.colors.primary_blue};
  :first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  :last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  a {
    color: ${({ theme }) => theme.colors.primary_blue};
    text-decoration: none;
  }
`;
export const DownloadButtonIcon = styled.div`
  display: block;
  border: 1px solid ${({ theme }) => theme.colors.secondary_background};
  border-radius: 5px;
  height: 25px;
  width: 25px;
  margin: 0 1px;
  background-color: ${({ theme }) => theme.colors.page_background};
  cursor: pointer;
`;
export const Image = styled.img`
  margin-top: 6px;
  margin-left: 5px;
`;
export const IncomeCardWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: -10px;
  margin-right: ${(props) => props.marginRight || 0};
  background-color: ${({ theme }) => theme.colors.white};
  border-top-left-radius: ${({ theme }) => theme.border.radius_m};
  border-top-right-radius: ${({ theme }) => theme.border.radius_m};
  padding: 10px 20px 20px 10px;
  cursor: pointer;
  height: 50px;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: ${({ theme }) => theme.colors.primary_blue};
//   line-height: 12px;
`;

export const Income = styled.div`
  border-radius: ${({ theme }) => theme.border.radius_m};
  // border-bottom-right-radius: ${({ theme }) => theme.border.radius_m};
  background-color:  ${({ theme }) => theme.colors.white};
  padding: 10px;
  margin: 10px 0;

`;

import React from 'react';
import PropTypes from 'prop-types';
import {
  SaveButton,
  ModalWrapper,
  ModalHeading,
  ConfirmModals,
} from './confirmModal.style';
import { Row, Col } from '../../../styled_components/grid/Grid.style';

const ConfirmModal = ({
  isOpen,
  handleClose,
  handleSubmit,
  toggleModal,
  confirmToggleModal,
}) => (
  <ConfirmModals isOpen={isOpen}>
    <ModalWrapper>
      <Row>
        <Col col={12}>
          <ModalHeading> Are you sure you want to delete ?</ModalHeading>
        </Col>
      </Row>
      <Row>
        <Col col={6}>
          <SaveButton
            type="button"
            onClick={() => {
              handleClose();
              toggleModal();
              handleSubmit();
            }}
          >
            Sure
          </SaveButton>
        </Col>
        <Col col={6}>
          <SaveButton
            type="button"
            onClick={() => {
              handleClose();
              confirmToggleModal();
            }}
          >
            Cancel
          </SaveButton>
        </Col>
      </Row>
    </ModalWrapper>
  </ConfirmModals>
);
ConfirmModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  toggleModal: PropTypes.func,
  handleSubmit: PropTypes.func,
  confirmToggleModal: PropTypes.func,
};

export default ConfirmModal;

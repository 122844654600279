const development = {
  servicePath: 'https://trinity.dev.edstem.com',
  accountingPath: 'https://books.trinity.dev.edstem.com',
  currentEnv: 'development',
};

const stage = {
  servicePath: 'https://trinity.stage.edstem.com',
  accountingPath: 'https://books.trinity.stage.edstem.com',
  currentEnv: 'stage',
};

const production = {
  servicePath: 'https://trinity.prod.edstem.com',
  accountingPath: 'https://books.trinity.stage.edstem.com',
  currentEnv: 'production',
};

let config = development;

switch (process.env.REACT_APP_ENV) {
  case 'stage': {
    config = stage;
    break;
  }
  case 'production': {
    config = production;
    break;
  }
  default: {
    config = development;
    break;
  }
}

export default {
  ...config,
};

import styled from 'styled-components';

export const TableDiv = styled.div`
  overflow: auto;
  height: 500px;
  table {
    width: 100%;
    border-spacing: 0 8px;
    border-collapse: separate;
    tr {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const THead = styled.thead`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const TableHead = styled.th`
  background-color: ${({ theme }) => theme.colors.white};
  font-weight: 900;
  font-size: 15px;
  text-align: left;
  padding: 18px;
  position: sticky;
  top: 0;
  z-index: 1;
  border-right: 1px solid #e4e8f4;
  color: ${({ theme }) => theme.colors.primary_blue};

  :first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  :last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

export const TableSubHead = styled.th`
  background-color: ${({ theme }) => theme.colors.white};
  font-weight: 900;
  font-size: 12px;
  text-align: left;
  padding: 18px;
  border-right: 1px solid #e4e8f4;
  color: ${({ theme }) => theme.colors.primary_blue};
  :first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  :last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

export const TableData = styled.td`
  text-align: ${(props) => props.textAlign || 'left'};
  font-size: 12px;
  white-space: nowrap;
  padding: 18px;
  border-left: ${(props) => props.borderLeft || '1px solid #E4E8F4'};
  font-weight: ${(props) => props.fontWeight || 'left'};
  color: ${({ theme }) => theme.colors.primary_blue};
  :first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  :last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

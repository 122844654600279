import React from 'react';
import { Col, Row } from '../../components/styled_components/grid/Grid.style';
import { PageHeading, PageSubHeading } from '../../theme/GlobalStyles';
import { Logo, Wrapper } from './PrivacyPolicy.style';
import logo from '../../assets/img/logo.svg';

function PrivacyPolicy() {
  return (
    <>
      <Wrapper>
        <Row justifyCenter="center">
          <Col col={5} />
          <Col col={4}>
            <Logo src={logo} />
          </Col>
        </Row>
        <PageHeading>Privacy Policy</PageHeading>
        <Row>Effective date: September 20, 2020</Row>
        <br />
        <Row>
          Edstem Technologies Pvt Ltd (us, we, or our) operates the
          member.trinitymtc.org website.
        </Row>
        <br />
        <Row>
          This page informs you of our policies regarding the collection, use,
          and disclosure of personal data when you use our Service and the
          choices you have associated with that data. Our Privacy Policy for
          Trinity is managed through Free Privacy Policy.
        </Row>
        <br />
        <PageSubHeading>Types of Data Collected</PageSubHeading>
        <Row>
          While using our Service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you (Personal Data).
        </Row>
        <br />
        <PageSubHeading>Transfer Of Data</PageSubHeading>
        <Row>
          Your information, including Personal Data, may be transferred to — and
          maintained on — computers located outside of your state, province,
          country or other governmental jurisdiction where the data protection
          laws may differ than those from your jurisdiction.
        </Row>
        <br />
        <Row>
          If you are located outside India and choose to provide information to
          us, please note that we transfer the data, including Personal Data, to
          India and process it there.
        </Row>
        <br />
        <Row>
          Edstem Technologies Pvt Ltd will take all steps reasonably necessary
          to ensure that your data is treated securely and in accordance with
          this Privacy Policy and no transfer of your Personal Data will take
          place to an organization or a country unless there are adequate
          controls in place including the security of your data and other
          personal information.
        </Row>
        <br />
      </Wrapper>
    </>
  );
}

export default PrivacyPolicy;

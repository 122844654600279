import React from 'react';
import PropTypes from 'prop-types';
import PanelHeading from '../../membership_components/panel_heading';
import { Col, Row } from '../../styled_components/grid/Grid.style';
import {
  Div, Email, EmailBody, FieldLabel, FieldValue, Required,
} from './loginEmail.style';

const LoginEmail = ({
  caller, text, nextId, handleChange,
}) => (
  <>
    <Email>
      <PanelHeading label="Login Email" />
      <EmailBody>
        <Row>
          <Col col={4}>
            <Div className="addMargin">
              <FieldLabel>
                Login Email
                {' '}
                <Required>{caller === 'baptism' ? '' : '*' }</Required>
              </FieldLabel>
              <FieldValue
                name="email"
                value={text.email}
                type="text"
                onChange={handleChange}
              />
            </Div>
          </Col>
          {nextId && caller !== 'marriage'
            ? (
              <Col col={4}>
                <Div className="addMargin">
                  <FieldLabel>
                    Membership Id
                  </FieldLabel>
                  <FieldValue
                    name="email"
                    value={nextId}
                    type="text"
                    disabled
                    onChange={handleChange}
                  />
                </Div>
              </Col>
            ) : null}
        </Row>
      </EmailBody>
    </Email>
  </>
);

LoginEmail.propTypes = {
  caller: PropTypes.string,
  text: PropTypes.object,
  nextId: PropTypes.number,
  handleChange: PropTypes.func,
};

export default LoginEmail;

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { PageLayout, PageSubHeading } from '../../../theme/GlobalStyles';
import {
  TableDiv, TableHead, TableData, Icon, IconDiv,
} from './AddressApproval.style';
import tickIcon from '../../../assets/icons/tick.svg';
import closeIcon from '../../../assets/icons/close-icon.svg';
import { Col, Row } from '../../../components/styled_components/grid/Grid.style';
import { fetcher } from '../../../hooks/useFetch';
import { API } from '../../../constants';

const AddressApproval = () => {
  const [address, setAddress] = useState([]);
  // method for fetching pending address
  const getAddresses = async () => {
    const data = await fetcher({
      method: 'GET',
      url: API.PENDING_ADDRESS,
    });
    if (data && data.requests_list) {
      setAddress(data.requests_list);
    }
  };

  const approve = async (updateRequestId) => {
    const res = await fetcher({
      url: `${API.APPROVE_ADDRESS}?updateRequestId=${updateRequestId}&approved=True`,
      method: 'POST',
      authTokenKey: 'auth_token',
    });
    if (res.success) {
      toast.success('Approved', { autoClose: 3000 });
    } else {
      toast.error('Error', { autoClose: 3000 });
    }
    getAddresses();
  };
  const reject = async (updateRequestId) => {
    const res = await fetcher({
      url: `${API.APPROVE_ADDRESS}?updateRequestId=${updateRequestId}&approved=False`,
      method: 'POST',
      authTokenKey: 'auth_token',
    });
    if (res.success) {
      toast.success('Rejected', { autoClose: 3000 });
    } else {
      toast.error('Error', { autoClose: 3000 });
    }
    getAddresses();
  };
  useEffect(() => {
    getAddresses();
  }, []);
  return (
    <PageLayout>
      <PageSubHeading>Address Approval</PageSubHeading>
      <TableDiv>
        <table>
          <thead>
            <tr>
              <TableHead>Church ID</TableHead>
              <TableHead>Name</TableHead>
              <TableHead>Old House Address</TableHead>
              <TableHead>New House Address</TableHead>
              <TableHead>Actions</TableHead>
            </tr>
          </thead>
          <tbody>
            {
             address && address.map((row) => (
               <tr key={row.updateRequestId}>
                 <TableData>
                   {row.membershipId}
                 </TableData>
                 <TableData>
                   {row.name}
                 </TableData>
                 <TableData>
                   <span>
                     {row.oldAddress.Address1}
                     {' '}
                     {row.oldAddress.Address2}
                   </span>
                   <br />
                   <span>
                     {row.oldAddress.city}
                     {' '}
                     {row.oldAddress.state}
                   </span>
                   <br />
                   <span>
                     {row.oldAddress.zipcode}
                   </span>
                 </TableData>
                 <TableData>
                   <span>
                     {row.newAddress.Address1}
                     {' '}
                     {row.newAddress.Address2}
                   </span>
                   <br />
                   <span>
                     {row.newAddress.city}
                     {' '}
                     {row.newAddress.state}
                   </span>
                   <br />
                   <span>
                     {row.newAddress.zipcode}
                   </span>
                 </TableData>
                 <TableData>
                   <Row>
                     <Col col={4}>
                       <IconDiv>
                         <Icon
                           src={tickIcon}
                           onClick={() => approve(row.updateRequestId)}
                         />
                       </IconDiv>
                     </Col>
                     <Col col={4}>
                       <IconDiv>
                         <Icon
                           onClick={() => reject(row.updateRequestId)}
                           src={closeIcon}
                         />
                       </IconDiv>
                     </Col>
                   </Row>
                 </TableData>
               </tr>
             ))
            }
          </tbody>
        </table>
      </TableDiv>
    </PageLayout>
  );
};
export default AddressApproval;

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import pen from '../../../assets/icons/pen.svg';
import { Col, Row } from '../../styled_components/grid/Grid.style';
import {
  TableData,
  TableDiv,
  TableHead,
  DownloadButtonIcon,
  Image,
} from './Table.style';
import download from '../../../assets/icons/download.svg';
import trash from '../../../assets/icons/trash.svg';

const Table = ({
  data, tableLookup, downloadPdf, handleEdit, deleteItem,
}) => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return (
    <TableDiv>
      <table>
        <thead>
          <tr>
            {tableLookup.map((elem) => (
              <TableHead key={elem.heading}>{elem.heading}</TableHead>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.id}>
              {tableLookup.map((field) => (
                field.type === 'amount' ? (
                  <TableData key={field.field_name} textAlign="right" lineThrough={row.notes === 'Voided' ? 'line-through' : null}>
                    {`$ ${formatter.format(row.amount)}`}
                  </TableData>
                ) : field.type === 'array' ? (
                  <TableData key={field.field_name} textAlign="left" lineThrough={row.notes === 'Voided' ? 'line-through' : null}>
                    {row[field.field_name].map((subarray) => (
                      <div key={subarray.itemName}>
                        <span>{subarray.itemName || subarray.Category}</span>
                        <br />
                      </div>
                    ))}
                  </TableData>
                ) : field.type === 'particulars' ? (
                  <TableData key={field.field_name} lineThrough={row.notes === 'Voided' ? 'line-through' : null}>
                    {row[field.field_name].map((subarray) => (
                      <div key={subarray.particulars}>
                        <span>{subarray.particulars}</span>
                        <br />
                      </div>
                    ))}
                  </TableData>
                ) : field.type === 'rate' ? (
                  <TableData key={field.field_name} textAlign="right" lineThrough={row.notes === 'Voided' ? 'line-through' : null}>
                    {row[field.field_name].map((subarray) => (
                      <div key={subarray.rate}>
                        <span>{`$ ${formatter.format(subarray.rate || subarray.amount || 0)}`}</span>
                        <br />
                      </div>
                    ))}
                  </TableData>
                ) : field.type === 'date' ? (
                  <TableData key={field.field_name} lineThrough={row.notes === 'Voided' ? 'line-through' : null}>{moment(row[field.field_name]).format('MM-DD-YYYY')}</TableData>
                ) : field.type === 'link' ? (
                  <TableData key={field.field_name}><a href={`/members/details/${row.id}`}>{row[field.field_name]}</a></TableData>
                ) : (field.type === 'download' && row.notes !== 'Voided') ? (
                  <TableData key={field.field_name}>
                    <Row>
                      <Col>
                        <DownloadButtonIcon onClick={() => handleEdit(row.id)}>
                          <Image
                            src={pen}
                            alt=">"
                            height="13px"
                            marginTop="5px"
                          />
                        </DownloadButtonIcon>
                      </Col>
                      <Col>
                        <DownloadButtonIcon onClick={() => downloadPdf(row.id)}>
                          <Image
                            src={download}
                            alt=">"
                            height="13px"
                            marginTop="5px"
                          />
                        </DownloadButtonIcon>
                      </Col>
                      <Col>
                        <DownloadButtonIcon onClick={() => deleteItem(row.id)}>
                          <Image
                            src={trash}
                            alt=">"
                            height="13px"
                            marginTop="5px"
                          />
                        </DownloadButtonIcon>
                      </Col>
                    </Row>
                  </TableData>
                ) : (<TableData key={field.field_name} lineThrough={row.notes === 'Voided' ? 'line-through' : null}>{row[field.field_name]}</TableData>)
              ))}

            </tr>
          ))}
        </tbody>
      </table>
    </TableDiv>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  tableLookup: PropTypes.array,
  downloadPdf: PropTypes.func,
  handleEdit: PropTypes.func,
  deleteItem: PropTypes.func,
};
export default Table;

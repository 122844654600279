import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from '../../styled_components/grid/Grid.style';
import ArrowDown from '../../../assets/icons/arrow-down.svg';
import {
  MenuWrapper, Menu, DropdownContent, DropDownItem,
} from './ReportSubmenu.style';

const ReportSubMenu = () => {
  const navigate = useNavigate();
  const [displayCreateMenu, setDisplayCreateMenu] = useState('none');
  const [displaySubscriptionMenu, setDisplaySubsciptionMenu] = useState('none');
  const [displayIncomeReport, setDisplayIncomeReport] = useState('none');
  const [displayMemberReport, setDisplayMemberReport] = useState('none');
  const [displayGeneralReport, setDisplayGeneralReport] = useState('none');
  const [displayBudgetReport, setDisplayBudgetReport] = useState('none');
  const showChurchReport = () => {
    if (displayCreateMenu === 'block') {
      setDisplayCreateMenu('none');
    } else {
      setDisplayCreateMenu('block');
    }
  };
  const showSubscriptionReport = () => {
    if (displaySubscriptionMenu === 'block') {
      setDisplaySubsciptionMenu('none');
    } else {
      setDisplaySubsciptionMenu('block');
    }
  };
  const showIncomeReport = () => {
    if (displayIncomeReport === 'block') {
      setDisplayIncomeReport('none');
    } else {
      setDisplayIncomeReport('block');
    }
  };
  const showMemberReport = () => {
    if (displayMemberReport === 'block') {
      setDisplayMemberReport('none');
    } else {
      setDisplayMemberReport('block');
    }
  };
  const showGeneralReportMenu = () => {
    if (displayGeneralReport === 'block') {
      setDisplayGeneralReport('none');
    } else {
      setDisplayGeneralReport('block');
    }
  };
  const showBudgetReportMenu = () => {
    if (displayBudgetReport === 'block') {
      setDisplayBudgetReport('none');
    } else {
      setDisplayBudgetReport('block');
    }
  };
  document.addEventListener('click', (e) => {
    if (document.getElementById('churchmenubox')?.contains(e.target)) {
      setDisplayCreateMenu('block');
    } else {
      setDisplayCreateMenu('none');
    }
    if (document.getElementById('membermenubox')?.contains(e.target)) {
      setDisplayMemberReport('block');
    } else {
      setDisplayMemberReport('none');
    }
    if (document.getElementById('incomemenubox')?.contains(e.target)) {
      setDisplayIncomeReport('block');
    } else {
      setDisplayIncomeReport('none');
    }
    if (document.getElementById('subscriptionmenubox')?.contains(e.target)) {
      setDisplaySubsciptionMenu('block');
    } else {
      setDisplaySubsciptionMenu('none');
    }
    if (document.getElementById('generalmenubox')?.contains(e.target)) {
      setDisplayGeneralReport('block');
    } else {
      setDisplayGeneralReport('none');
    }
    if (document.getElementById('budgetmenubox')?.contains(e.target)) {
      setDisplayBudgetReport('block');
    } else {
      setDisplayBudgetReport('none');
    }
  });
  const handleClick = (link) => {
    navigate(link);
  };
  return (
    <>
      <MenuWrapper>
        <Row>
          <Col width="16.5%">
            <Menu id="churchmenubox" topLeftBorderRadius="40px" marginRight="7px">
              <Row className="dropdown" onClick={showChurchReport}>
                <Col col={11}>Church Report</Col>
                <Col col={1}>
                  <img
                    src={ArrowDown}
                    width={12}
                    height={10}
                    alt="arrow down"
                  />
                </Col>
              </Row>
              <DropdownContent display={displayCreateMenu}>
                <DropDownItem onClick={() => handleClick('/accounting/report/churchreceipts')}>
                  Church Receipts
                </DropDownItem>
                <DropDownItem onClick={() => handleClick('/accounting/report/churchpayments')}>
                  Church Payments
                </DropDownItem>
                {/* <DropDownItem
                onClick={() => handleClick('/accounting/report/summarizedreport')}>
                  Summarized Report
                </DropDownItem> */}
              </DropdownContent>
            </Menu>
          </Col>
          <Col width="16.5%">
            <Menu id="membermenubox" marginRight="7px">
              <Row className="dropdown" onClick={showMemberReport}>
                <Col col={11}>Member Report</Col>
                <Col col={1}>
                  <img
                    src={ArrowDown}
                    width={12}
                    height={10}
                    alt="arrow down"
                  />
                </Col>
              </Row>
              <DropdownContent display={displayMemberReport}>
                <DropDownItem onClick={() => handleClick('/accounting/reports/member-idwise-report')}>ID-Wise Statement</DropDownItem>
                <DropDownItem onClick={() => handleClick('/accounting/reports/idwise-contribution-letter-report')}>ID-Wise Contribution Letter</DropDownItem>
                <DropDownItem onClick={() => handleClick('/accounting/reports/itemized-contribution-list')}>Itemized Contribution List</DropDownItem>
              </DropdownContent>
            </Menu>
          </Col>
          <Col width="16.5%">
            <Menu id="incomemenubox" marginRight="7px">
              <Row className="dropdown" onClick={showIncomeReport}>
                <Col col={11}>Income Report</Col>
                <Col col={1}>
                  <img
                    src={ArrowDown}
                    width={12}
                    height={10}
                    alt="arrow down"
                  />
                </Col>
              </Row>
              <DropdownContent display={displayIncomeReport}>
                <DropDownItem onClick={() => handleClick('/accounting/report/incomewise-statement')}>
                  Income-Wise Statement
                </DropDownItem>
                <DropDownItem onClick={() => handleClick('/accounting/report/income-combined')}>
                  Income-Combined
                </DropDownItem>
              </DropdownContent>
            </Menu>
          </Col>
          <Col width="16.5%">
            <Menu id="subscriptionmenubox" marginRight="7px">
              <Row className="dropdown" onClick={showSubscriptionReport}>
                <Col col={11}>Subscription Reports</Col>
                <Col col={1}>
                  <img
                    src={ArrowDown}
                    width={12}
                    height={10}
                    alt="arrow down"
                  />
                </Col>
              </Row>
              <DropdownContent display={displaySubscriptionMenu}>
                <DropDownItem onClick={() => handleClick('/accounting/reports/subscription-due')}>
                  Subscription Due
                </DropDownItem>
              </DropdownContent>
            </Menu>
          </Col>
          <Col width="16.5%">
            <Menu id="generalmenubox">
              <Row onClick={showGeneralReportMenu}>
                <Col col={11}>
                  General Reports
                </Col>
                <Col col={1}>
                  <img
                    src={ArrowDown}
                    width={12}
                    height={10}
                    alt="arrow down"
                  />
                </Col>
              </Row>
              <DropdownContent display={displayGeneralReport}>
                <DropDownItem onClick={() => handleClick('/accounting/report/generalledger')}>General Ledger</DropDownItem>
                <DropDownItem onClick={() => handleClick('/accounting/report/trialbalance')}>Trial Balance</DropDownItem>
                <DropDownItem onClick={() => handleClick('/accounting/report/cashflow')}>Cash Flow</DropDownItem>
              </DropdownContent>
            </Menu>
          </Col>
          <Col width="16.5%" marginLeft="7px">
            <Menu id="budgetmenubox">
              <Row onClick={showBudgetReportMenu}>
                <Col col={11}>
                  Summarized Reports
                </Col>
                <Col col={1}>
                  <img
                    src={ArrowDown}
                    width={12}
                    height={10}
                    alt="arrow down"
                  />
                </Col>
              </Row>
              <DropdownContent display={displayBudgetReport}>
                <DropDownItem onClick={() => handleClick('/accounting/report/budgetreport')}>Summarized Report</DropDownItem>
                <DropDownItem onClick={() => handleClick('/accounting/report/overbudgetreport')}>Over & Non Budget</DropDownItem>
              </DropdownContent>
            </Menu>
          </Col>
        </Row>
      </MenuWrapper>
    </>
  );
};

export default ReportSubMenu;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PanelHeading from '../../membership_components/panel_heading';
import { Row, Col } from '../../styled_components/grid/Grid.style';
import RadioButtons from '../../common/radio_buttons';
import {
  BasicInfoLayout,
  Panel,
  PanelBody,
  FieldLabel,
  FieldValue,
  Div,
  BasicInfoCustomSelect,
  Required,
  OtherInfoCustomSelect,
} from './BasicInfo.style';

const BasicInfo = ({
  formType,
  caller,
  handleChurchId,
  handleChange,
  parents,
  text,
  relation,
  churchId,
}) => {
  const [select, setSelect] = useState('M');
  const handleSelectChange = (event) => {
    const { value } = event.target;
    setSelect(value);
    const gen = { target: { name: 'gender', value: event.target.value } };
    handleChange(gen);
  };
  return (
    <>
      <BasicInfoLayout>
        <Row>
          <Panel marginRight="20px">
            <PanelHeading label="Basic Information" />
            <PanelBody>
              {formType === 'existing' ? (
                <Row marginBottom="17px">
                  <Col col={6}>
                    <Div className="addMargin">
                      <FieldLabel>Church ID</FieldLabel>
                      <FieldValue
                        type="text"
                        name="churchId"
                        value={churchId}
                        onChange={(event) => {
                          handleChurchId(event.target.value);
                          handleChange(event);
                        }}
                      />
                    </Div>
                  </Col>
                  <Col col={6}>
                    <Div className="addMargin">
                      <FieldLabel>
                        Head Of Family
                        <Required>*</Required>
                      </FieldLabel>
                      <FieldValue value={text.headOfFamily} disabled type="text" />
                    </Div>
                  </Col>
                </Row>
              ) : (
                ''
              )}

              <Row marginBottom="17px">
                <Col col={4}>
                  <Div className="addMargin">
                    <FieldLabel>
                      Title
                      <Required>*</Required>
                    </FieldLabel>
                    <FieldValue
                      placeholder="Mr./Ms./Mrs/Master/Miss"
                      type="text"
                      name="title"
                      value={text.title}
                      onChange={handleChange}
                    />
                  </Div>
                </Col>
                <Col col={4}>
                  <Div className="addMargin">
                    <FieldLabel>
                      First Name
                      <Required>*</Required>
                    </FieldLabel>
                    <FieldValue
                      name="firstName"
                      value={text.firstName}
                      type="text"
                      onChange={handleChange}
                    />
                  </Div>
                </Col>
                <Col col={4}>
                  <Div className="addMargin">
                    <FieldLabel>
                      Middle Name
                    </FieldLabel>
                    <FieldValue
                      name="middleName"
                      value={text.middleName}
                      type="text"
                      onChange={handleChange}
                    />
                  </Div>
                </Col>
              </Row>
              <Row marginBottom="17px">
                <Col col={4}>
                  <Div className="addMargin">
                    <FieldLabel>
                      Last Name
                      <Required>*</Required>
                    </FieldLabel>
                    <FieldValue
                      name="lastName"
                      value={text.lastName}
                      type="text"
                      onChange={handleChange}
                    />
                  </Div>
                </Col>
                <Col col={4}>
                  <Div className="addMargin">
                    <FieldLabel>
                      Date Of Birth
                      <Required>*</Required>
                    </FieldLabel>
                    <FieldValue
                      type="date"
                      placeholder="DOB"
                      name="dob"
                      value={text.dob}
                      onChange={handleChange}
                    />
                  </Div>
                </Col>
                <Col col={4}>
                  <RadioButtons
                    label1="Male"
                    value1="M"
                    label2="Female"
                    value2="F"
                    marginTop="5px"
                    name="gender"
                    handleSelectChange={handleSelectChange}
                    select={select}
                  />
                </Col>
              </Row>
              <Row marginBottom="17px">
                {formType === 'existing' && caller === 'baptism' ? (
                  <>
                    <Col col={4}>
                      <Div className="addMargin">
                        <BasicInfoCustomSelect
                          className="select"
                          classNamePrefix="custom"
                          options={parents}
                          name="fatherMembershipNo"
                          value={text.fatherMembershipNo
                            && parents.find((i) => i.value === text.fatherMembershipNo)}
                          onChange={(event) => handleChange({
                            target: { name: 'fatherMembershipNo', value: event.value },
                          })}
                          placeholder="Father"
                        />
                      </Div>
                    </Col>
                    <Col col={4}>
                      <Div className="addMargin">
                        <BasicInfoCustomSelect
                          className="select"
                          classNamePrefix="custom"
                          options={parents}
                          name="motherMembershipNo"
                          value={text.motherMembershipNo
                            && parents.find((i) => i.value === text.motherMembershipNo)}
                          onChange={(event) => handleChange({
                            target: { name: 'motherMembershipNo', value: event.value },
                          })}
                          placeholder="Mother"
                        />
                      </Div>
                    </Col>
                  </>
                ) : (
                  ''
                )}

                <Col col={4}>
                  {caller === 'baptism' ? (
                    <>
                      <Div className="addMargin">
                        <FieldLabel>
                          Place Of Birth
                          <Required>*</Required>
                        </FieldLabel>
                        <FieldValue
                          type="text"
                          value={text.placeOfBirth}
                          name="placeOfBirth"
                          onChange={handleChange}
                        />
                      </Div>
                    </>
                  ) : (
                    ''
                  )}
                </Col>
              </Row>
              <Row marginBottom="17px">
                {formType === 'existing' && (caller === 'baptism' || caller === 'transfer') ? (
                  <>
                    <Col col={4}>
                      <Div className="addMargin">
                        <OtherInfoCustomSelect
                          className="select"
                          classNamePrefix="custom"
                          options={relation}
                          name="roleId"
                          value={text.roleId && relation.find((i) => i.value === text.roleId)}
                          onChange={(event) => handleChange({
                            target: { name: 'roleId', value: event.value },
                          })}
                          placeholder="Relation With Head of Family"
                        />
                      </Div>
                    </Col>
                  </>
                ) : (
                  ''
                )}
              </Row>
            </PanelBody>
          </Panel>
        </Row>
      </BasicInfoLayout>
    </>
  );
};
BasicInfo.propTypes = {
  formType: PropTypes.string,
  caller: PropTypes.string,
  handleChurchId: PropTypes.func,
  handleChange: PropTypes.func,
  text: PropTypes.object,
  relation: PropTypes.array,
  parents: PropTypes.array,
  churchId: PropTypes.string,
};

export default BasicInfo;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import close from '../../../assets/icons/close-icon.svg';
import Transfer from '../../../assets/icons/transfer.svg';
import { Col, Row } from '../../styled_components/grid/Grid.style';
import {
  StyledModal,
  ModalWrapper,
  ModalHeading,
  CustomSelect,
  CloseButton,
  CloseIcon,
  MembersInput,
  Div,
  FieldLabel,
  FieldValueInput,
  SelectedContainer,
  TransferButton,
  TransferIcon,
} from './transferModal.style';

const option = [
  { value: 'existing', label: 'Existing' },
  { value: 'new', label: 'New' },
];
const EditAddressModal = ({
  isOpen,
  selectedMembers,
  setSelectedMembers,
  toggleModal,
  familyMembers,
  handleExisting,
}) => {
  const [type, setType] = useState({ value: 'existing', label: 'Existing' });
  const [input, setInput] = useState('');
  const handleChange = (e) => {
    const data = { ...input };
    data[e.target.name] = e.target.value;
    setInput(data);
  };
  const handleClose = () => {
    setSelectedMembers([]);
  };
  const handleType = (e) => {
    setType(e);
  };
  const handleSelect = (e) => {
    if (!selectedMembers.find((item) => item.value === e.value)) {
      setSelectedMembers((prev) => [...prev, e]);
    } else {
      toast.warning('Already selected!', { autoClose: 2000 });
    }
  };
  const handleSubmit = (e) => {
    let inputValue;
    if (e?.caller === 'existing') {
      inputValue = { membership_number: e.id, headOfFamily: e.input };
    } else {
      inputValue = { membership_number: e.id };
    }
    handleExisting(inputValue, e);
  };
  const handleInput = (e) => {
    const data = e.target.value;
    setInput(data);
  };
  return (
    <StyledModal isOpen={isOpen}>
      <CloseButton type="button" onClick={() => { toggleModal(); handleClose(); }}>
        <CloseIcon>
          <img src={close} alt="arrow" height="16px" />
        </CloseIcon>
      </CloseButton>
      <ModalWrapper>
        <Row>
          <Col col={12}>
            <ModalHeading> Transfer Member</ModalHeading>
          </Col>
        </Row>
        <Row>
          <Col col={6}>
            <CustomSelect
              className="select"
              classNamePrefix="custom"
              name="type"
              defaultValue={type}
              options={option}
              placeholder="Type"
              onChange={(e) => handleType(e)}
            />
          </Col>
          {type.value === 'existing' ? (
            <Col col={6}>
              <CustomSelect
                className="select"
                classNamePrefix="custom"
                name="type"
                options={familyMembers}
                placeholder="Family"
                onChange={(e) => handleSelect(e)}
              />
            </Col>
          ) : null}
        </Row>
        {type.value === 'existing' ? (
          <>
            <Row>
              <Col col={6}>
                <MembersInput
                  id="members-search"
                  name="memberSearchName"
                  type="text"
                  value={input}
                  placeholder="Enter Name or Membership"
                  onChange={(e) => handleInput(e)}
                />
              </Col>
            </Row>
            <SelectedContainer>
              {selectedMembers.map((item, index) => (
                <Row>
                  <Col col={6}>
                    <Div>
                      <FieldLabel>Name</FieldLabel>
                      <FieldValueInput
                        id=""
                        name="name"
                        type="text"
                        value={item && item.label}
                        onChange={(e) => handleChange(e)}
                        disabled
                      />
                    </Div>
                  </Col>
                  <Col col={5}>
                    <Div>
                      <FieldLabel>Membership ID</FieldLabel>
                      <FieldValueInput
                        id=""
                        name="membershipId"
                        type="text"
                        value={item && item.value}
                        onChange={(e) => handleChange(e)}
                        disabled
                      />
                    </Div>
                  </Col>
                  <Col col={1} margin="auto">
                    <TransferButton onClick={() => {
                      handleSubmit({
                        id: item.value, input, caller: 'existing', index,
                      });
                    }}
                    >
                      <TransferIcon src={Transfer} />
                    </TransferButton>
                  </Col>
                </Row>
              ))}
            </SelectedContainer>
          </>
        ) : null }
        {type.value === 'new' ? (
          <>
            <SelectedContainer>
              {selectedMembers.map((item, index) => (
                <Row>
                  <Col col={6}>
                    <Div>
                      <FieldLabel>Name</FieldLabel>
                      <FieldValueInput
                        id=""
                        name="name"
                        type="text"
                        value={item && item.label}
                        disabled
                      />
                    </Div>
                  </Col>
                  <Col col={5}>
                    <Div>
                      <FieldLabel>Membership ID</FieldLabel>
                      <FieldValueInput
                        id=""
                        name="membershipId"
                        type="text"
                        value={item && item.value}
                        disabled
                      />
                    </Div>
                  </Col>
                  <Col col={1} margin="auto">
                    <TransferButton onClick={() => {
                      handleSubmit({
                        id: item.value, input, caller: 'new', index,
                      });
                    }}
                    >
                      <TransferIcon src={Transfer} />
                    </TransferButton>
                  </Col>
                </Row>
              ))}
            </SelectedContainer>
          </>
        ) : null}
      </ModalWrapper>
    </StyledModal>
  );
};
EditAddressModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  selectedMembers: PropTypes.array,
  setSelectedMembers: PropTypes.func,
  familyMembers: PropTypes.array,
  handleExisting: PropTypes.func,
};
export default EditAddressModal;

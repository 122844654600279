import styled from 'styled-components';

const Loader = styled.div`
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 4px solid ${({ theme }) => theme.colors.primary_red};
  border-radius: 50%;
  border-top-color: ${({ theme }) => theme.colors.white};
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  position: fixed;
  z-index: 1;

  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`;

export default Loader;

import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from '../styled_components/grid/Grid.style';

import defaultPic from '../../assets/icons/default-profile.png';
import locationIcon from '../../assets/icons/location.svg';
import mailIcon from '../../assets/icons/mail-icon.svg';
import mobileIcon from '../../assets/icons/mobile-icon.svg';
import remove from '../../assets/icons/delete.svg';
import Transfer from '../../assets/icons/transfer.svg';
import {
  LocationIcon,
  MemberCardWrapper,
  ContactText,
  ContactHeading,
  ContactIcon,
  MemberLocation,
  MemberName,
  MemberProfilePic,
  MemberContactContainer,
  ContactWrapper,
  TransferIcon,
  RemoveIcon,
  TransferButton,
  RemoveButton,
} from './MemberPanel.style';

const MemberPanel = ({
  marginRight, member, handleRemove, roles, handleClick, handleTransfer,
}) => (
  <MemberCardWrapper key={member.id} marginRight={marginRight}>
    <Row>
      <Col col={2}>
        <MemberProfilePic
          onError={(e) => { e.target.onerror = null; e.target.src = defaultPic; }}
          src={member.profilePic ? member.profilePic : defaultPic}
          alt="default pic"
        />
      </Col>
      <Col col={10}>
        <Row>
          <Col col={10} onClick={() => handleClick(`/members/details/${member.id}`)}>
            <MemberName>
              {member.name}
            </MemberName>
          </Col>
          {roles.includes('PowerUser')
            ? (
              <Col>
                <Col col={11}>
                  <TransferButton onClick={() => handleTransfer(member)}>
                    <TransferIcon src={Transfer} />
                  </TransferButton>
                </Col>
                <Col col={11}>
                  <RemoveButton onClick={() => handleRemove(member)}>
                    <RemoveIcon src={remove} />
                  </RemoveButton>
                </Col>
              </Col>
            ) : null}
        </Row>
        <Row>
          <Col col={11}>
            <MemberLocation>
              <LocationIcon src={locationIcon} alt="loc" />
              {member.prayerGroup}
            </MemberLocation>
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <MemberContactContainer>
              <Row>
                <Col col={6}>
                  <ContactWrapper>
                    <Col col={2}>
                      <ContactIcon src={mailIcon} alt="mail" />
                    </Col>
                    <Col col={11}>
                      <ContactHeading>Email</ContactHeading>
                      <ContactText>
                        {member.homeEmail}
                      </ContactText>
                    </Col>
                  </ContactWrapper>
                </Col>
                <Col col={6}>
                  <ContactWrapper borderLeft="1px solid #e4e8f4;">
                    <Col col={2} margin="auto">
                      <ContactIcon src={mobileIcon} alt="phone" />
                    </Col>
                    <Col col={11}>
                      <ContactHeading>Phone</ContactHeading>
                      <ContactText>
                        {member.contactDetail}
                      </ContactText>
                    </Col>
                  </ContactWrapper>
                </Col>
              </Row>
            </MemberContactContainer>
          </Col>
        </Row>
      </Col>
    </Row>
  </MemberCardWrapper>
);

MemberPanel.propTypes = {
  marginRight: PropTypes.string,
  member: PropTypes.object,
  handleRemove: PropTypes.func,
  handleTransfer: PropTypes.func,
  roles: PropTypes.array,
  handleClick: PropTypes.func,
};

export default MemberPanel;

import styled from 'styled-components';

export const FieldLabel = styled.div`
color:  ${({ theme }) => theme.colors.light_grey};
font-size: 14px;
font-weight: ${({ theme }) => theme.fonts.font_weight.bold_font};
margin: 8px 22px 0px;
opacity: 0.5;
`;

export const EditIconDiv = styled.div`
  background: ${({ theme }) => theme.colors.page_background};
  width: 30px;
  height: 30px;
  border-radius: 7px;
  text-align: center;
  cursor: pointer;
  float: right;
  margin-right: 7px;
  margin-top: 15px;
`;

export const EditIcon = styled.img`
  margin-top: 7px;
`;

export const FieldValueInput = styled.input`
  color:  ${({ theme }) => theme.colors.primary_blue};
  font-size: ${({ theme }) => theme.fonts.font_size.big_font};
  font-weight: 600;
  margin: 0px 18px 6px;
  border: 1px solid ${({ theme }) => theme.colors.grey_blue};
  border-radius: 10px;
  width: 75%;
  padding: 5px 5px 5px;;
  &:disabled {
    background-color: inherit;
    border: none;
  }
`;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import { Col, Row } from '../../components/styled_components/grid/Grid.style';
import prayingHandsImage from '../../assets/img/praying_hands.png';
import logo from '../../assets/img/logo.svg';
import emailIcon from '../../assets/icons/email.svg';
import arrowRightIcon from '../../assets/icons/arrow-right.svg';
import { fetcher } from '../../hooks/useFetch';
import { API } from '../../constants';
import MobileForgotPassword from '../mobile_view/forgot_password';

import {
  ChurchName,
  LoginButton,
  LoginButtonIcon,
  LoginHeader,
  LoginIcon,
  LoginInput,
  LoginInputWrapper,
  LoginLabel,
  LoginPagePrayingHands,
  Logo,
} from './ForgotPassword.style';

const ForgotPassword = () => {
  const [loginData, setLoginData] = useState({});
  const navigate = useNavigate();
  const handleChange = (evt) => {
    const currentLoginData = { ...loginData };
    currentLoginData[evt.target.name] = evt.target.value;
    setLoginData(currentLoginData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetcher({
        url: API.FORGOT_PASSWORD,
        method: 'POST',
        body: JSON.stringify(loginData),
      });
      if (response.success) {
        toast.success('Success! Reset email has been sent');
        navigate('/login');
      }
    } catch (e) {
      if (e.status === 401 || e.status === 404) {
        toast.error('Error! You have entered an invalid username');
      } else {
        toast.error('Error! Technical error occurred');
      }
    }
  };
  if (isMobile) {
    return <MobileForgotPassword />;
  }
  return (
    <>
      <LoginPagePrayingHands src={prayingHandsImage} alt="" />
      <Row justifyCenter="center">
        <Col col={4} />
        <Col col={4} textAlign="center">
          <Logo src={logo} />
        </Col>
      </Row>
      <Row>
        <Col col={4} />
        <Col col={4} textAlign="center">
          <ChurchName>Trinity Marthoma Church, Houston</ChurchName>
        </Col>
      </Row>
      <Row>
        <Col col={4} />
        <Col col={4}>
          <LoginHeader>Forgot Password</LoginHeader>
        </Col>
      </Row>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col col={4} />
          <Col col={4}>
            <LoginInputWrapper>
              <LoginLabel htmlFor="email">Enter Email</LoginLabel>
              <LoginInput
                id="email"
                name="email"
                onChange={handleChange}
                type="email"
                placeholder="yourmail@email.com"
                required="true"
              />
              <LoginIcon src={emailIcon} height="32px" alt="" />
            </LoginInputWrapper>
          </Col>
        </Row>
        <Row marginTop="30px">
          <Col col={4} />
          <Col col={4}>
            <LoginButton type="submit">
              Verify Email
              <LoginButtonIcon>
                <img src={arrowRightIcon} alt=">" height="16px" />
              </LoginButtonIcon>
            </LoginButton>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default ForgotPassword;

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import Breadcrumbs from '../../../../components/breadcrumbs';
import MemberReportsMenu from '../../../../components/member_reports/menu';
import { API } from '../../../../constants';
import downloadArrow from '../../../../assets/icons/download.svg';
import Loader from '../../../../components/styled_components/loader/loader.style';
import { fetcher, fileDownloader } from '../../../../hooks/useFetch';
import {
  AccountingPageLayout,
  PageHeading,
  PageSubHeading,
} from '../../../../theme/GlobalStyles';
import {
  Text,
  DownloadButton,
  DownloadButtonIcon,
  DownloadImage,
  WrapperDiv,
} from './MaleFemaleList';
import Table from '../../../../components/common/table';
import {
  Col,
  Row,
} from '../../../../components/styled_components/grid/Grid.style';
import ScrollUpButton from '../../../../utils/ScrollUpButton';
import ScrollDownButton from '../../../../utils/ScrollDownButton';

function MaleFemaleList() {
  const breadcrumbs = ['Reports', 'General Reports', 'Male Female'];
  const [loading, setLoading] = useState(false);
  const [maleList, setMaleList] = useState([]);
  const [femaleList, setFemaleList] = useState([]);
  const [text, setText] = useState('');

  const getList = async () => {
    setLoading(true);
    setText('');
    const res = await fetcher({
      url: `${API.GET_MALE_FEMALE_LIST}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    if (res.male_members_list || res.male_members_list) {
      setLoading(false);
      setMaleList(res.male_members_list);
      setFemaleList(res.female_members_list);
    } else {
      setLoading(false);
      setText('No Data');
      toast.warn('No Data', { autoClose: 3000 });
    }
  };

  const downloadReport = async () => {
    try {
      const download = await fileDownloader({
        url: `${API.DOWNLOAD_MALE_FEMALE_REPORT}`,
        method: 'GET',
        authTokenKey: 'auth_token',
      });
      if (download) {
        saveAs(new Blob([download], { type: 'application/vnd.ms-excel' }), 'Male/Female.xlsx');
      }
    } catch (error) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };

  const headers = [
    {
      heading: 'Membership Number',
      field_name: 'membership_number',
      type: 'id',
    },
    {
      heading: 'Name',
      field_name: 'name',
      type: 'name',
    },
  ];

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <MemberReportsMenu />
      {loading ? <Loader /> : null}
      <ScrollUpButton />
      <ScrollDownButton />
      <AccountingPageLayout>
        <PageHeading>All Male/Female</PageHeading>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Row>
          <Col col={1}>
            <PageSubHeading>{text}</PageSubHeading>
          </Col>
        </Row>
        {maleList.length > 0 && (
          <>
            <Row>
              <WrapperDiv>
                <Col col={8}>
                  <Text>Male List</Text>
                </Col>
                <Col col={4}>
                  <DownloadButton
                    type="button"
                    onClick={() => downloadReport()}
                  >
                    Download
                    <DownloadButtonIcon>
                      <DownloadImage
                        src={downloadArrow}
                        alt=">"
                        height="13px"
                      />
                    </DownloadButtonIcon>
                  </DownloadButton>
                </Col>
              </WrapperDiv>
            </Row>

            <Table data={maleList} tableLookup={headers} />
          </>
        )}
        {femaleList.length > 0 && (
          <>
            <Text>Female List</Text>
            <Table data={femaleList} tableLookup={headers} />
          </>
        )}
      </AccountingPageLayout>
    </>
  );
}

export default MaleFemaleList;

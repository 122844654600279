import React from 'react';
import AddMemberMenu from '../../components/add_member/menu';
import { PageLayout, PageHeading } from '../../theme/GlobalStyles';
import { WhiteWrap } from './AddMember.style';

const AddMember = () => (
  <>
    <PageLayout>
      <PageHeading>Add Member</PageHeading>
      <WhiteWrap>
        <AddMemberMenu />
      </WhiteWrap>
    </PageLayout>
  </>
);

export default AddMember;

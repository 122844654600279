import styled from 'styled-components';

export const DashboardLayout = styled.div`
  height: 100%;
`;
export const TopSection = styled.div`
  border-top-left-radius: 50px;
  font-size: 18px;
  padding: 50px 40px 40px 40px;
  background-color: ${({ theme }) => theme.colors.primary_blue};
`;

export const TopCol = styled.div`
  min-width: ${(props) => props.minWidth || 0};
  height: 100%;
  float: left;
  margin-right: 20px;
`;

export const FamilyPhoto = styled.img`
  height: 250px;
  border-radius: ${({ theme }) => theme.border.radius_l};
`;

export const MemberInformation = styled.div`
  color: ${({ theme }) => theme.colors.white};
  padding: 10px;
`;

export const MemberName = styled.h1`
  margin: 0 0 5px 0;
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  font-size: 32px;
`;

export const SeparatorIcon = styled.img`
  width: 10px;
  height: 10px;
`;

export const LocationIcon = styled.img`
  width: 20px;
  height: 30px;
`;

export const AddressLine = styled.div`
  margin: 5px 0;
  p {
    margin: 5px 0;
  }
`;

export const CommunicationLine = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grey_blue};
  border-radius: ${({ theme }) => theme.border.radius_m};
  .left {
    border-right: 1px solid ${({ theme }) => theme.colors.grey_blue};
  }
  min-width: 600px;
`;

export const CommunicationIcon = styled.img`
  width: 40px;
  height: 40px;
  margin: 15px 10px;
  float: left;
`;

export const CommunicationBody = styled.div`
  padding: 5px;
  margin: 10px 0;
  .heading {
    font-family: 'Playfair Display', serif;
    color: ${({ theme }) => theme.colors.white};
    opacity: 0.5;
  }
`;

export const SocialMediaLine = styled.div`
  margin-top: 10px;
`;

export const BlueDiv = styled.div`
  height: 40px;
  width: 40px;
  border-radius: ${({ theme }) => theme.border.radius_m};
  background-color: ${({ theme }) => theme.colors.light_blue};
  text-align: center;
  float: left;
  margin-right: 10px;
`;

export const SocialIcon = styled.img`
  margin-top: 8px;
  width: 24px;
  height: 24px;
`;

export const ClearSection = styled.div`
  clear: both;
`;

export const BodySection = styled.div`
  background-color: ${({ theme }) => theme.colors.page_background};
  padding: 10px 40px;
  .first {
    margin: 20px 0;
  }
  height: 70%;
`;

export const Panel = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius_m};
  margin: 20px ${(props) => props.marginRight || 0} 0 0;
  height: 96%;
`;

export const PanelHeading = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.tertiary_blue};
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondary_background};
  padding: 10px 20px;
  line-height: 40px;
`;
export const NoContribution = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.tertiary_blue};
  padding: 10px 20px;
  line-height: 40px;
`;
export const PanelBody = styled.div`
  padding: 20px;
`;
export const PersonalInformation = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.secondary_background};
  border-radius: ${({ theme }) => theme.border.radius_m};
  margin: 10px 20px;
  padding: 10px;
  color: ${({ theme }) => theme.colors.primary_blue};
  .value {
    font-weight: 700;
    font-size: 16px;
    padding-left: 3%;
    margin-top: 2px;
  }
`;
export const HouseholdWrapper = styled.div``;
export const HouseMember = styled.div`
  text-align: center;
  display: inline;
  float: left;
  margin: 20px;
`;
export const UserProfile = styled.img`
  border-radius: 50%;
  width: 75px;
  height: 75px;
  cursor: pointer;
`;
export const UserName = styled.div`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary_blue};
  margin: 5px 0;
  cursor: pointer;
`;
export const BirthdayUserName = styled(UserName)`
  cursor: pointer;
`;
export const WeddingUserName = styled(BirthdayUserName)`
  display: flex;
`;
export const UserRelation = styled.div`
  color: ${({ theme }) => theme.colors.primary_blue};
`;

export const ChartLegendItem = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.secondary_background};
  margin: 5px 20px 0 0;
  border-radius: ${({ theme }) => theme.border.radius_m};
`;
export const ChartLegend = styled.div`
  background-color: ${(props) => props.backgroundColor};
  height: 13px;
  width: 13px;
  border-radius: 50%;
  float: left;
  margin: 18px 6px;
`;
export const ChartLegendLabel = styled.div`
  font-size: 14px;
  text-align: left;
  color: ${({ theme }) => theme.colors.primary_blue};
  margin: 5px 0;
`;
export const BirthdayWrapper = styled.div`
  margin: 20px 10px;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;
`;
export const Birthday = styled.div`
  display: inline-block;
  border: 1px solid ${({ theme }) => theme.colors.secondary_background};
  width: 300px;
  border-radius: ${({ theme }) => theme.border.radius_m};
  padding: 30px 10px;
  margin-right: 20px;
`;
export const BirthdayProfile = styled.img`
  border-radius: 50%;
  width: 50px;
  height: 50px;
`;
export const BirthdayHatIcon = styled.div`
  position: absolute;
  left: 30px;
  top: -10px;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.light_blue};
  border: 3px solid #f8f9fa;
  img {
    height: 24px;
    width: 24px;
  }
`;
export const UserPrayerGroup = styled.div`
  color: ${({ theme }) => theme.colors.primary_blue};
`;
export const UserPhone = styled.div`
  margin-top: 2px;
  background-color: ${({ theme }) => theme.colors.page_background};
  padding: 4px 10px;
  color: ${({ theme }) => theme.colors.primary_blue};
  border-radius: ${({ theme }) => theme.border.radius_s};
  img {
    height: 16px;
    width: 16px;
  }
  div {
    display: inline;
    margin-left: 5px;
  }
`;
export const EventDiv = styled.div`
  cursor: pointer;
  color: ${(props) => props.color};
`;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import tick from '../../../assets/icons/tick-white.svg';
import close from '../../../assets/icons/close-icon.svg';
import remove from '../../../assets/icons/delete.svg';
import { Row, Col } from '../../styled_components/grid/Grid.style';
import ConfirmModal from './confirm_modal';
import {
  StyledModal,
  SaveButton,
  SaveButtonIcon,
  ModalWrapper,
  ModalHeading,
  CloseButton,
  CloseIcon,
  Div,
  FieldLabel,
  FieldValueInput,
  CustomSelect,
  RemoveButton,
  RemoveIcon,
  SelectedContainer,
} from './removeModal.style';

const option = [
  { value: 'death', label: 'By Death' },
  { value: 'transfer', label: 'By Transfer' },
  { value: 'disciplinary', label: 'By Disciplinary' },
  { value: 'mistake', label: 'By Mistake' },
];
const EditAddressModal = ({
  isOpen,
  confirm,
  confirmToggleModal,
  toggleModal,
  handleDelete,
  handleByMistake,
  member,
  familyMembers,
  selectedMembers,
  setSelectedMembers,
}) => {
  const [items, setItem] = useState({});
  const [type, setType] = useState('Select');
  const handleChange = (e) => {
    const data = { ...items };
    data[e.target.name] = e.target.value;
    setItem(data);
  };
  const handleType = (e) => {
    setType(e);
  };
  const handleClose = () => {
    setSelectedMembers([]);
  };
  const handleSelect = (e) => {
    if (!selectedMembers.find((item) => item.value === e.value)) {
      setSelectedMembers((prev) => [...prev, e]);
    } else {
      toast.warning('Already selected!', { autoClose: 2000 });
    }
  };
  const handleSubmit = (value) => {
    let data;
    if (value?.caller === 'transfer') {
      data = { ...items, membershipId: value.id, type: type.value };
    } else if (type.value === 'mistake') {
      data = { userId: (member.id || member.ID), confirm: 'yes' };
    } else {
      data = {
        ...items,
        membershipId: (member.membershipNumber || member.churchID),
        type: type.value,
      };
    }
    if (type.value === 'mistake') {
      handleByMistake(data);
    } else {
      handleDelete(data, value);
    }
    setItem({});
  };
  return (
    <StyledModal isOpen={isOpen}>
      <CloseButton type="button" onClick={() => { toggleModal(); handleClose(); }}>
        <CloseIcon>
          <img src={close} alt="arrow" height="16px" />
        </CloseIcon>
      </CloseButton>
      <ModalWrapper>
        <Row>
          <Col col={12}>
            <ModalHeading> Remove Member</ModalHeading>
          </Col>
        </Row>
        <Row>
          <Col col={6}>
            <CustomSelect
              className="select"
              classNamePrefix="custom"
              name="type"
              defaultValue={type}
              options={option}
              placeholder="Select"
              onChange={(e) => handleType(e)}
            />
          </Col>
          {type.value === 'transfer' ? (
            <Col col={6}>
              <CustomSelect
                className="select"
                classNamePrefix="custom"
                name="type"
                options={familyMembers}
                placeholder="Family"
                onChange={(e) => handleSelect(e)}
              />
            </Col>
          ) : null}
        </Row>
        {type.value === 'transfer' ? (
          <>
            <Row>
              <Col col={4}>
                <Div>
                  <FieldLabel>Date of Transfer</FieldLabel>
                  <FieldValueInput
                    id=""
                    name="dateOfTransfer"
                    type="date"
                    onChange={(e) => handleChange(e)}
                  />
                </Div>
              </Col>
              <Col col={4}>
                <Div>
                  <FieldLabel>Church Transferred To</FieldLabel>
                  <FieldValueInput
                    id=""
                    name="churchTransferred"
                    type="text"
                    onChange={(e) => handleChange(e)}
                  />
                </Div>
              </Col>
              <Col col={4}>
                <Div>
                  <FieldLabel>Comments</FieldLabel>
                  <FieldValueInput
                    id=""
                    name="comments"
                    type="text"
                    onChange={(e) => handleChange(e)}
                  />
                </Div>
              </Col>
            </Row>
            <SelectedContainer>
              {selectedMembers.map((item, index) => (
                <Row key={item.value}>
                  <Col col={6}>
                    <Div>
                      <FieldLabel>Name</FieldLabel>
                      <FieldValueInput
                        id=""
                        name="name"
                        type="text"
                        value={item && item.label}
                        onChange={(e) => handleChange(e)}
                        disabled
                      />
                    </Div>
                  </Col>
                  <Col col={5}>
                    <Div>
                      <FieldLabel>Membership ID</FieldLabel>
                      <FieldValueInput
                        id=""
                        name="membershipId"
                        type="text"
                        value={item && item.value}
                        onChange={(e) => handleChange(e)}
                        disabled
                      />
                    </Div>
                  </Col>
                  <Col col={1} margin="auto">
                    <RemoveButton onClick={() => handleSubmit({ id: item.value, caller: 'transfer', index })}>
                      <RemoveIcon src={remove} />
                    </RemoveButton>
                  </Col>
                </Row>
              ))}
            </SelectedContainer>
          </>
        ) : (
          <Row>
            <Col col={6}>
              <Div>
                <FieldLabel>Name</FieldLabel>
                <FieldValueInput
                  id=""
                  name="name"
                  type="text"
                  value={member && member.name}
                  onChange={(e) => handleChange(e)}
                  disabled
                />
              </Div>
            </Col>
            <Col col={6}>
              <Div>
                <FieldLabel>Membership ID</FieldLabel>
                <FieldValueInput
                  id=""
                  name="membershipId"
                  type="text"
                  value={(member && member.membershipNumber) || (member && member.churchID)}
                  onChange={(e) => handleChange(e)}
                  disabled
                />
              </Div>
            </Col>
          </Row>
        )}
        {type.value === 'death' ? (
          <>
            <Row>
              <Col col={6}>
                <Div>
                  <FieldLabel>Date of Death</FieldLabel>
                  <FieldValueInput
                    id=""
                    name="dateOfDeath"
                    type="date"
                    onChange={(e) => handleChange(e)}
                  />
                </Div>
              </Col>
              <Col col={6}>
                <Div>
                  <FieldLabel>Date of Burial</FieldLabel>
                  <FieldValueInput
                    id=""
                    name="dateOfBurial"
                    type="date"
                    onChange={(e) => handleChange(e)}
                  />
                </Div>
              </Col>
            </Row>
            <Row>
              <Col col={6}>
                <Div>
                  <FieldLabel>Comments</FieldLabel>
                  <FieldValueInput
                    id=""
                    name="comments"
                    type="text"
                    onChange={(e) => handleChange(e)}
                  />
                </Div>
              </Col>
            </Row>
          </>
        ) : type.value === 'disciplinary' ? (
          <>
            <Row>
              <Col col={6}>
                <Div>
                  <FieldLabel>Date of Removal</FieldLabel>
                  <FieldValueInput
                    id=""
                    name="dateOfRemoval"
                    type="date"
                    onChange={(e) => handleChange(e)}
                  />
                </Div>
              </Col>
              <Col col={6}>
                <Div>
                  <FieldLabel>Reason for Removal</FieldLabel>
                  <FieldValueInput
                    id=""
                    name="reasonforremoval"
                    type="text"
                    onChange={(e) => handleChange(e)}
                  />
                </Div>
              </Col>
            </Row>
            <Row>
              <Col col={6}>
                <Div>
                  <FieldLabel>Comments</FieldLabel>
                  <FieldValueInput
                    id=""
                    name="comments"
                    type="text"
                    onChange={(e) => handleChange(e)}
                  />
                </Div>
              </Col>
            </Row>
          </>
        ) : null}
        {type.value !== 'transfer' ? (
          <Row>
            <Col col={8} />
            <Col col={4}>
              <SaveButton
                type="button"
                onClick={() => {
                  confirmToggleModal();
                }}
              >
                Confirm
                <SaveButtonIcon>
                  <img src={tick} alt="arrow" height="16px" />
                </SaveButtonIcon>
              </SaveButton>
              {confirm ? (
                <ConfirmModal
                  isOpen={confirm}
                  handleClose={handleClose}
                  toggleModal={toggleModal}
                  handleSubmit={handleSubmit}
                  confirmToggleModal={confirmToggleModal}
                />
              ) : null}
            </Col>
          </Row>
        ) : null}
      </ModalWrapper>
    </StyledModal>
  );
};
EditAddressModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  handleDelete: PropTypes.func,
  handleByMistake: PropTypes.func,
  confirmToggleModal: PropTypes.func,
  confirm: PropTypes.bool,
  member: PropTypes.object,
  familyMembers: PropTypes.array,
  selectedMembers: PropTypes.array,
  setSelectedMembers: PropTypes.func,
};

export default EditAddressModal;

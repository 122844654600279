import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import Cookies from 'js-cookie';
import {
  Col,
  Row,
} from '../../../components/styled_components/grid/Grid.style';
import {
  AccountingPageLayout,
  PageHeading,
  PageSubHeading,
} from '../../../theme/GlobalStyles';
import {
  DateInput,
  IncomeInputWrap,
  IncomeDisplayButton,
  IncomeDisplayButtonIcon,
  SearchSelect,
  DownloadButton,
  DownloadIcon,
  DownloadImage,
  Total,
  NoData,
} from './IncomeCombined.style';
import ReportSubMenu from '../../../components/accounting/report_submenu';
import arrowRightIcon from '../../../assets/icons/arrow-right.svg';
import downloadArrow from '../../../assets/icons/download.svg';
import AccountingMenu from '../../../components/accounting/menu';
import Table from '../../../components/common/table';
import { fetcher, fileDownloader } from '../../../hooks/useFetch';
import { API, ACCT_API } from '../../../constants';
import Breadcrumbs from '../../../components/breadcrumbs';
import Loader from '../../../components/styled_components/loader/loader.style';
import ScrollDownButton from '../../../utils/ScrollDownButton';
import ScrollUpButton from '../../../utils/ScrollUpButton';

const IncomeCombined = () => {
  const [sums, setSums] = useState([]);
  const [rows, setRows] = useState();
  const breadcrumbs = ['Accounting', 'Reports', 'IncomeReports', 'Income Combined'];
  const [memberId, setMemberId] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loading, setLoading] = useState(false);
  const minDate = Cookies.get('accountant_year');

  const getName = async (value) => {
    const res = await fetcher({
      url: `${API.GET_MEMBER_LIST}?query=${value}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    const memberNames = res.member_list.map((item) => ({
      value: item.membershipNumber,
      label: item.name,
    }));
    if (value.match(/0/i) || value.match(/non/i)) {
      return [...memberNames, { value: '0001', label: '0001 Non Member' }];
    } else {
      return memberNames;
    }
  };
  const handleView = async () => {
    setLoading(true);
    const res = await fetcher({
      url: `${ACCT_API.GET_INCOME_COMBINED}?membershipId=${memberId}&startDate=${startDate}&endDate=${endDate}&family=true`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    if (res) {
      setRows(Object.values(res.receipts));
    }
    setLoading(false);
  };
  const handleDownload = async () => {
    const res = await fileDownloader({
      url: `${ACCT_API.INCOME_COMBINED_DOWNLOAD}?membershipId=${memberId}&startDate=${startDate}&endDate=${endDate}&family=true`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    saveAs(new Blob([res], { type: 'application/vnd.ms-excel' }), 'Income-Combined.pdf');
  };
  useEffect(() => {
    if (rows) {
      let sum = 0;
      for (const i of rows) {
        sum += i.amount;
      }
      setSums(sum);
    }
  }, [rows]);
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const handleDisplay = (e) => {
    e.preventDefault();
    setSums([]);
    handleView();
  };
  const tableLookup = [
    {
      heading: 'Recipient Number',
      field_name: 'id',
      type: 'number',
    },
    {
      heading: 'Date',
      field_name: 'date',
      type: 'date',
    },
    {
      heading: 'Payment Method',
      field_name: 'paymentMethod',
      type: 'string',
    },
    {
      heading: 'Category',
      field_name: 'lines',
      type: 'array',
    },
    {
      heading: 'Organization',
      field_name: 'organization',
      type: 'string',
    },
    {
      heading: 'Amount',
      field_name: 'lines',
      type: 'rate',
    },
  ];
  return (
    <>
      <AccountingMenu />
      {loading ? <Loader /> : null}
      <ScrollUpButton />
      <ScrollDownButton />
      <AccountingPageLayout>
        <Row>
          <Col>
            <PageHeading>Reports</PageHeading>
          </Col>
        </Row>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <ReportSubMenu />
        <PageSubHeading> Income Combined </PageSubHeading>
        <IncomeInputWrap>
          <form onSubmit={handleDisplay}>
            <Row>
              <Col width="20%" marginLeft="5px">
                <SearchSelect
                  className="select"
                  classNamePrefix="custom"
                  placeholder="Enter Family Id"
                  loadOptions={(value) => getName(value)}
                  onChange={(e) => {
                    setMemberId(e.value);
                  }}
                />
              </Col>
              <Col width="20%">
                <DateInput
                  type="date"
                  min={`${minDate}-01-01`}
                  max={`${minDate}-12-31`}
                  placeholder="Start Date"
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Col>
              <Col width="1%" />
              <Col width="20%">
                <DateInput
                  type="date"
                  min={`${minDate}-01-01`}
                  max={`${minDate}-12-31`}
                  placeholder="End Date"
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Col>
              <Col width="17%">
                <DownloadButton onClick={handleDownload}>
                  Download
                  <DownloadIcon>
                    <DownloadImage src={downloadArrow} alt=">" height="13px" />
                  </DownloadIcon>
                </DownloadButton>
              </Col>
              <Col width="21%">
                <IncomeDisplayButton type="submit" disabled={!(startDate && endDate)}>
                  Display
                  <IncomeDisplayButtonIcon>
                    <img src={arrowRightIcon} alt=">" height="16px" />
                  </IncomeDisplayButtonIcon>
                </IncomeDisplayButton>
              </Col>
            </Row>
          </form>
        </IncomeInputWrap>
        {rows && rows.length > 0 ? (
          <>
            <Table data={rows || []} tableLookup={tableLookup} />
            <Total>
              <Row>
                <Col col={12} textAlign="right">
                  $
                  {formatter.format(sums)}
                </Col>
              </Row>
            </Total>
          </>
        ) : rows && rows.length === 0 ? (
          <NoData>
            No Data
          </NoData>
        ) : ''}
      </AccountingPageLayout>
    </>
  );
};

export default IncomeCombined;

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModalProvider } from 'styled-react-modal';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { Col, Row } from '../../../components/styled_components/grid/Grid.style';
import arrowRightIcon from '../../../assets/icons/arrow-right.svg';
import {
  MembersDisplayButton,
  MembersInput,
  MembersInputWrap,
  MembersDisplayButtonIcon,
  MembersPageLayout,
} from './Members.style';
import { PageHeading } from '../../../theme/GlobalStyles';
import MemberPanel from '../../../components/mobile_views/member_panel';
import { fetcher } from '../../../hooks/useFetch';
import { API } from '../../../constants';
import CreateModal from '../../../components/membership_components/remove_modal';
import Loader from '../../../components/styled_components/loader/loader.style';

const MembersMobile = () => {
  const navigate = useNavigate();
  const roles = Cookies.get('user_roles').split(',');
  const [data, setData] = useState([]);
  const [query, setQuery] = useState('');
  const [scroll, setScroll] = useState(true);
  const [pageLimit, setPageLimit] = useState(1);
  const [page, setPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState();
  const [loading, setLoading] = useState(false);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const loader = useRef(null);
  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting) {
      setScroll(true);
      setPage((page) => page + 1);
    }
  };
  const getName = async (value, page) => {
    setLoading(true);
    if (scroll) {
      const res = await fetcher({
        url: `${API.LIST_MEMBERS}?query=${value}&limit=10&page=${page}`,
        method: 'GET',
        authTokenKey: 'auth_token',
      });
      setData((prev) => [...prev, ...res.member_list]);
      setPageLimit(Math.round((res.total_no_of_records / 10) + 1));
    }
    setLoading(false);
  };

  const getSearch = async (value) => {
    const res = await fetcher({
      url: `${API.LIST_MEMBERS}?query=${value}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });

    setData(res.member_list);
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current);
    }
  }, []);
  useEffect(() => {
    if (page <= pageLimit) {
      getName('', page);
    }
  }, [page]);

  const handleRemove = (value) => {
    toggleModal(true);
    setSelectedMember(value);
  };
  const handleDelete = async (value) => {
    try {
      const res = await fetcher({
        url: `${API.REMOVE_MEMBER}`,
        method: 'POST',
        authTokenKey: 'auth_token',
        body: JSON.stringify(value),
      });
      if (res.message) {
        toast.success('Successfully removed!', { autoClose: 3000 });
        window.location.reload();
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };

  const handleClick = (link) => {
    // eslint-disable-next-line no-restricted-globals
    navigate(link);
  };

  return (
    <>
      <MembersPageLayout>
        {loading ? <Loader /> : null}
        <ModalProvider>
          <CreateModal
            member={selectedMember}
            handleDelete={handleDelete}
            toggleModal={toggleModal}
            isOpen={isOpen}
          />
        </ModalProvider>
        <Row>
          <Col><PageHeading>Members</PageHeading></Col>
        </Row>
        <MembersInputWrap>
          <Row>
            <Col col={12} textAlign="center">
              <MembersInput
                // id="members-search"
                // name="memberSearchName"
                type="text"
                placeholder="Enter Name or Membership"
                onChange={({ target: { value } }) => setQuery(value)}
              />
            </Col>
          </Row>
          <Row marginTop="10px" marginBottom="7px">
            <Col col={12} textAlign="center">
              <MembersDisplayButton
                type="button"
                onClick={() => {
                  setScroll(false); getSearch(query); setData([]); setPage(1);
                }}
              >
                Display
                <MembersDisplayButtonIcon>
                  <img src={arrowRightIcon} alt=">" height="16px" />
                </MembersDisplayButtonIcon>
              </MembersDisplayButton>
            </Col>
          </Row>
        </MembersInputWrap>
        <Row>
          {data && data.length > 0
            ? data.map((member) => (
              <Col col={12}>
                <MemberPanel
                  roles={roles}
                  member={member}
                  handleClick={handleClick}
                  handleRemove={handleRemove}
                />
              </Col>
            )) : null}
          <div className="loading" ref={loader} />
        </Row>
      </MembersPageLayout>
    </>
  );
};

export default MembersMobile;

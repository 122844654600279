import styled from 'styled-components';

const colWidth = {
  12: (100 / 12) * 12,
  11: (100 / 12) * 11,
  10: (100 / 12) * 10,
  9: (100 / 12) * 9,
  8: (100 / 12) * 8,
  7: (100 / 12) * 7,
  6: (100 / 12) * 6,
  5: (100 / 12) * 5,
  4: (100 / 12) * 4,
  3: (100 / 12) * 3,
  2: (100 / 12) * 2,
  1: (100 / 12) * 1,
};

export const Row = styled.div`
 
  width: 100%;
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  flex-wrap: wrap;
  position: relative;
  border-radius: ${(props) => props.borderRadius};
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
  border: ${(props) => `${props.border} solid #E5E2E2` || 'none'};
`;

export const Col = styled.div`
  width: ${(props) => (props.width ? props.width : `${colWidth[props.col]}%` || '100%')};
  text-align: ${(props) => props.textAlign};
  position: relative;
  box-sizing: border-box;
  display: ${(props) => props.display};
  margin-top: ${(props) => props.marginTop};
  margin-left: ${(props) => props.marginLeft};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  align-self: ${(props) => props.alignSelf};
  border-radius: ${(props) => props.borderRadius};
  border: ${(props) => `${props.border} solid #E5E2E2` || 'none'};
  height: ${(props) => props.height};
  justify-content: ${(props) => props.justifyContent};
  padding: ${(props) => props.padding};
  align-items: ${(props) => props.alignItems};
`;

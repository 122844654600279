/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import Creatable from 'react-select/creatable';

export const OptionsWrapperDiv = styled.div`
  padding: 10px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius_m};
  margin-bottom: 30px;
`;
export const AddressBar = styled.textarea`
  margin: 5px 5px;
  padding: 5px 5px;
  height: 38px;
  width: 100%;
  resize: none;
  border-radius: 5px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary_blue};
  background-color: ${({ theme }) => theme.colors.page_background};
  border: 1px solid ${({ theme }) => theme.colors.page_background};
`;
export const CheckNumber = styled.input`
  margin: 5px 5px;
  height: 46px;
  width: 95%;
  border-radius: 5px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary_blue};
  background-color: ${({ theme }) => theme.colors.page_background};
  border: 1px solid ${({ theme }) => theme.colors.page_background};
`;
export const StyledDate = styled.input`
  margin: 5px 5px;
  height: 48px;
  width: ${(props) => props.width};
  padding-left: 3px;
  border-radius: 5px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary_blue};
  background-color: ${({ theme }) => theme.colors.page_background};
  border: 1px solid ${({ theme }) => theme.colors.page_background};
`;
export const CustomSelect = styled(Select)`
  width: 100%;
  margin: 5px 0;
  padding: 0 5px;
  .custom__control {
    height: 50px;
    background-color: ${({ theme }) => theme.colors.page_background};
    border: 1px solid ${({ theme }) => theme.colors.page_background};
    .custom__placeholder {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.primary_blue};
    }
    .custom__single-value {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.primary_blue};
    }
    .custom__indicator-separator {
      display: none;
    }
  }
`;
export const SearchSelect = styled(AsyncSelect)`
  width: 100%;
  margin: 5px 0;
  padding: 0 5px;
  .custom__control {
    height: 50px;
    background-color: ${({ theme }) => theme.colors.page_background};
    border: 1px solid ${({ theme }) => theme.colors.page_background};
    .custom__placeholder {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.primary_blue};
    }
    .custom__single-value {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.primary_blue};
    }
    .custom__indicator-separator {
      display: none;
    }
  }
`;
export const CreateSelect = styled(Creatable)`
  width: 100%;
  margin: 5px 0;
  padding: 0 5px;
  .custom__control {
    height: 50px;
    background-color: ${({ theme }) => theme.colors.page_background};
    border: 1px solid ${({ theme }) => theme.colors.page_background};
    .custom__placeholder {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.primary_blue};
    }
    .custom__single-value {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.primary_blue};
    }
    .custom__indicator-separator {
      display: none;
    }
  }
`;
export const ReceiptSaveButton = styled.button`
  position: relative;
  background-color: ${({ theme }) => theme.colors.primary_red};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius_m};
  border: none;
  height: 50px;
  padding: 0;
  margin: 15px 5px 5px 5px;
  width: 96%;
`;
export const ReceiptSaveButtonIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(12, 0, 0, 0.15);
  width: 60px;
  height: 35px;
  margin: 0;
  padding: 15px 0 0 0;
  border-radius: ${({ theme }) => theme.border.radius_m};
`;
export const EditButton = styled.button`
  border: none;
  border-radius: ${({ theme }) => theme.border.radius_s};
  padding: 3px 5px 0px 5px;
  margin: ${(props) => props.margin};
`;
export const EditCheckBank = styled.input`
  margin: 5px 5px;
  height: 46px;
  width: 85%;
  border-radius: 5px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary_blue};
  background-color: ${({ theme }) => theme.colors.page_background};
  border: 1px solid ${({ theme }) => theme.colors.page_background};
`;

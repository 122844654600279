import styled from 'styled-components';

export const Chart = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius_m};
  margin: 20px ${(props) => props.marginRight || 0} 0 0;
  height: 96%;
`;

export const ChartHeading = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.tertiary_blue};
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondary_background};
  padding: 10px 20px;
  line-height: 40px;
`;

export const ChartBody = styled.div`
  padding: 20px;
`;

import styled, { createGlobalStyle } from 'styled-components';

import normalize from './normalize';

export const GlobalStyles = createGlobalStyle`
${normalize}
body {
    background: ${({ theme }) => theme.colors.body};
    color: ${({ theme }) => theme.colors.text};
    font-family: 'Archivo', sans-serif;
    box-sizing: border-box;
    overflow-x: hidden;
    a:link{
      text-decoration: none;
    }
  }
`;

export const PageLayout = styled.div`
  background-color: ${({ theme }) => theme.colors.page_background};;
  border-top-left-radius: 60px;
  margin-top: 20px;
  height: 100%;
  padding: 30px 50px;
`;

export const PageHeading = styled.h1`
  font-family: 'Playfair Display', sans-serif;
  color: #474e73;
`;

export const PageSubHeading = styled.h3`
  color: ${({ theme }) => theme.colors.primary_blue};
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 12px;
`;

export const AccountingPageLayout = styled.div`
  background-color: ${({ theme }) => theme.colors.page_background};
  padding: 30px 50px;
  height: 100%;
`;
export const MobilePageLayout = styled.div`
  position: absolute;
  width: 100%;
  min-height: 100vh;
  border: 1px solid #E4E8F4;
  box-sizing: border-box;
  padding: 20px 10px;
  background-color: ${({ theme }) => theme.colors.page_background};
`;
export const MobilePageHeading = styled.h2`
  font-family: 'Playfair Display', sans-serif;
  color: #474e73;
  margin: 5px;
`;

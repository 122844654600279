import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const BapstismContainers = styled.div`
  width: 100%;
  padding: 25px;
  text-align: center;
  border-right: 1px solid ${({ theme }) => theme.colors.secondary_background};
  border-bottom: 2px solid ${(props) => props.borderBottom || '#E4E8F4'};
`;
export const TrasnferContainers = styled(BapstismContainers)`
  border-bottom: 2px solid ${(props) => props.borderBottom || '#E4E8F4'};
`;

export const MarriageContainers = styled(BapstismContainers)`
  border-bottom: 2px solid ${(props) => props.borderBottom || '#E4E8F4'};
`;

export const MenuContainer = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  border-top-left-radius: ${({ theme }) => theme.border.radius_l};
  border-top-right-radius: ${({ theme }) => theme.border.radius_l};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary_blue};
  font-family: Playfair Display;
  font-weight: bold;
  font-size: 18px;
`;

export const IconStyle = styled.img`
  margin: auto;
`;

export const Labels = styled.div`
  margin-top: 15px;
`;
export const WhiteBodySection = styled.div`
  // padding: 10px 0px;
  background-color: ${({ theme }) => theme.colors.white};
`;

import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const ActivityPanelWrapper = styled.div`
  margin: 30px 0;
  padding: 0;
`;

export const Line = styled.div`
  height: 100%;
  margin-left: 49%;
  width: 1px;
  border-left: 1px dashed rgba(0, 0, 0, 0.2);
`;

export const ActivityIndex = styled.div`
  float: left;
  margin-top: 0;
  background-color: ${({ theme }) => theme.colors.secondary_background};
  padding: 10px 15px;
  border-top-left-radius: ${({ theme }) => theme.border.radius_m};
  border-bottom-right-radius: ${({ theme }) => theme.border.radius_m};
  font-size: 12px;
  height: 12px;
`;

export const Date = styled.div`
  font-size: 14px;
  margin: 0 0 15px 10px;
`;
export const Content = styled.div`
  font-size: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  border-top-left-radius: ${({ theme }) => theme.border.radius_m};
  border-top-right-radius: ${({ theme }) => theme.border.radius_m};
`;
export const Body = styled.div`
  padding: 35px 30px 15px 50px;
`;
export const Footer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.page_background};
  border-bottom-left-radius: ${({ theme }) => theme.border.radius_m};
  border-bottom-right-radius: ${({ theme }) => theme.border.radius_m};
  background-color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
`;

export const Entity = styled.div`
  color: rgba(57, 62, 92, 0.6);
  padding: 10px 12px;
`;

export const Owner = styled.div`
  text-align: right;
  color: ${({ theme }) => theme.colors.light_grey};
  padding: 10px 12px;
  font-weight: 600;
`;

/* eslint-disable consistent-return */
import { useEffect, useReducer, useState } from 'react';
import Cookies from 'js-cookie';

export const fetcher = async (config) => {
  const {
    url,
    method = 'GET',
    header,
    body,
    multiPart = false,
    authTokenKey = 'auth_token',
    unauthorizedStatusCodes = [401, 403],
    unauthorizedRedirectPath = '/login',
  } = config;
  const authToken = Cookies.get(authTokenKey);
  const options = {
    method,
    headers: !multiPart ? {
      'Content-type': 'application/json; charset=UTF-8',
      ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
      ...(header ? { ...header } : {}),
    } : {
      ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
      ...(header ? { ...header } : {}),
    },
    body: method === 'POST' || method === 'PUT' ? body : null,
  };
  return fetch(url, options)
    .then(async (res) => {
      if (!res.ok) {
        if (
          unauthorizedStatusCodes.includes(res.status)
            && window.location.pathname !== unauthorizedRedirectPath
        ) {
          Cookies.remove(authTokenKey);
          window.location.replace(unauthorizedRedirectPath);
          return;
        }
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
          status: res.status,
          info: await res
            .clone()
            .json()
            .catch(() => res.text()),
        });
      }
      return Promise.resolve(
        await res
          .clone()
          .json()
          .catch(() => res.text()),
      );
    })
    .catch((error) => Promise.reject(error));
};
export const fileDownloader = async (config) => {
  const {
    url,
    method = 'GET',
    header,
    body,
    multiPart = false,
    authTokenKey = 'auth_token',
    unauthorizedStatusCodes = [401, 403],
    unauthorizedRedirectPath = '/login',
  } = config;
  const authToken = Cookies.get(authTokenKey);
  const options = {
    method,
    headers: !multiPart ? {
      'Content-type': 'application/json; charset=UTF-8',
      ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
      ...(header ? { ...header } : {}),
    } : {
      ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
      ...(header ? { ...header } : {}),
    },
    body: method === 'POST' || method === 'PUT' ? body : null,
  };
  return fetch(url, options)
    .then(async (res) => {
      if (!res.ok) {
        if (
          unauthorizedStatusCodes.includes(res.status)
            && window.location.pathname !== unauthorizedRedirectPath
        ) {
          Cookies.remove(authTokenKey);
          window.location.replace(unauthorizedRedirectPath);
          return;
        }
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
          status: res.status,
          info: await res
            .clone()
            .arrayBuffer()
            .catch(() => res.text()),
        });
      }
      return Promise.resolve(
        await res
          .clone()
          .arrayBuffer()
          .catch(() => res.text()),
      );
    })
    .catch((error) => Promise.reject(error));
};
const reducer = () => (state, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case 'PENDING':
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case 'SUCCESS': {
      return {
        isLoading: false,
        isError: false,
        error: undefined,
        data: action.payload,
      };
    }
    case 'FAILED':
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.error,
      };
  }
};
export const useFetch = (config, lazy = false) => {
  const [lazyFetch, setlazyFetch] = useState(lazy);
  const [options] = useState({ ...config, resetKey: 0 });
  const [state, dispatch] = useReducer(reducer(), {
    isLoading: !lazy,
    isError: false,
    data: undefined,
    error: undefined,
  });
  useEffect(() => {
    let cancel = false;
    const fetchData = async () => {
      dispatch({ type: 'PENDING' });
      const search = options.search ? `?${new URLSearchParams(options.search).toString()}` : '';
      return fetcher({ ...options, url: `${options.url}${search}` }).then(
        (result) => {
          if (!cancel) {
            dispatch({ type: 'SUCCESS', payload: result });
          }
        },
        (error) => {
          dispatch({ type: 'FAILED', error });
        },
      );
    };
    if (!lazyFetch) {
      fetchData();
    } else {
      dispatch({ type: 'SUCCESS', payload: undefined });
      setlazyFetch(false);
    }
    return () => {
      cancel = true;
    };
  }, [options.url, options.resetKey]);
  return {
    ...state,
    options,
  };
};

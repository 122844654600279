import React, { useState } from 'react';
import PropTypes from 'prop-types';
import tick from '../../../../assets/icons/tick-white.svg';
import close from '../../../../assets/icons/close-icon.svg';
import { Row, Col } from '../../../styled_components/grid/Grid.style';
import {
  StyledModal,
  SaveButton,
  SaveButtonIcon,
  ModalWrapper,
  ModalHeading,
  CloseButton,
  CloseIcon,
  AddressBar,
} from './deleteModal.style';

const DeleteModal = ({ isOpen, toggleDeleteModal, handleDeleteSubmit }) => {
  const [reason, setReason] = useState('');

  return (
    <StyledModal isOpen={isOpen}>
      <CloseButton type="button" onClick={() => { toggleDeleteModal(); setReason(''); }}>
        <CloseIcon>
          <img src={close} alt="arrow" height="16px" />
        </CloseIcon>
      </CloseButton>
      <ModalWrapper>
        <Row>
          <Col col={12}>
            <ModalHeading>Delete Transaction</ModalHeading>
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <Row>
              <AddressBar
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                placeholder="Type the reason for deleting the transaction."
              />
            </Row>
          </Col>
        </Row>
        <Row>
          <Col col={8} />
          {reason && (
            <Col col={4}>
              <SaveButton
                type="button"
                onClick={() => {
                  toggleDeleteModal();
                  handleDeleteSubmit(reason);
                  setReason('');
                }}
              >
                Confirm
                <SaveButtonIcon>
                  <img src={tick} alt="arrow" height="16px" />
                </SaveButtonIcon>
              </SaveButton>
            </Col>
          )}
        </Row>
      </ModalWrapper>
    </StyledModal>
  );
};
DeleteModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleDeleteModal: PropTypes.func,
  handleDeleteSubmit: PropTypes.func,
};

export default DeleteModal;

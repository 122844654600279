import styled from 'styled-components';

export const ListHeading = styled.h3`
  font-family: 'Playfair Display', serif;
  color: ${({ theme }) => theme.colors.primary_blue};
  margin: 30px 5px 0px 0px;
`;
export const ContributionInputWrap = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 10px 15px;
  border-radius: ${({ theme }) => theme.border.radius_m};
  margin-top: 10px;
`;
export const ContributionDisplayButton = styled.button`
  position: relative;
  background-color: ${({ theme }) => theme.colors.primary_red};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius_m};
  border: none;
  height: 45px;
  margin: 10px 0px;
  display: block;
  width: 100%;
`;
export const ContributionDisplayButtonIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(12, 0, 0, 0.15);
  width: 70px;
  height: 30px;
  margin: 0;
  padding: 15px 0 0 0;
  border-radius: ${({ theme }) => theme.border.radius_m};
`;

export const TotalAmtDiv = styled.div`
  height: 50px;
  margin-bottom: 50px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius_m};
`;
export const Total = styled.div`
  font-size: 18px;
  line-height: 50px;
  text-align: right;
  margin-right: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary_blue};
`;
export const DownloadButton = styled.button`
  position: relative;
  font-size: ${({ theme }) => theme.fonts.font_size.big_font};
  font-weight: ${({ theme }) => theme.fonts.font_weight.normal_font};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary_blue};
  border-radius: ${({ theme }) => theme.border.radius_m};
  border: 1px solid  ${({ theme }) => theme.colors.secondary_background};
  height: 40px;
  padding: 0;
  margin: 5px 10px;
  width: 100%;
  
`;
export const DownloadButtonIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  border: 1px solid ${({ theme }) => theme.colors.secondary_background};
  border-radius: 50px;
  height: 20px;
  width: 20px;
  margin: 8px;
`;
export const Image = styled.img`
  margin-top: 3px;
`;

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import Cookies from 'js-cookie';
import {
  Row,
  Col,
} from '../../../components/styled_components/grid/Grid.style';
import OrganisationMenu from '../../../components/organisation/menu';
import Breadcrumbs from '../../../components/breadcrumbs';
import { AccountingPageLayout, PageHeading } from '../../../theme/GlobalStyles';
import CreateTable from '../../../components/organisation/create_table';
import tick from '../../../assets/icons/tick-white.svg';
import editIcon from '../../../assets/icons/pen.svg';
import tickIcon from '../../../assets/icons/tick.svg';
import closeIcon from '../../../assets/icons/close-icon.svg';
import {
  OptionsWrapperDiv,
  CustomSelect,
  CheckNumber,
  StyledDate,
  PaymentSaveButton,
  PaymentSaveButtonIcon,
  CreateSelect,
  EditButton,
  EditVendor,
} from './organisationpayments.style';
import { fetcher, useFetch } from '../../../hooks/useFetch';
import { ACCT_API } from '../../../constants';
import Loader from '../../../components/styled_components/loader/loader.style';

const breadcrumbs = ['Organisation', 'Church Payments'];
const options = [
  { label: 'Check', value: 'Check' },
  { label: 'Direct', value: 'Direct' },
  { label: 'Debit Card', value: 'Debit Card' },
];
const OrganisationPayments = () => {
  const [selectVendor, setSelectVendor] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState('Check');
  const [vendorDisplayName, setVendorDisplayName] = useState();
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [checkNumber, setCheckNumber] = useState();
  const [items, setItems] = useState();
  const [loading, setLoading] = useState(false);
  const [button, setButton] = useState(false);
  const minDate = Cookies.get('Org_accountant_year');
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const [editedVendor, setEditedVendor] = useState();
  const [vendorEdit, setVendorEdit] = useState(false);
  const [organization, setOrganization] = useState();
  const today = moment().format('YYYY-MM-DD');
  const bankAccount = 'Organization Bank Account1';
  const account = 'Organization Bank Account2';
  const org = Cookies.get('organization').split(',');
  const org_values = org.map((item) => item.split('-'));
  const org_val = org_values.map((item) => item[1]);
  const values = org_val.map((item) => ({
    value: item,
    label: item,
  }));
  const handleLineItems = (value) => {
    setItems(
      value.map((item) => ({
        accountName: account,
        rate: item.amount,
        particulars: item.particular,
      })),
    );
  };
  const vendor = useFetch({
    url: `${ACCT_API.GET_VENDOR}`,
    method: 'GET',
    authTokenKey: 'auth_token',
  });
  if (vendor.data && selectVendor.length === 0) {
    setSelectVendor(
      vendor.data.response.map((item) => ({
        value: item.displayName,
        label: item.displayName,
        editIcon: <EditButton type="button" onClick={() => { setEditedVendor(item); setVendorEdit(true); }}><img src={editIcon} alt="edit" /></EditButton>,
      })),
    );
  }
  const getMaxDate = () => {
    let maxDate;
    if (today.substring(0, 4) === minDate) {
      maxDate = today;
    } else {
      maxDate = `${minDate}-12-31`;
    }
    return maxDate;
  };
  const handleSubmit = async () => {
    let payload = {
      paymentDate: date,
      bankAccount,
      paymentMethod,
      vendorDisplayName,
      organization,
      lineItems: items,
    };
    if (paymentMethod === 'Check') {
      payload = {
        ...payload,
        checkNumber,
      };
    }
    if (vendorDisplayName === '' || vendorDisplayName === undefined) {
      toast.error('Error! Please Select a vendor ', { autoClose: 3000 });
    } else if (items[0].accountName === '') {
      toast.error('Error! Please add category ', { autoClose: 3000 });
    } else if
    (items[0].rate === '') {
      toast.error('Error! Please add an amount ', { autoClose: 3000 });
    } else if
    (paymentMethod === 'Check' && (checkNumber === '' || checkNumber === undefined)) {
      toast.error('Error! Please add Check Number ', { autoClose: 3000 });
    } else {
      setLoading(true);
      setButton(true);
      try {
        const res = await fetcher({
          url: `${ACCT_API.CREATE_ORG_PAYMENT}`,
          method: 'POST',
          authTokenKey: 'auth_token',
          body: JSON.stringify(payload),
        });
        if (res.success) {
          toast.success('Successfully Created!', { autoClose: 3000 });
          setLoading(false);
          setTimeout(() => {
            setButton(false);
            window.location.reload();
          }, 2000);
        } else {
          toast.error('Error!', { autoClose: 3000 });
          setButton(false);
          setLoading(false);
        }
      } catch (e) {
        toast.error('Error!', { autoClose: 3000 });
        setButton(false);
        setLoading(false);
      }
    }
  };
  const getVendor = async () => {
    try {
      const res = await fetcher({
        url: `${ACCT_API.GET_VENDOR}`,
        method: 'GET',
        authTokenKey: 'auth_token',
      });
      if (res) {
        setSelectVendor(
          res.response.map((item) => ({
            value: item.displayName,
            label: item.displayName,
            editIcon: <EditButton type="button" onClick={() => { setEditedVendor(item); setVendorEdit(true); }}><img src={editIcon} alt="edit" /></EditButton>,
          })),
        );
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };
  const createVendor = async (value) => {
    try {
      const res = await fetcher({
        url: `${ACCT_API.CREATE_VENDOR}`,
        method: 'POST',
        authTokenKey: 'auth_token',
        body: JSON.stringify({ full_name: value }),
      });
      if (res.success) {
        toast.success('Successfully Created!', { autoClose: 3000 });
        getVendor();
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };
  const handleEdit = async () => {
    const payload = {
      vendorId: editedVendor.id,
      vendorName: editedVendor.displayName,
    };
    try {
      const res = await fetcher({
        url: `${ACCT_API.EDIT_VENDOR}`,
        method: 'POST',
        authTokenKey: 'auth_token',
        body: JSON.stringify(payload),
      });
      if (res.success) {
        toast.success('Successfully Edited!', { autoClose: 3000 });
        setVendorEdit(false);
        getVendor();
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };
  return (
    <>
      <OrganisationMenu />
      <AccountingPageLayout>
        {loading ? <Loader /> : null }
        <PageHeading>Church Payments</PageHeading>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <OptionsWrapperDiv>
          <Row>
            <Col col={12}>
              <Row>
                <Col col={2}>
                  <StyledDate
                    width="95%"
                    type="date"
                    min={`${minDate}-01-01`}
                    max={getMaxDate()}
                    placeholder="Check Number"
                    onKeyDown={(e) => e.preventDefault()}
                    onChange={(e) => setDate(e.target.value)}
                  />
                </Col>
                <Col col={2}>
                  <CustomSelect
                    className="select"
                    classNamePrefix="custom"
                    options={values}
                    placeholder="Organization"
                    onChange={(e) => setOrganization(e.value)}
                  />
                </Col>
                <Col col={2}>
                  <CustomSelect
                    className="select"
                    classNamePrefix="custom"
                    defaultValue={{ label: 'Check', value: 'Check' }}
                    options={options}
                    placeholder="Payment Methods"
                    onChange={(e) => setPaymentMethod(e.value)}
                  />
                </Col>
                {paymentMethod === 'Check'
                  ? (
                    <Col col={2}>
                      <CheckNumber
                        type="text"
                        placeholder="Check Number"
                        onChange={(e) => setCheckNumber(e.target.value)}
                      />
                    </Col>
                  ) : null}
                <Col col={2}>
                  {vendorEdit ? (
                    <Row>
                      <Col col={10}>
                        <EditVendor type="text" value={editedVendor.displayName} onChange={(e) => setEditedVendor({ ...editedVendor, displayName: e.target.value })} />
                      </Col>
                      <Col col={2} padding="4px 0 0 0">
                        <EditButton margin="3px 0px" type="button" onClick={() => handleEdit()}><img src={tickIcon} alt="save" /></EditButton>
                        <EditButton margin="3px 0px" type="button" onClick={() => { setVendorEdit(false); setEditedVendor(); }}><img src={closeIcon} alt="cancel" /></EditButton>
                      </Col>
                    </Row>
                  )
                    : (
                      <CreateSelect
                        className="select"
                        classNamePrefix="custom"
                        getOptionLabel={(e) => (
                          <Row>
                            <Col col={10}>{e.label}</Col>
                            <Col col={2}>{e.editIcon}</Col>
                          </Row>
                        )}
                        onCreateOption={(value) => createVendor(value)}
                        options={selectVendor}
                        placeholder="Paid To"
                        onChange={(e) => setVendorDisplayName(e.value)}
                      />
                    )}
                </Col>
              </Row>
            </Col>
          </Row>
        </OptionsWrapperDiv>
        <CreateTable
          categories={values}
          handleLineItems={handleLineItems}
          formatter={formatter}
        />
        <Row>
          <Col col={10} />
          <Col col={2}>
            <PaymentSaveButton type="button" disabled={button} onClick={() => handleSubmit()}>
              Submit
              <PaymentSaveButtonIcon>
                <img src={tick} alt="arrow" height="16px" />
              </PaymentSaveButtonIcon>
            </PaymentSaveButton>
          </Col>
        </Row>
      </AccountingPageLayout>
    </>
  );
};
export default OrganisationPayments;

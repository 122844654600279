import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const ContactLayout = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 10px 40px;
  width: 100%;
`;

export const Panel = styled.div`
  width: 100%;
  border-radius: ${({ theme }) => theme.border.radius_m};
  margin: 20px ${(props) => props.marginRight || 0} 20px 0;
`;

export const PanelBody = styled.div`
  margin-left: 20px;
  .FieldCol {
    border: 1px solid ${({ theme }) => theme.colors.grey_blue};
    border-radius: ${({ theme }) => theme.border.radius_m};
  }
  .addMargin {
    margin-right: 17px;
    margin-left: 17px;
  }
`;

export const FieldLabel = styled.div`
  color: ${({ theme }) => theme.colors.light_grey};
  font-size: 12px;
  margin: 13px 22px 0px;
  opacity: 0.5;
`;
export const FieldValue = styled.input`
  color: ${({ theme }) => theme.colors.primary_blue};
  background: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fonts.font_size.big_font};
  font-weight: ${({ theme }) => theme.fonts.font_weight.bold_font};
  margin: 5px 22px 8px;
  outline: none;
  border: none;
  width: 90%;
`;

export const Div = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grey_blue};
  border-radius: ${({ theme }) => theme.border.radius_m};
`;
export const Required = styled.span`
  color: ${({ theme }) => theme.colors.primary_red};
  font-size: ${({ theme }) => theme.fonts.font_size.big_font};
  font-weight: ${({ theme }) => theme.fonts.font_weight.bold_font};
  margin: 15px 0 0 10px;
`;

import styled from 'styled-components';

export const Table = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius_m};
  margin-right: ${(props) => props.marginRight || 0};
  margin-top: 20px;
`;

export const TableHeading = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.tertiary_blue};
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondary_background};
  padding: 10px 20px;
  line-height: 40px;
`;

export const ViewButton = styled.button`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey};
  border-radius: ${({ theme }) => theme.border.radius_s};
  color: ${({ theme }) => theme.colors.tertiary_blue};
  float: right;
  font-size: 14px;
  padding: 10px 20px;
`;

export const TableBody = styled.div`
  padding: 10px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondary_background};
  color: ${({ theme }) => theme.colors.primary_blue};
`;

export const Date = styled.div`
  background: ${({ theme }) => theme.colors.page_background};
  padding: 10px;
  border-radius: ${({ theme }) => theme.border.radius_m};
  text-align: center;
  color: ${({ theme }) => theme.colors.primary_blue};
`;

export const Month = styled.div`
  text-transform: uppercase;
  opacity: 0.5;
`;

export const Day = styled.div`
`;

export const Cell = styled.div`
  text-align: left;
  padding: 10px 20px;
`;

export const Label = styled.div`
  opacity: 0.5;
  margin-bottom: 2px;
`;

export const PaymentTypeValue = styled.div`
  font-weight: 600;
`;

export const Receipt = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grey};
  border-radius: ${({ theme }) => theme.border.radius_s};
  text-align: center;
  padding: 5px 10px;
  margin-top: 20px;
`;

export const Amount = styled.div`
  margin-top: 20px;
  text-align: right;
`;

export const Dollar = styled.span`
  font-weight: 600;
  font-size: 20px;
  margin-right: 5px;
  color: ${({ theme }) => theme.colors.grey_blue};
`;

export const AmountValue = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary_blue};
`;

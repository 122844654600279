import React from 'react';
import PropTypes from 'prop-types';
import PanelHeading from '../../membership_components/panel_heading';
import { Row, Col } from '../../styled_components/grid/Grid.style';
import RadioButtons from '../../common/radio_buttons';
import {
  Panel,
  PanelBody,
  FieldLabel,
  FieldValue,
  Div,
  BasicInfoCustomSelect,
  MarriageInfoLayout,
  MarriageSearchSelect,
  UploadButton,
  MarriageNote,
  Required,
} from './MarriageInfo.style';

const MarriageInfo = ({
  formType,
  getName,
  handleChurch1,
  headOfFamily1,
  relation,
  handleChurch2,
  headOfFamily2,
  setRoleId,
  handleSelectChange,
  select,
  setDom,
  roleId,
  handleChange,
  uploadTc, setTransfercert,
}) => (

  <>
    <MarriageInfoLayout>
      <Row>
        <Panel marginRight="20px">
          <PanelHeading label="Marriage Information" />
          <MarriageNote>
            Spouse 2 Will be transferred to Family of Spouse 1
          </MarriageNote>
          <PanelBody>
            <Row marginBottom="17px">
              <Col col={4}>
                <Div className="addMargin">
                  <FieldLabel>Date Of Wedding</FieldLabel>
                  <FieldValue type="date" placeholder="Date of wedding" onChange={(e) => setDom(e.target.value)} />
                </Div>
              </Col>
              <Col col={4}>
                <Div className="addMargin">
                  <MarriageSearchSelect
                    className="select"
                    classNamePrefix="custom"
                    placeholder="Enter Name or Church ID (Spouse 1)"
                    loadOptions={(value) => getName(value)}
                    onChange={(e) => {
                      handleChurch1(e.value);
                    }}
                  />
                </Div>
              </Col>
              <Col col={4}>
                <Div className="addMargin">
                  <FieldLabel>
                    Head Of Family (Spouse 1)
                    <Required>*</Required>
                  </FieldLabel>
                  <FieldValue disabled type="text" value={headOfFamily1} />
                </Div>
              </Col>
            </Row>
            <Row marginBottom="17px">
              {formType === 'existing' ? (
                <>
                  <Col col={4}>
                    <Div className="addMargin">
                      <MarriageSearchSelect
                        className="select"
                        classNamePrefix="custom"
                        placeholder="Enter Name or Church ID (Spouse 2)"
                        loadOptions={(value) => getName(value)}
                        onChange={(e) => {
                          handleChurch2(e.value);
                        }}
                      />
                    </Div>
                  </Col>
                  <Col col={4}>
                    <Div className="addMargin">
                      <FieldLabel>
                        Head Of Family (Spouse 2)
                        <Required>*</Required>
                      </FieldLabel>
                      <FieldValue disabled type="text" value={headOfFamily2} />
                    </Div>
                  </Col>
                </>
              ) : (
                ''
              )}
              <Col col={4}>
                <Div className="addMargin">
                  <BasicInfoCustomSelect
                    className="select"
                    classNamePrefix="custom"
                    options={relation}
                    value={roleId && relation.find((i) => i.value === roleId)}
                    onChange={(e) => setRoleId(e.value)}
                    placeholder="Relation With Head of Family"
                  />
                </Div>
              </Col>

            </Row>

            {formType === 'new' ? (
              <>
                <Row marginBottom="17px">
                  <Col col={4}>
                    <Div className="addMargin">
                      <FieldLabel>
                        Title
                        <Required>*</Required>
                      </FieldLabel>
                      <FieldValue
                        placeholder="Mr./Ms./Mrs/Master/Miss"
                        type="text"
                        name="title"
                        onChange={handleChange}
                      />
                    </Div>
                  </Col>
                  <Col col={4}>
                    <Div className="addMargin">
                      <FieldLabel>
                        First Name (Spouse 2)
                        <Required>*</Required>
                      </FieldLabel>
                      <FieldValue
                        type="text"
                        name="firstName"
                        onChange={handleChange}
                      />
                    </Div>
                  </Col>
                  <Col col={4}>
                    <Div className="addMargin">
                      <FieldLabel>
                        Middle Name (Spouse 2)
                      </FieldLabel>
                      <FieldValue
                        type="text"
                        name="middleName"
                        onChange={handleChange}
                      />
                    </Div>
                  </Col>
                </Row>
                <Row marginBottom="17px">
                  <Col col={4}>
                    <Div className="addMargin">
                      <FieldLabel>
                        Last Name (Spouse 2)
                        <Required>*</Required>
                      </FieldLabel>
                      <FieldValue
                        type="text"
                        name="lastName"
                        onChange={handleChange}
                      />
                    </Div>
                  </Col>
                  <Col col={4}>
                    <Div className="addMargin">
                      <FieldLabel>
                        Date Of Birth (Spouse 2)
                        <Required>*</Required>
                      </FieldLabel>
                      <FieldValue
                        type="date"
                        placeholder="DOB"
                        name="dob"
                        onChange={handleChange}
                      />
                    </Div>
                  </Col>
                  <Col col={4}>
                    <RadioButtons
                      label1="Male"
                      value1="male"
                      label2="Female"
                      value2="female"
                      marginTop="5px"
                      handleSelectChange={handleSelectChange}
                      select={select}
                    />
                  </Col>
                </Row>
                <Row marginBottom="17px">
                  <Col col={4}>
                    <Div className="addMargin">
                      <FieldLabel>
                        Marriage Certificate issued by
                      </FieldLabel>
                      <FieldValue
                        type="text"
                        name="tcIssuedBy"
                        onChange={handleChange}
                      />
                    </Div>
                  </Col>
                  <Col col={5}>
                    <Div className="addMargin">
                      <FieldLabel>
                        Upload Marriage Certificate
                      </FieldLabel>
                      <FieldValue type="file" onChange={(e) => setTransfercert(e.target.files[0])} />
                    </Div>
                  </Col>
                  <Col col={3}>
                    <UploadButton type="button" onClick={() => uploadTc()}>Upload</UploadButton>
                  </Col>
                </Row>
                <Row marginBottom="17px">
                  <Col col={4}>
                    <Div className="addMargin">
                      <FieldLabel>Comments</FieldLabel>
                      <FieldValue
                        type="text"
                        name="notes"
                        onChange={handleChange}
                      />
                    </Div>
                  </Col>
                </Row>
              </>
            ) : (
              <Row marginBottom="17px">
                <Col col={4}>
                  <Div className="addMargin">
                    <FieldLabel>Comments</FieldLabel>
                    <FieldValue
                      type="text"
                      name="notes"
                      onChange={handleChange}
                    />
                  </Div>
                </Col>
              </Row>
            )}
          </PanelBody>
        </Panel>
      </Row>
    </MarriageInfoLayout>
  </>
);
MarriageInfo.propTypes = {
  formType: PropTypes.string,
  getName: PropTypes.func,
  headOfFamily1: PropTypes.string,
  handleChurch1: PropTypes.func,
  headOfFamily2: PropTypes.string,
  handleChurch2: PropTypes.func,
  relation: PropTypes.array,
  setRoleId: PropTypes.string,
  handleSelectChange: PropTypes.func,
  select: PropTypes.string,
  setDom: PropTypes.string,
  handleChange: PropTypes.func,
  uploadTc: PropTypes.func,
  setTransfercert: PropTypes.func,
  roleId: PropTypes.string,
};

export default MarriageInfo;

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { saveAs } from 'file-saver';
import Breadcrumbs from '../../../components/breadcrumbs';
import MemberReportsMenu from '../../../components/member_reports/menu';
import { AccountingPageLayout, PageHeading, PageSubHeading } from '../../../theme/GlobalStyles';
import arrowRightIcon from '../../../assets/icons/arrow-right.svg';
import {
  Row,
  Col,
} from '../../../components/styled_components/grid/Grid.style';
import {
  PrayerGroupDiv,
  DisplayButton,
  DisplayButtonIcon,
  SubscriptionReportsCustomSelect,
  DownloadButton,
  DownloadButtonIcon,
  DownloadImage,
} from './prayerGroup.style';
import Table from '../../../components/common/table';
import { useFetch, fetcher, fileDownloader } from '../../../hooks/useFetch';
import Loader from '../../../components/styled_components/loader/loader.style';
import { API } from '../../../constants';
import downloadArrow from '../../../assets/icons/download.svg';

const breadcrumbs = ['Reports', 'Prayer Group'];
const PrayerGroup = () => {
  const navigate = useNavigate();
  const [selectedGroup] = useState([{ value: 'all', label: 'All' }]);
  const [selectedValue, setSelectedValue] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState('');
  const group = useFetch({
    url: API.GET_PRAYER_GROUP,
    method: 'GET',
    authTokenKey: 'auth_token',
  });
  if (group.data && selectedGroup.length === 1) {
    group.data.area_list
      .filter((i) => i.areaName !== '')
      .map((item) => selectedGroup.push({
        value: item.areaName.replace('&', '%26'),
        label: item.areaName,
      }));
  }

  const handleDisplay = async () => {
    setLoading(true);
    setData([]);
    setText('');
    try {
      const response = await fetcher({
        url: `${API.PRAYER_GROUP_LIST}?prayerGroup=${selectedValue}`,
        method: 'GET',
        authTokenKey: 'auth_token',
      });
      if (response) {
        setLoading(false);
        setData(response.prayer_group_list);
      } else {
        setLoading(false);
        setText('No Data');
        toast.warn('No Data', { autoClose: 3000 });
      }
    } catch (e) {
      setLoading(false);
      toast.error('Error!', { autoClose: 3000 });
    }
  };

  const handleClick = (id) => {
    navigate(`/members/details/${id}`);
  };

  const tableLookup = [
    {
      heading: 'Membership ID',
      field_name: 'membershipId',
      type: 'string',
    },
    {
      heading: 'Name',
      field_name: 'name',
      type: 'link',
    },
    {
      heading: 'Prayer Group',
      field_name: 'prayerGroup',
      type: 'string',
    },
  ];

  const downloadReport = async () => {
    try {
      const download = await fileDownloader({
        url: `${API.DOWNLOAD_PRAYER_GROUP_LIST}?prayerGroup=${selectedValue}`,
        method: 'GET',
        authTokenKey: 'auth_token',
      });
      if (download) {
        saveAs(
          new Blob([download], { type: 'application/vnd.ms-excel' }),
          'Prayer Group List.xlsx',
        );
      }
    } catch (error) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };

  return (
    <>
      <MemberReportsMenu />
      {loading ? <Loader /> : null}
      <AccountingPageLayout>
        <PageHeading>Prayer Group</PageHeading>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <PrayerGroupDiv>
          <Row>
            <Col col={6}>
              <SubscriptionReportsCustomSelect
                className="select"
                classNamePrefix="custom"
                options={selectedGroup}
                onChange={(e) => setSelectedValue(e.value)}
                placeholder="Select Prayer Group"
              />
            </Col>
            <Col col={3}>
              <DownloadButton type="button" onClick={() => downloadReport()}>
                Download
                <DownloadButtonIcon>
                  <DownloadImage src={downloadArrow} alt=">" height="13px" />
                </DownloadButtonIcon>
              </DownloadButton>
            </Col>
            <Col col={3}>
              <DisplayButton type="button" onClick={() => handleDisplay()}>
                Display
                <DisplayButtonIcon>
                  <img src={arrowRightIcon} alt=">" height="16px" />
                </DisplayButtonIcon>
              </DisplayButton>
            </Col>
          </Row>
        </PrayerGroupDiv>
        <Row>
          <Col col={1}>
            <PageSubHeading>{text}</PageSubHeading>
          </Col>
        </Row>
        {data.length > 0 && (
          <Table
            data={data}
            tableLookup={tableLookup}
            handleClick={handleClick}
          />
        )}
      </AccountingPageLayout>
    </>
  );
};

export default PrayerGroup;

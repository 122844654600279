import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from '../../../styled_components/grid/Grid.style';
import {
  SaveButton,
  ModalWrapper,
  ModalHeading,
  RemoveModals,
} from './removeModal.style';

const RemoveModal = ({
  isOpen,
  handleSubmit,
  toggleModal,
  confirmToggleModal,
}) => (
  <RemoveModals isOpen={isOpen}>
    <ModalWrapper>
      <Row>
        <Col col={12}>
          <ModalHeading> Are you sure you want to delete ?</ModalHeading>
        </Col>
      </Row>
      <Row>
        <Col col={6}>
          <SaveButton
            type="button"
            onClick={() => {
              toggleModal();
              handleSubmit();
            }}
          >
            Sure
          </SaveButton>
        </Col>
        <Col col={6}>
          <SaveButton
            type="button"
            onClick={() => {
              confirmToggleModal();
            }}
          >
            Cancel
          </SaveButton>
        </Col>
      </Row>
    </ModalWrapper>
  </RemoveModals>
);
RemoveModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  handleSubmit: PropTypes.func,
  confirmToggleModal: PropTypes.func,
};
export default RemoveModal;

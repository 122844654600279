import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import Cookies from 'js-cookie';
import {
  Col,
  Row,
} from '../../../components/styled_components/grid/Grid.style';
import ReportSubMenu from '../../../components/accounting/report_submenu';
import AccountingMenu from '../../../components/accounting/menu';
import {
  AccountingPageLayout,
  PageHeading,
  PageSubHeading,
} from '../../../theme/GlobalStyles';
import {
  DateInput,
  ReceiptsDiv,
  DisplayButton,
  DisplayButtonIcon,
  TotalAmtDiv,
  Total,
  DownloadButton,
  DownloadIcon,
  NoData,
} from './ChurchReceipts.style';
import Breadcrumbs from '../../../components/breadcrumbs';
import arrowRightIcon from '../../../assets/icons/arrow-right.svg';
import Download from '../../../assets/icons/download.svg';
import { fetcher, fileDownloader } from '../../../hooks/useFetch';
import { ACCT_API } from '../../../constants';
import Table from '../../../components/common/table';
import Loader from '../../../components/styled_components/loader/loader.style';
import ScrollDownButton from '../../../utils/ScrollDownButton';
import ScrollUpButton from '../../../utils/ScrollUpButton';

const breadcrumbs = ['Accounting', 'Reports', 'Church Reports'];
const tableLookup = [
  {
    heading: 'Membership ID',
    field_name: 'membershipId',
    type: 'string',
  },
  {
    heading: 'Member Name',
    field_name: 'memberName',
    type: 'string',
  },
  {
    heading: 'Date',
    field_name: 'date',
    type: 'date',
  },
  {
    heading: 'Category',
    field_name: 'lines',
    type: 'array',
  },
  {
    heading: 'Receipt Num',
    field_name: 'id',
    type: 'string',
  },
  {
    heading: 'Payment Method',
    field_name: 'paymentMethod',
    type: 'string',
  },
  {
    heading: 'Amount',
    field_name: 'lines',
    type: 'rate',
  },
];
const ChurchReceipts = () => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loading, setLoading] = useState(false);
  const [receipts, setReceipts] = useState();
  const [total, setTotal] = useState(0);
  const minDate = Cookies.get('accountant_year');

  const fetchReceipts = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await fetcher({
      method: 'GET',
      url: `${ACCT_API.GET_CHURCH_RECEIPTS}?startDate=${startDate}&endDate=${endDate}`,
      authTokenKey: 'auth_token',
    });
    if (res?.response) {
      const arr = res?.response.filter((x) => x.notes !== 'Voided');
      setReceipts(arr);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (receipts) {
      let sum = 0;
      for (const i of receipts) {
        sum += i.amount;
      }
      setTotal(sum);
    }
  }, [receipts]);

  const handleDownload = async () => {
    const res = await fileDownloader({
      url: `${ACCT_API.CHURCH_RECEIPTS_DOWNLOAD}?startDate=${startDate}&endDate=${endDate}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    saveAs(new Blob([res], { type: 'application/vnd.ms-excel' }), 'myExcel.xlsx');
  };
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return (
    <>
      <AccountingMenu />
      {loading ? <Loader /> : null}
      <ScrollUpButton />
      <ScrollDownButton />
      <AccountingPageLayout>
        <PageHeading>Reports</PageHeading>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <ReportSubMenu />
        <PageSubHeading>Church Receipts</PageSubHeading>
        <ReceiptsDiv>
          <form onSubmit={fetchReceipts}>
            <Row>
              <Col col={3}>
                <DateInput
                  type="date"
                  min={`${minDate}-01-01`}
                  max={`${minDate}-12-31`}
                  placeholder="Start Date"
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Col>
              <Col col={3}>
                <DateInput
                  type="date"
                  min={`${minDate}-01-01`}
                  max={`${minDate}-12-31`}
                  placeholder="Start Date"
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Col>
              <Col col={3} />
              <Col col={3}>
                <DisplayButton type="submit" disabled={!(startDate && endDate)}>
                  Display
                  <DisplayButtonIcon>
                    <img src={arrowRightIcon} alt=">" height="16px" />
                  </DisplayButtonIcon>
                </DisplayButton>
              </Col>
            </Row>
          </form>
        </ReceiptsDiv>
        { receipts && receipts.length > 0
          ? (
            <>
              <Table data={receipts} tableLookup={tableLookup} />
              <TotalAmtDiv>
                <DownloadButton onClick={handleDownload}>
                  Download
                  <DownloadIcon>
                    <img src={Download} alt="download" />
                  </DownloadIcon>
                </DownloadButton>
                <Total>
                  $
                  {formatter.format(total)}
                </Total>
              </TotalAmtDiv>
            </>
          ) : receipts && receipts.length === 0 ? (
            <NoData>
              No Data
            </NoData>
          ) : ''}
      </AccountingPageLayout>
    </>
  );
};

export default ChurchReceipts;

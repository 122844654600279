import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Col, Row } from '../../styled_components/grid/Grid.style';
import {
  Date,
  Day,
  Month, Amount, Dollar,
  Cell,
  Label,
  PaymentTypeValue, AmountValue,
  Table, TableBody, TableHeading,
} from './DashboardTable.style';
import RedDot from '../../../assets/icons/red-dot.svg';

const DashboardTable = ({
  heading, data, marginRight, formatter,
}) => (
  <>
    <Table marginRight={marginRight}>
      <TableHeading>
        <Row>
          <Col width="3%">
            <img src={RedDot} width={5} height={10} alt="." />
          </Col>
          <Col col={4}>{heading}</Col>
        </Row>
      </TableHeading>
      {data && data.map((row) => (
        <TableBody>
          <Row>
            <Col col={1}>
              <Date>
                <Month>{moment(row.Date).format('MMM')}</Month>
                <Day>{moment(row.Date).format('DD')}</Day>
              </Date>
            </Col>
            <Col col={5}>
              <Cell>
                <Label>PaymentType</Label>
                <PaymentTypeValue>{row.Category}</PaymentTypeValue>
              </Cell>
            </Col>
            <Col col={3}>
              <Cell>
                <Label>Payment Method</Label>
                <PaymentTypeValue>{row.Type}</PaymentTypeValue>
              </Cell>
            </Col>
            {/* <Col col={2}>
              <Receipt>#5484</Receipt>
            </Col> */}
            <Col col={3}>
              <Amount>
                <Dollar>$</Dollar>
                <AmountValue>{formatter.format(row.Amount)}</AmountValue>
              </Amount>
            </Col>
          </Row>
        </TableBody>
      ))}
    </Table>
  </>
);

DashboardTable.propTypes = {
  heading: PropTypes.string,
  data: PropTypes.array,
  formatter: PropTypes.func,
  marginRight: PropTypes.string,
};

export default DashboardTable;

import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  margin: 0vw 4vw;
`;

export const Logo = styled.img`
  margin-top: 20px;
  height: 157px;
  width: 157px;
`;

import React, { useState } from 'react';
import { Row, Col } from '../../styled_components/grid/Grid.style';
import {
  BapstismContainers,
  IconStyle,
  Labels,
  MarriageContainers,
  MenuContainer,
  TrasnferContainers,
} from './AddMemberMenu.style';
import BaptismIcon from '../../../assets/icons/baptism.svg';
import TransferIcon from '../../../assets/icons/transfer.svg';
import MarriageIcon from '../../../assets/icons/marriage.svg';
import ByBaptism from '../../../pages/add-member/by_baptism';
import ByTransfer from '../../../pages/add-member/by_transfer';
import ByMarriage from '../../../pages/add-member/by_marriage';

const AddMemberMenu = () => {
  const [page, setPage] = useState('baptism');
  return (
    <>
      <Row>
        <MenuContainer>
          <BapstismContainers
            borderBottom={page === 'baptism' ? 'red' : ''}
            onClick={() => {
              setPage('baptism');
            }}
          >
            <Col style={{ display: 'inline' }} col={4}>
              <Row>
                <IconStyle src={BaptismIcon} alt="bapt" />
              </Row>
              <Labels>By Baptism</Labels>
            </Col>
          </BapstismContainers>
          <TrasnferContainers
            borderBottom={page === 'transfer' ? 'red' : ''}
            onClick={() => {
              setPage('transfer');
            }}
          >
            <Col style={{ display: 'inline' }} col={4}>
              <Row>
                <IconStyle src={TransferIcon} alt="trans" />
              </Row>
              <Labels>By Transfer</Labels>
            </Col>
          </TrasnferContainers>
          <MarriageContainers
            borderBottom={page === 'marriage' ? 'red' : ''}
            onClick={() => {
              setPage('marriage');
            }}
          >
            <Col style={{ display: 'inline' }} col={4}>
              <Row>
                <IconStyle src={MarriageIcon} alt="marg" />
              </Row>
              <Labels>By Marriage</Labels>
            </Col>
          </MarriageContainers>
        </MenuContainer>
      </Row>
      <Row>
        {page === 'baptism' ? (
          <ByBaptism />
        ) : page === 'transfer' ? (
          <ByTransfer />
        ) : page === 'marriage' ? (
          <ByMarriage />
        ) : (
          ''
        )}
      </Row>
    </>
  );
};

export default AddMemberMenu;

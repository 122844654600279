import React from 'react';
import { Col, Row } from '../../../components/styled_components/grid/Grid.style';
import { MobilePageHeading, MobilePageLayout } from '../../../theme/GlobalStyles';
import MobileFamilyPanel from '../../../components/mobile_views/family_panel';
import { useFetch } from '../../../hooks/useFetch';
import { API } from '../../../constants';

const Family = () => {
  const { data } = useFetch(
    {
      url: API.GET_FAMILY,
      method: 'GET',
      authTokenKey: 'auth_token',
    },
  );
  return (
    <>
      <MobilePageLayout>
        <MobilePageHeading>Family</MobilePageHeading>
        <Row>
          {data && data.details.length > 0
            ? data.details.map((member, ind) => {
              if (ind % 2 === 0) {
                return (
                  <Col col={12}>
                    <MobileFamilyPanel member={member} />
                  </Col>
                );
              }
              return (
                <Col col={12}>
                  <MobileFamilyPanel member={member} />
                </Col>
              );
            })
            : null}
        </Row>
      </MobilePageLayout>
    </>
  );
};

export default Family;

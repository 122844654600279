import styled from 'styled-components';
import Select from 'react-select';

// eslint-disable-next-line import/prefer-default-export
export const PaymentSearchInputWrap = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 10px 12px;
  border-radius: ${({ theme }) => theme.border.radius_m};
`;
export const PaymentRowWrap = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 15px 15px;
  color: ${({ theme }) => theme.colors.primary_blue};
  margin-top: 10px;
  border-radius: ${({ theme }) => theme.border.radius_m};
`;

export const TransactionPaymentDisplayButton = styled.button`
  position: relative;
  font-family: 'Playfair Display', serif;
  background-color: ${({ theme }) => theme.colors.primary_red};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius_m};
  border: none;
  height: 50px;
  padding: 0;
  margin: 5px 20px;
  width: 85%;
`;

export const TransactionPaymentDisplayButtonIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(12, 0, 0, 0.15);
  width: 42px;
  height: 35px;
  margin: 0;
  padding: 15px 0 0 0;
  border-radius: ${({ theme }) => theme.border.radius_m};
`;
export const ActionsButton = styled.select`
  background: ${({ theme }) => theme.colors.page_background};
  border: none;
  border-radius: 6px;
  width: 25px;
  height: 25px;
`;

export const SearchInput = styled.input`
  background-color: ${({ theme }) => theme.colors.page_background};
  border-radius: ${({ theme }) => theme.border.radius_m};
  padding: 10px 15px;
  margin-top: 7px;
  border: none;
  height: 25px;
  width: 80%;
`;

export const TransactionPaymentsCustomSelect = styled(Select)`
  width: 95%;
  margin: 6px 0;
  padding: 0 5px;
  .custom__control {
    height: 48px;
    background-color: ${({ theme }) => theme.colors.page_background};
    border: 1px solid ${({ theme }) => theme.colors.page_background};
    border-radius: 10px;
    .custom__placeholder {
      font-size: 13px;
      color: ${({ theme }) => theme.colors.primary_blue};
    }
    .custom__single-value {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.primary_blue};
    }
    .custom__indicator-separator {
      display: none;
    }
  }
`;

export const TotalAmtDiv = styled.div`
  height: 50px;
  background: #ffffff;
  border-radius: ${({ theme }) => theme.border.radius_m};
`;
export const Total = styled.span`
  font-weight: bold;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.primary_blue};
  line-height: 43px;
  float: right;
  margin-right: 150px;
`;
export const NoData = styled.div`
  font-weight:${({ theme }) => theme.fonts.font_weight.normal_font};
  color : ${({ theme }) => theme.colors.primary_blue};
  font-size: 20px;
  margin-top: 20px;
`;

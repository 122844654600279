import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import tick from '../../../../assets/icons/tick-white.svg';
import close from '../../../../assets/icons/close-icon.svg';
import { Row, Col } from '../../../styled_components/grid/Grid.style';
import {
  StyledModal,
  SaveButton,
  SaveButtonIcon,
  CheckBox,
  CustomSelect,
  ModalWrapper,
  ModalHeading,
  CheckBoxField,
  AccountName,
  AccountNumber,
  CloseButton,
  CloseIcon,
} from './createModal.style';

const type = [
  { label: 'Bank', value: 'Bank' },
  { label: 'Income', value: 'Income' },
  { label: 'Expense', value: 'Expense' },
];
const CreateModal = ({
  isOpen, toggleModal, bankAccount,
  expenseAccount, incomeAccount, addNewAccount, selectedType, setSelectedType, accountNo,
}) => {
  const [state, setState] = useState({});
  const [parentAccounts, setParentAccounts] = useState([]);
  const [accSubtype, setAccSubtype] = useState([]);
  const [subAccountChecked, setSubAccountChecked] = useState(false);
  useEffect(() => {
    if (selectedType === 'Bank') {
      if (bankAccount) {
        setParentAccounts(
          bankAccount.filter((item) => !item.subAccount)
            .map((i) => ({ value: i.accountName, label: i.accountName })),
        );
      }
      setAccSubtype([
        { label: 'Savings', value: 'Savings' },
        { label: 'Checking', value: 'Checking' },
        { label: 'CashOnHand', value: 'CashOnHand' },
      ]);
    }
    if (selectedType === 'Expense') {
      if (expenseAccount) {
        setParentAccounts(
          expenseAccount.filter((item) => !item.subAccount)
            .map((i) => ({ value: i.accountName, label: i.accountName })),
        );
      }
      setAccSubtype([
        { label: 'CharitableContributions', value: 'CharitableContributions' },
        { label: 'Insurance', value: 'Insurance' },
        { label: 'Entertainment', value: 'Entertainment' },
        {
          label: 'OfficeGeneralAdministrativeExpenses',
          value: 'OfficeGeneralAdministrativeExpenses',
        },
        { label: 'Utilities', value: 'Utilities' },
      ]);
    }
    if (selectedType === 'Income') {
      if (incomeAccount) {
        setParentAccounts(
          incomeAccount.filter((item) => !item.subAccount)
            .map((i) => ({ value: i.accountName, label: i.accountName })),
        );
      }
      setAccSubtype([
        { label: 'NonProfitIncome', value: 'NonProfitIncome' },
        { label: 'OtherPrimaryIncome', value: 'OtherPrimaryIncome' },
        { label: 'CashReceiptIncome', value: 'CashReceiptIncome' },
        { label: 'RevenueGeneral', value: 'RevenueGeneral' },
        { label: 'ServiceFeeIncome', value: 'ServiceFeeIncome' },
      ]);
    }
  }, [selectedType]);
  const handleClickNewAccount = () => {
    if (state.name.match(/delete/gi)) {
      toast.error('Entered value is not a valid account name!', { autoClose: 3000 });
    } else {
      addNewAccount(subAccountChecked, selectedType, state);
    }
  };
  useEffect(() => {
    if (state.parentAccount && (selectedType === 'Expense' || selectedType === 'Income')) {
      const number = accountNo[state.parentAccount]?.next;
      setState({ ...state, accNum: number });
    }
  }, [state.parentAccount]);
  return (
    <StyledModal isOpen={isOpen}>
      <CloseButton type="button" onClick={toggleModal}>
        <CloseIcon>
          <img src={close} alt="arrow" height="16px" />
        </CloseIcon>
      </CloseButton>
      <ModalWrapper>
        <Row>
          <Col col={12}>
            <ModalHeading> Add Account</ModalHeading>
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <CheckBoxField onChange={(e) => {
              setSubAccountChecked(e.target.checked);
            }}
            >
              <CheckBox type="checkbox" />
              Sub Account
            </CheckBoxField>
          </Col>
        </Row>
        <Row>
          <Col col={6}>
            <CustomSelect
              className="select"
              classNamePrefix="custom"
              options={type}
              placeholder="Type"
              name="type"
              onChange={(e) => {
                setSelectedType(e.label);
                setState({
                  type: e.value,
                  accNum: '',
                });
              }}
            />
          </Col>
          {subAccountChecked
            ? (
              <>
                <Col col={6}>
                  <CustomSelect
                    className="select"
                    classNamePrefix="custom"
                    options={parentAccounts}
                    placeholder="Parent Account"
                    onChange={(e) => {
                      setState({
                        ...state,
                        parentAccount: e.value,
                      });
                    }}
                  />
                </Col>
                <Col col={6}>
                  { selectedType === 'Bank' ? (
                    <AccountNumber
                      className="select"
                      classNamePrefix="custom"
                      options=""
                      value={state.accNum}
                      onChange={(e) => {
                        setState({
                          ...state,
                          accNum: e.target.value,
                        });
                      }}
                      placeholder="Account Number"
                    />
                  ) : (
                    <AccountNumber
                      className="select"
                      classNamePrefix="custom"
                      value={state.accNum}
                      placeholder="Account Number"
                      onChange={(e) => {
                        setState({
                          ...state,
                          accNum: e.target.value,
                        });
                      }}
                    />
                  )}
                </Col>
              </>
            ) : null}
          <Col col={6}>
            <CustomSelect
              className="select"
              classNamePrefix="custom"
              options={accSubtype}
              onChange={(e) => {
                setState({
                  ...state,
                  subType: e.value,
                });
              }}
              placeholder="Account SubType"
            />
          </Col>
          <Col col={6}>
            <AccountName
              className="select"
              classNamePrefix="custom"
              options=""
              placeholder="Account Name"
              onChange={(e) => {
                setState({
                  ...state,
                  name: e.target.value,
                });
              }}
            />
          </Col>
          <Col col={6}>
            {!subAccountChecked
              ? (
                <AccountNumber
                  className="select"
                  classNamePrefix="custom"
                  options=""
                  value={state.accNum}
                  onChange={(e) => {
                    setState({
                      ...state,
                      accNum: e.target.value,
                    });
                  }}
                  placeholder="Account Number"
                />
              ) : null}
          </Col>
        </Row>
        <Row>
          <Col col={8} />
          <Col col={4}>
            <SaveButton type="button" onClick={handleClickNewAccount}>
              Add Account
              <SaveButtonIcon>
                <img src={tick} alt="arrow" height="16px" />
              </SaveButtonIcon>
            </SaveButton>
          </Col>
        </Row>
      </ModalWrapper>
    </StyledModal>
  );
};
CreateModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  addNewAccount: PropTypes.func,
  bankAccount: PropTypes.array,
  expenseAccount: PropTypes.array,
  incomeAccount: PropTypes.array,
  selectedType: PropTypes.string,
  setSelectedType: PropTypes.func,
  accountNo: PropTypes.object,
};
export default CreateModal;

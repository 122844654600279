import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModalProvider } from 'styled-react-modal';
import Cookies from 'js-cookie';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';
import { Col, Row } from '../../components/styled_components/grid/Grid.style';
import arrowRightIcon from '../../assets/icons/arrow-right.svg';
import {
  MembersDisplayButton,
  MembersInput,
  MembersInputWrap,
  MembersDisplayButtonIcon,
  ApprovelButton,
  AddMemberButton,
} from './Members.style';
import { PageHeading, PageLayout } from '../../theme/GlobalStyles';
import MemberPanel from '../../components/member_panel';
import { fetcher } from '../../hooks/useFetch';
import { API, ACCT_API } from '../../constants';
import CreateModal from '../../components/membership_components/remove_modal';
import TransferModal from '../../components/membership_components/transfer_modal';
import Loader from '../../components/styled_components/loader/loader.style';
import MembersMobile from '../mobile_view/members';

const Members = () => {
  const navigate = useNavigate();
  const roles = Cookies.get('user_roles').split(',');
  const [data, setData] = useState([]);
  const [query, setQuery] = useState('');
  const [scroll, setScroll] = useState(true);
  const [pageLimit, setPageLimit] = useState(1);
  const [page, setPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState();
  const [familyMembers, setFamilyMembers] = useState();
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const toggleModal = (member) => {
    if (member) {
      setSelectedMembers([{ value: member.membershipNumber, label: member.name }]);
    }
    setIsOpen(!isOpen);
  };
  const toggleTransferModal = (member) => {
    if (member) {
      setSelectedMembers([{ value: member.membershipNumber, label: member.name }]);
    }
    setModal(!modal);
  };
  const loader = useRef(null);
  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting) {
      setScroll(true);
      setPage((page) => page + 1);
    }
  };
  const getName = async (value, page) => {
    setLoading(true);
    if (scroll) {
      const res = await fetcher({
        url: `${API.LIST_MEMBERS}?query=${value}&limit=10&page=${page}`,
        method: 'GET',
        authTokenKey: 'auth_token',
      });
      setData((prev) => [...prev, ...res.member_list]);
      setPageLimit(Math.round((res.total_no_of_records / 10) + 1));
    }
    setLoading(false);
  };
  const getSearch = async (value) => {
    const res = await fetcher({
      url: `${API.LIST_MEMBERS}?query=${value}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    setData(res.member_list);
  };
  const getFamilyDetails = async (id) => {
    const res = await fetcher({
      url: `${API.GET_FAMILY_MEMBERS}?userId=${id}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    setFamilyMembers(res.map((item) => ({
      value: item.churchID,
      label: item.name,
    })));
  };
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current);
    }
  }, []);
  useEffect(() => {
    if (page <= pageLimit) {
      getName(query, page);
    }
  }, [page]);
  const handleRemove = (value) => {
    getFamilyDetails(value.id);
    toggleModal(value);
    setSelectedMember(value);
  };
  const handleTransfer = (value) => {
    toggleTransferModal(value);
    getFamilyDetails(value.id);
    setSelectedMember(value);
  };
  const handleEditCustomer = async (new_id, id) => {
    try {
      const res = await fetcher({
        url: `${ACCT_API.EDIT_CUSTOMER}`,
        method: 'POST',
        authTokenKey: 'auth_token',
        body: JSON.stringify({ membership_number: id, new_membership_number: new_id }),
      });
      if (res.success) {
        toast.success('Success', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };
  const handleExisting = async (value, params) => {
    try {
      const res = await fetcher({
        url: `${API.TRANSFER_MEMBER}`,
        method: 'POST',
        authTokenKey: 'auth_token',
        body: JSON.stringify(value),
      });
      if (res) {
        toast.success('Sucessfully Transfered!', { autoClose: 3000 });
        setSelectedMembers(selectedMembers.filter((x) => (value.membership_number !== x.value
          ? { value: x.membership_number, label: x.label } : null)));
        handleEditCustomer(res.newMembershipId, value.membership_number);
        if (params?.caller === 'new') {
          toggleTransferModal();
        }
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };
  const handleDelete = async (value, params) => {
    try {
      const res = await fetcher({
        url: `${API.REMOVE_MEMBER}`,
        method: 'POST',
        authTokenKey: 'auth_token',
        body: JSON.stringify(value),
      });
      if (res) {
        if (res.message) {
          toast.success('Successfully removed!', { autoClose: 3000 });
          if (params?.caller !== 'transfer') {
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            const items = [...selectedMembers];
            items.splice(params.index, 1);
            if (items.length === 0) {
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              setSelectedMembers(items);
            }
          }
        } else if (res.warning) {
          toast.success(res.warning, { autoClose: 3000 });
          if (params?.caller !== 'transfer') {
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            const items = [...selectedMembers];
            items.splice(params.index, 1);
            if (items.length === 0) {
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            } else {
              setSelectedMembers(items);
            }
          }
        } else {
          toast.error('Error!', { autoClose: 3000 });
        }
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };
  const handleByMistake = async (value) => {
    try {
      const res = await fetcher({
        url: `${API.DELETE_MEMBER}`,
        method: 'POST',
        authTokenKey: 'auth_token',
        body: JSON.stringify(value),
      });
      if (res) {
        if (res.message) {
          toast.success('Successfully removed!', { autoClose: 3000 });
        } else if (res.warning) {
          toast.success(res.warning, { autoClose: 3000 });
        }
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (error) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };
  const handleClick = (link) => {
    // eslint-disable-next-line no-restricted-globals
    navigate(link);
  };
  if (isMobile) {
    return <MembersMobile />;
  }
  const handleDisplay = (e) => {
    e.preventDefault();
    setScroll(false);
    getSearch(query);
    setData([]);
    setPage(1);
  };
  const confirmToggleModal = () => {
    setConfirm(!confirm);
  };
  return (
    <>
      <PageLayout>
        {loading ? <Loader /> : null}
        <ModalProvider>
          <CreateModal
            member={selectedMember}
            handleDelete={handleDelete}
            handleByMistake={handleByMistake}
            confirmToggleModal={confirmToggleModal}
            toggleModal={toggleModal}
            confirm={confirm}
            familyMembers={familyMembers}
            isOpen={isOpen}
            selectedMembers={selectedMembers}
            setSelectedMembers={setSelectedMembers}
          />
        </ModalProvider>
        <ModalProvider>
          <TransferModal
            handleExisting={handleExisting}
            member={selectedMember}
            isOpen={modal}
            familyMembers={familyMembers}
            toggleModal={toggleTransferModal}
            selectedMembers={selectedMembers}
            setSelectedMembers={setSelectedMembers}
          />
        </ModalProvider>
        <Row>
          <Col><PageHeading>Members</PageHeading></Col>
        </Row>
        <MembersInputWrap>
          <form onSubmit={handleDisplay}>
            {roles.includes('PowerUser') ? (
              <Row>
                <Col col={6}>
                  <MembersInput
                    id="members-search"
                    name="memberSearchName"
                    type="text"
                    placeholder="Enter Name or Membership"
                    onChange={({ target: { value } }) => setQuery(value)}
                  />
                </Col>
                <Col col={2}>
                  <MembersDisplayButton
                    type="submit"
                  >
                    Display
                    <MembersDisplayButtonIcon>
                      <img src={arrowRightIcon} alt=">" height="16px" />
                    </MembersDisplayButtonIcon>
                  </MembersDisplayButton>
                </Col>
                <Col col={2}>
                  <ApprovelButton
                    type="button"
                    onClick={() => {
                      handleClick('/members/address-approval');
                    }}
                  >
                    Approvals
                  </ApprovelButton>
                </Col>
                <Col col={2}>
                  <AddMemberButton
                    type="button"
                    onClick={() => {
                      handleClick('/add-member');
                    }}
                  >
                    Add Member
                  </AddMemberButton>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col col={9}>
                  <MembersInput
                    id="members-search"
                    name="memberSearchName"
                    type="text"
                    placeholder="Enter Name or Membership"
                    onChange={({ target: { value } }) => setQuery(value)}
                  />
                </Col>
                <Col col={3}>
                  <MembersDisplayButton
                    type="submit"
                  >
                    Display
                    <MembersDisplayButtonIcon>
                      <img src={arrowRightIcon} alt=">" height="16px" />
                    </MembersDisplayButtonIcon>
                  </MembersDisplayButton>
                </Col>
              </Row>
            )}
          </form>
        </MembersInputWrap>
        <Row>
          {data && data.length > 0
            ? data.map((member, ind) => {
              if (ind % 2 === 0) {
                return (
                  <Col col={6}>
                    <MemberPanel roles={roles} member={member} handleClick={handleClick} handleRemove={handleRemove} handleTransfer={handleTransfer} marginRight="20px" />
                  </Col>
                );
              }
              return (
                <Col col={6}>
                  <MemberPanel
                    roles={roles}
                    member={member}
                    handleClick={handleClick}
                    handleRemove={handleRemove}
                    handleTransfer={handleTransfer}
                  />
                </Col>
              );
            })
            : null}
          <div className="loading" ref={loader} />
        </Row>
      </PageLayout>
    </>
  );
};
export default Members;

import styled from 'styled-components';

const PanelHeadingSection = styled.div`
  font-size: 18px;
  font-weight: ${({ theme }) => theme.fonts.font_weight.bold_font};;
  color: ${({ theme }) => theme.colors.tertiary_blue};
  padding: 10px 10px;
  line-height: 40px;
  font-family: 'Playfair Display', serif;
`;

export default PanelHeadingSection;

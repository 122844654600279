import React, { useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { saveAs } from 'file-saver';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router';
import Breadcrumbs from '../../../../components/breadcrumbs';
import arrowRightIcon from '../../../../assets/icons/arrow-right.svg';
import MemberReportsMenu from '../../../../components/member_reports/menu';
import {
  Col,
  Row,
} from '../../../../components/styled_components/grid/Grid.style';
import Loader from '../../../../components/styled_components/loader/loader.style';
import {
  AccountingPageLayout,
  PageHeading,
  PageSubHeading,
} from '../../../../theme/GlobalStyles';
import downloadArrow from '../../../../assets/icons/download.svg';
import {
  WrapperDiv,
  StyledDate,
  DisplayButton,
  DisplayButtonIcon,
  DownloadButton,
  DownloadButtonIcon,
  DownloadImage,
} from './NewAddressChange.style';
import Table from '../../../../components/common/table';
import { fetcher, fileDownloader } from '../../../../hooks/useFetch';
import { API } from '../../../../constants';
import ScrollUpButton from '../../../../utils/ScrollUpButton';
import ScrollDownButton from '../../../../utils/ScrollDownButton';

const NewAddressChange = () => {
  const navigate = useNavigate();
  const breadcrumbs = ['Reports', 'General Reports', 'New Address Change'];
  const [startDate, setStartDate] = useState(moment().format('YYYY-01-01'));
  const [loading, setLoading] = useState(false);
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [data, setData] = useState([]);
  const [text, setText] = useState('');
  const minDate = Cookies.get('accountant_year');

  const getList = async () => {
    setLoading(true);
    setData([]);
    setText('');
    try {
      const res = await fetcher({
        url: `${API.GET_NEW_ADDRESS_CHANGE}?startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
        authTokenKey: 'auth_token',
      });
      if (res.new_address_list.length) {
        setLoading(false);
        setData(res.new_address_list);
      } else {
        setLoading(false);
        setText('No Data');
        toast.warn('No Data', { autoClose: 3000 });
      }
    } catch (error) {
      setLoading(false);
      toast.error('Error!', { autoClose: 3000 });
    }
  };

  const handleClick = (id) => {
    navigate(`/members/details/${id}`);
  };

  const listHeader = [
    {
      heading: 'Membership ID',
      field_name: 'membershipId',
      type: 'name',
    },
    {
      heading: 'Name',
      field_name: 'name',
      type: 'link',
    },
    {
      heading: 'New Address',
      field_name: 'new address',
      type: 'name',
    },
  ];

  const downloadReport = async () => {
    try {
      const download = await fileDownloader({
        url: `${API.DOWNLOAD_NEW_ADDRESS_CHANGE}?startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
        authTokenKey: 'auth_token',
      });
      if (download) {
        saveAs(
          new Blob([download], { type: 'application/vnd.ms-excel' }),
          'New Address Change List.xlsx',
        );
      }
    } catch (error) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };

  return (
    <>
      <MemberReportsMenu />
      {loading ? <Loader /> : null}
      <ScrollUpButton />
      <ScrollDownButton />
      <AccountingPageLayout>
        <PageHeading>Newly Address Change</PageHeading>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <WrapperDiv>
          <Row>
            <Col col={2}>
              <StyledDate
                width="88%"
                type="date"
                min={`${minDate}-01-01`}
                max={`${minDate}-12-31`}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Col>
            <Col col={2}>
              <StyledDate
                width="88%"
                type="date"
                min={`${minDate}-01-01`}
                max={`${minDate}-12-31`}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Col>
            <Col col={2} />
            <Col col={3}>
              <DownloadButton type="button" onClick={() => downloadReport()}>
                Download
                <DownloadButtonIcon>
                  <DownloadImage src={downloadArrow} alt=">" height="13px" />
                </DownloadButtonIcon>
              </DownloadButton>
            </Col>
            <Col col={3}>
              <DisplayButton type="button" onClick={() => getList()}>
                Display
                <DisplayButtonIcon>
                  <img src={arrowRightIcon} alt=">" height="16px" />
                </DisplayButtonIcon>
              </DisplayButton>
            </Col>
          </Row>
        </WrapperDiv>
        <Row>
          <Col col={1}>
            <PageSubHeading>{text}</PageSubHeading>
          </Col>
        </Row>
        {data.length > 0 && (
          <Table
            data={data}
            tableLookup={listHeader}
            handleClick={handleClick}
          />
        )}
      </AccountingPageLayout>
    </>
  );
};

export default NewAddressChange;

import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const BaptismPageContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  // padding: 10px 10px;
`;

export const WhiteWrap = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-top-left-radius: ${({ theme }) => theme.border.radius_m};
  border-top-right-radius: ${({ theme }) => theme.border.radius_m};
  width: 100%;
`;

export const RadioWrap = styled.div`
  backround: ${({ theme }) => theme.colors.white};
  height: 90px;
  padding: 15px 0 0 0;
  margin: 0 0 0 60px;
  width: 75%;
`;

export const AddMemberButton = styled.button`
  position: relative;
  background-color: ${({ theme }) => theme.colors.primary_red};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius_m};
  border: none;
  height: 50px;
  padding: 0;
  margin-top: 1%;
  margin-left: 90%;
  width: 50%;
  align-items: right;
`;

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Col,
  Row,
} from '../../../../components/styled_components/grid/Grid.style';
import {
  AccountingPageLayout,
  PageHeading,
} from '../../../../theme/GlobalStyles';
import {
  Menu,
  CustomSelect,
  AmountInput,
  PaymentSaveButton,
  PaymentSaveButtonIcon,
} from './EditBudget.style';
import tick from '../../../../assets/icons/tick-white.svg';
import { fetcher } from '../../../../hooks/useFetch';
import { ACCT_API } from '../../../../constants';
import Breadcrumbs from '../../../../components/breadcrumbs';
import AccountingMenu from '../../../../components/accounting/menu';
import Loader from '../../../../components/styled_components/loader/loader.style';

const EditBudget = () => {
  const [loading, setLoading] = useState(false);
  const breadcrumbs = ['Accounting', 'Transactions', 'Edit Budget'];
  const [budgetList, setBudgetList] = useState([]);
  const [incomeAccountData, setIncomeAccountData] = useState([]);
  const [incomeData, setIncomeData] = useState([]);
  const [expenseAccountData, setExpenseAccountData] = useState([]);
  const [expenseData, setExpenseData] = useState([]);
  const [bankData, setBankData] = useState([]);
  const [bankOpenData, setBankOpenData] = useState([]);
  const [bankCloseData, setBankCloseData] = useState([]);
  const [accountType, setAccountType] = useState([]);
  const [bankType, setBankType] = useState('');
  const [accountName, setAccountName] = useState([]);
  const [amount, setAmount] = useState();
  const [budgetName, setBudgetName] = useState();

  const accounts = [
    {
      label: 'Income',
      value: 'Income',
    },
    {
      label: 'Expense',
      value: 'Expense',
    },
    {
      label: 'Bank',
      value: 'Bank',
    },
  ];
  const bankAccounts = [
    {
      label: 'Opening',
      value: 'Open',
    },
    {
      label: 'Closing',
      value: 'Close',
    },
  ];

  const getBudgetList = async () => {
    setLoading(true);
    const res = await fetcher({
      url: `${ACCT_API.GET_BUDGET_LIST}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    if (res.budget_list) {
      const arr = [];
      res.budget_list.forEach((x) => {
        arr.push({ label: x.name, value: x.name });
      });
      setBudgetList(arr);
      setLoading(false);
    }
  };

  const getIncomeAccountDetails = async () => {
    setLoading(true);
    const res = await fetcher({
      url: `${ACCT_API.GET_SALES_ITEM}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    if (res.response) {
      setLoading(false);
      setIncomeData(res.response.data);
      const arr = [];
      res.response.data.forEach((acc) => {
        arr.push({
          value: acc.accountName,
          label: acc.accountName,
        });
      });
      setIncomeAccountData(arr);
    }
  };
  const getExpenseAccountDetails = async () => {
    setLoading(true);
    const res = await fetcher({
      url: `${ACCT_API.GET_BANK_ACCOUNTS}?type=Expense`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    if (res.response) {
      setLoading(false);
      const arr = res.response.data.filter((acc) => acc.subAccount);
      setExpenseData(arr);
      const arr1 = [];
      arr.forEach((acc) => {
        arr1.push({
          value: acc.accountName,
          label: acc.accountName,
        });
      });
      setExpenseAccountData(arr1);
    }
  };

  const getBankDetails = async () => {
    setLoading(true);
    const res = await fetcher({
      url: `${ACCT_API.GET_BANK_ACCOUNTS}?type=Bank`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    if (res.response) {
      setLoading(false);
      const arr = res.response.data.filter((acc) => acc.subAccount);
      //   const date = new Date();
      setBankData(arr);
      const bankOpenarr = [];
      const bankClosearr = [];
      arr.forEach((acc) => {
        bankOpenarr.push({
          value: acc.accountName,
          label: acc.accountName,
        });
        bankClosearr.push({
          value: acc.accountName,
          label: acc.accountName,
        });
      });
      setBankOpenData(bankOpenarr);
      //   setBankOpenDetails(bankOpenarr);
      setBankCloseData(bankClosearr);
      //   setBankCloseDetails(bankClosearr);
    }
  };

  useEffect(() => {
    getBudgetList();
    getIncomeAccountDetails();
    getExpenseAccountDetails();
    getBankDetails();
  }, []);

  const handleSubmit = async () => {
    let accNum = '';
    if (accountType === 'Expense') {
      accNum = expenseData.filter((x) => x.accountName === accountName);
    } else if (accountType === 'Bank') {
      accNum = bankData.filter((x) => x.accountName === accountName);
    } else {
      accNum = incomeData.filter((x) => x.accountName === accountName);
    }
    const payload = {
      name: budgetName,
      accountName,
      accountType,
      amount: Number(amount),
      bankType,
      accNum: accNum[0].accountNumber,
    };

    if (bankType === 'Open') {
      payload.bankType = 'Open';
    } else if (bankType === 'Close') {
      payload.bankType = 'Close';
    } else if (accountType !== 'Bank') {
      payload.bankType = '';
    }

    const res = await fetcher({
      url: `${ACCT_API.EDIT_BUDGET}`,
      method: 'POST',
      authTokenKey: 'auth_token',
      body: JSON.stringify(payload),
    });

    if (res.success) {
      toast.success('Amount edited successfully', { autoClose: 3000 });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  return (
    <>
      <AccountingMenu />
      {loading ? <Loader /> : null}
      <AccountingPageLayout>
        <Row>
          <Col>
            <PageHeading>Edit Budget</PageHeading>
          </Col>
        </Row>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Menu>
          <Row>
            <Col col={4}>
              <CustomSelect
                className="select"
                classNamePrefix="custom"
                options={budgetList}
                placeholder="Budget List"
                name="type"
                onChange={(e) => {
                  setBudgetName(e.value);
                }}
              />
            </Col>
            <Col col={4} display="flex">
              <CustomSelect
                className="select"
                classNamePrefix="custom"
                options={accounts}
                placeholder="Account Type"
                name="type"
                onChange={(e) => {
                  setAccountType(e.value);
                }}
              />
              {accountType === 'Bank'
                ? (
                  <CustomSelect
                    className="select"
                    classNamePrefix="custom"
                    options={bankAccounts}
                    placeholder="Account Type"
                    name="type"
                    onChange={(e) => {
                      setBankType(e.value);
                    }}
                  />
                ) : null}
            </Col>
            <Col col={4}>
              <CustomSelect
                className="select"
                classNamePrefix="custom"
                options={
                  accountType === 'Income'
                    ? incomeAccountData
                    : accountType === 'Expense' ? expenseAccountData : accountType === 'Bank Opening'
                      ? bankOpenData
                      : bankCloseData
                }
                placeholder="Account Name"
                name="type"
                onChange={(e) => {
                  setAccountName(e.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col col={4}>
              <AmountInput
                type="text"
                placeholder="Amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col col={10} />
            <Col col={2}>
              <PaymentSaveButton
                type="button"
                onClick={() => handleSubmit()}
              >
                Submit
                <PaymentSaveButtonIcon>
                  <img src={tick} alt="arrow" height="16px" />
                </PaymentSaveButtonIcon>
              </PaymentSaveButton>
            </Col>
          </Row>
        </Menu>
      </AccountingPageLayout>
    </>
  );
};

export default EditBudget;

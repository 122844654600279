import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { useNavigate } from 'react-router';
import Breadcrumbs from '../../../components/breadcrumbs';
import MemberReportsMenu from '../../../components/member_reports/menu';
import arrowRightIcon from '../../../assets/icons/arrow-right.svg';
import downloadArrow from '../../../assets/icons/download.svg';
import { AccountingPageLayout, PageHeading } from '../../../theme/GlobalStyles';
import {
  Row,
  Col,
} from '../../../components/styled_components/grid/Grid.style';
import {
  GeneralBodyDiv,
  StyledDate,
  DisplayButton,
  DisplayButtonIcon,
  CustomInput,
  DownloadButton,
  DownloadButtonIcon,
  DownloadImage,
} from './generalBody.style';
import { fetcher, fileDownloader } from '../../../hooks/useFetch';
import { API } from '../../../constants';
import Table from '../../../components/common/table';
import TablePrayerGroup from '../../../components/common/table_prayerGroup';
import Loader from '../../../components/styled_components/loader/loader.style';
import ScrollUpButton from '../../../utils/ScrollUpButton';
import ScrollDownButton from '../../../utils/ScrollDownButton';
import RadioButtons from '../../../components/common/radio_buttons';

const breadcrumbs = ['Reports', 'General Body List'];
const GeneralBodyList = () => {
  const navigate = useNavigate();
  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState();
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [age, setAge] = useState();
  const [endage, setEndAge] = useState();
  const [data, setData] = useState([]);
  const [datas, setDatas] = useState([]);
  const [select, setSelect] = useState('general_body');
  const [loading, setLoading] = useState(false);
  const handleDisplay = async () => {
    if (select !== 'general_body') {
      if (!age || age < 1) {
        toast.error('Please enter a valid age', { autoClose: 3000 });
      } if (!endage || endage < 1) {
        toast.error('Please enter a valid age', { autoClose: 3000 });
      } else {
        setLoading(true);
        try {
          const response = await fetcher({
            url: `${API.GENERAL_AGE_LIST}?date=${startDate}&startAge=${age}&endAge=${endage}`,
            method: 'GET',
            authTokenKey: 'auth_token',
          });
          if (response) {
            setLoading(false);
            setData(response.age_list);
          } else {
            setLoading(false);
            toast.error('Error!', { autoClose: 3000 });
          }
        } catch (e) {
          setLoading(false);
          toast.error('Error!', { autoClose: 3000 });
        }
      }
    } else {
      setLoading(true);
      try {
        const res = await fetcher({
          url: `${API.GENERAL_BODY_LIST}?date=${startDate}`,
          method: 'GET',
          authTokenKey: 'auth_token',
        });
        if (res) {
          setHeaders(Object.keys(res.general_body_list));
          setRows(Object.values(res.general_body_list));
          setDatas(res.general_body_list);
        } else {
          setLoading(false);
          toast.error('Error!', { autoClose: 3000 });
        }
        setDatas(res.general_body_list);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        toast.error('Error!', { autoClose: 3000 });
      }
    }
  };
  const handleClick = (id) => {
    navigate(`/members/details/${id}`);
  };
  const handleSelectChange = (event) => {
    setSelect(event.target.value);
  };
  const generalLookup = [
    {
      heading: 'Membership Number',
      field_name: 'membership_number',
      type: 'id',
    },
    {
      heading: 'Name',
      field_name: 'name',
      type: 'name',
    },
  ];
  const ageLookup = [
    {
      heading: 'MembershipId',
      field_name: 'membershipId',
      type: 'id',
    },
    {
      heading: 'Name',
      field_name: 'name',
      type: 'link',
    },
    {
      heading: 'PrayerGroup',
      field_name: 'prayerGroup',
      type: 'string',
    },
    {
      heading: 'Date of Birth',
      field_name: 'dob',
      type: 'date',
    },
    {
      heading: 'Age',
      field_name: 'age',
      type: 'string',
    },
  ];
  const downloadReport = async () => {
    if (select !== 'general_body') {
      if (!age || age < 1) {
        toast.error('Please enter a valid age', { autoClose: 3000 });
      } if (!endage || endage < 1) {
        toast.error('Please enter a valid age', { autoClose: 3000 });
      } else {
        try {
          const download = await fileDownloader({
            url: `${API.DOWNLOAD_GENERAL_AGE_LIST}?date=${startDate}&startAge=${age}&endAge=${endage}`,
            method: 'GET',
            authTokenKey: 'auth_token',
          });
          if (download) {
            saveAs(
              new Blob([download], { type: 'application/vnd.ms-excel' }),
              'Age_List_Report.xlsx',
            );
          }
        } catch (e) {
          setLoading(false);
          toast.error('Error!', { autoClose: 3000 });
        }
      }
    } else {
      try {
        const download = await fileDownloader({
          url: `${API.DOWNLOAD_GENERAL_BODY_LIST}?date=${startDate}`,
          method: 'GET',
          authTokenKey: 'auth_token',
        });
        if (download) {
          saveAs(
            new Blob([download], { type: 'application/vnd.ms-excel' }),
            'General_Body_List.xlsx',
          );
        }
      } catch (error) {
        toast.error('Error!', { autoClose: 3000 });
      }
    }
  };
  return (
    <>
      <MemberReportsMenu />
      {loading ? <Loader /> : null}
      <ScrollUpButton />
      <ScrollDownButton />
      <AccountingPageLayout>
        <PageHeading>General Body List</PageHeading>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <GeneralBodyDiv>
          <Row>
            <Col col={4}>
              <RadioButtons
                label1="General Body"
                value1="general_body"
                label2="By Age"
                value2="by_age"
                marginTop="5px"
                handleSelectChange={handleSelectChange}
                select={select}
              />
            </Col>
            {select === 'general_body' ? (
              <Col col={4}>
                <StyledDate
                  type="date"
                  value={startDate}
                  placeholder="Start Date"
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Col>
            ) : (
              <>
                <Col col={2}>
                  <StyledDate
                    type="date"
                    value={startDate}
                    placeholder="Start Date"
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </Col>
                <Col col={1}>
                  <CustomInput
                    value={age}
                    placeholder="Enter Start Age"
                    onChange={(e) => setAge(e.target.value)}
                  />
                </Col>
                <Col col={1}>
                  <CustomInput
                    value={endage}
                    placeholder=" Enter End Age"
                    onChange={(e) => setEndAge(e.target.value)}
                  />
                </Col>
              </>
            )}
            <Col col={2}>
              <DownloadButton type="button" onClick={() => downloadReport()}>
                Download
                <DownloadButtonIcon>
                  <DownloadImage src={downloadArrow} alt=">" height="13px" />
                </DownloadButtonIcon>
              </DownloadButton>
            </Col>
            <Col col={2}>
              <DisplayButton type="button" onClick={() => handleDisplay()}>
                Display
                <DisplayButtonIcon>
                  <img src={arrowRightIcon} alt=">" height="16px" />
                </DisplayButtonIcon>
              </DisplayButton>
            </Col>
          </Row>
        </GeneralBodyDiv>
        {data.length > 0 && select !== 'general_body' ? (
          <Table
            data={data}
            tableLookup={ageLookup}
            handleClick={handleClick}
          />
        ) : null}
        {datas && select === 'general_body' ? (
          <div>
            <TablePrayerGroup
              headers={headers}
              tableLookup={generalLookup}
              rows={rows}
              handleClick={handleClick}
            />
          </div>
        ) : null}
      </AccountingPageLayout>
    </>
  );
};

export default GeneralBodyList;

import React from 'react';
import PropTypes from 'prop-types';
import PanelHeading from '../../membership_components/panel_heading';
import { Row, Col } from '../../styled_components/grid/Grid.style';
import {
  BaptismInfoLayout,
  Panel,
  PanelBody,
  FieldLabel,
  FieldValue,
  Div,
  Required,
} from './BaptismInfo.style';

const BaptismInfo = ({ handleChange, text, caller }) => (
  <>
    <BaptismInfoLayout>
      <Row>
        <Panel marginRight="20px">
          <PanelHeading label="Baptism Information" />
          <PanelBody>
            <Row marginBottom="17px">
              <Col col={4}>
                <Div className="addMargin">
                  <FieldLabel>
                    Date Of Baptism
                    <Required>{caller === 'new' ? '' : '*' }</Required>
                  </FieldLabel>
                  <FieldValue
                    type="date"
                    name="dateOfBaptism"
                    placeholder="DOB"
                    onChange={handleChange}
                    value={text.dateOfBaptism}
                  />
                </Div>
              </Col>
              <Col col={4}>
                <Div className="addMargin">
                  <FieldLabel>
                    Place of Baptism
                    <Required>{caller === 'new' ? '' : '*' }</Required>
                  </FieldLabel>
                  <FieldValue
                    name="placeOfBaptism"
                    onChange={handleChange}
                    value={text.placeOfBaptism}
                    type="text"
                  />
                </Div>
              </Col>
              <Col col={4}>
                <Div className="addMargin">
                  <FieldLabel>
                    Priest Conduted Baptism
                    <Required>{caller === 'new' ? '' : '*' }</Required>
                  </FieldLabel>
                  <FieldValue
                    name="priestBaptism"
                    value={text.priestBaptism}
                    onChange={handleChange}
                    type="text"
                  />
                </Div>
              </Col>
            </Row>
          </PanelBody>
        </Panel>
      </Row>
    </BaptismInfoLayout>
  </>
);

BaptismInfo.propTypes = {
  handleChange: PropTypes.func,
  text: PropTypes.object,
  caller: PropTypes.string,
};

export default BaptismInfo;

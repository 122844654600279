import styled from 'styled-components';

export const GeneralBodyDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 5px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 4%);
  border-radius: ${({ theme }) => theme.border.radius_m};
`;
export const DisplayButton = styled.button`
  position: relative;
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  background-color: ${({ theme }) => theme.colors.primary_red};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius_m};
  border: none;
  height: 45px;
  padding: 0;
  margin: 5px 20px;
  width: 90%;
  float: left;
`;
export const DisplayButtonIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(12, 0, 0, 0.15);
  width: 42px;
  height: 30px;
  margin: 0px;
  padding: 15px 0 0 0;
  border-radius: ${({ theme }) => theme.border.radius_m};
`;
export const StyledDate = styled.input`
  padding: 0px 10px;
  margin: 5px 7px;
  height: 48px;
  width: ${(props) => props.width};
  border-radius: ${({ theme }) => theme.border.radius_m};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary_blue};
  background-color: ${({ theme }) => theme.colors.page_background};
  border: 1px solid ${({ theme }) => theme.colors.page_background};
`;
export const CustomInput = styled.input`
  margin: 5px 5px;
  padding:3px 0px 0px 0px;
  height: 46px;
  width: 93%;
  border-radius: ${({ theme }) => theme.border.radius_m};
  font-size: 12px;
  color: ${({ theme }) => theme.colors.primary_blue};
  background-color: ${({ theme }) => theme.colors.page_background};
  border: 1px solid ${({ theme }) => theme.colors.page_background};
  float:left;
`;

export const DownloadButton = styled.button`
  position: relative;
  font-size: ${({ theme }) => theme.fonts.font_size.big_font};
  font-weight: ${({ theme }) => theme.fonts.font_weight.normal_font};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary_blue};
  border-radius: ${({ theme }) => theme.border.radius_s};
  border: 2px solid ${({ theme }) => theme.colors.secondary_background};
  height: 40px;
  padding: 0;
  margin:10px 0 10px 20px;
  width: 90%;
  float: left;
`;
export const DownloadButtonIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  border: 1px solid #000;
  border-radius: 50px;
  height: 20px;
  width: 20px;
  margin: 8px;
`;
export const DownloadImage = styled.img`
  margin-top: 3px;
`;
export const IncomeInputWrap = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 10px 20px;
  border-radius: ${({ theme }) => theme.border.radius_m};
`;

export const IncomeDisplayButton = styled.button`
  position: relative;
  background-color: ${({ theme }) => theme.colors.primary_red};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius_m};
  border: none;
  height: 50px;
  padding: 0;
  margin: 5px 20px;
  width: 55%;
`;

export const IncomeDisplayButtonIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(12, 0, 0, 0.15);
  width: 42px;
  height: 35px;
  margin: 0;
  padding: 15px 0 0 0;
  border-radius: ${({ theme }) => theme.border.radius_m};
`;

export const DateInput = styled.input`
  background-color: ${({ theme }) => theme.colors.page_background};
  border-radius: ${({ theme }) => theme.border.radius_m};
  padding: 10px 15px;
  font-size: 14px;
  margin-top: 7px;
  border: none;
  height: 25px;
  width: 88%;
`;

export const IncomeTableHead = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  font-size: 18px;
  text-align: left;
  padding: 18px;
  color: ${({ theme }) => theme.colors.primary_blue};
  border-radius: 10px;
  margin-top: 10px;
`;
export const IncomeCardWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: -10px;
  margin-right: ${(props) => props.marginRight || 0};
  background-color: ${({ theme }) => theme.colors.white};
  border-top-left-radius: ${({ theme }) => theme.border.radius_m};
  border-top-right-radius: ${({ theme }) => theme.border.radius_m};
  padding: 10px 20px 20px 10px;
  cursor: pointer;
  height: 50px;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: ${({ theme }) => theme.colors.primary_blue};
//   line-height: 12px;
`;
export const Income = styled.div`
border-radius: ${({ theme }) => theme.border.radius_m};
// border-bottom-right-radius: ${({ theme }) => theme.border.radius_m};
background-color:  ${({ theme }) => theme.colors.white};
padding: 10px;
margin: 10px 0;

`;
export const TableDiv = styled.div`
  table {
    width: 100%;
    border-spacing: 0 2px;
    border-collapse: separate;
    tr {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const TableHead = styled.th`
  background-color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  font-size: 18px;
  text-align: left;
  padding: 18px;
  color: ${({ theme }) => theme.colors.primary_blue};
  :first-child {
    border-top-left-radius: ${({ theme }) => theme.border.radius_m};
  }

  :last-child {
    border-top-right-radius: ${({ theme }) => theme.border.radius_m};
  }
`;
export const TableData = styled.td`
  text-align: ${(props) => props.textAlign || 'left'};
  font-size: 16px;
  white-space: nowrap;
  padding: 18px;
  margin-top: -8px;
  color: ${({ theme }) => theme.colors.primary_blue};
`;
export const Total = styled.div`
font-size: 22px;
font-weight: 600;
height: 35px;
color: ${({ theme }) => theme.colors.primary_blue};
`;
export const Line = styled.div`
border-bottom : 1px solid  rgba(228, 232, 244, 1)};
`;
export const Linehead = styled.div`
border-bottom : 1px solid  rgba(228, 232, 244, 1)};
`;
export const NoData = styled.div`
  font-weight:${({ theme }) => theme.fonts.font_weight.normal_font};
  color : ${({ theme }) => theme.colors.primary_blue};
  font-size: 20px;
  margin-top: 20px;
`;

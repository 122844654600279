import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from '../styled_components/grid/Grid.style';
import arrowRightIcon from '../../assets/icons/arrow-right-with-opacity.svg';
import {
  BreadcrumbsWrapper,
  BreadcrumbText,
  BreadcrumbIcon,
  BreadcrumbTextActive,
} from './Breadcrumbs.style';

const Breadcrumbs = ({ breadcrumbs }) => (
  <BreadcrumbsWrapper>
    <Row>
      {breadcrumbs.map((route, index) => (
        <Col key={route}>
          {breadcrumbs.length - 1 !== index ? (
            <>
              <BreadcrumbText>{route}</BreadcrumbText>
              <BreadcrumbIcon src={arrowRightIcon} alt="arrow-right" />
            </>
          ) : (
            <BreadcrumbTextActive>{route}</BreadcrumbTextActive>
          )}
        </Col>
      ))}
    </Row>
  </BreadcrumbsWrapper>
);
Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.array,
};

export default Breadcrumbs;

import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const BudgetWrap = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 5px 20px;
  border-radius: ${({ theme }) => theme.border.radius_m};
`;

export const CreateNewButton = styled.button`
  position: relative;
  background-color: ${({ theme }) => theme.colors.primary_red};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius_m};
  border: none;
  height: 40px;
  padding: 0;
  margin: 5px 20px;
  width: 75%;
`;

export const CreateNewButtonIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(12, 0, 0, 0.15);
  width: 42px;
  height: 25px;
  margin: 0;
  padding: 15px 0 0 0;
  border-radius: ${({ theme }) => theme.border.radius_m};
`;

export const SummarizedHeadDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 5px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 4%);
  border-radius: ${({ theme }) => theme.border.radius_m};
  margin: 10px 0 10px 0;
  font-weight: ${({ theme }) => theme.fonts.font_weight.bold_font};
  font-size: ${({ theme }) => theme.fonts.font_size.heavy_font};
  color: ${({ theme }) => theme.colors.primary_blue};
`;

export const SummarizedBodyDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 5px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 4%);
  border-radius: ${({ theme }) => theme.border.radius_m};
  margin: 10px 0 10px 0;
  font-weight: ${({ theme }) => theme.fonts.font_weight.normal_font};
  font-size: ${({ theme }) => theme.fonts.font_size.normal_font};
  color: ${({ theme }) => theme.colors.primary_blue};
`;

export const LineItems = styled.div`
  font-size: ${({ theme }) => theme.fonts.font_size.big_font};
  padding: 10px 0 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
`;

export const AmountField = styled.input`
  margin: 0px 5px;
  height: 35px;
  width: 95%;
  border-radius: 5px;
  font-size: 14px;
  padding-left: 25px;
  padding-right: 25px;
  text-align: right;
  color: ${({ theme }) => theme.colors.primary_blue};
  background-color: ${({ theme }) => theme.colors.page_background};
  border: 1px solid ${({ theme }) => theme.colors.page_background};

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const BudgetField = styled.input`
  margin: 0px 5px;
  height: 35px;
  width: 95%;
  border-radius: 5px;
  font-size: 14px;
  padding-left: 25px;
  color: ${({ theme }) => theme.colors.primary_blue};
  background-color: ${({ theme }) => theme.colors.page_background};
  border: 1px solid ${({ theme }) => theme.colors.page_background};
`;

import React, { useState } from 'react';
import styled from 'styled-components';
import ArrowUp from '../assets/icons/arrow_up.svg';

const ScrollUp = styled.div`
    display: ${(props) => (props.display ? 'inline' : 'none')};
    left: calc(98% - 10px);
    top: calc(45% - 10px);
    position: fixed;
    z-index: 1;
    background-color: ${({ theme }) => theme.colors.white};
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 50%;
`;
const ScrollUpButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled <= 0) {
      setVisible(true);
    } else if (scrolled > 0) {
      setVisible(true);
    }
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <ScrollUp display={visible} onClick={scrollToBottom}>
      <img style={{ margin: '5px 0 0 0' }} src={ArrowUp} width="20px" height="20px" alt="scrollDown" />
    </ScrollUp>
  );
};

export default ScrollUpButton;

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Row, Col } from '../../styled_components/grid/Grid.style';
import tickIcon from '../../../assets/icons/tick.svg';
import deleteIcon from '../../../assets/icons/delete.svg';
import closeIcon from '../../../assets/icons/close-icon.svg';
import editIcon from '../../../assets/icons/pen.svg';
import {
  TableInput,
  TableDiv,
  TableHead,
  CustomSelect,
  StyledInput,
  IconWrapper,
  ActionIcon,
  TableData,
} from './JournalCreateTable.style';

const JournalCreateTable = ({ accounts, handleLineItems, formatter }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [items, setItems] = useState([]);
  const [creditTotal, setCreditTotal] = useState(0);
  const [debitTotal, setDebitTotal] = useState(0);
  const [currentData, setCurrentData] = useState({
    account: '',
    description: '',
    credit: '',
    debit: '',
  });
  const [editData, setEditData] = useState({
    account: '',
    description: '',
    credit: '',
    debit: '',
  });

  const addCurrentItem = () => {
    if (!currentData.account) {
      toast.error('Account not selected', { autoClose: 3000 });
    } else {
      const currentItems = [...items];
      currentItems.push(currentData);
      setCreditTotal(creditTotal + Number(currentData.credit));
      setDebitTotal(debitTotal + Number(currentData.debit));
      setItems(currentItems);
    }
    setCurrentData({
      account: '',
      description: '',
      credit: '',
      debit: '',
    });
  };
  const updateEditItem = () => {
    const currentItems = [...items];
    const editedElement = currentItems[editIndex];
    setCreditTotal(
      creditTotal + Number(editData.credit) - Number(editedElement.credit),
    );
    setDebitTotal(
      debitTotal + Number(editData.debit) - Number(editedElement.debit),
    );
    currentItems[editIndex] = editData;
    setItems(currentItems);
    setIsEditing(false);
    setEditIndex(null);
  };
  const deleteItem = (index) => {
    const currentItems = [...items];
    const deleteElement = currentItems[index];
    setCreditTotal(creditTotal - Number(deleteElement.credit));
    setDebitTotal(debitTotal - Number(deleteElement.debit));
    currentItems.splice(index, 1);
    setItems(currentItems);
  };
  const cancelChange = () => {
    setCurrentData({
      account: '',
      description: '',
      credit: '',
      debit: '',
    });
  };
  const cancelEditChange = () => {
    setIsEditing(false);
    setEditIndex(null);
    setEditData({
      account: '',
      description: '',
      credit: '',
      debit: '',
    });
  };
  const handleSelectChange = (e) => {
    setIsEditing(false);
    setEditIndex(null);
    const data = { ...currentData };
    data.account = e.value;
    setCurrentData(data);
  };
  const handleChange = (e) => {
    setIsEditing(false);
    setEditIndex(null);
    const data = { ...currentData };
    if (e.target.name === 'debit') {
      data.credit = '';
    }
    if (e.target.name === 'credit') {
      data.debit = '';
    }
    data[e.target.name] = e.target.value;
    setCurrentData(data);
  };
  const handleEditSelectChange = (e) => {
    const data = { ...editData };
    data.account = e.value;
    setEditData(data);
  };
  const handleEditChange = (e) => {
    const data = { ...editData };
    if (e.target.name === 'debit') {
      data.credit = '';
    }
    if (e.target.name === 'credit') {
      data.debit = '';
    }
    data[e.target.name] = e.target.value;
    setEditData(data);
  };
  const getOptionLabel = (val) => {
    const option = accounts.find((elem) => elem.value === val);
    return option && option.label ? option.label : '';
  };
  const onEditClick = (index, elem) => {
    const data = {
      account: elem.account,
      description: elem.description,
      credit: elem.credit,
      debit: elem.debit,
    };
    setIsEditing(true);
    setEditIndex(index);
    setEditData(data);
  };
  useEffect(() => {
    handleLineItems(items, debitTotal, creditTotal);
  }, [items]);
  return (
    <TableDiv>
      <table>
        <tr>
          <TableHead>Account</TableHead>
          <TableHead width="100px">Debit</TableHead>
          <TableHead width="100px">Credit</TableHead>
          <TableHead>Description</TableHead>
          <TableHead width="100px">Action</TableHead>
        </tr>
        <tbody>
          <tr>
            <TableInput>
              <CustomSelect
                className="select"
                classNamePrefix="custom"
                options={accounts}
                value={accounts.find((elem) => elem.value === currentData.account) || null}
                placeholder="Account"
                onChange={handleSelectChange}
              />
            </TableInput>
            <TableInput textAlign="right">
              <StyledInput
                textAlign="right"
                name="debit"
                placeholder="0"
                value={currentData.debit}
                onChange={handleChange}
                type="number"
              />
            </TableInput>
            <TableInput textAlign="right">
              <StyledInput
                textAlign="right"
                name="credit"
                placeholder="0"
                value={currentData.credit}
                onChange={handleChange}
                type="number"
              />
            </TableInput>
            <TableInput>
              <StyledInput
                type="text"
                name="description"
                value={currentData.description}
                onChange={handleChange}
              />
            </TableInput>
            <TableInput>
              <Row justifyContent="center">
                <Col textAlign="center">
                  <IconWrapper onClick={addCurrentItem}>
                    <ActionIcon src={tickIcon} alt="tick-icon" />
                  </IconWrapper>
                </Col>
                <Col textAlign="center">
                  <IconWrapper onClick={cancelChange}>
                    <ActionIcon src={closeIcon} alt="cross-icon" />
                  </IconWrapper>
                </Col>
              </Row>
            </TableInput>
          </tr>
          {items.map((item, index) => ((isEditing && editIndex) === index ? (
            <tr>
              <TableInput>
                <CustomSelect
                  className="select"
                  classNamePrefix="custom"
                  options={accounts}
                  value={{
                    value: editData.account,
                    label: getOptionLabel(editData.account),
                  }}
                  placeholder="Account"
                  onChange={handleEditSelectChange}
                />
              </TableInput>
              <TableInput textAlign="right">
                <StyledInput
                  textAlign="right"
                  name="debit"
                  placeholder="0"
                  onChange={handleEditChange}
                  value={editData.debit}
                  type="number"
                />
              </TableInput>
              <TableInput textAlign="right">
                <StyledInput
                  textAlign="right"
                  name="credit"
                  placeholder="0"
                  onChange={handleEditChange}
                  value={editData.credit}
                  type="number"
                />
              </TableInput>
              <TableInput>
                <StyledInput
                  type="text"
                  name="description"
                  onChange={handleEditChange}
                  value={editData.description}
                />
              </TableInput>
              <TableInput>
                <Row justifyContent="center">
                  <Col textAlign="center">
                    <IconWrapper onClick={updateEditItem}>
                      <ActionIcon src={tickIcon} alt="tick-icon" />
                    </IconWrapper>
                  </Col>
                  <Col textAlign="center">
                    <IconWrapper onClick={cancelEditChange}>
                      <ActionIcon src={closeIcon} alt="cross-icon" />
                    </IconWrapper>
                  </Col>
                </Row>
              </TableInput>
            </tr>
          ) : (
            <tr>
              <TableData>{getOptionLabel(item.account)}</TableData>
              <TableData textAlign="right">{`$ ${formatter.format(item.debit)}`}</TableData>
              <TableData textAlign="right">{`$ ${formatter.format(item.credit)}`}</TableData>
              <TableData>{item.description}</TableData>
              <TableInput>
                <Row justifyContent="center">
                  <Col textAlign="center">
                    <IconWrapper onClick={() => onEditClick(index, item)}>
                      <ActionIcon src={editIcon} alt="tick-icon" />
                    </IconWrapper>
                  </Col>
                  <Col textAlign="center">
                    <IconWrapper onClick={() => deleteItem(index)}>
                      <ActionIcon src={deleteIcon} alt="cross-icon" />
                    </IconWrapper>
                  </Col>
                </Row>
              </TableInput>
            </tr>
          )))}
          <tr>
            <TableData />
            <TableData textAlign="right">{`$ ${formatter.format(debitTotal)}`}</TableData>
            <TableData textAlign="right">{`$ ${formatter.format(creditTotal)}`}</TableData>
            <TableData />
            <TableInput />
          </tr>
        </tbody>
      </table>
    </TableDiv>
  );
};
JournalCreateTable.propTypes = {
  accounts: PropTypes.array,
  handleLineItems: PropTypes.func,
  formatter: PropTypes.func,
};
export default JournalCreateTable;

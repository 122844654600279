import React, { useState } from 'react';
import styled from 'styled-components';
import ArrowDown from '../assets/icons/arrow-down-white.svg';

const ScrollDown = styled.div`
    display: ${(props) => (props.display ? 'inline' : 'none')};
    left: calc(98% - 10px);
    top: calc(50% - 10px);
    position: fixed;
    z-index: 1;
    background-color: ${({ theme }) => theme.colors.white};
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 50%;
`;

const ScrollDownButton = () => {
  const [visible, setVisible] = useState(true);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 0) {
      setVisible(true);
    } else if (scrolled <= 0) {
      setVisible(true);
    }
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <ScrollDown display={visible} onClick={scrollToBottom}>
      <img style={{ margin: '5px 0 0 0' }} src={ArrowDown} width="20px" height="20px" alt="scrollDown" />
    </ScrollDown>
  );
};

export default ScrollDownButton;

import React from 'react';
import PropTypes from 'prop-types';
import { Col } from '../../styled_components/grid/Grid.style';
import {
  FieldLabel,
  EditIcon,
  EditIconDiv,
  FieldValueInput,
} from './InputTextBox.style';
import editIcon from '../../../assets/icons/pen.svg';
import tickIcon from '../../../assets/icons/tick.svg';
import closeIcon from '../../../assets/icons/close-icon.svg';

const InputTextBox = ({
  isEdit,
  label,
  name,
  editValue = '',
  value = '',
  onHandleChange,
  onCancel,
  handleSubmit,
  onEdit,
  selectedId,
  userId,
  isHead,
}) => {
  if (isEdit) {
    return (
      <>
        <Col col={10}>
          <FieldLabel>{label}</FieldLabel>
          <FieldValueInput
            name={name}
            type="text"
            value={editValue}
            onChange={(e) => onHandleChange(e)}
          />
        </Col>
        <Col col={2} display="flex" alignSelf="center" justifyContent="space-evenly">
          <EditIconDiv onClick={onCancel}>
            <EditIcon src={closeIcon} />
          </EditIconDiv>
          <EditIconDiv onClick={handleSubmit}>
            <EditIcon src={tickIcon} />
          </EditIconDiv>
        </Col>
      </>
    );
  }
  return (
    <>
      <Col col={10}>
        <FieldLabel>{label}</FieldLabel>
        <FieldValueInput
          name={name}
          type="text"
          value={value}
          onChange={(e) => onHandleChange(e)}
          disabled
        />
      </Col>
      <Col col={2} alignSelf="center">
        {(selectedId === userId) || isHead
          ? (
            <EditIconDiv onClick={() => onEdit(name)}>
              <EditIcon
                src={editIcon}
              />
            </EditIconDiv>
          )
          : null}
      </Col>
    </>
  );
};
InputTextBox.propTypes = {
  isEdit: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  editValue: PropTypes.string,
  onHandleChange: PropTypes.func,
  onCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  onEdit: PropTypes.func,
  selectedId: PropTypes.string,
  isHead: PropTypes.bool,
  userId: PropTypes.string,
};

export default InputTextBox;

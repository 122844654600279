import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Row, Col } from '../../components/styled_components/grid/Grid.style';
import RedDot from '../../assets/icons/red-dot.svg';

import AccountingMenu from '../../components/accounting/menu';
import SummaryPanel from '../../components/accounting/summary_panel';
import { AccountingPageLayout } from '../../theme/GlobalStyles';
import DashboardTable from '../../components/accounting/dashboard_table';
import { Chart, ChartBody, ChartHeading } from './Accounting.style';
import { useFetch } from '../../hooks/useFetch';
import { ACCT_API } from '../../constants';
import Loader from '../../components/styled_components/loader/loader.style';

const month = ['Jan', 'Feb', 'Mar', 'Apr',
  'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const Dashboard = () => {
  const [transaction, setTransaction] = useState();
  const [loading, setLoading] = useState(true);
  const [income, setIncome] = useState();
  const minDate = Cookies.get('accountant_year');

  const data = useFetch({
    url: `${ACCT_API.ACCOUNTING_DASHBOARD}?endDate=${minDate}-12-31`,
    method: 'GET',
    authTokenKey: 'auth_token',
  });

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const chartsData = useFetch({
    url: `${ACCT_API.DASHBOARD_MONTHLY}?endDate=${minDate}-12-31`,
    method: 'GET',
    authTokenKey: 'auth_token',
  });
  const currentYear = Cookies.get('accountant_year');
  if (chartsData.data && !transaction) {
    setTransaction(chartsData.data.monthlyPayments.map((item, index) => ({
      name: month[index], [currentYear]: parseFloat(item.toFixed(2)),
    })));
  }
  if (chartsData.data && !income) {
    setIncome(chartsData.data.monthlyReceipts.map((item, index) => ({
      name: month[index], [currentYear]: parseFloat(item.toFixed(2)),
    })));
    setLoading(false);
  }

  const lastFive = useFetch({
    url: `${ACCT_API.ACCOUNTING_DASHBOARD_LAST_FIVE}?endDate=${minDate}-12-31`,
    method: 'GET',
    authTokenKey: 'auth_token',
  });

  useEffect(() => {
    if (data?.data) {
      setLoading(false);
    }
  }, [data]);

  return (
    <>
      <AccountingMenu />
      {loading ? <Loader /> : null}
      <AccountingPageLayout>
        <Row>
          <Col col={3}>
            <SummaryPanel
              label="YTD Income"
              amount={formatter.format(
                data.data ? data.data.totalReceipt : 0.0,
              )}
              marginRight="20px"
            />
          </Col>
          <Col col={3}>
            <SummaryPanel
              label="YTD Expense"
              amount={formatter.format(
                data.data ? data.data.totalPayment : 0.0,
              )}
              marginRight="20px"
            />
          </Col>
          <Col col={3}>
            <SummaryPanel
              label="YTD Subscription"
              amount={formatter.format(
                data.data ? data.data.totalSubscription : 0.0,
              )}
              marginRight="20px"
            />
          </Col>
          <Col col={3}>
            <SummaryPanel
              label="YTD Offertory"
              amount={formatter.format(
                data.data ? data.data.totalOffertory : 0.0,
              )}
            />
          </Col>
        </Row>
        {chartsData?.data && (
          <Row>
            <Col col={6}>
              <Chart marginRight="20px">
                <ChartHeading>
                  <Row>
                    <Col width="3%">
                      <img src={RedDot} width={5} height={10} alt="." />
                    </Col>
                    <Col col={11}>YTD Transactions</Col>
                  </Row>
                </ChartHeading>
                <ChartBody>
                  <Row>
                    <Col col={12}>
                      <ResponsiveContainer width="99%" aspect={2}>
                        <AreaChart
                          className="area-chart"
                          width={620}
                          height={320}
                          data={transaction}
                          margin={{
                            top: 10,
                            right: 0,
                            left: 0,
                            bottom: 0,
                          }}
                        >
                          <defs>
                            <linearGradient
                              id="colorPv"
                              x1="0"
                              y1="0"
                              x2="0"
                              y2="1"
                            >
                              <stop
                                offset="5%"
                                stopColor="#6BC2D2"
                                stopOpacity={0.8}
                              />
                              <stop
                                offset="95%"
                                stopColor="#6BC2D2"
                                stopOpacity={0}
                              />
                            </linearGradient>
                          </defs>
                          <XAxis dataKey="name" />
                          <YAxis />
                          <CartesianGrid strokeDasharray="3 3" />
                          <Tooltip />
                          <Legend />
                          <Area
                            type="monotone"
                            dataKey={currentYear}
                            stroke="#82ca9d"
                            fillOpacity={1}
                            fill="url(#colorPv)"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </Col>
                  </Row>
                </ChartBody>
              </Chart>
            </Col>
            <Col col={6}>
              <Chart>
                <ChartHeading>
                  <Row>
                    <Col width="3%">
                      <img src={RedDot} width={5} height={10} alt="." />
                    </Col>
                    <Col col={11}>YTD Income</Col>
                  </Row>
                </ChartHeading>
                <ChartBody>
                  <Row>
                    <Col col={12}>
                      <ResponsiveContainer width="99%" aspect={2}>
                        <BarChart width={600} height={320} data={income}>
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip />
                          <Legend verticalAlign="top" align="right" />
                          <Bar dataKey={currentYear} fill="#6ED3DF" />
                        </BarChart>
                      </ResponsiveContainer>
                    </Col>
                  </Row>
                </ChartBody>
              </Chart>
            </Col>
          </Row>
        )}
        {lastFive?.data && (
          <Row>
            <Col col={6}>
              <DashboardTable
                formatter={formatter}
                data={lastFive?.data?.fivePayments.slice(0, 5)}
                heading="Recent Payments"
                marginRight="20px"
              />
            </Col>
            <Col col={6}>
              <DashboardTable
                formatter={formatter}
                data={lastFive?.data?.fiveReceipts.slice(0, 5)}
                heading="Recent Receipts"
              />
            </Col>
          </Row>
        )}
      </AccountingPageLayout>
    </>
  );
};

export default Dashboard;

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Col } from '../../styled_components/grid/Grid.style';
import {
  ContributionIndex,
  ContributionWrapper,
  Body,
  Content,
  Date,
  Footer,
  Amount,
  VerticalLine,
  CardHead,
  CardValue,
} from './ContributionGrid.style';

const ContributionGrid = ({ cData }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const newLineFunction = (item) => (
    <>
      <span>
        {item.itemName.slice(0, 22)}
        -
      </span>
      <br />
      <span>{item.itemName.slice(22, item.itemName.length)}</span>
    </>
  );
  return (
    <>
      <ContributionWrapper>
        <Row>
          <Col col={12}>
            <Content>
              <ContributionIndex>{cData.id}</ContributionIndex>
              <Body>
                <Row>
                  <Col col={5}>
                    <Row>
                      <Col col={12}><CardHead>Payment Method</CardHead></Col>
                      <Col col={12}><CardValue>{cData.paymentMethod}</CardValue></Col>
                    </Row>
                  </Col>
                  <Col col={1}><VerticalLine /></Col>
                  <Col col={6}>
                    <Row>
                      <Col col={12}><CardHead>Category</CardHead></Col>
                      <Col col={12}>
                        {cData.lines.map((item) => (
                          <>
                            <CardValue>
                              {item.itemName.length > 20
                                ? newLineFunction(item)
                                : item.itemName}
                            </CardValue>
                            <br />
                          </>
                        ))}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Body>
            </Content>
            <Footer>
              <Row>
                <Col col={6}>
                  <Date>
                    {moment(cData.date).format('MM-DD-YYYY')}
                  </Date>
                </Col>
                <Col col={6}>
                  <Amount>
                    {`$ ${formatter.format(cData.amount)}`}
                  </Amount>
                </Col>
              </Row>
            </Footer>
          </Col>
        </Row>
      </ContributionWrapper>
    </>
  );
};
ContributionGrid.propTypes = {
  cData: PropTypes.object,
};
export default ContributionGrid;

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Cookies from 'js-cookie';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import AccountingMenu from '../../../components/accounting/menu';
import ReportSubMenu from '../../../components/accounting/report_submenu';
import Breadcrumbs from '../../../components/breadcrumbs';
import arrowRightIcon from '../../../assets/icons/arrow-right.svg';
import downloadArrow from '../../../assets/icons/download.svg';
import { Row, Col } from '../../../components/styled_components/grid/Grid.style';
import { AccountingPageLayout, PageHeading, PageSubHeading } from '../../../theme/GlobalStyles';
import {
  BudgetReportDiv,
  BoldItems,
  LineItems,
  ItemTotal,
  DownloadButton,
  DownloadButtonIcon,
  DownloadImage,
  FinalTotal,
  BudgetReportSubHeadDiv,
  NoData,
  StyledDate,
  CustomSelect,
  DisplayButton, DisplayButtonIcon, BudgetReportHeadDiv, BudgetReportBodyDiv, ReportTitle,
} from './BudgetReport.style';
import { fetcher, fileDownloader } from '../../../hooks/useFetch';
import { ACCT_API } from '../../../constants';
import Loader from '../../../components/styled_components/loader/loader.style';
import ScrollDownButton from '../../../utils/ScrollDownButton';
import ScrollUpButton from '../../../utils/ScrollUpButton';

const breadcrumbs = ['Accounting', 'Reports', 'Summarized Report'];

const BudgetReport = () => {
  const [startDate, setStartDate] = useState(moment().format('YYYY-01-01'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [bankAccount, setBankAccount] = useState();
  const [receiptAccount, setReceiptAccount] = useState();
  const [paymentAccount, setPaymentAccount] = useState();
  const [accountObject, setAccountObject] = useState({});
  const [receiptTotal, setReceiptTotal] = useState();
  const [paymentTotal, setPaymentTotal] = useState();
  const [openingTotal, setOpeningTotal] = useState();
  const [closingTotal, setClosingTotal] = useState();
  const [income, setIncome] = useState();
  const [expense, setExpense] = useState();
  const [openingBudgetTotal, setOpeningBudgetTotal] = useState();
  const [receiptBudgetTotal, setReceiptBudgetTotal] = useState();
  const [paymentBudgetTotal, setPaymentBudgetTotal] = useState();
  const [closingBudgetTotal, setClosingBudgetTotal] = useState();
  const [budgetObject, setBudgetObject] = useState({});
  const [openingBudget, setOpeningBudget] = useState({});
  const [closingBudget, setClosingBudget] = useState({});
  const [totalBudget1, setTotalBudget1] = useState();
  const [totalBudget2, setTotalBudget2] = useState();
  const [budgetName, setBudgetName] = useState();
  const [view, setView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [budgetList, setBudgetList] = useState([]);
  const [opening, setOpening] = useState(false);
  const minDate = Cookies.get('accountant_year');
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const getBudgetList = async () => {
    setLoading(true);
    const res = await fetcher(
      {
        url: `${ACCT_API.GET_BUDGET_LIST}`,
        method: 'GET',
        authTokenKey: 'auth_token',
      },
    );
    if (res.budget_list) {
      const arr = [];
      res.budget_list.forEach((x) => {
        arr.push({ label: x.name, value: x.name });
      });
      setBudgetList(arr);
      setLoading(false);
    }
  };
  const getBudgetVariance = (type, num1, num2) => {
    let total;
    if (type === 'item') {
      total = parseFloat(num1) - parseFloat(num2);
    } else if (type === 'subTotal') {
      const budget = num1.value.reduce(
        (acc, curVal) => acc + parseFloat(curVal.openingBudget, 10),
        0,
      );
      const amount = num1.value.reduce(
        (acc, curVal) => acc + parseFloat(curVal.openingBalance, 10),
        0,
      );
      total = parseFloat(budget) - parseFloat(amount);
    } else if (type === 'subTotalIncome') {
      const budget = num1.value.reduce(
        (acc, curVal) => acc + parseFloat(curVal.budget, 10),
        0,
      );
      const amount = num1.value.reduce(
        (acc, curVal) => acc + parseFloat(curVal.amount, 10),
        0,
      );
      total = parseFloat(amount) - parseFloat(budget);
    } else if (type === 'subTotalPayments') {
      const budget = num1.value.reduce(
        (acc, curVal) => acc + parseFloat(curVal.budget, 10),
        0,
      );
      const amount = num1.value.reduce(
        (acc, curVal) => acc + parseFloat(curVal.amount, 10),
        0,
      );
      total = parseFloat(budget) - parseFloat(amount);
    } else if (type === 'subTotalExpense') {
      const budget = num1.value.reduce(
        (acc, curVal) => acc + parseFloat(curVal.closingBudget, 10),
        0,
      );
      const amount = num1.value.reduce(
        (acc, curVal) => acc + parseFloat(curVal.closingBalance, 10),
        0,
      );
      total = parseFloat(budget) - parseFloat(amount);
    }
    return total;
  };
  useEffect(() => {
    getBudgetList();
  }, []);
  const myFun = (acct) => {
    const obj = acct.value.map((item) => ({
      ...item,
      budget: parseFloat(budgetObject[item.acct_head_description] || 0),
    }));
    return obj;
  };
  const getReport = async () => {
    if (!budgetName) {
      toast.error('Please select a budget name', { autoClose: 3000 });
      return;
    }
    setLoading(true);
    setOpening(false);
    const budgetReport = await fetcher(
      {
        url: `${ACCT_API.GET_BUDGET}?name=${budgetName}`,
        method: 'GET',
        authTokenKey: 'auth_token',
      },
    );
    if (budgetReport) {
      const arr = budgetReport.budget.BudgetDetail.filter((x) => x.AccountRef.name !== 'Parsonage Maintenance');
      arr.forEach((item) => (() => {
        const accountNumber = item.AccountRef.accNum;
        let budget;
        if (item.Amount === undefined) {
          budget = 0;
        } else {
          budget = item.Amount;
        }
        const tempObject = budgetObject;
        const openObject = openingBudget;
        const closeObject = closingBudget;
        if (item.BankType === 'Open') {
          openObject[accountNumber] = budget;
        } else if (item.BankType === 'Close') {
          closeObject[accountNumber] = budget;
        } else {
          tempObject[accountNumber] = budget;
        }
        setOpeningBudget(openObject);
        setClosingBudget(closeObject);
        setBudgetObject(tempObject);
      })());
    }
    const bank = await fetcher(
      {
        url: `${ACCT_API.GET_BANK_SUMMARIZED}`,
        method: 'GET',
        authTokenKey: 'auth_token',
      },
    );
    const receipts = await fetcher(
      {
        url: `${ACCT_API.GET_RECEIPTS_SUMMARIZED}?startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
        authTokenKey: 'auth_token',
      },
    );
    if (receipts) {
      const getBudget = receipts.response.map((item) => ({ ...item, value: myFun(item) }));
      setReceiptAccount(getBudget);
    }
    const payments = await fetcher(
      {
        url: `${ACCT_API.GET_PAYMENTS_SUMMARIZED}?startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
        authTokenKey: 'auth_token',
      },
    );
    if (payments) {
      const indexHead = payments.response.findIndex((x) => x.parent_acct_head === 'Church and Parsonage Maintenance');
      const index = payments.response[indexHead].value.findIndex((x) => x.acct_head_name === 'Parsonage Maintenance');
      payments.response[indexHead].value.splice(index, 1);
      const getBudget = payments.response.map((item) => ({ ...item, value: myFun(item) }));
      setPaymentAccount(getBudget);
    }
    const balanceSheet = await fetcher(
      {
        url: `${ACCT_API.GET_BALANCE_SHEET}?endDate=${endDate}`,
        method: 'GET',
        authTokenKey: 'auth_token',
      },
    );
    if (balanceSheet) {
      balanceSheet.forEach((item) => (() => {
        let opening = item.openingBalance;
        const closing = item.closingBalance;
        if (opening === undefined) {
          opening = 0;
        }
        const tempObject = accountObject;
        tempObject[item.acctNumber] = [opening, closing];
        setAccountObject(tempObject);
      })());
    }
    if (bank && Object.keys(accountObject).length > 0) {
      const check = ['EM Church Facility Project', 'EM Pay Check Protection', 'EM Gen. Trinity Center Equipment XXXX7994'];
      setBankAccount(
        bank.response.map((item) => ({
          ...item,
          value: item.value
            .filter((i) => !check.includes(i.acct_head_name))
            .map((i) => (accountObject[i.accountNumber]
              ? {
                ...i,
                openingBalance: parseFloat(accountObject[i.accountNumber][0]),
                closingBalance: parseFloat(accountObject[i.accountNumber][1]),
              }
              : {
                ...i,
                openingBalance: 0,
                closingBalance: 0,
              })),
        })),
      );
    }
  };
  useEffect(() => {
    if (bankAccount && !opening) {
      setBankAccount(
        bankAccount.map((item) => ({
          ...item,
          value: item.value.map((i) => (openingBudget[i.accountNumber]
            || closingBudget[i.accountNumber]
            ? {
              ...i,
              openingBudget: parseFloat(openingBudget[i.accountNumber]),
              closingBudget: parseFloat(closingBudget[i.accountNumber]),
            }
            : {
              ...i,
              openingBudget: 0,
              closingBudget: 0,
            })),
        })),
      );
      setOpening(true);
    }
  }, [bankAccount]);
  const downloadReport = async () => {
    if (!budgetName) {
      toast.error('Please select a budget name', { autoClose: 3000 });
      return;
    }
    try {
      const download = await fileDownloader(
        {
          url: `${ACCT_API.DOWNLOAD_BUDGET_REPORT}?startDate=${startDate}&endDate=${endDate}&name=${budgetName}`,
          method: 'GET',
          authTokenKey: 'auth_token',
        },
      );
      if (download) {
        saveAs(new Blob([download], { type: 'application/vnd.ms-excel' }), 'Budget Report.xlsx');
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };
  useEffect(() => {
    if (bankAccount && receiptAccount) {
      const receiptTotal = [];
      const openingTotal = [];
      const openingBudget = [];
      const receiptBudget = [];
      receiptAccount.map((item) => receiptTotal.push(
        item.value.reduce(
          (acc, curVal) => acc + parseFloat(curVal.amount, 10),
          0,
        ),
      ));
      receiptAccount.map((item) => receiptBudget.push(
        item.value.reduce(
          (acc, curVal) => acc + parseFloat(curVal.budget, 10),
          0,
        ),
      ));
      setReceiptTotal(receiptTotal.reduce((a, b) => a + b, 0));
      setReceiptBudgetTotal(receiptBudget.reduce((a, b) => a + b, 0));
      bankAccount.map((item) => openingTotal.push(
        item.value.reduce(
          (acc, curVal) => acc + parseFloat(curVal.openingBalance, 10),
          0,
        ),
      ));
      bankAccount.map((item) => openingBudget.push(
        item.value.reduce(
          (acc, curVal) => acc + parseFloat(curVal.openingBudget, 10),
          0,
        ),
      ));
      const merged = receiptTotal.concat(openingTotal);
      const budgetMerged = receiptBudget.concat(openingBudget);
      setIncome(merged.reduce((a, b) => a + b, 0));
      setOpeningTotal(openingTotal.reduce((a, b) => a + b, 0));
      setOpeningBudgetTotal(openingBudget.reduce((a, b) => a + b, 0));
      setTotalBudget1(budgetMerged.reduce((a, b) => a + b, 0));
    }
    if (bankAccount && paymentAccount) {
      const paymentTotal = [];
      const closingTotal = [];
      const paymentBudget = [];
      const closingBudget = [];
      paymentAccount.map((item) => paymentTotal.push(
        item.value.reduce(
          (acc, curVal) => acc + parseFloat(curVal.amount, 10),
          0,
        ),
      ));
      paymentAccount.map((item) => paymentBudget.push(
        item.value.reduce(
          (acc, curVal) => acc + parseFloat(curVal.budget, 10),
          0,
        ),
      ));
      setPaymentTotal(paymentTotal.reduce((a, b) => a + b, 0));
      setPaymentBudgetTotal(paymentBudget.reduce((a, b) => a + b, 0));
      bankAccount.map((item) => closingTotal.push(
        item.value.reduce(
          (acc, curVal) => acc + parseFloat(curVal.closingBalance, 10),
          0,
        ),
      ));
      bankAccount.map((item) => closingBudget.push(
        item.value.reduce(
          (acc, curVal) => acc + parseFloat(curVal.closingBudget, 10),
          0,
        ),
      ));
      const merged = paymentTotal.concat(closingTotal);
      const budgetMerged = paymentBudget.concat(closingBudget);
      setExpense(merged.reduce((a, b) => a + b, 0));
      setClosingTotal(closingTotal.reduce((a, b) => a + b, 0));
      setClosingBudgetTotal(closingBudget.reduce((a, b) => a + b, 0));
      setTotalBudget2(budgetMerged.reduce((a, b) => a + b, 0));
      setLoading(false);
      setView(true);
    }
  }, [bankAccount]);
  return (
    <>
      <AccountingMenu />
      {loading ? <Loader /> : null}
      <ScrollUpButton />
      <ScrollDownButton />
      <AccountingPageLayout>
        <PageHeading>Reports</PageHeading>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <ReportSubMenu />
        <PageSubHeading>Summarized Report</PageSubHeading>
        <BudgetReportDiv>
          <Row>
            <Col col={2}>
              <CustomSelect
                className="select"
                classNamePrefix="custom"
                options={budgetList}
                placeholder="Budget List"
                name="type"
                onChange={(e) => {
                  setBudgetName(e.value);
                }}
              />
            </Col>
            <Col col={2}>
              <StyledDate
                width="88%"
                type="date"
                min={`${minDate}-01-01`}
                max={`${minDate}-12-31`}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Col>
            <Col col={2}>
              <StyledDate
                width="88%"
                type="date"
                min={`${minDate}-01-01`}
                max={`${minDate}-12-31`}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Col>
            <Col col={6}>
              <DownloadButton type="button" onClick={() => downloadReport()}>
                Download
                <DownloadButtonIcon>
                  <DownloadImage
                    src={downloadArrow}
                    alt=">"
                    height="13px"
                  />
                </DownloadButtonIcon>
              </DownloadButton>
              <DisplayButton type="button" onClick={() => getReport()}>
                Display
                <DisplayButtonIcon>
                  <img src={arrowRightIcon} alt=">" height="16px" />
                </DisplayButtonIcon>
              </DisplayButton>
            </Col>
          </Row>
        </BudgetReportDiv>
        {view && ((bankAccount && bankAccount.length > 0)
        || (receiptAccount && receiptAccount.length > 0)
        || (paymentAccount && paymentAccount.length > 0)) ? (
          <>
            <BudgetReportHeadDiv>
              <Row marginTop="18px" marginBottom="18px">
                <Col textAlign="center" col={1}>
                  Sr.
                </Col>
                <Col col={4}>
                  Description
                </Col>
                <Col col={1}>
                  Acct#
                </Col>
                <Col col={2} textAlign="right">
                  Budget
                </Col>
                <Col textAlign="right" col={2}>
                  Actual
                </Col>
                <Col textAlign="right" col={2}>
                  Difference
                </Col>
              </Row>
            </BudgetReportHeadDiv>
            <BudgetReportBodyDiv>
              <>
                <BudgetReportSubHeadDiv>
                  <Row marginTop="18px" marginBottom="18px" marginLeft="15px">
                    <Col textAlign="left" col={12}>
                      Receipts
                    </Col>
                  </Row>
                </BudgetReportSubHeadDiv>
                <ReportTitle>General Fund Balance Sheet Account - Opening Balance</ReportTitle>
                {bankAccount && bankAccount.map((main) => (
                  <>
                    <Row>
                      <Col col={12}><BoldItems>{main.parent_acct_head}</BoldItems></Col>
                    </Row>
                    {main.value.map((sub, index) => (
                      <LineItems>
                        <Row>
                          <Col textAlign="center" col={1}>{index + 1}</Col>
                          <Col col={4}>{sub.acct_head_name}</Col>
                          <Col col={1}>{sub.accountNumber}</Col>
                          <Col col={2} textAlign="right">
                            $
                            {' '}
                            {formatter
                              .format(sub.openingBudget)}
                          </Col>
                          <Col col={2} textAlign="right">
                            $
                            {' '}
                            {formatter.format(sub.openingBalance)}
                          </Col>
                          <Col col={2} textAlign="right">
                            $
                            {' '}
                            {formatter
                              .format(getBudgetVariance('item', sub.openingBudget, sub.openingBalance))}
                          </Col>
                        </Row>
                      </LineItems>
                    ))}
                    <ItemTotal>
                      <Row>
                        <Col col={6} textAlign="right">
                          Total for
                          {' '}
                          {main.parent_acct_head}
                          {' '}
                          :
                        </Col>
                        <Col col={2} textAlign="right">
                          $
                          {' '}
                          {formatter
                            .format(main.value.reduce(
                              (acc, curVal) => acc + parseFloat(curVal.openingBudget, 10),
                              0,
                            ))}
                        </Col>
                        <Col col={2} textAlign="right">
                          $
                          {' '}
                          {formatter
                            .format(main.value.reduce(
                              (acc, curVal) => acc + parseFloat(curVal.openingBalance, 10),
                              0,
                            ))}
                        </Col>
                        <Col col={2} textAlign="right">
                          $
                          {' '}
                          {formatter
                            .format(getBudgetVariance('subTotal', main))}
                        </Col>
                      </Row>
                    </ItemTotal>
                  </>
                ))}
                <FinalTotal>
                  <Row>
                    <Col col={6} textAlign="right">Total for : General Fund Balance Sheet Account - Opening Balance</Col>
                    <Col col={2} textAlign="right">
                      $
                      {' '}
                      {formatter.format(openingBudgetTotal)}
                    </Col>
                    <Col col={2} textAlign="right">
                      $
                      {' '}
                      {formatter.format(openingTotal)}
                    </Col>
                    <Col col={2} textAlign="right">
                      $
                      {' '}
                      {formatter.format(getBudgetVariance('item', openingBudgetTotal, openingTotal))}
                    </Col>
                  </Row>
                </FinalTotal>
              </>
            </BudgetReportBodyDiv>
            <BudgetReportBodyDiv>
              <>
                <ReportTitle>General Fund</ReportTitle>
                {receiptAccount && receiptAccount.map((main) => (
                  <>
                    <Row>
                      <Col col={12}><BoldItems>{main.parent_acct_head}</BoldItems></Col>
                    </Row>
                    {main.value.map((sub, index) => (
                      <LineItems>
                        <Row>
                          <Col textAlign="center" col={1}>{index + 1}</Col>
                          <Col col={4}>{sub.acct_head_name}</Col>
                          <Col col={1}>{sub.acct_head_description}</Col>
                          <Col col={2} textAlign="right">
                            $
                            {' '}
                            {formatter
                              .format(sub.budget || 0)}
                          </Col>
                          <Col col={2} textAlign="right">
                            $
                            {' '}
                            {formatter.format(sub.amount)}
                          </Col>
                          <Col col={2} textAlign="right">
                            $
                            {' '}
                            {formatter.format(getBudgetVariance('item', sub.amount, sub.budget))}
                          </Col>
                        </Row>
                      </LineItems>
                    ))}
                    <ItemTotal>
                      <Row>
                        <Col col={6} textAlign="right">
                          Total for
                          {' '}
                          {main.parent_acct_head}
                          {' '}
                          :
                        </Col>
                        <Col col={2} textAlign="right">
                          $
                          {' '}
                          {formatter
                            .format(main.value.reduce(
                              (acc, curVal) => acc + parseFloat(curVal.budget, 10),
                              0,
                            ))}
                        </Col>
                        <Col col={2} textAlign="right">
                          $
                          {' '}
                          {formatter
                            .format(main.value.reduce(
                              (acc, curVal) => acc + parseFloat(curVal.amount, 10),
                              0,
                            ))}
                        </Col>
                        <Col col={2} textAlign="right">
                          $
                          {' '}
                          {formatter
                            .format(getBudgetVariance('subTotalIncome', main))}
                        </Col>
                      </Row>

                    </ItemTotal>
                  </>
                ))}
                <Row>
                  <Col col={6} textAlign="right"><BoldItems>Total for: General Fund</BoldItems></Col>
                  <Col col={2} textAlign="right">
                    <BoldItems>
                      $
                      {' '}
                      {formatter.format(receiptBudgetTotal)}
                    </BoldItems>
                  </Col>
                  <Col col={2} textAlign="right">
                    <BoldItems>
                      $
                      {' '}
                      {formatter.format(receiptTotal)}
                    </BoldItems>
                  </Col>
                  <Col col={2} textAlign="right">
                    <BoldItems>
                      $
                      {' '}
                      {formatter.format(getBudgetVariance('item', receiptTotal, receiptBudgetTotal))}
                    </BoldItems>
                  </Col>
                </Row>
                <FinalTotal>
                  <Row>
                    <Col col={6} textAlign="right">Total for: Receipts</Col>
                    <Col col={2} textAlign="right">
                      $
                      {' '}
                      {formatter.format(totalBudget1)}
                    </Col>
                    <Col col={2} textAlign="right">
                      $
                      {' '}
                      {formatter.format(income)}
                    </Col>
                    <Col col={2} textAlign="right">
                      $
                      {' '}
                      {formatter.format(getBudgetVariance('item', income, totalBudget1))}
                    </Col>
                  </Row>
                </FinalTotal>
              </>
            </BudgetReportBodyDiv>
            <BudgetReportBodyDiv>
              <BudgetReportSubHeadDiv>
                <Row marginTop="18px" marginBottom="18px" marginLeft="15px">
                  <Col textAlign="left" col={12}>
                    Payments
                  </Col>
                </Row>
              </BudgetReportSubHeadDiv>
              <ReportTitle>General Fund</ReportTitle>
              {paymentAccount && paymentAccount.map((main) => (
                <>
                  <Row>
                    <Col col={12}><BoldItems>{main.parent_acct_head}</BoldItems></Col>
                  </Row>
                  {main.value.map((sub, index) => (
                    <LineItems>
                      <Row>
                        <Col textAlign="center" col={1}>{index + 1}</Col>
                        <Col col={4}>{sub.acct_head_name}</Col>
                        <Col col={1}>{sub.acct_head_description}</Col>
                        <Col col={2} textAlign="right">
                          $
                          {' '}
                          {formatter
                            .format(sub.budget || 0)}
                        </Col>
                        <Col col={2} textAlign="right">
                          $
                          {' '}
                          {formatter.format(sub.amount)}
                        </Col>
                        <Col col={2} textAlign="right">
                          $
                          {' '}
                          {formatter.format(getBudgetVariance('item', sub.budget, sub.amount))}
                        </Col>
                      </Row>
                    </LineItems>
                  ))}
                  <ItemTotal>
                    <Row>
                      <Col col={6} textAlign="right">
                        Total for
                        {' '}
                        {main.parent_acct_head}
                        {' '}
                        :
                      </Col>
                      <Col col={2} textAlign="right">
                        $
                        {' '}
                        {formatter
                          .format(main.value.reduce(
                            (acc, curVal) => acc + parseFloat(curVal.budget, 10),
                            0,
                          ))}
                      </Col>
                      <Col col={2} textAlign="right">
                        $
                        {' '}
                        {formatter
                          .format(main.value.reduce(
                            (acc, curVal) => acc + parseFloat(curVal.amount, 10),
                            0,
                          ))}
                      </Col>
                      <Col col={2} textAlign="right">
                        $
                        {' '}
                        {formatter
                          .format(getBudgetVariance('subTotalPayments', main))}
                      </Col>
                    </Row>
                  </ItemTotal>

                </>
              ))}
              <Row>
                <Col col={6} textAlign="right"><BoldItems>Total for General Fund:</BoldItems></Col>
                <Col col={2} textAlign="right">
                  <BoldItems>
                    $
                    {' '}
                    {formatter.format(paymentBudgetTotal)}
                  </BoldItems>
                </Col>
                <Col col={2} textAlign="right">
                  <BoldItems>
                    $
                    {' '}
                    {formatter.format(paymentTotal)}
                  </BoldItems>
                </Col>
                <Col col={2} textAlign="right">
                  <BoldItems>
                    $
                    {' '}
                    {formatter.format(getBudgetVariance('item', paymentBudgetTotal, paymentTotal))}
                  </BoldItems>
                </Col>
              </Row>
            </BudgetReportBodyDiv>
            <BudgetReportBodyDiv>
              <ReportTitle>General Fund Balance Sheet Account - Closing Balance</ReportTitle>
              {bankAccount && bankAccount.map((main) => (
                <>
                  <Row>
                    <Col col={12}><BoldItems>{main.parent_acct_head}</BoldItems></Col>
                  </Row>
                  {main.value.map((sub, index) => (
                    <LineItems>
                      <Row>
                        <Col textAlign="center" col={1}>{index + 1}</Col>
                        <Col col={4}>{sub.acct_head_name}</Col>
                        <Col col={1}>{sub.accountNumber}</Col>
                        <Col col={2} textAlign="right">
                          $
                          {' '}
                          {formatter
                            .format(sub.closingBudget)}
                        </Col>
                        <Col col={2} textAlign="right">
                          $
                          {' '}
                          {formatter.format(sub.closingBalance)}
                        </Col>
                        <Col col={2} textAlign="right">
                          $
                          {' '}
                          {formatter.format(getBudgetVariance('item', sub.closingBudget, sub.closingBalance))}
                        </Col>
                      </Row>
                    </LineItems>
                  ))}
                  <ItemTotal>
                    <Row>
                      <Col col={6} textAlign="right">
                        Total for
                        {' '}
                        {main.parent_acct_head}
                        {' '}
                        :
                      </Col>
                      <Col col={2} textAlign="right">
                        $
                        {' '}
                        {formatter
                          .format(main.value.reduce(
                            (acc, curVal) => acc + parseFloat(curVal.closingBudget, 10),
                            0,
                          ))}
                      </Col>
                      <Col col={2} textAlign="right">
                        $
                        {' '}
                        {formatter
                          .format(main.value.reduce(
                            (acc, curVal) => acc + parseFloat(curVal.closingBalance, 10),
                            0,
                          ))}
                      </Col>
                      <Col col={2} textAlign="right">
                        $
                        {' '}
                        {formatter
                          .format(getBudgetVariance('subTotalExpense', main))}
                      </Col>
                    </Row>
                  </ItemTotal>
                </>
              ))}
              <Row>
                <Col col={6} textAlign="right"><BoldItems>Total for : General Fund Balance Sheet Account - Closing Balance:</BoldItems></Col>
                <Col col={2} textAlign="right">
                  <BoldItems>
                    $
                    {' '}
                    {formatter.format(closingBudgetTotal)}
                  </BoldItems>
                </Col>
                <Col col={2} textAlign="right">
                  <BoldItems>
                    $
                    {' '}
                    {formatter.format(closingTotal)}
                  </BoldItems>
                </Col>
                <Col col={2} textAlign="right">
                  <BoldItems>
                    $
                    {' '}
                    {formatter.format(getBudgetVariance('item', closingBudgetTotal, closingTotal))}
                  </BoldItems>
                </Col>
              </Row>
              <FinalTotal>
                <Row>
                  <Col col={6} textAlign="right">Total for: Payments</Col>
                  <Col col={2} textAlign="right">
                    $
                    {' '}
                    {formatter.format(totalBudget2)}
                  </Col>
                  <Col col={2} textAlign="right">
                    $
                    {' '}
                    {formatter.format(expense)}
                  </Col>
                  <Col col={2} textAlign="right">
                    $
                    {' '}
                    {formatter.format(getBudgetVariance('item', totalBudget2, expense))}
                  </Col>
                </Row>
              </FinalTotal>
            </BudgetReportBodyDiv>
          </>
          ) : view && (bankAccount && bankAccount.length > 0)
          && (receiptAccount && receiptAccount.length > 0)
          && (paymentAccount && paymentAccount.length > 0)
            ? (
              <NoData>
                No Data
              </NoData>
            )
            : ''}
      </AccountingPageLayout>

    </>
  );
};
export default BudgetReport;

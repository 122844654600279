import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from '../../styled_components/grid/Grid.style';
import {
  MenuWrapper,
  Menu,
  DropdownContent,
  DropDownItem,
} from './MemberReportsMenu.style';
import ArrowDown from '../../../assets/icons/arrow-down.svg';

const MemberReportsMenu = () => {
  const [displayGeneralReport, setDisplayGeneralReport] = useState('none');
  const [displayLabelReport, setDisplayLabelReport] = useState('none');
  const navigate = useNavigate();
  const handleClick = (link) => {
    navigate(link);
  };

  const showGeneralReportMenu = () => {
    setDisplayLabelReport('none');
    if (displayGeneralReport === 'block') {
      setDisplayGeneralReport('none');
    } else {
      setDisplayGeneralReport('block');
    }
  };

  const showLabelReportMenu = () => {
    setDisplayGeneralReport('none');
    if (displayLabelReport === 'block') {
      setDisplayLabelReport('none');
    } else {
      setDisplayLabelReport('block');
    }
  };

  document.addEventListener('click', (e) => {
    if (document.getElementById('labelreports')?.contains(e.target)) {
      setDisplayLabelReport('block');
    } else {
      setDisplayLabelReport('none');
    }
    if (document.getElementById('generalreports')?.contains(e.target)) {
      setDisplayGeneralReport('block');
    } else {
      setDisplayGeneralReport('none');
    }
  });

  return (
    <>
      <MenuWrapper>
        <Row>
          <Col col={4}>
            <Menu topLeftBorderRadius="40px">
              <Row
                onClick={() => handleClick('/member-reports/general-body-list')}
              >
                <Col col={1} />
                <Col col={10}>General Body List</Col>
              </Row>
            </Menu>
          </Col>
          <Col col={4}>
            <Menu id="labelreports">
              <Row onClick={showLabelReportMenu}>
                <Col col={11}>Label Reports</Col>
                <Col col={1}>
                  <img
                    src={ArrowDown}
                    width={12}
                    height={10}
                    alt="arrow down"
                  />
                </Col>
              </Row>
              <DropdownContent display={displayLabelReport}>
                <DropDownItem
                  onClick={() => handleClick('/member-reports/label-reports/birthday-report')}
                >
                  Birthday/Wedding
                </DropDownItem>
                <DropDownItem
                  onClick={() => handleClick('/member-reports/label-reports/all-members-details')}
                >
                  All Head Of The House
                </DropDownItem>
              </DropdownContent>
            </Menu>
          </Col>
          <Col col={4}>
            <Menu id="generalreports">
              <Row onClick={showGeneralReportMenu}>
                <Col col={11}>General Reports</Col>
                <Col col={1}>
                  <img
                    src={ArrowDown}
                    width={12}
                    height={10}
                    alt="arrow down"
                  />
                </Col>
              </Row>
              <DropdownContent display={displayGeneralReport}>
                <DropDownItem
                  onClick={() => handleClick('/member-reports/general-reports/death-report')}
                >
                  Death Report
                </DropDownItem>
                <DropDownItem
                  onClick={() => handleClick('/member-reports/general-reports/prayer-group')}
                >
                  Prayer Group
                </DropDownItem>
                <DropDownItem
                  onClick={() => handleClick('/member-reports/general-reports/newly-added-member')}
                >
                  Newly Added Member
                </DropDownItem>
                <DropDownItem
                  onClick={() => handleClick('/member-reports/general-reports/male-female')}
                >
                  All Male/Female
                </DropDownItem>
                <DropDownItem
                  onClick={() => handleClick('/member-reports/general-reports/newly-wedded-membership')}
                >
                  Newly Wedded Membership
                </DropDownItem>
                <DropDownItem
                  onClick={() => handleClick('/member-reports/general-reports/new-address-change')}
                >
                  New Address Change
                </DropDownItem>
              </DropdownContent>
            </Menu>
          </Col>
        </Row>
      </MenuWrapper>
    </>
  );
};

export default MemberReportsMenu;

import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { ModalProvider } from 'styled-react-modal';
import moment from 'moment';
import Cookies from 'js-cookie';
import {
  Col,
  Row,
} from '../../../../components/styled_components/grid/Grid.style';
import {
  AccountingPageLayout,
  PageHeading,
} from '../../../../theme/GlobalStyles';
import {
  ReceiptSearchInputWrap,
  TransactionReceiptDisplayButton,
  TransactionReceiptDisplayButtonIcon,
  SearchInput,
  TotalAmtDiv,
  Total,
  NoData,
  CustomSelect,
} from './Receipts.style';
import arrowRightIcon from '../../../../assets/icons/arrow-right.svg';
import Table from '../../../../components/common/table';
import OrganisationMenu from '../../../../components/organisation/menu';
import Breadcrumbs from '../../../../components/breadcrumbs';
import { fetcher, fileDownloader, useFetch } from '../../../../hooks/useFetch';
import { API, ACCT_API } from '../../../../constants';
import Loader from '../../../../components/styled_components/loader/loader.style';
import ScrollDownButton from '../../../../utils/ScrollDownButton';
import ScrollUpButton from '../../../../utils/ScrollUpButton';
import EditModal from '../../../../components/organisation/edit_transactions/edit_modal';
import DeleteModal from '../../../../components/organisation/delete_transactions/delete_modal';

const TransactionsReceipts = () => {
  const breadcrumbs = ['Organisation', 'Transactions', 'Receipts'];
  const [editModal, setEditModal] = useState(false);
  const [data, setData] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loading, setLoading] = useState(false);
  const [lineItems, setLineItems] = useState();
  const [total, setTotalAmt] = useState(0);
  const [checkBank, setCheckBank] = useState([]);
  const [address, setAddress] = useState();
  const [organization, setOrganization] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [selectedReceipt, setSelectedReceipt] = useState();
  const today = moment().format('YYYY-MM-DD');
  const minDate = Cookies.get('Org_accountant_year');
  const bankAccount = 'Organization Bank Account1';
  const itemName = 'Organization';
  const org = Cookies.get('organization').split(',');
  const org_values = org.map((item) => item.split('-'));
  const org_val = org_values.map((item) => item[1]);
  const values = org_val.map((item) => ({
    value: item,
    label: item,
  }));
  const getMaxDate = () => {
    let maxDate;
    if (today.substring(0, 4) === minDate) {
      maxDate = today;
    } else {
      maxDate = `${minDate}-12-31`;
    }
    return maxDate;
  };
  const getName = async (value) => {
    const res = await fetcher({
      url: `${API.GET_MEMBER_LIST}?query=${value}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    const memberNames = res.member_list.map((item) => ({
      value: item.membershipNumber,
      label: item.name,
    }));
    if (value.match(/0/i) || value.match(/non/i)) {
      return [...memberNames, { value: '0001', label: '0001 Non Member' }];
    } else {
      return memberNames;
    }
  };
  const getAddress = async (memberId) => {
    const res = await fetcher({
      url: `${API.GET_MEMBER_ADDRESS}?family_id=${memberId}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    setAddress(res);
  };
  const check = useFetch({
    url: `${ACCT_API.GET_CHECKBANKS}`,
    method: 'GET',
    authTokenKey: 'auth_token',
  });
  if (check.data && checkBank.length === 0) {
    setCheckBank(
      check.data.response.filter((i) => i.checkBank !== '').map((item) => ({
        value: item.checkBank,
        label: item.checkBank,
      })),
    );
  }
  const handleView = async () => {
    setLoading(true);
    const res = await fetcher({
      url: `${ACCT_API.GET_ORG_RECEIPTS}?&startDate=${startDate}&endDate=${endDate}&organization=${organization}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    setData(res.response);
    setLoading(false);
    let amt = 0;
    res.response.forEach((item) => {
      amt += item.amount;
    });
    setTotalAmt(amt);
  };
  const getCheckBank = async () => {
    try {
      const res = await fetcher({
        url: `${ACCT_API.GET_CHECKBANKS}`,
        method: 'GET',
        authTokenKey: 'auth_token',
      });
      if (res) {
        setCheckBank(
          res.response
            .map((item) => ({
              value: item.checkBank,
              label: item.checkBank,
            })),
        );
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };
  const createCheckBank = async (value) => {
    try {
      const res = await fetcher({
        url: `${ACCT_API.CREATE_CHECK_BANK}`,
        method: 'POST',
        authTokenKey: 'auth_token',
        body: JSON.stringify({ name: value }),
      });
      if (res.success) {
        toast.success('Successfully Created!', { autoClose: 3000 });
        getCheckBank();
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };
  const handleEdit = async (id) => {
    setEditModal(true);
    setLoading(true);
    const receipt = await fetcher({
      url: `${ACCT_API.GET_ORG_RECEIPT}?id=${id}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    getAddress(receipt.response.membershipId);
    setSelectedReceipt(receipt.response);
    setLineItems(receipt.response.lines.map((item) => ({
      category: item.itemName,
      amount: item.rate,
      particular: item.particulars,
    })));
    setLoading(false);
  };
  const handleChange = (e) => {
    const data = { ...selectedReceipt };
    data[e.target.name] = e.target.value;
    setSelectedReceipt(data);
  };
  const handleLineItems = (items) => {
    setLineItems(items);
  };
  const handleSubmit = async () => {
    const items = lineItems.map((item) => ({
      item_name: itemName,
      particulars: item.particular,
      rate: parseFloat(item.amount),
    }));
    let payload = {
      receiptId: selectedReceipt.id,
      receiptDate: selectedReceipt.date,
      bankAccount,
      membershipId: selectedReceipt.membershipId,
      organization,
      paymentMethod: selectedReceipt.paymentMethod,
      lineItems: items,
    };
    if (selectedReceipt.paymentMethod === 'Check') {
      payload = {
        ...payload,
        checkBank: selectedReceipt.checkBank,
        checkNumber: selectedReceipt.checkNumber,
      };
    }
    try {
      const res = await fetcher({
        url: `${ACCT_API.EDIT_ORG_RECEIPTS}`,
        method: 'POST',
        authTokenKey: 'auth_token',
        body: JSON.stringify(payload),
      });
      if (res.success) {
        toast.success('Successfully Edited!', { autoClose: 3000 });
        handleView();
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };
  const handleDeleteSubmit = async (reason) => {
    const payload = {
      receiptId: deleteId,
      confirm: 'yes',
      notes: reason,
    };
    try {
      const res = await fetcher({
        url: ACCT_API.VOID_RECEIPTS,
        method: 'POST',
        authTokenKey: 'auth_token',
        body: JSON.stringify(payload),
      });
      if (res) {
        toast.success('Successfully Deleted!', { autoClose: 3000 });
        handleView();
        setDeleteId('');
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };
  const deleteItem = async (id) => {
    setDeleteId(id);
    setDeleteModal(true);
  };
  const toggleModal = () => {
    setSelectedReceipt();
    setLineItems();
    setEditModal(false);
  };
  const toggleDeleteModal = () => {
    setDeleteModal(false);
    setDeleteId('');
  };
  const tableLookup = [
    {
      heading: 'Family ID',
      field_name: 'membershipId',
      type: 'string',
    },
    {
      heading: 'Member Name',
      field_name: 'memberName',
      type: 'string',
    },
    {
      heading: 'Date',
      field_name: 'date',
      type: 'date',
    },
    {
      heading: 'Organization',
      field_name: 'organization',
      type: 'string',
    },
    {
      heading: 'Notes',
      field_name: 'lines',
      type: 'particulars',
    },
    {
      heading: 'Payment Method',
      field_name: 'paymentMethod',
      type: 'string',
    },
    {
      heading: 'Amount',
      field_name: 'lines',
      type: 'rate',
    },
    {
      heading: 'Action',
      field_name: 'actions',
      type: 'download',
    },
  ];
  const downloadPdf = async (id) => {
    const downloadReceipts = await fileDownloader({
      url: `${ACCT_API.DOWNLOAD_ORG_RECEIPT}?id=${id}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    saveAs(new Blob([downloadReceipts], { type: 'application/pdf' }), 'Transaction Receipt.pdf');
  };
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const handleDisplay = (e) => {
    e.preventDefault();
    handleView();
    setData();
  };

  return (
    <>
      <OrganisationMenu />
      {loading ? <Loader /> : null}
      <ScrollUpButton />
      <ScrollDownButton />
      <ModalProvider>
        {lineItems ? (
          <EditModal
            lineItems={lineItems}
            selected={selectedReceipt}
            category={values}
            organizations={organization}
            address={address}
            checkBank={checkBank}
            createCheckBank={createCheckBank}
            getAddress={getAddress}
            getName={getName}
            handleChange={handleChange}
            handleLineItems={handleLineItems}
            handleSubmit={handleSubmit}
            toggleModal={toggleModal}
            isOpen={editModal}
            formatter={formatter}
            minDate={minDate}
            getMaxDate={getMaxDate}
          />
        ) : null}
      </ModalProvider>
      <ModalProvider>
        <DeleteModal
          handleDeleteSubmit={handleDeleteSubmit}
          toggleDeleteModal={toggleDeleteModal}
          isOpen={deleteModal}
        />
      </ModalProvider>
      <AccountingPageLayout>
        <Row>
          <Col>
            <PageHeading>Receipts</PageHeading>
          </Col>
        </Row>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <ReceiptSearchInputWrap>
          <form onSubmit={handleDisplay}>
            <Row>
              <Col col={3}>
                <SearchInput
                  type="date"
                  min={`${minDate}-01-01`}
                  max={`${minDate}-12-31`}
                  placeholder="Start Date"
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Col>
              <Col col={3}>
                <SearchInput
                  type="date"
                  min={`${minDate}-01-01`}
                  max={`${minDate}-12-31`}
                  placeholder="End Date"
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Col>
              <Col col={3}>
                <CustomSelect
                  className="select"
                  classNamePrefix="custom"
                  options={values}
                  placeholder="Organization"
                  onChange={(e) => setOrganization(e.value)}
                />
              </Col>
              <Col col={3}>
                <TransactionReceiptDisplayButton
                  type="submit"
                  disabled={!(startDate && endDate)}
                >
                  Display
                  <TransactionReceiptDisplayButtonIcon>
                    <img src={arrowRightIcon} alt=">" height="16px" />
                  </TransactionReceiptDisplayButtonIcon>
                </TransactionReceiptDisplayButton>
              </Col>
            </Row>
          </form>
        </ReceiptSearchInputWrap>
        {data && data.length > 0 ? (
          <>
            <Table
              data={data || []}
              tableLookup={tableLookup}
              downloadPdf={downloadPdf}
              handleEdit={handleEdit}
              deleteItem={deleteItem}
            />
            <TotalAmtDiv>
              <Total>
                {`$ ${formatter.format(total)}`}
              </Total>
            </TotalAmtDiv>
          </>
        ) : data && data.length === 0 ? (
          <NoData>
            No Data
          </NoData>
        ) : ''}
      </AccountingPageLayout>
    </>
  );
};

export default TransactionsReceipts;

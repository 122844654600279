import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  TableData, TableDiv, TableHead, TableSubHead, THead,
} from './ict.style';

const formatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const ItemizedContributionTable = ({ data, tableLookup }) => (
  <TableDiv>
    <table>
      <THead>
        <tr>
          {tableLookup.map(
            (elem) => (
              (elem.heading === 'Area' || elem.heading === 'ID #' || elem.heading === 'Name' || elem.heading === 'Total')
                ? (
                  <TableHead key={elem.heading}>{elem.heading}</TableHead>
                ) : (
                  <TableSubHead key={elem.heading}>{elem.heading}</TableSubHead>
                )
            ),
          )}
        </tr>
      </THead>
      <tbody>
        {data.map((row) => (
          <tr key={row.id}>
            {tableLookup.map((field) => (
              field.type === 'amount' ? (
                <TableData fontWeight={row.name ? 'bold' : 500} key={field.field_name} textAlign="right">
                  $
                  {' '}
                  {formatter.format(row[field.field_name])}
                </TableData>
              ) : field.type === 'array' ? (
                <TableData key={field.field_name} textAlign="left">
                  {row[field.field_name].map((subarray) => (
                    <div key={subarray.itemName}>
                      <span>{subarray.itemName || subarray.Category}</span>
                      <br />
                    </div>
                  ))}
                </TableData>

              ) : field.type === 'date' ? (
                <TableData key={field.field_name}>{moment(row[field.field_name]).format('MM-DD-YYYY')}</TableData>
              )
                : (row.name && (field.field_name === 'Area' || field.field_name === 'ID #' || field.field_name === 'Name'))
                  ? (
                    <TableData borderLeft="0px" fontWeight="bold" key={field.field_name}>
                      {field.field_name === 'Area' ? row.name : ''}
                    </TableData>
                  )
                  : (
                    <TableData fontWeight={row.name ? 'bold' : 500} key={field.field_name}>
                      {typeof (row[field.field_name]) === 'string'
                        ? row[field.field_name]
                        : formatter.format(row[field.field_name])}
                    </TableData>
                  )
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </TableDiv>
);

ItemizedContributionTable.propTypes = {
  data: PropTypes.array,
  tableLookup: PropTypes.array,
};
export default ItemizedContributionTable;

import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { ModalProvider } from 'styled-react-modal';
import Cookies from 'js-cookie';
import moment from 'moment';
import { PageHeading, PageLayout } from '../../theme/GlobalStyles';
import { Col, Row } from '../../components/styled_components/grid/Grid.style';
import ProfileSection from '../../components/membership_components/profile_section';
import PanelHeading from '../../components/membership_components/panel_heading';
import { normalizeInput } from '../../utils/NormalizeUSNumber';
import {
  WhiteBodySection,
  BlueBodySection,
  Panel,
  CustomSelect,
  PanelBody,
  FieldLabel,
  FieldValue,
  EditIcon,
  EditIconDiv,
  FieldValueInput,
  Div,
} from './Membership.style';
import FamilyPanel from '../../components/family_panel';
import tickIcon from '../../assets/icons/tick.svg';
import editIcon from '../../assets/icons/pen.svg';
import closeIcon from '../../assets/icons/close-icon.svg';
import { useFetch, fetcher } from '../../hooks/useFetch';
import { API } from '../../constants';
import EditAddressModal from '../../components/membership_components/edit_modal';
import InputTextBox from '../../components/membership_components/input_text_box';

const Membership = () => {
  const options = [
    { label: 'S', value: 'S' },
    { label: 'M', value: 'M' },
    { label: 'L', value: 'L' },
    { label: 'XL', value: 'XL' },
    { label: 'XXL', value: 'XXL' },
  ];
  const [editing, setEditing] = useState('');
  const [disableEditing, setDisableEditing] = useState(true);
  const [item, setItem] = useState();
  const [memberDetails, setMemberDetails] = useState();
  const [modal, setModal] = useState(false);
  const [isHead, setIsHead] = useState(false);
  const [address, setAddress] = useState();
  const userId = Cookies.get('user_id');
  const roles = Cookies.get('user_roles').split(',');
  const [selectedId, setSelectedId] = useState(userId);
  const onHandleChange = (e) => {
    let getFormated;
    if (e.target.name === 'mobilePhone' || e.target.name === 'homePhone') {
      getFormated = normalizeInput(e.target.value);
      setItem({ [e.target.name]: getFormated });
    } else {
      setItem({ [e.target.name]: e.target.value });
    }
  };
  const toggleModal = () => {
    setModal(!modal);
  };
  const handleAddress = (e) => {
    setAddress({ ...address, [e.target.name]: e.target.value });
  };
  const { data } = useFetch({
    method: 'GET',
    url: API.MEMBER_DETAILS,
    authTokenKey: 'auth_token',
  });

  const getMemberDetails = async (userId) => {
    const res = await fetcher({
      url: `${API.MEMBER_DATA}?userid=${userId}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    if (res) {
      setMemberDetails(res);
      setAddress({
        address_1: res.address1,
        address_2: res.address2,
        city: res.city,
        state: res.state,
        ZipCode: res.zipcode,
      });
    }
  };
  useEffect(() => {
    getMemberDetails(userId);
  }, []);
  const member = useFetch(
    {
      url: API.GET_FAMILY,
      method: 'GET',
      authTokenKey: 'auth_token',
    },
  );
  const handleCheck = () => {
    const currentMember = { ...memberDetails };
    Object.keys(item).forEach((elem) => {
      currentMember[elem] = item[elem];
    });
    setMemberDetails(currentMember);
  };
  const handleSubmit = async (e) => {
    handleCheck(e);
    try {
      const response = await fetcher({
        url: API.UPDATE_MEMBER_DETAILS,
        method: 'POST',
        body: JSON.stringify({ ...item, userId: selectedId }),
      });
      if (response.success) {
        toast.success('Successfully updated', { autoClose: 3000 });
        setDisableEditing(true);
        setEditing('');
        setItem();
        setMemberDetails();
        getMemberDetails(selectedId);
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
      setEditing('');
    }
  };
  const handleUpdate = async () => {
    try {
      const response = await fetcher({
        url: API.UPDATE_ADDRESS,
        method: 'POST',
        body: JSON.stringify(address),
      });
      if (response.success) {
        toast.success('Request send for approval', { autoClose: 3000 });
        setDisableEditing(true);
        setEditing('');
        setItem();
        setMemberDetails();
        getMemberDetails(selectedId);
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error(e.info.message, { autoClose: 3000 });
      setEditing('');
    }
  };
  const updateContact = async (e) => {
    handleCheck(e);
    e.preventDefault();
    try {
      const response = await fetcher({
        url: API.UPDATE_CONTACT,
        method: 'POST',
        body: JSON.stringify({ ...item, userId: selectedId }),
      });
      if (response.success) {
        toast.success('Successfully updated', { autoClose: 3000 });
        setDisableEditing(true);
        setEditing('');
        setItem();
        setMemberDetails();
        getMemberDetails(selectedId);
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
      setEditing('');
    }
  };
  const updateSocial = async (e) => {
    handleCheck(e);
    e.preventDefault();
    try {
      const response = await fetcher({
        url: API.UPDATE_SOCIAL,
        method: 'POST',
        body: JSON.stringify({ ...item, userId: selectedId }),
      });
      if (response.success) {
        toast.success('Successfully updated', { autoClose: 3000 });
        setDisableEditing(true);
        setEditing('');
        setItem();
        setMemberDetails();
        getMemberDetails(selectedId);
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
      setEditing('');
    }
  };
  const uploadProfilePic = async (event) => {
    const image = event.target.files[0];
    if (image.size > 1e6) {
      toast.warning('Error: Image size should be less than 1MB', {
        autoClose: 3000,
      });
    } else {
      const formData = new FormData();
      formData.append('profile_pic', image);
      formData.append('userId', selectedId);
      try {
        const res = await fetcher({
          url: `${API.UPLOAD_MEMBER_PHOTO}`,
          method: 'POST',
          authTokenKey: 'auth_token',
          body: formData,
          multiPart: true,
        });
        if (res.success) {
          toast.success('Successfully Updated!', { autoClose: 3000 });
          const res = await fetcher({
            url: `${API.MEMBER_DETAILS}`,
            method: 'GET',
            authTokenKey: 'auth_token',
          });
          if (res) {
            Cookies.set('photoUrl', res.profilePic);
          }
          window.location.reload();
        } else {
          toast.error('Error!', { autoClose: 3000 });
        }
      } catch (e) {
        toast.error('Error!', { autoClose: 3000 });
      }
    }
  };

  if (roles.includes('HeadOfFamily') && !isHead) {
    setIsHead(true);
  }
  const onEdit = (editField) => {
    setItem({ [editField]: memberDetails[editField] });
    setDisableEditing(false);
    setEditing(editField);
  };
  const onCancel = () => {
    setDisableEditing(true);
    setEditing('');
  };
  const memberChange = (id) => {
    setMemberDetails();
    getMemberDetails(id);
    setSelectedId(id);
  };

  return (
    <>
      <PageLayout className="layout">
        <ModalProvider>
          <EditAddressModal
            toggleModal={toggleModal}
            isOpen={modal}
            memberDetails={memberDetails}
            handleAddress={handleAddress}
            handleUpdate={handleUpdate}
          />

        </ModalProvider>
        <PageHeading>Membership</PageHeading>
        <ProfileSection
          member={data}
          memberDetails={memberDetails}
          showEditIcon={isHead}
          uploadProfilePic={uploadProfilePic}
        />
        <BlueBodySection>
          <Row>
            <Panel marginRight="20px">
              <PanelHeading label="My Households" />
              <PanelBody>
                <Row>
                  {member.data && member.data.details.length > 0
                    ? member.data.details.map((member, ind) => {
                      if (ind % 2 === 0) {
                        return (
                          <Col col={6} key={member.id}>
                            <FamilyPanel showEditIcon membership member={member} memberChange={memberChange} caller="membership" marginRight="20px" />
                          </Col>
                        );
                      }
                      return (
                        <Col col={6} key={member.id}>
                          <FamilyPanel
                            showEditIcon
                            membership
                            member={member}
                            memberChange={memberChange}
                            caller="membership"
                          />
                        </Col>
                      );
                    })
                    : null}
                </Row>
              </PanelBody>
            </Panel>
          </Row>
        </BlueBodySection>
        <WhiteBodySection>
          <Row>
            <Panel marginRight="20px">
              <PanelHeading label="Basic Information" />
              <PanelBody>
                <Row marginBottom="17px">
                  <Col col={4}>
                    <Div>
                      <FieldLabel>Name Prefix</FieldLabel>
                      <FieldValue>{memberDetails && memberDetails.saute}</FieldValue>
                    </Div>
                  </Col>
                  <Col col={4}>
                    <Div className="addMargin">
                      <FieldLabel>Given Name</FieldLabel>
                      <FieldValue>{memberDetails && memberDetails.givenName}</FieldValue>
                    </Div>
                  </Col>
                  <Col col={4}>
                    <Div>
                      <FieldLabel>First Name</FieldLabel>
                      <FieldValue>{memberDetails && memberDetails.firstName}</FieldValue>
                    </Div>
                  </Col>
                </Row>
                <Row marginBottom="17px">
                  <Col col={4}>
                    <Div>
                      <Row>
                        {
                          !disableEditing && editing === 'nickName' ? (
                            <>
                              <InputTextBox
                                label="Nick Name"
                                name="nickName"
                                editValue={item.nickName}
                                onHandleChange={onHandleChange}
                                handleSubmit={handleSubmit}
                                onCancel={onCancel}
                                isEdit
                              />
                            </>
                          ) : (
                            <>
                              <InputTextBox
                                label="Nick Name"
                                name="nickName"
                                onHandleChange={onHandleChange}
                                handleSubmit={handleSubmit}
                                onCancel={onCancel}
                                isEdit={false}
                                isHead={isHead}
                                selectedId={selectedId}
                                userId={userId}
                                value={memberDetails && memberDetails.nickName}
                                onEdit={onEdit}
                              />
                            </>
                          )
                        }
                      </Row>
                    </Div>
                  </Col>
                  <Col col={4}>
                    <Div className="addMargin">
                      <FieldLabel>Middle Name</FieldLabel>
                      <FieldValue>{memberDetails && memberDetails.secondName}</FieldValue>
                    </Div>
                  </Col>
                  <Col col={4}>
                    <Div>
                      <FieldLabel>Last Name</FieldLabel>
                      <FieldValue>{memberDetails && memberDetails.lastName}</FieldValue>
                    </Div>
                  </Col>
                </Row>
                <Row marginBottom="17px">
                  <Col col={4}>
                    <Div>
                      <FieldLabel>Name Suffix</FieldLabel>
                      <FieldValue>{memberDetails && memberDetails.nameSuffix}</FieldValue>
                    </Div>
                  </Col>
                  <Col col={4}>
                    <Div className="addMargin">
                      <FieldLabel>Gender</FieldLabel>
                      <FieldValue>{memberDetails && memberDetails.sex}</FieldValue>
                    </Div>
                  </Col>
                  <Col col={4}>
                    <Div>
                      <FieldLabel>DOB</FieldLabel>
                      <FieldValue>
                        {memberDetails && moment(memberDetails.dateOfBirth, 'M/D/YY').format('MMM, DD')}
                      </FieldValue>
                    </Div>
                  </Col>
                </Row>
                <Row marginBottom="17px">
                  <Col col={4}>
                    <Div>
                      <FieldLabel>Mother Parish</FieldLabel>
                      <FieldValue>
                        {memberDetails && memberDetails.motherParish}

                      </FieldValue>
                    </Div>
                  </Col>
                  <Col col={4}>
                    <Div className="addMargin">
                      <FieldLabel>Status</FieldLabel>
                      <FieldValue>{memberDetails && memberDetails.status}</FieldValue>
                    </Div>
                  </Col>
                </Row>
              </PanelBody>
            </Panel>
          </Row>
        </WhiteBodySection>
        <BlueBodySection>
          <Row>
            <Panel marginRight="20px">
              <PanelHeading label="Other Info" />
              <PanelBody>
                <Row marginBottom="17px">
                  <Col col={4}>
                    <Div>
                      <Row>
                        {
                          !disableEditing && editing === 'grade' ? (
                            <>
                              <InputTextBox
                                label="Grade"
                                name="grade"
                                editValue={item.grade}
                                onHandleChange={onHandleChange}
                                handleSubmit={handleSubmit}
                                onCancel={onCancel}
                                isEdit
                              />
                            </>
                          ) : (
                            <>
                              <InputTextBox
                                label="Grade"
                                name="grade"
                                onHandleChange={onHandleChange}
                                handleSubmit={handleSubmit}
                                onCancel={onCancel}
                                isEdit={false}
                                isHead={isHead}
                                selectedId={selectedId}
                                userId={userId}
                                value={memberDetails && memberDetails.grade}
                                onEdit={onEdit}
                              />
                            </>
                          )
                        }
                      </Row>
                    </Div>

                  </Col>
                  <Col col={4}>
                    <Div className="addMargin">
                      <Row>
                        {
                          !disableEditing && editing === 'school' ? (
                            <>
                              <InputTextBox
                                label="School"
                                name="school"
                                editValue={item.school}
                                onHandleChange={onHandleChange}
                                handleSubmit={handleSubmit}
                                onCancel={onCancel}
                                isEdit
                              />
                            </>
                          ) : (
                            <>
                              <InputTextBox
                                label="School"
                                name="school"
                                onHandleChange={onHandleChange}
                                handleSubmit={handleSubmit}
                                onCancel={onCancel}
                                isEdit={false}
                                isHead={isHead}
                                selectedId={selectedId}
                                userId={userId}
                                value={memberDetails && memberDetails.school}
                                onEdit={onEdit}
                              />
                            </>
                          )
                        }
                      </Row>
                    </Div>
                  </Col>
                  <Col col={4}>
                    <Div>
                      <Row>
                        {
                          !disableEditing && editing === 'medicalNotes' ? (
                            <>
                              <InputTextBox
                                label="Medical Notes"
                                name="medicalNotes"
                                editValue={item.medicalNotes}
                                onHandleChange={onHandleChange}
                                handleSubmit={handleSubmit}
                                onCancel={onCancel}
                                isEdit
                              />
                            </>
                          ) : (
                            <>
                              <InputTextBox
                                label="Medical Notes"
                                name="medicalNotes"
                                onHandleChange={onHandleChange}
                                handleSubmit={handleSubmit}
                                onCancel={onCancel}
                                isEdit={false}
                                isHead={isHead}
                                selectedId={selectedId}
                                userId={userId}
                                value={memberDetails && memberDetails.medicalNotes}
                                onEdit={onEdit}
                              />
                            </>
                          )
                        }
                      </Row>
                    </Div>
                  </Col>
                </Row>
                <Row marginBottom="17px">
                  <Col col={4}>
                    <Div>
                      <FieldLabel>Martial Status</FieldLabel>
                      <FieldValue>
                        {memberDetails && memberDetails.maritalStatus}
                      </FieldValue>
                    </Div>
                  </Col>
                  <Col col={4}>
                    <Div className="addMargin">
                      <FieldLabel>Anniversary</FieldLabel>
                      <FieldValue>
                        {memberDetails && memberDetails.anniversary}
                      </FieldValue>
                    </Div>
                  </Col>
                  <Col col={4}>
                    <Div>
                      <Row>
                        {
                          !disableEditing && editing === 'tShirtSize' ? (
                            <>
                              <Row>
                                <FieldLabel>T-Shirt Size</FieldLabel>
                              </Row>
                              <Row>
                                <Col col={10}>
                                  <CustomSelect
                                    name="tShirtSize"
                                    editValue={item.tShirtSize}
                                    onChange={onHandleChange}
                                    handleSubmit={handleSubmit}
                                    options={options}
                                    onCancel={onCancel}
                                    isEdit
                                  >
                                    <option value="S">S</option>
                                    <option value="M">M</option>
                                    <option value="L">L</option>
                                    <option value="XL">XL</option>
                                    <option value="XL">XXL</option>
                                  </CustomSelect>
                                </Col>
                                <Col col={2} display="flex" alignSelf="center" justifyContent="space-evenly">
                                  <EditIconDiv>
                                    <EditIcon src={closeIcon} onClick={onCancel} />
                                  </EditIconDiv>
                                  <EditIconDiv>
                                    <EditIcon src={tickIcon} onClick={handleSubmit} />
                                  </EditIconDiv>
                                </Col>
                              </Row>

                            </>
                          ) : (
                            <>
                              <InputTextBox
                                label="T-Shirt Size"
                                name="tShirtSize"
                                onHandleChange={onHandleChange}
                                handleSubmit={handleSubmit}
                                onCancel={onCancel}
                                isEdit={false}
                                isHead={isHead}
                                selectedId={selectedId}
                                userId={userId}
                                value={memberDetails && memberDetails.tShirtSize}
                                onEdit={onEdit}
                              />
                            </>
                          )
                        }
                      </Row>
                    </Div>
                  </Col>
                </Row>
              </PanelBody>
            </Panel>
          </Row>
        </BlueBodySection>
        <WhiteBodySection>
          <Row>
            <Panel marginRight="20px">
              <PanelHeading label="Phone Number" />
              <PanelBody>
                <Row marginBottom="17px">
                  <Col col={4}>
                    <Div>
                      <Row>
                        {
                          !disableEditing && editing === 'mobilePhone' ? (
                            <>
                              <InputTextBox
                                label="Mobile Phone Number"
                                name="mobilePhone"
                                editValue={item.mobilePhone}
                                onHandleChange={onHandleChange}
                                handleSubmit={updateContact}
                                onCancel={onCancel}
                                isEdit
                              />
                            </>
                          ) : (
                            <>
                              <InputTextBox
                                label="Mobile Phone Number"
                                name="mobilePhone"
                                onHandleChange={onHandleChange}
                                handleSubmit={updateContact}
                                onCancel={onCancel}
                                isEdit={false}
                                selectedId={selectedId}
                                userId={userId}
                                isHead={isHead}
                                value={memberDetails && memberDetails.contactNo}
                                onEdit={onEdit}
                              />
                            </>
                          )
                        }
                      </Row>
                    </Div>
                  </Col>
                  <Col col={4}>
                    <Div className="addMargin">
                      <Row>
                        {
                          !disableEditing && editing === 'homePhone' ? (
                            <>
                              <InputTextBox
                                label="Home Phone Number"
                                name="homePhone"
                                editValue={item.homePhone}
                                onHandleChange={onHandleChange}
                                handleSubmit={updateContact}
                                onCancel={onCancel}
                                isEdit
                              />
                            </>
                          ) : (
                            <>
                              <InputTextBox
                                label="Home Phone Number"
                                name="homePhone"
                                onHandleChange={onHandleChange}
                                handleSubmit={updateContact}
                                onCancel={onCancel}
                                isEdit={false}
                                selectedId={selectedId}
                                userId={userId}
                                isHead={isHead}
                                value={memberDetails && memberDetails.contactDetail}
                                onEdit={onEdit}
                              />
                            </>
                          )
                        }
                      </Row>
                    </Div>

                  </Col>
                  <Col col={4}>
                    <Div>
                      <Row>
                        {
                          !disableEditing && editing === 'homeEmail' ? (
                            <>
                              <InputTextBox
                                label="Home Email"
                                name="homeEmail"
                                editValue={item.homeEmail}
                                onHandleChange={onHandleChange}
                                handleSubmit={updateContact}
                                onCancel={onCancel}
                                isEdit
                              />
                            </>
                          ) : (
                            <>
                              <InputTextBox
                                label="Home Email"
                                name="homeEmail"
                                onHandleChange={onHandleChange}
                                handleSubmit={updateContact}
                                onCancel={onCancel}
                                isEdit={false}
                                selectedId={selectedId}
                                userId={userId}
                                isHead={isHead}
                                value={memberDetails && memberDetails.homeEmail}
                                onEdit={onEdit}
                              />
                            </>
                          )
                        }
                      </Row>
                    </Div>
                  </Col>
                </Row>
                <Row marginBottom="17px">
                  <Col col={4}>
                    <Div>
                      <FieldLabel>Login Email</FieldLabel>
                      <FieldValue>{memberDetails && memberDetails.loginEmail}</FieldValue>
                    </Div>
                  </Col>
                  <Col col={4}>
                    <Div className="addMargin">
                      <FieldLabel>Household Primary Contact</FieldLabel>
                      <FieldValue>{memberDetails && memberDetails.contactNo}</FieldValue>
                    </Div>
                  </Col>
                  <Col col={4} margin="16px" />
                </Row>
              </PanelBody>
            </Panel>
          </Row>
        </WhiteBodySection>
        <BlueBodySection>
          <Row>
            <Panel marginRight="20px">
              <PanelHeading label="Membership Information" />
              <PanelBody>
                <Row marginBottom="17px">
                  <Col col={4}>
                    <Div>
                      <FieldLabel>Membership Number</FieldLabel>
                      <FieldValue>
                        {memberDetails && memberDetails.membershipNumber}

                      </FieldValue>
                    </Div>
                  </Col>
                  <Col col={4}>
                    <Div className="addMargin">
                      <FieldLabel>Prayer Group</FieldLabel>
                      <FieldValue>{memberDetails && memberDetails.areaName}</FieldValue>
                    </Div>
                  </Col>
                  <Col col={4}>
                    <Div>
                      <FieldLabel>Household Role</FieldLabel>
                      <FieldValue>{memberDetails && memberDetails.roleName}</FieldValue>
                    </Div>
                  </Col>
                </Row>
                <Row marginBottom="17px">
                  <Col col={4}>
                    <Div>
                      <FieldLabel>Member since</FieldLabel>
                      <FieldValue>
                        {memberDetails && memberDetails.memberSince}

                      </FieldValue>
                    </Div>
                  </Col>
                  <Col col={4}>
                    <Div className="addMargin">
                      <FieldLabel>Church Position</FieldLabel>
                      <FieldValue>
                        {memberDetails && memberDetails.churchPosition}

                      </FieldValue>
                    </Div>
                  </Col>
                  <Col col={4}>
                    <Div>
                      <FieldLabel>Family Name</FieldLabel>
                      <FieldValue>{memberDetails && memberDetails.familyName}</FieldValue>
                    </Div>
                  </Col>
                </Row>
                <Row marginBottom="17px">
                  <Col col={4}>
                    <Div>
                      <FieldLabel>Organization Memberships</FieldLabel>
                      <FieldValue>
                        {memberDetails && memberDetails.organizationsMemberships}

                      </FieldValue>
                    </Div>
                  </Col>
                  <Col col={4}>
                    <Div className="addMargin">
                      <FieldLabel>Status</FieldLabel>
                      <FieldValue>{memberDetails && memberDetails.status}</FieldValue>
                    </Div>
                  </Col>
                  <Col col={4} margin="16px" />
                </Row>
              </PanelBody>
            </Panel>
          </Row>
        </BlueBodySection>
        <WhiteBodySection>
          <Row>
            <Panel marginRight="20px">
              <PanelHeading label="Address" />
              <PanelBody>
                <Row marginBottom="17px">
                  <Col col={4}>
                    <Div>
                      <Row>
                        <Col col={10} margin="5px 0 0 0">
                          <FieldLabel>Street Line 1</FieldLabel>
                          <FieldValueInput
                            name="address_1"
                            type="text"
                            disabled
                            value={memberDetails && memberDetails.address1}
                          />
                        </Col>
                        <Col col={2} alignSelf="center" textAlign="right">
                          <EditIconDiv>
                            <EditIcon
                              src={editIcon}
                              onClick={() => toggleModal()}
                            />
                          </EditIconDiv>
                        </Col>
                      </Row>
                    </Div>
                  </Col>
                  <Col col={4}>
                    <Div className="addMargin">
                      <Row>
                        <Col col={10} margin="5px 0 0 0">
                          <FieldLabel>Street Line 2</FieldLabel>
                          <FieldValueInput
                            name="address_1"
                            type="text"
                            disabled
                            value={memberDetails && memberDetails.address2}
                          />
                        </Col>
                        <Col col={2} alignSelf="center" textAlign="right">
                          <EditIconDiv>
                            <EditIcon
                              src={editIcon}
                              onClick={() => toggleModal()}
                            />
                          </EditIconDiv>
                        </Col>
                      </Row>
                    </Div>
                  </Col>
                  <Col col={4}>
                    <Div>
                      <Row>
                        <Col col={10} margin="5px 0 0 0">
                          <FieldLabel>City</FieldLabel>
                          <FieldValueInput
                            name="address_2"
                            type="text"
                            disabled
                            value={memberDetails && memberDetails.city}
                          />
                        </Col>
                        <Col col={2} alignSelf="center" textAlign="right">
                          <EditIconDiv>
                            <EditIcon
                              src={editIcon}
                              onClick={() => toggleModal()}
                            />
                          </EditIconDiv>
                        </Col>
                      </Row>
                    </Div>
                  </Col>
                </Row>
                <Row marginBottom="17px">
                  <Col col={4}>
                    <Div>
                      <Row>
                        <Col col={10} margin="5px 0 0 0">
                          <FieldLabel>State</FieldLabel>
                          <FieldValueInput
                            name="state"
                            type="text"
                            disabled
                            value={memberDetails && memberDetails.state}
                          />
                        </Col>
                        <Col col={2} alignSelf="center" textAlign="right">
                          <EditIconDiv>
                            <EditIcon
                              src={editIcon}
                              onClick={() => toggleModal()}
                            />
                          </EditIconDiv>
                        </Col>
                      </Row>
                    </Div>
                  </Col>
                  <Col col={4}>
                    <Div className="addMargin">
                      <Row>
                        <Col col={10} margin="5px 0 0 0">
                          <FieldLabel>Zip Code</FieldLabel>
                          <FieldValueInput
                            name="zipcode"
                            type="text"
                            disabled
                            value={memberDetails && memberDetails.zip}
                          />
                        </Col>
                        <Col col={2} alignSelf="center" textAlign="right">
                          <EditIconDiv>
                            <EditIcon
                              src={editIcon}
                              onClick={() => toggleModal()}
                            />
                          </EditIconDiv>
                        </Col>
                      </Row>
                    </Div>
                  </Col>
                  <Col col={4}>
                    <Div>
                      <FieldLabel>Address Native</FieldLabel>
                      <FieldValue>
                        {memberDetails && memberDetails.otherAddress1}

                      </FieldValue>
                    </Div>
                  </Col>
                </Row>
              </PanelBody>
            </Panel>
          </Row>
        </WhiteBodySection>
        <BlueBodySection>
          <Row>
            <Panel marginRight="20px">
              <PanelHeading label="Social Media Link" />
              <PanelBody>
                <Row marginBottom="17px">
                  <Col col={6}>
                    <Div>
                      <Row>
                        {
                          !disableEditing && editing === 'twitter' ? (
                            <>
                              <InputTextBox
                                label="Twitter"
                                name="twitter"
                                editValue={item.twitter}
                                onHandleChange={onHandleChange}
                                handleSubmit={updateSocial}
                                onCancel={onCancel}
                                isEdit
                              />
                            </>
                          ) : (
                            <>
                              <InputTextBox
                                label="Twitter"
                                name="twitter"
                                onHandleChange={onHandleChange}
                                handleSubmit={updateSocial}
                                onCancel={onCancel}
                                isEdit={false}
                                selectedId={selectedId}
                                userId={userId}
                                isHead={isHead}
                                value={memberDetails && memberDetails.twitter}
                                onEdit={onEdit}
                              />
                            </>
                          )
                        }
                      </Row>
                    </Div>
                  </Col>
                  <Col col={6}>
                    <Div className="addMargin">
                      <Row>
                        {
                          !disableEditing && editing === 'instagram' ? (
                            <>
                              <InputTextBox
                                label="Instagram"
                                name="instagram"
                                editValue={item.instagram}
                                onHandleChange={onHandleChange}
                                handleSubmit={updateSocial}
                                onCancel={onCancel}
                                isEdit
                              />
                            </>
                          ) : (
                            <>
                              <InputTextBox
                                label="Instagram"
                                name="instagram"
                                onHandleChange={onHandleChange}
                                handleSubmit={updateSocial}
                                onCancel={onCancel}
                                isEdit={false}
                                selectedId={selectedId}
                                userId={userId}
                                isHead={isHead}
                                value={memberDetails && memberDetails.instagram}
                                onEdit={onEdit}
                              />
                            </>
                          )
                        }
                      </Row>
                    </Div>
                  </Col>
                </Row>
                <Row marginBottom="17px">
                  <Col col={6}>
                    <Div>
                      <Row>
                        {
                          !disableEditing && editing === 'facebook' ? (
                            <>
                              <InputTextBox
                                label="Facebook"
                                name="facebook"
                                editValue={item.facebook}
                                onHandleChange={onHandleChange}
                                handleSubmit={updateSocial}
                                onCancel={onCancel}
                                isEdit
                              />
                            </>
                          ) : (
                            <>
                              <InputTextBox
                                label="Facebook"
                                name="facebook"
                                onHandleChange={onHandleChange}
                                handleSubmit={updateSocial}
                                onCancel={onCancel}
                                isEdit={false}
                                selectedId={selectedId}
                                userId={userId}
                                isHead={isHead}
                                value={memberDetails && memberDetails.facebook}
                                onEdit={onEdit}
                              />
                            </>
                          )
                        }
                      </Row>
                    </Div>
                  </Col>
                  <Col col={6}>
                    <Div className="addMargin">
                      <Row>
                        {
                          !disableEditing && editing === 'linkedIn' ? (
                            <>
                              <InputTextBox
                                label="LinkedIn"
                                name="linkedIn"
                                editValue={item.linkedIn}
                                onHandleChange={onHandleChange}
                                handleSubmit={updateSocial}
                                onCancel={onCancel}
                                isEdit
                              />
                            </>
                          ) : (
                            <>
                              <InputTextBox
                                label="LinkedIn"
                                name="linkedIn"
                                onHandleChange={onHandleChange}
                                handleSubmit={updateSocial}
                                onCancel={onCancel}
                                isEdit={false}
                                selectedId={selectedId}
                                userId={userId}
                                isHead={isHead}
                                value={memberDetails && memberDetails.linkedIn}
                                onEdit={onEdit}
                              />
                            </>
                          )
                        }
                      </Row>
                    </Div>
                  </Col>
                </Row>
              </PanelBody>
            </Panel>
          </Row>
        </BlueBodySection>
      </PageLayout>
    </>
  );
};
export default Membership;

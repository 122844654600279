import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { ReactComponent as Dashboard } from '../../assets/icons/dashboard.svg';
import { ReactComponent as DashboardActive } from '../../assets/icons/dashboard-active.svg';
import { ReactComponent as Family } from '../../assets/icons/family.svg';
import { ReactComponent as FamilyActive } from '../../assets/icons/family-active.svg';
import { ReactComponent as Members } from '../../assets/icons/members.svg';
import { ReactComponent as MembersActive } from '../../assets/icons/members-active.svg';
import { ReactComponent as Contribution } from '../../assets/icons/contribution.svg';
import { ReactComponent as ContributionActive } from '../../assets/icons/contribution-active.svg';
import { ReactComponent as Activity } from '../../assets/icons/activity.svg';
import { ReactComponent as ActivityActive } from '../../assets/icons/activity-active.svg';
import { ReactComponent as Membership } from '../../assets/icons/membership.svg';
import { ReactComponent as MembershipActive } from '../../assets/icons/membership-active.svg';
import { ReactComponent as Accounting } from '../../assets/icons/accounting.svg';
import { ReactComponent as AccountingActive } from '../../assets/icons/accounting-active.svg';

import {
  SideBarDiv, IconDiv, IconRow, IconText,
} from './Sidebar.style';

const Sidebar = () => {
  const [active, setActive] = React.useState();
  const navigate = useNavigate();
  const roles = Cookies.get('user_roles').split(',');
  const { pathname } = useLocation();
  React.useEffect(() => {
    if (pathname === '/') {
      setActive('dashboard');
    }
    if (pathname === '/family') {
      setActive('family');
    }
    if (pathname === '/membership') {
      setActive('membership');
    }
    if (pathname === '/members') {
      setActive('members');
    }
    if (pathname === '/activity') {
      setActive('activity');
    }
    if (pathname === '/contribution') {
      setActive('contribution');
    }
    if (pathname === '/accounting') {
      setActive('accounting');
    }
    if (pathname === '/organization') {
      setActive('organization');
    }
    if (pathname.match(/member-reports/i)) {
      setActive('member_reports');
    }
  }, [pathname]);
  const handleClick = (link) => {
    // eslint-disable-next-line no-restricted-globals
    navigate(link);
  };
  return (
    <SideBarDiv>
      <IconRow onClick={() => handleClick('/')}>
        <IconDiv>
          {active === 'dashboard' ? <DashboardActive /> : <Dashboard />}
        </IconDiv>
        <IconText active={active === 'dashboard' ? '#EC1C24' : '#767c9c'}>
          Dashboard
        </IconText>
      </IconRow>
      <IconRow onClick={() => handleClick('/family')}>
        <IconDiv>{active === 'family' ? <FamilyActive /> : <Family />}</IconDiv>
        <IconText active={active === 'family' ? '#EC1C24' : '#767c9c'}>
          Family
        </IconText>
      </IconRow>
      <IconRow onClick={() => handleClick('/membership')}>
        <IconDiv>
          {active === 'membership' ? <MembershipActive /> : <Membership />}
        </IconDiv>
        <IconText active={active === 'membership' ? '#EC1C24' : '#767c9c'}>
          Membership
        </IconText>
      </IconRow>
      <IconRow onClick={() => handleClick('/members')}>
        <IconDiv>
          {active === 'members' ? <MembersActive /> : <Members />}
        </IconDiv>
        <IconText active={active === 'members' ? '#EC1C24' : '#767c9c'}>
          Members
        </IconText>
      </IconRow>
      <IconRow onClick={() => handleClick('/activity')}>
        <IconDiv>
          {active === 'activity' ? <ActivityActive /> : <Activity />}
        </IconDiv>
        <IconText active={active === 'activity' ? '#EC1C24' : '#767c9c'}>
          Activity
        </IconText>
      </IconRow>
      <IconRow onClick={() => handleClick('/contribution')}>
        <IconDiv>
          {active === 'contribution' ? (
            <ContributionActive />
          ) : (
            <Contribution />
          )}
        </IconDiv>
        <IconText active={active === 'contribution' ? '#EC1C24' : '#767c9c'}>
          Contribution
        </IconText>
      </IconRow>
      {roles.find((item) => (item.match(/accountant/i))) ? (
        <>
          <IconRow onClick={() => handleClick('/accounting')}>
            <IconDiv>
              {active === 'accounting' ? <AccountingActive /> : <Accounting />}
            </IconDiv>
            <IconText active={active === 'accounting' ? '#EC1C24' : '#767c9c'}>
              Accounting
            </IconText>
          </IconRow>
        </>
      ) : null}
      {roles.find((item) => (item.match(/organization/i))) ? (
        <IconRow onClick={() => handleClick('/organization/create/receipts')}>
          <IconDiv>
            {active === 'organization' ? <AccountingActive /> : <Accounting />}
          </IconDiv>
          <IconText active={active === 'organization' ? '#EC1C24' : '#767c9c'}>
            Organisation
          </IconText>
        </IconRow>
      ) : null}
      {roles.includes('PowerUser') ? (
        <IconRow onClick={() => handleClick('/member-reports/general-body-list')}>
          <IconDiv>
            {active === 'member_reports' ? <AccountingActive /> : <Accounting />}
          </IconDiv>
          <IconText active={active === 'member_reports' ? '#EC1C24' : '#767c9c'}>
            Reports
          </IconText>
        </IconRow>
      ) : null}
    </SideBarDiv>
  );
};

export default Sidebar;

import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const MenuWrapper = styled.div`
 width: -webkit-fill-available;
 position: fixed;
 bottom: 0;
 background-color: ${({ theme }) => theme.colors.white};
 padding: 5px;
`;
export const IconDiv = styled.div`
  padding: 5px;
  text-align: center;
  color: #767c9c;
`;

export const IconText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  text-align: center;
  color: ${(props) => props.active};
  opacity: 0.62;
`;

import React from 'react';
import PropTypes from 'prop-types';
import {
  TableData,
  TableDiv,
  TableHead,
  Income,
  IncomeCardWrapper,
} from './tablePG_styles';

const TablePrayergroup = ({
  headers, tableLookup, rows,
}) => (
  <>
    {headers.map((item, index) => (
      <Income>
        <IncomeCardWrapper>{ item }</IncomeCardWrapper>
        <TableDiv>
          <table>
            <thead>
              <tr>
                {tableLookup.map((elem) => (
                  <TableHead key={elem.heading}>{elem.heading}</TableHead>
                ))}
              </tr>
            </thead>
            <tbody>
              { rows && rows[index].map((row) => (
                <tr key={row.id}>
                  {tableLookup.map((field) => (
                    (<TableData key={field.field_name} lineThrough={row.notes === 'Voided' ? 'line-through' : null}>{row[field.field_name]}</TableData>)
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </TableDiv>
      </Income>
    ))}
  </>
);

TablePrayergroup.propTypes = {
  headers: PropTypes.array,
  tableLookup: PropTypes.array,
  rows: PropTypes.object,
};
export default TablePrayergroup;

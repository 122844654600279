import styled from 'styled-components';

/* eslint-disable import/prefer-default-export */
export const PageSubHeading = styled.h3`
  color: ${({ theme }) => theme.colors.primary_blue};
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 12px;
`;

export const FilterWrap = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 10px 12px;
  border-radius: ${({ theme }) => theme.border.radius_m};
  margin-bottom: 5px;
`;

export const SearchInput = styled.input`
  background-color: ${({ theme }) => theme.colors.page_background};
  border-radius: ${({ theme }) => theme.border.radius_m};
  padding: 10px 15px;
  margin-top: 7px;
  border: none;
  height: 25px;
  width: 80%;
  float: right;
`;

export const TransactionReceiptDisplayButton = styled.button`
  position: relative;
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  background-color: ${({ theme }) => theme.colors.primary_red};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius_m};
  border: none;
  height: 50px;
  padding: 0;
  margin: 5px 20px;
  width: 85%;
`;

export const TransactionReceiptDisplayButtonIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(12, 0, 0, 0.15);
  width: 42px;
  height: 35px;
  margin: 0;
  padding: 15px 0 0 0;
  border-radius: ${({ theme }) => theme.border.radius_m};
`;

export const BoldHeader = styled.th`
  border-right: 5px solid #e4e8f4;
`;

export const Header = styled.td``;

export const TotalAmtDiv = styled.div`
  height: 50px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius_m};
`;

export const DownloadButton = styled.button`
  border: 1px solid rgba(118, 124, 156, 0.4);
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Archivo;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 12px;
  background: #fff;
  float: left;
  margin: 0.5rem 1rem 1rem;
  padding: 0.5rem;
`;
export const DownloadIcon = styled.span`
  float: right;
  margin-left: 1rem;
`;
export const NoData = styled.div`
  font-weight:${({ theme }) => theme.fonts.font_weight.normal_font};
  color : ${({ theme }) => theme.colors.primary_blue};
  font-size: 20px;
  margin-top: 20px;
`;

import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from '../styled_components/grid/Grid.style';
import logo from '../../assets/img/logo.svg';
import arrowDown from '../../assets/icons/arrow-down.svg';
import userProfile from '../../assets/img/default-user.png';
import { fetcher } from '../../hooks/useFetch';
import { API } from '../../constants';
import {
  Logo,
  ChurchName,
  UserDiv,
  UserProfileImg,
  Welcome,
  UserName,
  DropDownItem,
  DropDownIcon,
  DropdownContent,
} from './Header.style';

const Header = () => {
  const [displayMenu, setDisplayeMenu] = useState('none');
  const memberName = Cookies.get('memberName');
  const photoUrl = Cookies.get('photoUrl');
  const roles = Cookies.get('user_roles');
  const familyInput = useRef();
  const profileInput = useRef();
  const navigate = useNavigate();

  const handleClick = () => {
    setDisplayeMenu('none');
    if (displayMenu === 'block') {
      setDisplayeMenu('none');
    } else {
      setDisplayeMenu('block');
    }
  };
  const handleSignOut = () => {
    Cookies.remove('auth_token');
    Cookies.remove('user_id');
    Cookies.remove('user_roles');
    Cookies.remove('memberName');
    Cookies.remove('photoUrl');
    navigate('/login');
  };

  const handleFamilyInput = () => {
    familyInput.current.click();
  };
  const handleProfileInput = () => {
    profileInput.current.click();
  };

  const uploadProfilePic = async (event) => {
    const image = event.target.files[0];

    if (image.size > 1e6) {
      toast.warning('Error: Image size should be less than 1MB', {
        autoClose: 3000,
      });
    } else {
      const formData = new FormData();
      formData.append('profile_pic', image);
      try {
        const res = await fetcher({
          url: `${API.UPLOAD_MEMBER_PHOTO}`,
          method: 'POST',
          authTokenKey: 'auth_token',
          body: formData,
          multiPart: true,
        });
        if (res.success) {
          toast.success('Successfully Updated!', { autoClose: 3000 });
          const res = await fetcher({
            url: `${API.MEMBER_DETAILS}`,
            method: 'GET',
            authTokenKey: 'auth_token',
          });
          if (res) {
            Cookies.set('photoUrl', res.profilePic);
          }
          window.location.reload();
        } else {
          toast.error('Error!', { autoClose: 3000 });
        }
      } catch (e) {
        toast.error('Error!', { autoClose: 3000 });
      }
    }
  };

  const uploadFamilyPic = async (event) => {
    const image = event.target.files[0];

    if (image.size > 2e6) {
      toast.warning('Error: Image size should be less than 2MB', {
        autoClose: 3000,
      });
    } else {
      const formData = new FormData();
      formData.append('family_pic', image);
      try {
        const res = await fetcher({
          url: `${API.UPLOAD_FAMILY_PHOTO}`,
          method: 'POST',
          authTokenKey: 'auth_token',
          body: formData,
          multiPart: true,
        });
        if (res.success) {
          toast.success('Successfully Updated!', { autoClose: 3000 });
          window.location.reload();
        } else {
          toast.error('Error!', { autoClose: 3000 });
        }
      } catch (e) {
        toast.error('Error!', { autoClose: 3000 });
      }
    }
  };
  document.addEventListener('click', (e) => {
    if (document.getElementById('userdiv')?.contains(e.target)) {
      setDisplayeMenu('block');
    } else {
      setDisplayeMenu('none');
    }
  });
  return (
    <>
      <Row>
        <Col col={1}>
          <Logo src={logo} />
        </Col>
        <Col col={8}>
          <ChurchName>Trinity Marthoma Church, Houston</ChurchName>
        </Col>
        <Col col={3}>
          <UserDiv id="userdiv" onClick={handleClick}>
            <Row>
              <Col>
                <UserProfileImg
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = userProfile;
                  }}
                  src={photoUrl || userProfile}
                />
              </Col>
              <Col>
                <Welcome>Welcome</Welcome>
                <UserName>{memberName}</UserName>
              </Col>
              <DropdownContent display={displayMenu}>
                {roles.includes('HeadOfFamily') ? (
                  <DropDownItem onClick={handleFamilyInput}>
                    Update Family Pic
                    <label htmlFor="file">
                      <input
                        type="file"
                        ref={familyInput}
                        accept="image/*"
                        name="image"
                        id="profileimg"
                        onChange={(event) => uploadFamilyPic(event)}
                        hidden
                      />
                    </label>
                  </DropDownItem>
                ) : null}
                <DropDownItem onClick={handleProfileInput}>
                  Update Profile Pic
                  <input
                    type="file"
                    ref={profileInput}
                    accept="image/*"
                    name="image"
                    id="profileimg"
                    onChange={(event) => uploadProfilePic(event)}
                    hidden
                  />
                </DropDownItem>
                <DropDownItem onClick={handleSignOut}>Sign Out</DropDownItem>
              </DropdownContent>
            </Row>
            <DropDownIcon src={arrowDown} alt="arrow-down" />
          </UserDiv>
        </Col>
      </Row>
    </>
  );
};

export default Header;

import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const JournalInputWrap = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 10px 20px;
  border-radius: ${({ theme }) => theme.border.radius_m};
`;

export const JournalDisplayButton = styled.button`
  position: relative;
  background-color: ${({ theme }) => theme.colors.primary_red};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius_m};
  border: none;
  height: 50px;
  padding: 0;
  margin: 5px 20px;
  width: 55%;
`;

export const JournalDisplayButtonIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(12, 0, 0, 0.15);
  width: 42px;
  height: 35px;
  margin: 0;
  padding: 15px 0 0 0;
  border-radius: ${({ theme }) => theme.border.radius_m};
`;

export const DateInput = styled.input`
  background-color: ${({ theme }) => theme.colors.page_background};
  border-radius: ${({ theme }) => theme.border.radius_m};
  padding: 10px 15px;
  font-size: 14px;
  margin-top: 7px;
  border: none;
  height: 25px;
  width: 80%;
`;

export const JournalTableHead = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  font-size: 18px;
  text-align: left;
  padding: 18px;
  color: ${({ theme }) => theme.colors.primary_blue};
  border-radius: 10px;
  margin-top: 10px;
`;
export const NoData = styled.div`
  font-weight:${({ theme }) => theme.fonts.font_weight.normal_font};
  color : ${({ theme }) => theme.colors.primary_blue};
  font-size: 20px;
  margin-top: 20px;
`;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Row, Col } from '../../../styled_components/grid/Grid.style';
import tickIcon from '../../../../assets/icons/tick.svg';
import deleteIcon from '../../../../assets/icons/delete.svg';
import closeIcon from '../../../../assets/icons/close-icon.svg';
import editIcon from '../../../../assets/icons/pen.svg';
import {
  TableInput, TableDiv, TableHead,
  CustomSelect, StyledInput, IconWrapper, ActionIcon, TableData,
  // ItemDiv,
} from './editTable.style';

const EditTable = (
  {
    categories, handleLineItems, formatter, items,
  },
) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [total, setTotal] = useState(0);
  const [currentData, setCurrentData] = useState({
    category: '',
    particular: '',
    amount: '',
  });
  const [editData, setEditData] = useState({
    category: '',
    particular: '',
    amount: '',
  });

  const addCurrentItem = () => {
    if (currentData.category === '') {
      toast.error('Add Category', { autoClose: 3000 });
    } else {
      const currentItems = [...items];
      currentItems.push(currentData);
      setTotal(total + Number(currentData.amount));
      handleLineItems(currentItems);
      setCurrentData({
        category: '',
        particular: '',
        amount: '',
      });
    }
  };
  const updateEditItem = () => {
    const currentItems = [...items];
    const editedElement = currentItems[editIndex];
    setTotal(total + Number(editData.amount) - Number(editedElement.amount));
    currentItems[editIndex] = editData;
    handleLineItems(currentItems);
    setIsEditing(false);
    setEditIndex(null);
  };
  const deleteItem = (index) => {
    const currentItems = [...items];
    const deleteElement = currentItems[index];
    setTotal(total - Number(deleteElement.amount));
    currentItems.splice(index, 1);
    handleLineItems(currentItems);
  };
  const cancelChange = () => {
    setCurrentData({
      category: '',
      particular: '',
      amount: '',
    });
  };
  const cancelEditChange = () => {
    setIsEditing(false);
    setEditIndex(null);
    setEditData({
      category: '',
      particular: '',
      amount: '',
    });
  };
  const handleSelectChange = (e) => {
    setIsEditing(false);
    setEditIndex(null);
    const data = { ...currentData };
    data.category = e.value;
    setCurrentData(data);
  };
  const handleChange = (e) => {
    setIsEditing(false);
    setEditIndex(null);
    const data = { ...currentData };
    data[e.target.name] = e.target.value;
    setCurrentData(data);
  };
  const handleEditSelectChange = (e) => {
    const data = { ...editData };
    data.category = e.value;
    setEditData(data);
  };
  const handleEditChange = (e) => {
    const data = { ...editData };
    data[e.target.name] = e.target.value;
    setEditData(data);
  };
  const getOptionLabel = (val) => {
    const option = categories.find((elem) => elem.label === val);
    return option && option.label ? option.label : '';
  };
  const onEditClick = (index, elem) => {
    const data = {
      category: elem.category,
      particular: elem.particular,
      amount: elem.amount,
    };
    setIsEditing(true);
    setEditIndex(index);
    setEditData(data);
  };
  useEffect(() => {
    handleLineItems(items);
    setTotal(items.reduce((acc, curVal) => acc + parseFloat(curVal.amount, 10), 0));
  }, [items]);
  return (
    <TableDiv>
      <table>
        <tr>
          <TableHead>Category</TableHead>
          <TableHead>Particulars</TableHead>
          <TableHead width="100px">Amount</TableHead>
          <TableHead width="100px">Action</TableHead>
        </tr>
        <tbody>
          <tr>
            <TableInput>
              <CustomSelect
                className="select"
                classNamePrefix="custom"
                options={categories}
                value={categories.find((elem) => elem.label === currentData.category) || null}
                placeholder="Category"
                onChange={handleSelectChange}
              />
            </TableInput>
            <TableInput>
              <StyledInput type="text" name="particular" value={currentData.particular} onChange={handleChange} />
            </TableInput>
            <TableInput textAlign="right">
              <StyledInput textAlign="right" name="amount" placeholder="0" value={currentData.amount} onChange={handleChange} type="number" />
            </TableInput>
            <TableInput>
              <Row justifyContent="center">
                <Col textAlign="center">
                  <IconWrapper onClick={addCurrentItem}>
                    <ActionIcon src={tickIcon} alt="tick-icon" />
                  </IconWrapper>
                </Col>
                <Col textAlign="center">
                  <IconWrapper onClick={cancelChange}>
                    <ActionIcon src={closeIcon} alt="cross-icon" />
                  </IconWrapper>
                </Col>
              </Row>
            </TableInput>
          </tr>
          {
              items.map((item, index) => (
                ((isEditing && editIndex) === index ? (
                  <tr>
                    <TableInput>
                      <CustomSelect
                        className="select"
                        classNamePrefix="custom"
                        options={categories}
                        value={{
                          value: editData.category,
                          label: getOptionLabel(editData.category),
                        }}
                        placeholder="Category"
                        onChange={handleEditSelectChange}
                      />
                    </TableInput>
                    <TableInput>
                      <StyledInput type="text" name="particular" onChange={handleEditChange} value={editData.particular} />
                    </TableInput>
                    <TableInput textAlign="right">
                      <StyledInput textAlign="right" name="amount" placeholder="0" onChange={handleEditChange} value={editData.amount} type="number" />
                    </TableInput>
                    <TableInput>
                      <Row justifyContent="center">
                        <Col textAlign="center">
                          <IconWrapper onClick={updateEditItem}>
                            <ActionIcon src={tickIcon} alt="tick-icon" />
                          </IconWrapper>
                        </Col>
                        <Col textAlign="center">
                          <IconWrapper onClick={cancelEditChange}>
                            <ActionIcon src={closeIcon} alt="cross-icon" />
                          </IconWrapper>
                        </Col>
                      </Row>
                    </TableInput>
                  </tr>
                ) : (
                  <tr>
                    <TableData>{getOptionLabel(item.category)}</TableData>
                    <TableData>{item.particular}</TableData>
                    <TableData textAlign="right">{`$ ${formatter.format(item.amount)}`}</TableData>
                    <TableInput>
                      <Row justifyContent="center">
                        <Col textAlign="center">
                          <IconWrapper onClick={() => onEditClick(index, item)}>
                            <ActionIcon src={editIcon} alt="tick-icon" />
                          </IconWrapper>
                        </Col>
                        <Col textAlign="center">
                          <IconWrapper onClick={() => deleteItem(index)}>
                            <ActionIcon src={deleteIcon} alt="cross-icon" />
                          </IconWrapper>
                        </Col>
                      </Row>
                    </TableInput>
                  </tr>
                ))
              ))
          }
          <tr>
            <TableData />
            <TableData />
            <TableData textAlign="right">{`$ ${formatter.format(total)}`}</TableData>
            <TableInput />
          </tr>
        </tbody>
      </table>

    </TableDiv>
  );
};
EditTable.propTypes = {
  categories: PropTypes.array,
  handleLineItems: PropTypes.func,
  formatter: PropTypes.func,
  items: PropTypes.array,
};
export default EditTable;

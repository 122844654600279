import styled from 'styled-components';

export const Logo = styled.img`
  width: 71px;
  height: 71px;
  margin-left: 20px;
  margin-top: 6px;
`;

export const UserDiv = styled.div`
  position: relative;
  margin-top: 12px;
  padding: 5px;
  background: #ecf2f8;
  border-radius: 10px;
  width: 70%;
  float: right;
  margin-right: 10px;
`;
export const DropDownIcon = styled.img`
  position: absolute;
  right: 20px;
  top: 25px;
`;
export const Welcome = styled.h6`
  color: #6ed3df;
  margin-top: 8px;
  margin-bottom: 3px;
  font-size: 10px;
`;
export const UserName = styled.h5`
  margin: 0px;
  font-size: 15px;
  color: #393e5c;
  font-family: 'Playfair Display', serif;
`;
export const UserProfileImg = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 3px;
  margin-right: 8px;
  margin-top: 3px;
`;
export const DropdownContent = styled.div`
  display: ${(props) => props.display || 'none'};
  position: absolute;
  left: 0;
  top: 52px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.secondary_background};
  border-bottom-left-radius: ${({ theme }) => theme.border.radius_m};
  border-bottom-right-radius: ${({ theme }) => theme.border.radius_m};
  width: 100%;
  z-index: 1;
  text-align: center;
  font-weight: ${({ theme }) => theme.fonts.font_weight.bold_font};
  font-size: ${({ theme }) => theme.fonts.font_size.big_font};
`;
export const DropDownItem = styled.div`
  color: ${({ theme }) => theme.colors.primary_blue};
  border-top: 1px solid ${({ theme }) => theme.colors.secondary_background};
  text-align: center;
  margin: 0 0 0 0;
  cursor: pointer;
  padding: 10px 0 10px 0px;
  width: 100%;
`;

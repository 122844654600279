import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const ContributionWrapper = styled.div`
  margin: 25px 0;
  padding: 0;
`;

export const ContributionIndex = styled.div`
  float: right;
  margin-top: 0;
  background-color: ${({ theme }) => theme.colors.secondary_background};
  padding: 10px 15px;
  border-top-right-radius: ${(props) => props.theme.border.radius_m};
  border-bottom-left-radius: ${(props) => props.theme.border.radius_m};
  font-size: 12px;
  height: 12px;
`;

export const Content = styled.div`
  font-size: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  border-top-left-radius: ${({ theme }) => theme.border.radius_m};
  border-top-right-radius: ${({ theme }) => theme.border.radius_m};
`;
export const Body = styled.div`
  padding: 30px 20px 13px 10px;
`;
export const Footer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.page_background};
  border-bottom-left-radius: ${({ theme }) => theme.border.radius_m};
  border-bottom-right-radius: ${({ theme }) => theme.border.radius_m};
  background-color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
`;

export const Date = styled.div`
  color: rgba(57, 62, 92, 0.6);
  padding: 10px 12px;
  font-weight: 500;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.light_grey};
`;

export const Amount = styled.div`
  text-align: right;
  color: ${({ theme }) => theme.colors.light_grey};
  padding: 10px 12px;
  font-weight: 600;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.light_grey};
`;
export const VerticalLine = styled.div` 
  height: 35px;
  width: 0;
  border: 1px solid ${({ theme }) => theme.colors.secondary_background};
`;
export const CardHead = styled.span` 
  left: 5px;
  font-size: 13px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.light_grey};
  opacity: 0.5;
`;
export const CardValue = styled.span` 
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.light_grey};
  white-space: noWrap;
`;

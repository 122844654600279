import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from '../styled_components/grid/Grid.style';
import { ReactComponent as Circle } from '../../assets/icons/activity-circle.svg';
import {
  ActivityIndex,
  ActivityPanelWrapper,
  Body,
  Content,
  Date,
  Entity,
  Footer,
  Line,
  Owner,
} from './ActivityPanel.style';

const ActivityPanel = ({ activity, index }) => {
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };
  return (
    <>
      <ActivityPanelWrapper>
        <Row>
          <Col col={1} textAlign="center">
            <Circle />
            <Line />
          </Col>
          <Col col={11}>
            <Date>
              {new window.Date(activity.created_at).toLocaleString(
                'en-US',
                options,
              )}
            </Date>
            <Content>
              <ActivityIndex>{index + 1}</ActivityIndex>
              <Body>{activity.remarks.join(', ')}</Body>
            </Content>
            <Footer>
              <Row>
                <Col col={6}>
                  <Entity>{activity.entity_name}</Entity>
                </Col>
                <Col col={6}>
                  <Owner>{activity.created_by.name}</Owner>
                </Col>
              </Row>
            </Footer>
          </Col>
        </Row>
      </ActivityPanelWrapper>
    </>
  );
};
ActivityPanel.propTypes = {
  activity: PropTypes.object,
  index: PropTypes.number,
};
export default ActivityPanel;

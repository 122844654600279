import styled from 'styled-components';

export const MemberCardWrapper = styled.div`
  margin-top: 20px;
  margin-right: ${(props) => props.marginRight || 0};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius_m};
  padding: 10px 20px 20px 10px;
  cursor: pointer;
`;

export const MemberProfilePic = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  padding: 10px;
`;

export const MemberName = styled.div`
  font-family: 'Playfair Display', serif;
  color: ${({ theme }) => theme.colors.primary_blue};
  font-size: 20px;
  margin: 10px 0;
`;

export const MemberLocation = styled.div`
  font-size: 14px;
`;

export const LocationIcon = styled.img`
  width: 10px;
  height: 12px;
  margin-right: 5px;
`;

export const MemberContactContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  border: 1px solid #e4e8f4;
  border-radius: ${({ theme }) => theme.border.radius_m};
`;

export const ContactHeading = styled.div`
  display: inline;
  font-family: 'Playfair Display', serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
`;

export const ContactText = styled.div`
  font-size: 14px;
`;

export const ContactIcon = styled.img`
  width: 22px;
  // margin-top: 6px;
  margin: 6px 6px 0 0;
`;

export const ContactWrapper = styled.div`
  padding: 10px;
  border-left: ${(props) => props.borderLeft || 'none'};
  border-right: ${(props) => props.borderRight || 'none'};
  display: flex;
  word-wrap: break-word;
`;
export const RemoveButton = styled.div`
  background: ${({ theme }) => theme.colors.page_background};;
  width: 30px;
  height: 30px;
  margin-left: 20px;
  border-radius: 7px;
  text-align: center;
  cursor: pointer;
`;
export const RemoveIcon = styled.img`
  margin-top: 7px;
`;

import React, { useState, useEffect } from 'react';
import { ModalProvider } from 'styled-react-modal';
import { toast } from 'react-toastify';
import AccountingMenu from '../../../components/accounting/menu';
import Breadcrumbs from '../../../components/breadcrumbs';
import {
  Row,
  Col,
} from '../../../components/styled_components/grid/Grid.style';
import plusIcon from '../../../assets/icons/plus-icon.svg';
import {
  AccountingPageLayout,
  PageHeading,
  PageSubHeading,
} from '../../../theme/GlobalStyles';
import editIcon from '../../../assets/icons/pen.svg';
import remove from '../../../assets/icons/delete.svg';
import {
  ChartOfAccountsWrap,
  CreateNewButton,
  CreateNewButtonIcon,
  TableDiv,
  TableHead,
  TableData,
  IconWrapper,
  IconWrappers,
  ActionIcon,
  TypeHead,
} from './chartOfAccounts.style';
import CreateModal from '../../../components/accounting/chart_of_accounts/create_modal';
import RemoveModal from '../../../components/accounting/chart_of_accounts/remove_modal';
import EditModal from '../../../components/accounting/chart_of_accounts/edit_modal';
import { ACCT_API } from '../../../constants';
import { fetcher } from '../../../hooks/useFetch';
import Loader from '../../../components/styled_components/loader/loader.style';
import ScrollDownButton from '../../../utils/ScrollDownButton';
import ScrollUpButton from '../../../utils/ScrollUpButton';

const breadcrumbs = ['Accounting', 'Chart Of Accounts'];

const ChartOfAccounts = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [editType, setEditType] = useState();
  const [selectedData, setSelectedData] = useState();
  const [selectedDetail, setSelectedDetail] = useState();
  const [bankData, setBankData] = useState([]);
  const [incomeData, setIncomeData] = useState([]);
  const [expenseData, setExpenseData] = useState([]);
  const [accountData, setAccountData] = useState([]);
  const [incomeAccountData, setIncomeAccountData] = useState([]);
  const [expenseAccountData, setExpenseAccountData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [accountNo, setAccountNo] = useState();
  const [selectedType, setSelectedType] = useState();
  const check = ['EM Church Facility Project', 'EM Pay Check Protection', 'EM Gen. Trinity Center Equipment XXXX7994'];
  const getBankDetails = async () => {
    const res = await fetcher({
      url: `${ACCT_API.GET_BANK_DETAILS}?type=Bank`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    if (res.response) {
      setBankData(res.response.data);
    }
    setLoading(false);
  };
  const getIncomeDetails = async () => {
    const res = await fetcher({
      url: `${ACCT_API.GET_BANK_DETAILS}?type=Income`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    if (res.response) {
      setIncomeData(res.response.data);
    }
    setLoading(false);
  };
  const getExpenseDetails = async () => {
    const res = await fetcher({
      url: `${ACCT_API.GET_BANK_DETAILS}?type=Expense`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    if (res.response) {
      const temp = res.response.data.filter((x) => x.accountName !== 'Parsonage Maintenance');
      setExpenseData(temp);
    }
    setLoading(false);
  };
  const getAccountDetails = async () => {
    const res = await fetcher({
      url: `${ACCT_API.GET_BANK_SUMMARIZED}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    if (res.response) {
      setAccountData(res.response);
    }
  };
  const getIncomeAccountDetails = async () => {
    const res = await fetcher({
      url: `${ACCT_API.GET_INCOME_DETAILS}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    if (res.response) {
      setIncomeAccountData(res.response);
    }
  };
  const getExpenseAccountDetails = async () => {
    const res = await fetcher({
      url: `${ACCT_API.GET_EXPENSE_DETAILS}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    if (res.response) {
      setExpenseAccountData(res.response);
      const indexHead = res.response.findIndex((x) => x.parent_acct_head === 'Church and Parsonage Maintenance');
      const index = res.response[indexHead].value.findIndex((x) => x.acct_head_name === 'Parsonage Maintenance');
      res.response[indexHead].value.splice(index, 1);
    }
  };
  useEffect(() => {
    getBankDetails();
    getIncomeDetails();
    getExpenseDetails();
    getAccountDetails();
    getIncomeAccountDetails();
    getExpenseAccountDetails();
  }, []);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const toggleEditModal = () => {
    setIsEditOpen(!isEditOpen);
  };
  const confirmToggleModal = () => {
    setIsDeleteOpen(!isDeleteOpen);
  };
  const addNewAccount = async (subAccountChecked, selectedType, state) => {
    if (subAccountChecked) {
      if (selectedType === 'Income') {
        const finalValues = {
          name: state.name,
          uniqueNo: state.accNum,
          parentAccount: state.parentAccount,
        };
        const res = await fetcher({
          url: `${ACCT_API.CREATE_INCOME_SUB_ACCOUNT}`,
          method: 'POST',
          authTokenKey: 'auth_token',
          body: JSON.stringify(finalValues),
        });
        if (res.success) {
          toast.success('Account added successfully', { autoClose: 3000 });
        }
      } else {
        const finalValues = {
          accNum: state.accNum,
          name: state.name,
          subType: state.subType,
          type: state.type,
          parentAccount: state.parentAccount,
        };
        const res = await fetcher({
          url: `${ACCT_API.CREATE_SUB_ACCOUNT}`,
          method: 'POST',
          authTokenKey: 'auth_token',
          body: JSON.stringify(finalValues),
        });
        if (res.success) {
          toast.success('Account added successfully', { autoClose: 3000 });
        }
      }
    } else {
      const finalValues = {
        account_number: state.accNum,
        account_name: state.name,
        account_sub_type: state.subType,
        account_type: state.type,
      };
      const res = await fetcher({
        url: `${ACCT_API.CREATE_ACCOUNT_HEAD}`,
        method: 'POST',
        authTokenKey: 'auth_token',
        body: JSON.stringify(finalValues),
      });
      if (res.success) {
        toast.success('Account added successfully', { autoClose: 3000 });
      }
    }
    getAccountDetails();
    getIncomeAccountDetails();
    getExpenseAccountDetails();
    setIsOpen(!isOpen);
  };
  const editAccount = async (state) => {
    if (editType.subType === 'head') {
      if (editType.type === 'bank') {
        const finalValues = {
          acct_name: state.accountName,
          acct_id: selectedData.id,
        };
        await fetcher({
          url: `${ACCT_API.EDIT_BANK_ACCOUNT}`,
          method: 'POST',
          authTokenKey: 'auth_token',
          body: JSON.stringify(finalValues),
        });
        getBankDetails();
      }
      if (editType.type === 'income') {
        const finalValues = {
          is_parent_account: true,
          acct_name: state.accountName,
          acct_id: selectedData.id,
        };
        await fetcher({
          url: `${ACCT_API.EDIT_INCOME_ACCOUNT}`,
          method: 'POST',
          authTokenKey: 'auth_token',
          body: JSON.stringify(finalValues),
        });
      }
      if (editType.type === 'expense') {
        const finalValues = {
          acct_name: state.accountName,
          acct_id: selectedData.id,
        };
        await fetcher({
          url: `${ACCT_API.EDIT_EXPENSE_ACCOUNT}`,
          method: 'POST',
          authTokenKey: 'auth_token',
          body: JSON.stringify(finalValues),
        });
      }
    }
    if (editType.subType === 'sub') {
      if (editType.type === 'bank') {
        const finalValues = {
          acct_id: selectedData.acct_id,
          acct_number: state.accountNumber,
          acct_name: state.accountName,
          parent_acct_head: state.parentAccount,
        };
        await fetcher({
          url: `${ACCT_API.EDIT_BANK_ACCOUNT}`,
          method: 'POST',
          authTokenKey: 'auth_token',
          body: JSON.stringify(finalValues),
        });
        getAccountDetails();
      }
      if (editType.type === 'income') {
        const finalValues = {
          is_parent_account: false,
          acct_id: selectedData.acct_id,
          acct_number: state.accountNumber,
          acct_name: state.accountName,
          acct_parent_account: state.parentAccount,
        };
        await fetcher({
          url: `${ACCT_API.EDIT_INCOME_ACCOUNT}`,
          method: 'POST',
          authTokenKey: 'auth_token',
          body: JSON.stringify(finalValues),
        });
        getIncomeAccountDetails();
      }
      if (editType.type === 'expense') {
        const finalValues = {
          acct_id: selectedData.acct_id,
          acct_number: state.accountNumber,
          acct_name: state.accountName,
          parent_acct_head: state.parentAccount,
        };
        await fetcher({
          url: `${ACCT_API.EDIT_EXPENSE_ACCOUNT}`,
          method: 'POST',
          authTokenKey: 'auth_token',
          body: JSON.stringify(finalValues),
        });
        getExpenseAccountDetails();
      }
    }
    setIsEditOpen(!isEditOpen);
  };
  const handleDelete = async () => {
    const payload = {
      acct_id: selectedDetail.acct_id,
      acct_number: selectedDetail.accountNumber,
      acct_name: selectedDetail.accountName,
      acct_parent_account: selectedDetail.parentAccount,
    };
    try {
      const res = await fetcher({
        url: `${ACCT_API.DELETE_BANK_ACCOUNT}`,
        method: 'POST',
        authTokenKey: 'auth_token',
        body: JSON.stringify(payload),
      });
      if (res) {
        toast.success('Successfully removed!', { autoClose: 3000 });
        getBankDetails();
        getIncomeDetails();
        getExpenseDetails();
        getAccountDetails();
        getIncomeAccountDetails();
        getExpenseAccountDetails();
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error('Account having Zero balance can only deleted!', { autoClose: 3000 });
    }
  };
  const toggleDeleteModal = () => {
    setIsDeleteOpen(!isDeleteOpen);
  };
  const getAccountNO = async () => {
    try {
      const res = await fetcher({
        // url: `${ACCT_API.GET_BANK_DETAILS}?type=${selectedType}`,
        url: `${ACCT_API.GET_ACCOUNT_NO}?type=${selectedType}`,
        method: 'GET',
        authTokenKey: 'auth_token',
      });
      if (res.message) {
        toast.error(res.message, { autoClose: 3000 });
      }
      // const arr = res.response.data.filter((x) => x.subAccount);
      // setAccountNo(arr);
      setAccountNo(res);
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };
  useEffect(() => {
    if (selectedType === 'Expense' || selectedType === 'Income') {
      getAccountNO();
    }
  }, [selectedType]);
  return (
    <>
      <AccountingMenu />
      {loading ? <Loader /> : null}
      <ScrollUpButton />
      <ScrollDownButton />
      <ModalProvider>
        <CreateModal
          toggleModal={toggleModal}
          addNewAccount={addNewAccount}
          isOpen={isOpen}
          accountNo={accountNo}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          bankAccount={bankData && bankData.length
            ? bankData : []}
          expenseAccount={expenseData && expenseData.length
            ? expenseData : []}
          incomeAccount={incomeData && incomeData.length
            ? incomeData : []}
        />
        <EditModal
          isEditOpen={isEditOpen}
          toggleEditModal={toggleEditModal}
          editType={editType}
          selectedData={selectedData && selectedData}
          editAccount={editAccount}
          parentAccoutData={editType && editType.subType === 'head' ? []
            : editType && editType.type === 'bank' ? bankData && bankData
              : editType && editType.type === 'income' ? incomeAccountData && incomeAccountData
                : expenseAccountData && expenseAccountData}
        />
        <RemoveModal
          handleDelete={handleDelete}
          toggleModal={toggleDeleteModal}
          isOpen={isDeleteOpen}
          confirmToggleModal={confirmToggleModal}
          handleSubmit={handleDelete}
          selectedData={selectedDetail && selectedDetail}
        />
      </ModalProvider>
      <AccountingPageLayout>
        <PageHeading>Chart Of Accounts</PageHeading>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        {accountData.length > 0 && incomeAccountData.length > 0 && expenseAccountData.length > 0 ? (
          <>
            <ChartOfAccountsWrap>
              <Row>
                <Col col={8}>
                  <PageSubHeading>Accounts</PageSubHeading>
                </Col>
                <Col col={4} textAlign="right">
                  <CreateNewButton type="button" onClick={toggleModal}>
                    Create New
                    <CreateNewButtonIcon>
                      <img src={plusIcon} alt=">" height="16px" />
                    </CreateNewButtonIcon>
                  </CreateNewButton>
                </Col>
              </Row>
            </ChartOfAccountsWrap>
            <TableDiv>
              <table>
                <tbody>
                  <tr>
                    <TableHead>Name</TableHead>
                    <TableHead>Account Number</TableHead>
                    <TableHead>Action</TableHead>
                  </tr>
                </tbody>
                <tbody>
                  <TypeHead colSpan="3">Assets</TypeHead>
                  {accountData && accountData
                    && accountData.map((head) => (
                      <>
                        <tr>
                          <TableData fontWeight="700">
                            {head.parent_acct_head}
                          </TableData>
                          <TableData />
                          <TableData />
                        </tr>
                        {head.value.sort((a, b) => a.accountNumber - b.accountNumber)
                          .filter((i) => !check.includes(i.acct_head_name)).map((subItem) => (
                            <tr>
                              <TableData>
                                {' '}
                              &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;
                                {subItem.acct_head_name}
                              </TableData>
                              <TableData>{subItem.accountNumber}</TableData>
                              <TableData>
                                <Col>
                                  <Col col={1}>
                                    <IconWrapper>
                                      <ActionIcon
                                        src={editIcon}
                                        alt="tick-icon"
                                        onClick={() => {
                                          toggleEditModal();
                                          setEditType({
                                            type: 'bank',
                                            subType: 'sub',
                                          });
                                          setSelectedData({
                                            ...subItem,
                                            parentAccount: head.parent_acct_head,
                                            accountName: subItem.acct_head_name,
                                          });
                                        }}
                                      />
                                    </IconWrapper>
                                  </Col>
                                  <Col col={1}>
                                    <IconWrappers>
                                      <ActionIcon
                                        src={remove}
                                        alt="remove"
                                        onClick={() => {
                                          toggleDeleteModal();
                                          setSelectedDetail({
                                            ...subItem,
                                            acct_id: subItem.acct_id,
                                            parentAccount: head.parent_acct_head,
                                            accountName: subItem.acct_head_name,
                                          });
                                        }}
                                      />
                                    </IconWrappers>
                                  </Col>
                                </Col>
                              </TableData>
                            </tr>
                          ))}
                      </>
                    ))}
                  <TypeHead colSpan="3">Income (Receipts)</TypeHead>
                  {incomeAccountData && incomeAccountData
                    && incomeAccountData.map((head) => (
                      <>
                        <tr>
                          <TableData fontWeight="700">
                            {head.parent_acct_head}
                          </TableData>
                          <TableData />
                          <TableData />
                        </tr>
                        {head.value.sort((a, b) => a.acct_head_description
                        - b.acct_head_description)
                          .map((subItem) => (
                            <tr>
                              <TableData>
                                {' '}
                                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;
                                {subItem.acct_head_name}
                              </TableData>
                              <TableData>{subItem.acct_head_description}</TableData>
                              <TableData>
                                <Col>
                                  <Col col={1}>
                                    <IconWrapper>
                                      <ActionIcon
                                        src={editIcon}
                                        alt="tick-icon"
                                        onClick={() => {
                                          toggleEditModal();
                                          setEditType({
                                            type: 'income',
                                            subType: 'sub',
                                          });
                                          setSelectedData({
                                            ...subItem,
                                            parentAccount: head.parent_acct_head,
                                            accountName: subItem.acct_head_name,
                                            accountNumber: subItem.acct_head_description,
                                          });
                                        }}
                                      />
                                    </IconWrapper>
                                  </Col>
                                  <Col col={1}>
                                    <IconWrappers>
                                      <ActionIcon
                                        src={remove}
                                        alt="remove"
                                        onClick={() => {
                                          toggleDeleteModal();
                                          setSelectedDetail({
                                            ...subItem,
                                            acct_id: subItem.acct_id,
                                            parentAccount: head.parent_acct_head,
                                            accountName: subItem.acct_head_name,
                                            accountNumber: subItem.acct_head_description,
                                          });
                                        }}
                                      />
                                    </IconWrappers>
                                  </Col>
                                </Col>
                              </TableData>
                            </tr>
                          ))}
                      </>
                    ))}
                  <TypeHead colSpan="3">Expense</TypeHead>
                  {expenseAccountData && expenseAccountData
                    && expenseAccountData.map((head) => (
                      <>
                        <tr>
                          <TableData fontWeight="700">
                            {head.parent_acct_head}
                          </TableData>
                          <TableData />
                          <TableData />
                        </tr>
                        {head.value.sort((a, b) => a.acct_head_description
                        - b.acct_head_description)
                          .map((subItem) => (
                            <tr>
                              <TableData>
                                {' '}
                                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;
                                {subItem.acct_head_name}
                              </TableData>
                              <TableData>{subItem.acct_head_description}</TableData>
                              <TableData>
                                <Col>
                                  <Col col={1}>
                                    <IconWrapper>
                                      <ActionIcon
                                        src={editIcon}
                                        onClick={() => {
                                          toggleEditModal();
                                          setEditType({
                                            type: 'expense',
                                            subType: 'sub',
                                          });
                                          setSelectedData({
                                            ...subItem,
                                            parentAccount: head.parent_acct_head,
                                            accountName: subItem.acct_head_name,
                                            accountNumber: subItem.acct_head_description,
                                          });
                                        }}
                                        alt="tick-icon"
                                      />
                                    </IconWrapper>
                                  </Col>
                                  <Col col={1}>
                                    <IconWrappers>
                                      <ActionIcon
                                        src={remove}
                                        alt="remove"
                                        onClick={() => {
                                          toggleDeleteModal();
                                          setSelectedDetail({
                                            ...subItem,
                                            acct_id: subItem.acct_id,
                                            parentAccount: head.parent_acct_head,
                                            accountName: subItem.acct_head_name,
                                            accountNumber: subItem.acct_head_description,
                                          });
                                        }}
                                      />
                                    </IconWrappers>
                                  </Col>
                                </Col>
                              </TableData>
                            </tr>
                          ))}
                      </>
                    ))}
                </tbody>
              </table>
            </TableDiv>
          </>
        ) : <Loader />}
      </AccountingPageLayout>
    </>
  );
};

export default ChartOfAccounts;

import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import Cookies from 'js-cookie';
import {
  Col,
  Row,
} from '../../../components/styled_components/grid/Grid.style';
import ReportSubMenu from '../../../components/accounting/report_submenu';
import AccountingMenu from '../../../components/accounting/menu';
import {
  AccountingPageLayout,
  PageHeading,
  PageSubHeading,
} from '../../../theme/GlobalStyles';
import {
  ReceiptsDiv,
  DisplayButton,
  DisplayButtonIcon,
  SubscriptionReportsCustomSelect,
  SubscriptionTotalAmtDiv,
  SubscriptionDownloadButton,
  SubscriptionDownloadButtonIcon,
  SubscriptionDownloadImage,
  NoData,
} from './SubscriptionReports.style';
import Breadcrumbs from '../../../components/breadcrumbs';
import arrowRightIcon from '../../../assets/icons/arrow-right.svg';
import downloadArrow from '../../../assets/icons/download.svg';
import Table from '../../../components/common/table';
import { ACCT_API, API } from '../../../constants';
import { fetcher, useFetch, fileDownloader } from '../../../hooks/useFetch';
import Loader from '../../../components/styled_components/loader/loader.style';
import ScrollDownButton from '../../../utils/ScrollDownButton';
import ScrollUpButton from '../../../utils/ScrollUpButton';

const SubscriptionReport = () => {
  const breadcrumbs = [
    'Accounting',
    'Reports',
    'Subscription Reports',
    'Subscription Due',
  ];
  const d = new Date();
  d.setDate(d.getDate() - 30);
  const [data, setData] = useState();
  const [selectedGroup] = useState([{ value: '', label: 'All' }]);
  const [loading, setLoading] = useState(false);
  const [prayerGroup, setPrayerGroup] = useState('');
  const minDate = Cookies.get('accountant_year');

  const handleView = async () => {
    setLoading(true);
    const res = await fetcher({
      url: `${ACCT_API.GET_SUBSCRIPTION_DUE}?prayerGroup=${prayerGroup}&endDate=${minDate}-12-31`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    setData(res.response.filter((item) => item.balAmount > 0).map((item) => ({
      ...item, membershipId: item.membershipId.split('-')[0],
    })));
    setLoading(false);
  };

  const group = useFetch({
    url: API.GET_PRAYER_GROUP,
    method: 'GET',
    authTokenKey: 'auth_token',
  });
  if (group.data && selectedGroup.length === 1) {
    group.data.area_list.filter((i) => i.areaName !== '').map((item) => selectedGroup.push({
      value: item.areaName.replace('&', '%26'),
      label: item.areaName,
    }));
  }
  const handleDownload = async () => {
    const res = await fileDownloader({
      url: `${ACCT_API.GET_SUBSCRIPTION_DUE_DOWNLOAD}?prayerGroup=${prayerGroup}&endDate=${minDate}-12-31`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    saveAs(new Blob([res], { type: 'application/vnd.ms-excel' }), 'SubscriptionDue.xlsx');
  };

  const handleDisplay = (e) => {
    e.preventDefault();
    handleView();
    setData(null);
  };

  const tableLookup = [
    {
      heading: 'Family Id',
      field_name: 'membershipId',
      type: 'number',
    },
    {
      heading: 'Primary Member Name',
      field_name: 'memberName',
      type: 'string',
    },
    {
      heading: 'Arrears',
      field_name: 'balAmount',
      type: 'number',
    },
  ];

  return (
    <>
      <AccountingMenu />
      {loading ? <Loader /> : null}
      <ScrollUpButton />
      <ScrollDownButton />
      <AccountingPageLayout>
        <PageHeading>Reports</PageHeading>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <ReportSubMenu />
        <PageSubHeading>Subsription Due</PageSubHeading>
        <ReceiptsDiv>
          <form onSubmit={handleDisplay}>
            <Row>
              <Col col={6}>
                <SubscriptionReportsCustomSelect
                  className="select"
                  classNamePrefix="custom"
                  options={selectedGroup}
                  onChange={(e) => setPrayerGroup(e.value)}
                  placeholder="Select Prayer Group"
                />
              </Col>
              <Col col={6} textAlign="right">
                <DisplayButton
                  type="submit"
                >
                  Display
                  <DisplayButtonIcon>
                    <img src={arrowRightIcon} alt=">" height="16px" />
                  </DisplayButtonIcon>
                </DisplayButton>
              </Col>
            </Row>
          </form>
        </ReceiptsDiv>
        {data && data.length > 0 ? (
          <>

            <Table data={data} tableLookup={tableLookup} />
            <SubscriptionTotalAmtDiv>
              <Row>
                <Col col={6}>
                  <SubscriptionDownloadButton
                    type="button"
                    onClick={() => handleDownload()}
                  >
                    Download
                    <SubscriptionDownloadButtonIcon>
                      <SubscriptionDownloadImage
                        src={downloadArrow}
                        alt=">"
                        height="13px"
                      />
                    </SubscriptionDownloadButtonIcon>
                  </SubscriptionDownloadButton>
                </Col>
              </Row>

            </SubscriptionTotalAmtDiv>
          </>
        ) : data && data.length === 0 ? (
          <NoData>
            No Data
          </NoData>
        ) : ''}
      </AccountingPageLayout>
    </>
  );
};

export default SubscriptionReport;

import React from 'react';
import PropTypes from 'prop-types';
import tick from '../../../assets/icons/tick-white.svg';
import close from '../../../assets/icons/close-icon.svg';
import { Row, Col } from '../../styled_components/grid/Grid.style';
import {
  StyledModal,
  SaveButton,
  SaveButtonIcon,
  ModalWrapper,
  ModalHeading,
  CloseButton,
  CloseIcon,
  Div,
  FieldLabel,
  FieldValueInput,
  Info,
} from './editModal.style';

const EditAddressModal = ({
  isOpen,
  toggleModal,
  memberDetails,
  handleAddress,
  handleUpdate,
  caller,
}) => (
  <StyledModal isOpen={isOpen}>
    <CloseButton type="button" onClick={toggleModal}>
      <CloseIcon>
        <img src={close} alt="arrow" height="16px" />
      </CloseIcon>
    </CloseButton>
    <ModalWrapper>
      <Row>
        <Col col={12}>
          <ModalHeading> Edit Address</ModalHeading>
        </Col>
      </Row>
      <Row>
        <Col col={6}>
          <Div>
            <FieldLabel>Street Line 1</FieldLabel>
            <FieldValueInput
              id=""
              name="address_1"
              type="text"
              defaultValue={memberDetails && memberDetails.address1}
              onChange={(e) => handleAddress(e)}
            />
          </Div>
        </Col>
        <Col col={6}>
          <Div>
            <FieldLabel>Street Line 2</FieldLabel>
            <FieldValueInput
              id=""
              name="address_2"
              type="text"
              defaultValue={memberDetails && memberDetails.address2}
              onChange={(e) => handleAddress(e)}
            />
          </Div>
        </Col>
      </Row>
      <Row>
        <Col col={6}>
          <Div>
            <FieldLabel>City</FieldLabel>
            <FieldValueInput
              id=""
              name="city"
              type="text"
              defaultValue={memberDetails && memberDetails.city}
              onChange={(e) => handleAddress(e)}
            />
          </Div>
        </Col>
        <Col col={6}>
          <Div>
            <FieldLabel>State</FieldLabel>
            <FieldValueInput
              id=""
              name="state"
              type="text"
              defaultValue={memberDetails && memberDetails.state}
              onChange={(e) => handleAddress(e)}
            />
          </Div>
        </Col>
      </Row>
      <Row>
        <Col col={6}>
          <Div>
            <FieldLabel>Zip code</FieldLabel>
            <FieldValueInput
              id=""
              name="zipcode"
              type="text"
              defaultValue={memberDetails && memberDetails.zip}
              onChange={(e) => handleAddress(e)}
            />
          </Div>
          <Info>*Please enter 5-digit numeric Zipcode</Info>
        </Col>
      </Row>
      <Row>
        <Col col={8} />
        <Col col={4}>
          <SaveButton
            type="button"
            onClick={() => {
              toggleModal();
              handleUpdate();
            }}
          >
            {caller === 'admin' ? 'Submit' : 'Send for Approval'}
            <SaveButtonIcon>
              <img src={tick} alt="arrow" height="16px" />
            </SaveButtonIcon>
          </SaveButton>
        </Col>
      </Row>
    </ModalWrapper>
  </StyledModal>
);
EditAddressModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  memberDetails: PropTypes.object,
  handleAddress: PropTypes.func,
  handleUpdate: PropTypes.func,
  caller: PropTypes.string,
};

export default EditAddressModal;

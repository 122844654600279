import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from '../../styled_components/grid/Grid.style';
import {
  JournalVoucherPanelBody,
  JournalVoucherPanelDateDiv,
  JournalVoucherPanelTotal,
  JournalVoucherPanelWrapper,
} from './JournalVoucherPanel.style';

const JournalVoucherPanel = ({ voucher }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return (
    <>
      <JournalVoucherPanelWrapper>
        <Row>
          <Row>
            <Col col={6}>
              <JournalVoucherPanelDateDiv>
                {voucher.date}
              </JournalVoucherPanelDateDiv>
            </Col>
            <Col col={6} textAlign="right">
              <JournalVoucherPanelTotal>
                {`$ ${formatter.format(voucher.totalAmount)}`}
              </JournalVoucherPanelTotal>
            </Col>
          </Row>
          {/* eslint-disable-next-line */}
          {voucher.lines.map((item) => {
            return (
              <JournalVoucherPanelBody>
                <Row alignItems="center">
                  <Col col={1}>
                    #
                    {voucher.id}
                  </Col>
                  <Col col={4}>{item.account}</Col>
                  <Col col={4}>{item.description}</Col>
                  <Col col={1}>{item.debitOrCredit}</Col>
                  <Col col={2} textAlign="right">
                    {`$ ${formatter.format(item.amount)}`}
                  </Col>
                </Row>
              </JournalVoucherPanelBody>
            );
          })}
        </Row>
      </JournalVoucherPanelWrapper>
    </>
  );
};
JournalVoucherPanel.propTypes = {
  voucher: PropTypes.object,
};
export default JournalVoucherPanel;

import React, { useState, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import { isMobile } from 'react-device-detect';
import { Row, Col } from '../../components/styled_components/grid/Grid.style';
import { PageHeading, PageLayout } from '../../theme/GlobalStyles';
import { ActivityLoader, ActivityWrapper } from './Activity.style';
import ActivityPanel from '../../components/activity_panel';
import { fetcher } from '../../hooks/useFetch';
import { API } from '../../constants';
import Loader from '../../components/styled_components/loader/loader.style';
import ActivityMobile from '../mobile_view/activity';

const Activity = () => {
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(1);
  const loader = useRef(null);
  const user_id = Cookies.get('user_id');

  const handleView = async () => {
    setLoading(true);
    const res = await fetcher({
      url: `${API.GET_ACTIVITY}?limit=10&page=${page}&query=${user_id}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    setData((prev) => [...prev, ...res.activity_list]);
    setTotal(Math.ceil(res.total_activity / 10));
    setLoading(false);
  };

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting) {
      setPage((_page) => _page + 1);
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current);
    }
  }, []);

  useEffect(() => {
    if (page <= total) {
      handleView();
    }
  }, [page]);

  if (isMobile) {
    return <ActivityMobile />;
  }
  return (
    <>
      <PageLayout>
        {loading ? <Loader /> : null}
        <Row>
          <Col>
            <PageHeading>Activity</PageHeading>
          </Col>
        </Row>
        <ActivityWrapper>
          {data && data.length > 0
            ? data.filter((item) => item.remarks.length !== 0).map((data, index) => (
              <ActivityPanel activity={data} index={index} />
            ))
            : ''}
          <ActivityLoader ref={loader} />
        </ActivityWrapper>
      </PageLayout>
    </>
  );
};

export default Activity;

import styled from 'styled-components';

export const BreadcrumbsWrapper = styled.div`
  margin-top: -10px;
  margin-bottom: 15px;
`;
export const BreadcrumbText = styled.span`
  font-size: 14px;
  color: ${(props) => props.theme.colors.primary_blue};
  opacity: 0.5;
`;
export const BreadcrumbTextActive = styled.span`
  font-size: 14px;
  color: ${(props) => props.theme.colors.primary_red};
`;
export const BreadcrumbIcon = styled.img`
  margin-left: 8px;
  margin-right: 8px;
`;

import styled from 'styled-components';

export const MembersPageLayout = styled.div`
  background-color: ${({ theme }) => theme.colors.page_background};
  margin-top: 10px;
  height: 100%;
  padding: 10px 10px;
`;
export const BlueBodySection = styled.div`
  padding: 10px 0px;
  background-color: ${({ theme }) => theme.colors.blue_background};
`;

export const WhiteBodySection = styled.div`
  padding: 0;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Panel = styled.div`
  width: 100%;
  border-radius: ${({ theme }) => theme.border.radius_m};
  margin: 10px ${(props) => props.marginRight || 0} 10px 0;
`;

export const PanelBody = styled.div`
  margin-left: 20px;
  .FieldCol {
    border: 1px solid ${({ theme }) => theme.colors.grey_blue};
    border-radius: ${({ theme }) => theme.border.radius_m};
  }
  .addMargin {
    margin-right: 17px;
    margin-left: 17px;
  }
`;

export const FieldLabel = styled.div`
  color:  ${({ theme }) => theme.colors.light_grey};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fonts.font_weight.bold_font};
  margin: 13px 22px 0px;
  opacity: 0.5;
`;
export const FieldValue = styled.div`
  color:  ${({ theme }) => theme.colors.primary_blue};
  font-size: ${({ theme }) => theme.fonts.font_size.big_font};
  font-weight: ${({ theme }) => theme.fonts.font_weight.bold_font};
  margin: 0px 22px 8px;
`;
export const TopCol = styled.div`
  min-width: ${(props) => props.minWidth || 0};
  height: 100%;
  text-align: center;
  margin-right: 20px;
  margin-top: 33px;
  position: relative;
  .profileEdit {
    position: absolute;
    left: 1250px;
    top: 350px;
  }
`;
export const UserPhoto = styled.img`
  height: 150px;
  border-radius: ${({ theme }) => theme.border.radius_l};
  margin-left: 50px;
`;

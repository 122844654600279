import styled from 'styled-components';

export const BlueBodySection = styled.div`
  padding: 10px 40px;
  background-color: ${({ theme }) => theme.colors.blue_background};
`;

export const WhiteBodySection = styled.div`
  padding: 10px 40px;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Panel = styled.div`
  width: 100%;
  border-radius: ${({ theme }) => theme.border.radius_m};
  margin: 20px ${(props) => props.marginRight || 0} 20px 0;
`;

export const PanelBody = styled.div`
  margin-left: 20px;
  .FieldCol {
    border: 1px solid ${({ theme }) => theme.colors.grey_blue};
    border-radius: ${({ theme }) => theme.border.radius_m};
  }
  .addMargin {
    margin-right: 17px;
    margin-left: 17px;
  }
`;

export const FieldLabel = styled.div`
  color: ${({ theme }) => theme.colors.light_grey};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fonts.font_weight.bold_font};
  margin: 8px 22px 0px;
  opacity: 0.5;
  display: inline;
`;
export const FieldValue = styled.div`
  color: ${({ theme }) => theme.colors.primary_blue};
  font-size: ${({ theme }) => theme.fonts.font_size.big_font};
  font-weight: ${({ theme }) => theme.fonts.font_weight.bold_font};
  margin: 5px 22px 8px;
`;

export const FieldValueInput = styled.input`
  color:  ${({ theme }) => theme.colors.primary_blue};
  font-size: ${({ theme }) => theme.fonts.font_size.big_font};
  font-weight: 600;
  margin: 0px 18px 6px;
  border: 1px solid ${({ theme }) => theme.colors.grey_blue};
  border-radius: 10px;
  width: 75%;
  padding: 5px 5px 5px;;
  &:disabled {
    background-color: inherit;
    border: none;
  }
`;

export const EditIconDiv = styled.div`
  background: ${({ theme }) => theme.colors.page_background};
  width: 30px;
  height: 30px;
  border-radius: 7px;
  text-align: center;
  cursor: pointer;
  float: right;
  margin-right: 7px;
`;

export const EditIcon = styled.img`
  margin-top: 7px;
`;

export const Div = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grey_blue};
  border-radius: ${({ theme }) => theme.border.radius_m};
  min-height: 60px;
`;

export const CustomSelect = styled.select`
  border: 1px solid rgba(118, 124, 156, 0.2);
  background-color: ${({ theme }) => theme.colors.page_background};
  height: 30px;
  // z-index: 9;
  width: 80%;
  border-radius: 10px;
  margin-left: 20px;
`;

export const EditAddressField = styled.div``;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from '../../../components/styled_components/grid/Grid.style';
import ProfileSection from '../../../components/mobile_views/member_details/profile_section';
import PanelHeading from '../../../components/mobile_views/member_details/panel_heading';
import {
  MembersPageLayout,
  WhiteBodySection,
  BlueBodySection,
  Panel,
  PanelBody,
  FieldLabel,
  FieldValue,
  TopCol,
  UserPhoto,
} from './MemberDetails.style';
import MobileFamilyPanel from '../../../components/mobile_views/family_panel';
import defaultFamilyProfileImg from '../../../assets/img/default-family.png';
import { fetcher } from '../../../hooks/useFetch';
import { API } from '../../../constants';

const MembershipMobile = () => {
  const { id } = useParams();
  const [memberDetails, setMemberDetails] = useState();
  const [familyDetails, setFamilyDetails] = useState();
  const getMemberDetails = async () => {
    const res = await fetcher({
      url: `${API.MEMBER_DATA}?userid=${id}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    setMemberDetails(res);
  };
  const getFamilyDetails = async () => {
    const res = await fetcher({
      url: `${API.GET_FAMILY_MEMBERS}?userId=${id}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    setFamilyDetails(res);
  };
  useEffect(() => {
    getMemberDetails();
    getFamilyDetails();
  }, [id]);
  return (
    <>
      <MembersPageLayout className="layout">
        <ProfileSection memberDetails={memberDetails && memberDetails} />
        <BlueBodySection>
          <Row>
            <Panel marginRight="20px">
              <PanelHeading label="My Households" />
              <PanelBody>
                <Row>
                  {familyDetails && familyDetails.length > 0
                    ? familyDetails.map((member) => (
                      <Col col={12}>
                        <MobileFamilyPanel member={member} />
                      </Col>
                    ))
                    : null}
                </Row>
              </PanelBody>
            </Panel>
          </Row>
        </BlueBodySection>
        <WhiteBodySection>
          <Row>
            <Panel marginRight="20px">
              <Row>
                <Col col={11}><PanelHeading label="Family Pic" /></Col>
                <TopCol col={12}>
                  <UserPhoto
                    height="550px"
                    width="250px"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = defaultFamilyProfileImg;
                    }}
                    src={
                memberDetails && memberDetails && memberDetails.photoUrl
                  ? memberDetails.photoUrl
                  : defaultFamilyProfileImg
              }
                    alt="User Photo"
                  />
                </TopCol>

              </Row>
            </Panel>
          </Row>
        </WhiteBodySection>
        <BlueBodySection>
          <Row>
            <Panel marginRight="20px">
              <PanelHeading label="Address" />
              <PanelBody>
                <Row>
                  <Col col={12}>
                    <FieldLabel>Street Line 1</FieldLabel>
                    <FieldValue>
                      {memberDetails && memberDetails.address1}
                    </FieldValue>
                  </Col>
                  <Col col={12}>
                    <FieldLabel>Street Line 2</FieldLabel>
                    <FieldValue>
                      {memberDetails && memberDetails.address2}
                    </FieldValue>
                  </Col>
                  <Col col={12}>
                    <FieldLabel>City</FieldLabel>
                    <FieldValue>
                      {memberDetails && memberDetails.city}
                    </FieldValue>
                  </Col>
                </Row>
                <Row marginBottom="17px">
                  <Col col={12}>
                    <FieldLabel>State</FieldLabel>
                    <FieldValue>
                      {memberDetails && memberDetails.state}
                    </FieldValue>
                  </Col>
                  <Col col={12}>
                    <FieldLabel>Zip Code</FieldLabel>
                    <FieldValue>
                      {memberDetails && memberDetails.zip}
                    </FieldValue>
                  </Col>
                  <Col col={12}>
                    <FieldLabel>Address Native</FieldLabel>
                    <FieldValue>
                      {memberDetails
                        && `${memberDetails.otherAddress1} ${memberDetails.otherAddress2} ${memberDetails.otherAddressCity} ${memberDetails.otherAddressState}`}
                    </FieldValue>
                  </Col>
                </Row>
              </PanelBody>
            </Panel>
          </Row>
        </BlueBodySection>
        <WhiteBodySection>
          <Row>
            <Panel marginRight="20px">
              <PanelHeading label="Contact Information" />
              <PanelBody>
                <Row marginBottom="20px">
                  <Col col={12}>
                    <FieldLabel>Phone Number</FieldLabel>
                    <FieldValue>
                      {memberDetails && memberDetails.contactDetail}
                    </FieldValue>
                  </Col>
                  <Col col={12}>
                    <FieldLabel>Email</FieldLabel>
                    <FieldValue>
                      {memberDetails && memberDetails.homeEmail}
                    </FieldValue>
                  </Col>
                </Row>
              </PanelBody>
            </Panel>
          </Row>
        </WhiteBodySection>
      </MembersPageLayout>
    </>
  );
};
export default MembershipMobile;

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import Cookies from 'js-cookie';
import {
  Row,
  Col,
} from '../../../components/styled_components/grid/Grid.style';
import AccountingMenu from '../../../components/accounting/menu';
import Breadcrumbs from '../../../components/breadcrumbs';
import { AccountingPageLayout, PageHeading } from '../../../theme/GlobalStyles';
import JournalCreateTable from '../../../components/accounting/journal_create_table';
import tick from '../../../assets/icons/tick-white.svg';
import {
  OptionsWrapperDiv,
  StyledDate,
  JournalSaveButton,
  JournalSaveButtonIcon,
} from './Journal.style';
import { fetcher, useFetch } from '../../../hooks/useFetch';
import { ACCT_API } from '../../../constants';
import Loader from '../../../components/styled_components/loader/loader.style';

const breadcrumbs = ['Accounting', 'Journal Voucher'];

const CreateJournal = () => {
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [account, setAccount] = useState([]);
  const [credit, setCredit] = useState();
  const [debit, setDebit] = useState();
  const [items, setItems] = useState([]);
  const [getExpense, setGetExpense] = useState(false);
  const [loading, setLoading] = useState(false);
  const [button, setButton] = useState(false);
  const minDate = Cookies.get('accountant_year');
  const today = moment().format('YYYY-MM-DD');
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const expense = useFetch({
    url: `${ACCT_API.GET_BANK_ACCOUNTS}?type=Expense`,
    method: 'GET',
    authTokenKey: 'auth_token',
  });
  if (expense.data && account.length === 0) {
    setAccount(
      expense.data.response.data
        .filter((item) => item.subAccount)
        .map((item) => ({
          value: item.accountName,
          label: item.accountName,
        })),
    );
    setGetExpense(true);
  }
  const getMaxDate = () => {
    let maxDate;
    if (today.substring(0, 4) === minDate) {
      maxDate = today;
    } else {
      maxDate = `${minDate}-12-31`;
    }
    return maxDate;
  };
  const income = useFetch({
    url: `${ACCT_API.GET_BANK_ACCOUNTS}?type=Income`,
    method: 'GET',
    authTokenKey: 'auth_token',
  });
  if (income.data && getExpense) {
    setAccount([
      ...income.data.response.data.map((item) => ({
        value: item.accountName,
        label: item.accountName,
      })),
      ...account,
    ]);
    setGetExpense(false);
  }

  const handleLineItems = (items, debit, credit) => {
    setDebit(debit);
    setCredit(credit);
    setItems(
      items.map((item) => ({
        accountName: item.account,
        debit: item.debit === '' ? 0 : item.debit,
        credit: item.credit === '' ? 0 : item.credit,
        particulars: item.description,
      })),
    );
  };

  const handleSubmit = async () => {
    const payload = {
      journalDate: date,
      lineItems: items,
    };
    if (debit === credit) {
      setLoading(true);
      setButton(true);
      try {
        const res = await fetcher({
          url: `${ACCT_API.CREATE_JOURNAL_ENTRY}`,
          method: 'POST',
          authTokenKey: 'auth_token',
          body: JSON.stringify(payload),
        });
        if (res.journalVoucherId) {
          toast.success('Successfully Created!', { autoClose: 3000 });
          setLoading(false);
          setTimeout(() => {
            setButton(false);
            window.location.reload();
          }, 2000);
        } else {
          toast.error('Error!', { autoClose: 3000 });
          setLoading(false);
          setButton(false);
        }
      } catch (e) {
        toast.error('Error!', { autoClose: 3000 });
        setLoading(false);
        setButton(false);
      }
    } else {
      toast.error('Debit amount needs to be equal to Credit amount', {
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      <AccountingMenu />
      <AccountingPageLayout>
        {loading ? <Loader /> : null }
        <PageHeading>Journal Voucher</PageHeading>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <OptionsWrapperDiv>
          <Row>
            <Col col={12}>
              <Row>
                <Col col={2}>
                  <StyledDate
                    width="93%"
                    type="date"
                    min={`${minDate}-01-01`}
                    max={getMaxDate()}
                    onKeyDown={(e) => e.preventDefault()}
                    onChange={(e) => setDate(e.target.value)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </OptionsWrapperDiv>
        <JournalCreateTable
          accounts={account}
          handleLineItems={handleLineItems}
          formatter={formatter}
        />
        <Row>
          <Col col={10} />
          <Col col={2}>
            <JournalSaveButton type="button" disabled={button} onClick={() => handleSubmit()}>
              Submit
              <JournalSaveButtonIcon>
                <img src={tick} alt="arrow" height="16px" />
              </JournalSaveButtonIcon>
            </JournalSaveButton>
          </Col>
        </Row>
      </AccountingPageLayout>
    </>
  );
};

export default CreateJournal;

import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from '../../styled_components/grid/Grid.style';
import RedDot from '../../../assets/icons/red-dot.svg';
import PanelHeadingSection from './PanelHeading.style';

const PanelHeading = ({ label }) => (
  <PanelHeadingSection>
    <Row>
      <Col width="2%">
        <img src={RedDot} width={20} height={10} alt="." />
      </Col>
      <Col col={3}>{label}</Col>
    </Row>
  </PanelHeadingSection>
);

PanelHeading.propTypes = {
  label: PropTypes.string,
};

export default PanelHeading;

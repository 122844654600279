import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Row, Col } from '../../styled_components/grid/Grid.style';
import { MenuWrapper, IconDiv, IconText } from './Menu.style';
import Dashboard from '../../../assets/icons/dashboard.svg';
import Family from '../../../assets/icons/family.svg';
import Members from '../../../assets/icons/members.svg';
import Contribution from '../../../assets/icons/contribution.svg';
import DashboardActive from '../../../assets/icons/dashboard-active.svg';
import FamilyActive from '../../../assets/icons/family-active.svg';
import MembersActive from '../../../assets/icons/members-active.svg';
import ContributionActive from '../../../assets/icons/contribution-active.svg';

const Menu = () => {
  const [active, setActive] = React.useState();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  React.useEffect(() => {
    if (pathname === '/') {
      setActive('dashboard');
    }
    if (pathname === '/family') {
      setActive('family');
    }
    if (pathname === '/membership') {
      setActive('membership');
    }
    if (pathname === '/members') {
      setActive('members');
    }
    if (pathname === '/activity') {
      setActive('activity');
    }
    if (pathname === '/contribution') {
      setActive('contribution');
    }
  }, [pathname]);
  const handleClick = (link) => {
    // eslint-disable-next-line no-restricted-globals
    navigate(link);
  };
  return (

    <MenuWrapper>
      <Row>
        <Col col={3} onClick={() => handleClick('/')}>
          <IconDiv>
            <img src={active === 'dashboard' ? DashboardActive : Dashboard} height="25px" alt="icon" />
          </IconDiv>
          <IconText active={active === 'dashboard' ? '#EC1C24' : '#767c9c'}>
            Dashboard
          </IconText>
        </Col>
        <Col col={3} onClick={() => handleClick('/family')}>
          <IconDiv>
            <img src={active === 'family' ? FamilyActive : Family} height="25px" alt="icon" />
          </IconDiv>
          <IconText active={active === 'family' ? '#EC1C24' : '#767c9c'}>
            Family
          </IconText>
        </Col>
        <Col col={3} onClick={() => handleClick('/members')}>
          <IconDiv>
            <img src={active === 'members' ? MembersActive : Members} height="25px" alt="icon" />
          </IconDiv>
          <IconText active={active === 'members' ? '#EC1C24' : '#767c9c'}>
            Members
          </IconText>
        </Col>
        <Col col={3} onClick={() => handleClick('/contribution')}>
          <IconDiv>
            <img src={active === 'contribution' ? ContributionActive : Contribution} height="25px" alt="icon" />
          </IconDiv>
          <IconText active={active === 'contribution' ? '#EC1C24' : '#767c9c'}>
            Contribution
          </IconText>
        </Col>
      </Row>
    </MenuWrapper>
  );
};

export default Menu;

import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const MembersInputWrap = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 10px;
  border-radius: ${({ theme }) => theme.border.radius_m};
  width: auto;
`;

export const MembersInput = styled.input`
  background-color: ${({ theme }) => theme.colors.page_background};
  border: 1px solid ${({ theme }) => theme.colors.white};
  font-family: Archivo, sans-serif;
  padding: 8px 0px 8px 5px;
  // margin: 7px 0 0 0;
  height: 40px;
  width: 99%;
  border-radius: ${({ theme }) => theme.border.radius_m};
`;

export const MembersDisplayButton = styled.button`
  position: relative;
  background-color: ${({ theme }) => theme.colors.primary_red};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius_m};
  border: none;
  height: 50px;
  padding: 0;
  margin: 4px 0 0 0;
  width: 100%;
`;

export const MembersDisplayButtonIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(12, 0, 0, 0.15);
  width: 40px;
  height: 35px;
  margin: 0;
  padding: 15px 0 0 0;
  border-radius: ${({ theme }) => theme.border.radius_m};
`;

export const SeperaterLine = styled.div`
  border-left: 1px solid #e4e8f4;
  height: auto;
  display: inline-block;
  margin-block: -6px;
`;

export const MembersPageLayout = styled.div`
  background-color: ${({ theme }) => theme.colors.page_background};
  margin-top: 20px;
  height: 100%;
  padding: 10px 20px;
`;

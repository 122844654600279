import React, { useState } from 'react';
import { toast } from 'react-toastify';
import AddressPanel from '../../../components/add_member/address_panel';
import BaptismInfo from '../../../components/add_member/baptism_information_panel';
import BasicInfo from '../../../components/add_member/basic_information_panel';
import ContactPanel from '../../../components/add_member/contact_panel';
import OtherInfo from '../../../components/add_member/other_information_panel';
import RadioButtons from '../../../components/common/radio_buttons';
import {
  Col,
  Row,
} from '../../../components/styled_components/grid/Grid.style';
import {
  AddMemberButton,
  BaptismPageContainer,
  RadioWrap,
  WhiteWrap,
} from './ByBaptism.style';
import { fetcher, useFetch } from '../../../hooks/useFetch';
import { API, ACCT_API } from '../../../constants';
import { normalizeInput } from '../../../utils/NormalizeUSNumber';
import LoginEmail from '../../../components/add_member/login_email';
import Loader from '../../../components/styled_components/loader/loader.style';

const ByBaptism = () => {
  const [formType, setFormType] = useState('existing');
  const [loading, setLoading] = useState(false);
  const [areas, setAreas] = useState([]);
  const [address, setAddress] = useState();
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [relation, setRelation] = useState([]);
  const [parents, setParents] = useState([]);
  const [churchId, setChurchId] = useState();
  const [nextId, setNextId] = useState();
  const [text, setText] = useState({
    subtype: formType,
    type: 'baptism',
    title: '',
    firstName: '',
    lastName: '',
    middleName: '',
    placeOfBirth: '',
    dob: '',
    notes: '',
    fatherMembershipNo: '',
    motherMembershipNo: '',
    headOfFamily: '',
    gender: 'M',
    priestBaptism: '',
    dateOfBaptism: '',
    placeOfBaptism: '',
    roleId: '',
    areaName: '',
    areaId: '',
    godParent: '',
    Address1: '',
    Address2: '',
    City: '',
    State: '',
    Zip: '',
    homePhone: '',
    homeEmail: '',
    mobilePhone: '',
    email: '',
  });
  const handleFormChange = (event) => {
    const { value } = event.target;
    setFormType(value);
    setChurchId('');
    setAddress({
      address1: '',
      address2: '',
      city: '',
      motherParish: '',
      state: '',
      zipcode: '',
    });
    setParents([]);
    setText({
      value,
      title: '',
      firstName: '',
      lastName: '',
      middleName: '',
      placeOfBirth: '',
      dob: '',
      notes: '',
      fatherMembershipNo: '',
      motherMembershipNo: '',
      headOfFamily: '',
      gender: 'M',
      priestBaptism: '',
      dateOfBaptism: '',
      placeOfBaptism: '',
      roleId: '',
      areaName: '',
      areaId: '',
      godParent: '',
      Address1: '',
      Address2: '',
      City: '',
      State: '',
      Zip: '',
      homePhone: '',
      homeEmail: '',
      mobilePhone: '',
      email: '',
    });
  };

  const handleChange = (evt) => {
    const { value } = evt.target;
    if (evt.target.name === 'homePhone' || evt.target.name === 'mobilePhone') {
      setText({
        ...text,
        [evt.target.name]: normalizeInput(value, text[evt.target.name]),
      });
    } else if (evt.target.name === 'areaId') {
      const currentArea = areas.find(
        (area) => area.areaId === evt.target.value,
      );
      if (currentArea) {
        setText({
          ...text,
          areaId: currentArea.areaId,
          areaName: currentArea.areaName,
        });
      }
    } else if (evt.target.name === 'churchId') {
      setChurchId(value);
    } else {
      setText({
        ...text,
        [evt.target.name]: value,
      });
    }
  };
  const addMemberAccounting = async (id) => {
    try {
      const add = await fetcher({
        url: `${ACCT_API.ADD_MEMBER_ACCOUNTING}?membershipNo=${id}`,
        method: 'POST',
        authTokenKey: 'auth_token',
      });
      if (add.success) {
        setLoading(false);
      } else {
        setLoading(false);
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      setLoading(false);
      toast.error('Error!', { autoClose: 3000 });
    }
  };
  const newMemberEmail = async (id) => {
    try {
      const add = await fetcher({
        url: `${API.NEW_MEMBER_EMAIL}?membershipNo=${id}`,
        method: 'POST',
        authTokenKey: 'auth_token',
      });
      if (add.success) {
        setLoading(false);
      } else {
        setLoading(false);
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      setLoading(false);
      toast.error('Error!', { autoClose: 3000 });
    }
  };
  const handleAdd = async (setBody) => {
    setLoading(true);
    try {
      const add = await fetcher({
        url: API.ADD_MEMBER,
        method: 'POST',
        authTokenKey: 'auth_token',
        body: JSON.stringify(setBody),
      });
      if (add.success) {
        addMemberAccounting(add.newMembershipId);
        newMemberEmail(add.newMembershipId);
        handleFormChange({ target: { value: 'existing' } });
        toast.success(`Successfully Added!, Membership-ID is ${add.newMembershipId}`, { autoClose: 6000 });
      } else {
        setLoading(false);
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      if (e.status === 400) {
        setLoading(false);
        toast.error(e.info.error, { autoClose: 3000 });
      } else {
        setLoading(false);
        toast.error('Error!', { autoClose: 3000 });
      }
    }
  };
  const handleSave = async () => {
    let setBody = {
      type: 'baptism',
      title: text.title,
      firstName: text.firstName,
      lastName: text.lastName,
      middleName: text.middleName,
      dob: text.dob,
      notes: text.notes || '',
      gender: text.gender,
      email: text.email === '' ? null : text.email,
    };
    if (formType === 'existing') {
      setBody = {
        ...setBody,
        subtype: 'existing',
        fatherMembershipNo: text.fatherMembershipNo,
        motherMembershipNo: text.motherMembershipNo,
        placeOfBirth: text.placeOfBirth,
        dateOfBaptism: text.dateOfBaptism,
        placeOfBaptism: text.placeOfBaptism,
        priestBaptism: text.priestBaptism,
        roleId: text.roleId,
        godParent: text.godParent,
      };
    } else {
      setBody = {
        ...setBody,
        subtype: 'new',
        Address1: text.Address1,
        Address2: text.Address2,
        City: text.City,
        Zip: text.Zip,
        State: text.State,
        homePhone: text.homePhone,
        mobilePhone: text.mobilePhone,
        areaName: text.areaName,
        areaId: text.areaId,
        homeEmail: text.homeEmail,
      };
    }
    const validate = Object.keys(setBody).filter((item) => item !== 'notes' && item !== 'email' && item !== 'middleName').find((key) => (setBody[key] === '' || setBody[key] === null));
    if (!validate) {
      handleAdd(setBody);
    } else if (validate === 'roleId') {
      toast.warning('Relation can\'t be blank', { autoClose: 3000 });
    } else if (validate === 'dob') {
      toast.warning('Date of Birth can\'t be blank', { autoClose: 3000 });
    } else {
      toast.warning(`${validate} can't be blank`, { autoClose: 3000 });
    }
  };

  const handleChurchId = async (id) => {
    if (id.length > 2) {
      const res = await fetcher({
        url: `${API.GET_FAMILY_MEMBERS}?churchId=${id}`,
        method: 'GET',
        authTokenKey: 'auth_token',
      });
      const temp = res.filter((x) => x.relation === 'Head of Household');
      setText({ ...text, headOfFamily: temp[0].name });

      const tempNames = res.map((item) => ({
        value: item.churchID,
        label: item.name,
      }));
      setParents(tempNames);

      const add = await fetcher({
        url: `${API.GET_MEMBER_ADDRESS}?family_id=${id}`,
        method: 'GET',
        authTokenKey: 'auth_token',
      });
      setAddress(add);
    }
  };

  const group = useFetch({
    url: API.GET_PRAYER_GROUP,
    method: 'GET',
    authTokenKey: 'auth_token',
  });
  if (group.data && selectedGroup.length === 0) {
    setAreas(group.data.area_list);
    setSelectedGroup(
      group.data.area_list.map((item) => ({
        value: item.areaId,
        label: item.areaName,
      })),
    );
  }
  const rel = useFetch({
    url: API.GET_RELATIONS,
    method: 'GET',
    authTokenKey: 'auth_token',
  });
  if (rel.data && relation.length === 0) {
    setNextId(rel.data.next_family_id);
    setRelation(
      rel.data.relation_list.map((item) => ({
        value: item.roleId,
        label: item.roleName,
      })),
    );
  }
  return (
    <WhiteWrap>
      {loading ? <Loader /> : null}
      <BaptismPageContainer>
        <RadioWrap>
          <Row>
            <Col col={6}>
              <RadioButtons
                label2="New"
                value2="new"
                label1="Existing"
                value1="existing"
                marginTop="5px"
                handleSelectChange={handleFormChange}
                select={formType}
              />
            </Col>
            <Col col={6}>
              <AddMemberButton type="button" onClick={handleSave}>
                Save
              </AddMemberButton>
            </Col>
          </Row>
        </RadioWrap>
        <Row>
          <LoginEmail caller="baptism" text={text} nextId={formType === 'new' ? nextId : null} handleChange={handleChange} />
        </Row>
        <Row>
          <BasicInfo
            caller="baptism"
            formType={formType}
            handleChurchId={handleChurchId}
            handleChange={handleChange}
            relation={relation}
            text={text}
            parents={parents || ''}
            churchId={churchId}
          />
        </Row>
        <Row>
          <BaptismInfo caller={formType} text={text} handleChange={handleChange} />
        </Row>
        <Row>
          <OtherInfo
            type={formType}
            areaName={selectedGroup}
            caller="baptism"
            formType={formType}
            handleChange={handleChange}
            text={text}
          />
        </Row>
        <Row>
          <AddressPanel
            handleChange={handleChange}
            text={text}
            address={address || []}
            formType={formType}
          />
        </Row>
        {formType === 'new' ? (
          <Row>
            <ContactPanel handleChange={handleChange} text={text} />
          </Row>
        ) : (
          ''
        )}
      </BaptismPageContainer>
    </WhiteWrap>
  );
};

export default ByBaptism;

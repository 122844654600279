import styled from 'styled-components';

export const SummarizedDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 5px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 4%);
  border-radius: ${({ theme }) => theme.border.radius_m};
`;
export const SummarizedHeadDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 5px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 4%);
  border-radius: ${({ theme }) => theme.border.radius_m};
  margin: 10px 0 10px 0;
  font-weight: ${({ theme }) => theme.fonts.font_weight.bold_font};
  font-size: ${({ theme }) => theme.fonts.font_size.heavy_font};
  color: ${({ theme }) => theme.colors.primary_blue};
`;
export const SummarizedSubHeadDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 5px;
  border-radius: ${({ theme }) => theme.border.radius_m};
  margin: 0;
  font-weight: ${({ theme }) => theme.fonts.font_weight.bolder_font};
  font-size: ${({ theme }) => theme.fonts.font_size.heavy_font};
  color: ${({ theme }) => theme.colors.primary_blue};
`;
export const SummarizedBodyDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 5px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 4%);
  border-radius: ${({ theme }) => theme.border.radius_m};
  margin: 10px 0 10px 0;
  font-weight: ${({ theme }) => theme.fonts.font_weight.normal_font};
  font-size: ${({ theme }) => theme.fonts.font_size.normal_font};
  color: ${({ theme }) => theme.colors.primary_blue};
`;
export const ReportTitle = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 5px;
  margin: 10px 0 10px 0;
  text-align: center;
  font-weight: ${({ theme }) => theme.fonts.font_weight.bold_font};
  font-size: ${({ theme }) => theme.fonts.font_size.heavy_font};
  color: ${({ theme }) => theme.colors.primary_blue};
`;
export const StyledDate = styled.input`
  padding: 0px 10px;
  margin: 5px 10px;
  height: 48px;
  width: ${(props) => props.width};
  border-radius: ${({ theme }) => theme.border.radius_m};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary_blue};
  background-color: ${({ theme }) => theme.colors.page_background};
  border: 1px solid ${({ theme }) => theme.colors.page_background};
`;
export const DisplayButton = styled.button`
  position: relative;
  background-color: ${({ theme }) => theme.colors.primary_red};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius_m};
  border: none;
  height: 50px;
  padding: 0;
  margin: 5px 20px;
  width: 35%;
  float: right;
`;
export const DisplayButtonIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(12, 0, 0, 0.15);
  width: 42px;
  height: 35px;
  margin: 0;
  padding: 15px 0 0 0;
  border-radius: ${({ theme }) => theme.border.radius_m};
`;
export const BoldItems = styled.div`
  margin: 10px 0 10px 10px;
  font-size: ${({ theme }) => theme.fonts.font_size.big_font};
  font-weight: ${({ theme }) => theme.fonts.font_weight.bold_font};
`;

export const LineItems = styled.div`
  font-size: ${({ theme }) => theme.fonts.font_size.big_font};
  padding: 10px 0 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
`;

export const ItemTotal = styled.div`
  margin: 10px 0 10px 10px;
  font-size: ${({ theme }) => theme.fonts.font_size.big_font};
  font-weight: ${({ theme }) => theme.fonts.font_weight.bold_font};
`;
export const DownloadButton = styled.button`
  position: relative;
  font-size: ${({ theme }) => theme.fonts.font_size.big_font};
  font-weight: ${({ theme }) => theme.fonts.font_weight.normal_font};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary_blue};
  border-radius: ${({ theme }) => theme.border.radius_s};
  border: 2px solid ${({ theme }) => theme.colors.secondary_background};
  height: 40px;
  padding: 0;
  margin: 10px 0 10px 10px;
  width: 35%;
`;
export const DownloadButtonIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  border: 1px solid #000;
  border-radius: 50px;
  height: 20px;
  width: 20px;
  margin: 8px;
`;
export const DownloadImage = styled.img`
  margin-top: 3px;
`;
export const FinalTotal = styled.div`
  margin: 10px 0 10px 10px;
  font-size: ${({ theme }) => theme.fonts.font_size.heavy_font};
  font-weight: ${({ theme }) => theme.fonts.font_weight.bold_font};
`;
export const NoData = styled.div`
  font-weight:${({ theme }) => theme.fonts.font_weight.normal_font};
  color : ${({ theme }) => theme.colors.primary_blue};
  font-size: 20px;
  margin-top: 20px;
`;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import tick from '../../../assets/icons/tick-white.svg';
import close from '../../../assets/icons/close-icon.svg';
import { Row, Col } from '../../styled_components/grid/Grid.style';

import {
  StyledModal,
  SaveButton,
  SaveButtonIcon,
  FieldLabel,
  ModalWrapper,
  ModalHeading,
  FieldValueInput,
  Div,
  CloseButton,
  CloseIcon,
  MessageField,
} from './configureModal.style';
// import { PageSubHeading } from '../../../theme/GlobalStyles';

const CreateModal = ({
  isOpen, toggleModal, handleUpdate, configuration,
}) => {
  const [items, setItem] = useState(null);
  if (configuration && !items) {
    setItem({
      duration: moment(new Date()).format('yyyy'),
      contact1: configuration.results.contact1,
      contact2: configuration.results.contact2,
      phone1: configuration.results.phone1,
      phone2: configuration.results.phone2,
      vicarName: configuration.results.vicar,
      trusteeFinanceName: configuration.results.trusteeFinanceName,
      trusteeAccountsName: configuration.results.trusteeAccountsName,
      message: configuration.results.message,
    });
  }
  const handleChange = (e) => {
    const data = { ...items };
    data[e.target.name] = e.target.value;
    setItem(data);
  };

  return (

    <StyledModal isOpen={isOpen}>
      <CloseButton type="button" onClick={toggleModal}>
        <CloseIcon>
          <img src={close} alt="arrow" height="16px" />
        </CloseIcon>
      </CloseButton>
      <ModalWrapper>
        <Row>
          <Col col={12}>
            <ModalHeading>Configure Statement</ModalHeading>
          </Col>
        </Row>

        <Row>
          <Col col={6}>
            <Div>
              <FieldLabel>Contact1 Name</FieldLabel>
              <FieldValueInput
                id=""
                name="contact1"
                type="text"
                onChange={(e) => handleChange(e)}
                defaultValue={configuration && configuration.results.contact1}
              />
            </Div>
          </Col>
          <Col col={6}>
            <Div>
              <FieldLabel>Contact1 Phone</FieldLabel>
              <FieldValueInput
                id=""
                name="phone1"
                type="text"
                defaultValue={configuration && configuration.results.phone1}
                onChange={(e) => handleChange(e)}
              />
            </Div>
          </Col>
        </Row>
        <Row>
          <Col col={6}>
            <Div>
              <FieldLabel>Contact2 Name</FieldLabel>
              <FieldValueInput
                id=""
                name="contact2"
                type="text"
                defaultValue={configuration && configuration.results.contact2}
                onChange={(e) => handleChange(e)}
              />
            </Div>
          </Col>
          <Col col={6}>
            <Div>
              <FieldLabel>Contact2 Phone</FieldLabel>
              <FieldValueInput
                id=""
                name="phone2"
                type="text"
                defaultValue={configuration && configuration.results.phone2}
                onChange={(e) => handleChange(e)}
              />
            </Div>
          </Col>
        </Row>
        <Row>
          <Col col={6}>
            <Div>
              <FieldLabel>Vicar Name</FieldLabel>
              <FieldValueInput
                id=""
                name="vicarName"
                type="text"
                defaultValue={configuration && configuration.results.vicar}
                onChange={(e) => handleChange(e)}
              />
            </Div>
          </Col>
          <Col col={6}>
            <Div>
              <FieldLabel>Trustee-Finance Name</FieldLabel>
              <FieldValueInput
                id=""
                name="trusteeFinanceName"
                type="text"
                defaultValue={configuration && configuration.results.trusteeFinanceName}
                onChange={(e) => handleChange(e)}
              />
            </Div>
          </Col>

        </Row>
        <Row>
          <Col col={6}>
            <Div>
              <FieldLabel>Trustee-Accounts Name</FieldLabel>
              <FieldValueInput
                id=""
                name="trusteeAccountsName"
                type="text"
                defaultValue={configuration && configuration.results.trusteeAccountsName}
                onChange={(e) => handleChange(e)}
              />
            </Div>
          </Col>
        </Row>
        <Row>
          <Col col={12}>
            <Div>
              <FieldLabel>Message</FieldLabel>
              <MessageField
                id=""
                name="message"
                type="text"
                defaultValue={configuration && `${configuration.results.message}${configuration.results.message2}${configuration.results.message3}`}
                onChange={(e) => handleChange(e)}
              />
            </Div>
          </Col>
        </Row>
        <Row>
          <Col col={8} />
          <Col col={4}>
            <SaveButton type="button" onClick={() => { toggleModal(); handleUpdate(items); }}>
              Submit
              <SaveButtonIcon>
                <img src={tick} alt="arrow" height="16px" />
              </SaveButtonIcon>
            </SaveButton>
          </Col>
        </Row>
      </ModalWrapper>
    </StyledModal>
  );
};
CreateModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  handleUpdate: PropTypes.func,
  configuration: PropTypes.object,
};

export default CreateModal;

import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import Cookies from 'js-cookie';
import AccountingMenu from '../../../components/accounting/menu';
import { AccountingPageLayout, PageHeading } from '../../../theme/GlobalStyles';
import Breadcrumbs from '../../../components/breadcrumbs';
import ReportSubMenu from '../../../components/accounting/report_submenu';
import Download from '../../../assets/icons/download.svg';
import {
  PageSubHeading,
  FilterWrap,
  SearchInput,
  TransactionReceiptDisplayButton,
  TransactionReceiptDisplayButtonIcon,
  TotalAmtDiv,
  DownloadButton,
  DownloadIcon,
} from './ItemizedContributionList.style';
import arrowRightIcon from '../../../assets/icons/arrow-right.svg';
import RadioButtons from '../../../components/common/radio_buttons';
import {
  Col,
  Row,
} from '../../../components/styled_components/grid/Grid.style';
import { fileDownloader, fetcher } from '../../../hooks/useFetch';
import { ACCT_API } from '../../../constants';
import ItemizedContributionTable from '../../../components/common/itemized_contribution_table';
import Loader from '../../../components/styled_components/loader/loader.style';
import ScrollDownButton from '../../../utils/ScrollDownButton';
import ScrollUpButton from '../../../utils/ScrollUpButton';

const ItemizedContributionList = () => {
  const breadcrumbs = [
    'Accounting',
    'Reports',
    'Member Reports',
    'Itemized Contribution List',
  ];
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [select, setSelect] = useState('main_info');
  const [headers, setHeaders] = useState([]);
  const [generalTableLookup, setGeneralTableLookup] = useState([]);
  const [loading, setLoading] = useState(false);
  const minDate = Cookies.get('accountant_year');
  const mainTableLookup = [
    {
      heading: 'Area',
      field_name: 'Area',
      type: 'string',
    },
    {
      heading: 'ID #',
      field_name: 'ID #',
      type: 'string',
    },
    {
      heading: 'Name',
      field_name: 'Name',
      type: 'string',
    },
    {
      heading: 'Baptism & Marriage Offertory/Donations',
      field_name: 'Baptism & Marriage Offertory/Donations',
      type: 'string',
    },
    {
      heading: 'Birthday Offertory',
      field_name: 'Birthday Offertory',
      type: 'string',
    },
    {
      heading: 'COVID Relief Fund',
      field_name: 'COVID Relief Fund',
      type: 'string',
    },
    {
      heading: 'Diocesan Sunday Donation',
      field_name: 'Diocesan Sunday Donation',
      type: 'string',
    },
    {
      heading: 'Church Facility Project',
      field_name: 'Church Facility Project',
      type: 'string',
    },
    {
      heading: 'Harvest -Donation, Auction',
      field_name: 'Harvest -Donation, Auction',
      type: 'string',
    },
    {
      heading: 'Light to Life',
      field_name: 'Light to Life',
      type: 'string',
    },
    {
      heading: 'Special. Don. (Mission Projects in India)',
      field_name: 'Spe. Don. (Mission Projects in India)',
      type: 'string',
    },
    {
      heading: 'Special Donation',
      field_name: 'Special Donation',
      type: 'string',
    },
    {
      heading: 'Subscription',
      field_name: 'Subscription',
      type: 'string',
    },
    {
      heading: 'Suvisesha Nidhi',
      field_name: 'Suvisesha Nidhi',
      type: 'string',
    },
    {
      heading: 'Trinity center Income (Rent & Donation)',
      field_name: 'Trinity center Income (Rent & Donation)',
      type: 'string',
    },
    {
      heading: 'Wedding Anniversary Offertory',
      field_name: 'Wedding Anniversary Offertory',
      type: 'string',
    },
    {
      heading: 'One Day Income',
      field_name: 'One Day Income',
      type: 'string',
    },
    {
      heading: 'Christmas Donation',
      field_name: 'Christmas Donation',
      type: 'string',
    },
    {
      heading: 'General',
      field_name: 'General',
      type: 'amount',
    },
    {
      heading: 'Total',
      field_name: 'Total',
      type: 'amount',
    },
  ];

  useEffect(() => {
    if (select === 'general_info' && headers) {
      const arr = [];
      for (const i of headers) {
        if (i) {
          const temp = {
            heading: i,
            field_name: i,
            type: i === 'Total' ? 'amount' : 'string',
          };
          arr.push(temp);
        }
      }
      setGeneralTableLookup(arr);
    }
  }, [select]);

  const handleMainDownload = async () => {
    const url = select === 'main_info'
      ? `${ACCT_API.DOWNLOAD_ITEMIZED_LIST}?startDate=${startDate}&endDate=${endDate}`
      : `${ACCT_API.DOWNLOAD_ITEMIZED_GENERAL}?startDate=${startDate}&endDate=${endDate}`;
    const res = await fileDownloader({
      url,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    saveAs(
      new Blob([res], { type: 'application/vnd.ms-excel' }),
      'myExcel.xlsx',
    );
  };

  const handleSelectChange = async (event) => {
    setLoading(true);
    if (event.target.value === 'general_info') {
      const data = await fetcher({
        url: `${ACCT_API.GET_ITEMIZED_GENERAL}?startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
        authTokenKey: 'auth_token',
      });
      if (data?.response) {
        const temp = [];
        data?.response.filter((x) => {
          if (x.rows) {
            x.rows.forEach((field) => {
              temp.push(field);
            });
          } else if (x.summary) temp.push(x.summary);
          else if (x.footer) temp.push(x.footer);
          return temp;
        });
        setData(temp);
      }
      const head = data?.response.slice(0, 1);
      setHeaders(head[0].headers);
      setLoading(false);
    } else {
      const data = await fetcher({
        url: `${ACCT_API.GET_ITEMIZED_LIST}?startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
        authTokenKey: 'auth_token',
      });
      if (data?.response) {
        const temp = [];
        data?.response.filter((x) => {
          if (x.rows) {
            x.rows.forEach((field) => {
              temp.push(field);
            });
          } else if (x.summary) temp.push(x.summary);
          else if (x.footer) temp.push(x.footer);
          return temp;
        });
        setLoading(false);
        setData(temp);
      }
    }
    setSelect(event.target.value);
  };

  const handleDisplay = async () => {
    const url = select === 'main_info'
      ? `${ACCT_API.GET_ITEMIZED_LIST}?startDate=${startDate}&endDate=${endDate}`
      : `${ACCT_API.GET_ITEMIZED_GENERAL}?startDate=${startDate}&endDate=${endDate}`;
    setLoading(true);
    const data = await fetcher({
      url,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    if (data?.response) {
      const temp = [];
      data?.response.filter((x) => {
        if (x.rows) {
          x.rows.forEach((field) => {
            temp.push(field);
          });
        } else if (x.summary) temp.push(x.summary);
        else if (x.footer) temp.push(x.footer);
        return temp;
      });
      setData(temp);
    }
    if (select === 'general_info') {
      const head = data?.response?.slice(0, 1);
      setHeaders(head[0].headers);
    }
    setLoading(false);
  };

  return (
    <>
      <AccountingMenu />
      {loading ? <Loader /> : null}
      <ScrollUpButton />
      <ScrollDownButton />
      <AccountingPageLayout>
        <PageHeading>Reports</PageHeading>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <ReportSubMenu />
        <PageSubHeading>Itemized Contribution List</PageSubHeading>
        <FilterWrap>
          <Row>
            <Col col={4}>
              <RadioButtons
                label1="Main Info"
                value1="main_info"
                label2="General Info"
                value2="general_info"
                marginTop="5px"
                handleSelectChange={handleSelectChange}
                select={select}
              />
            </Col>
            <Col col={3}>
              <SearchInput
                type="date"
                min={`${minDate}-01-01`}
                max={`${minDate}-12-31`}
                placeholder="Start Date"
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Col>
            <Col col={3}>
              <SearchInput
                type="date"
                min={`${minDate}-01-01`}
                max={`${minDate}-12-31`}
                placeholder="End Date"
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Col>
            <Col col={2}>
              <TransactionReceiptDisplayButton
                type="button"
                onClick={() => handleDisplay()}
                disabled={!(startDate && endDate)}
              >
                Display
                <TransactionReceiptDisplayButtonIcon>
                  <img src={arrowRightIcon} alt=">" height="16px" />
                </TransactionReceiptDisplayButtonIcon>
              </TransactionReceiptDisplayButton>
            </Col>
          </Row>
        </FilterWrap>
        {
          !loading && data.length > 0
            ? (
              <>
                <>
                  {select === 'main_info' && (
                    <ItemizedContributionTable
                      data={data}
                      tableLookup={mainTableLookup}
                    />
                  )}
                  {select === 'general_info' && (
                    <ItemizedContributionTable
                      data={data}
                      tableLookup={generalTableLookup}
                    />
                  )}
                </>
                <TotalAmtDiv>
                  <DownloadButton onClick={handleMainDownload}>
                    Download
                    <DownloadIcon>
                      <img src={Download} alt="download" />
                    </DownloadIcon>
                  </DownloadButton>
                </TotalAmtDiv>
              </>
            ) : null
            }
      </AccountingPageLayout>
    </>
  );
};

export default ItemizedContributionList;

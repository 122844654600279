import React from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Col, Row } from '../../../styled_components/grid/Grid.style';
import {
  UserPhoto,
  MemberName,
  SeparatorIcon,
  TopSection,
  AddressLine,
  CommunicationLine,
  CommunicationIcon,
  CommunicationBody,
  SocialIcon,
  BlueDiv,
  // SocialMediaLine,
  MemberInformation,
  SocialButton,
  EditIconDiv,
  EditIcon,
} from './ProfileSection.style';
import defaultUserProfileImg from '../../../../assets/img/default-user.png';
import diamondIcon from '../../../../assets/icons/diamond.svg';
import mailIcon from '../../../../assets/icons/mail.svg';
import phoneIcon from '../../../../assets/icons/phone.svg';
import twitter from '../../../../assets/icons/twitter.svg';
import linkedIn from '../../../../assets/icons/linkedIn.svg';
import facebook from '../../../../assets/icons/facebook.svg';
import instagram from '../../../../assets/icons/instagram-icon.svg';
import editIcon from '../../../../assets/icons/pen.svg';

const ProfileSection = ({ showEditIcon, memberDetails }) => {
  const goToProfile = (link) => {
    window.open(link);
  };
  const showNotification = (value) => {
    toast.error(value, { autoClose: 3000 });
  };
  return (
    <TopSection>
      <Row>
        <Col col={12} textAlign="center" margin="10px 0">
          <UserPhoto
            height="150px"
            width="150px"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = defaultUserProfileImg;
            }}
            src={
                memberDetails && memberDetails && memberDetails.photoUrl
                  ? memberDetails.photoUrl
                  : defaultUserProfileImg
              }
            alt="User Photo"
          />
          {showEditIcon ? (
            <EditIconDiv className="profileEdit">
              <EditIcon src={editIcon} alt="edit" />
            </EditIconDiv>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col col={12}>
          <MemberInformation>
            <Row>
              <Col col={12} textAlign="center">
                <MemberName>
                  {memberDetails
                      && `${memberDetails.firstName} ${memberDetails.secondName} ${memberDetails.lastName}`}
                </MemberName>
              </Col>
            </Row>
            <AddressLine>
              <Row>
                <Col col={6} textAlign="center">{memberDetails && memberDetails.areaName}</Col>
                <Col width="5%" textAlign="center">
                  <SeparatorIcon src={diamondIcon} />
                </Col>
                <Col col={2}>
                  {memberDetails && memberDetails.membershipNumber}
                </Col>
                <Col width="5%" textAlign="center">
                  <SeparatorIcon src={diamondIcon} />
                </Col>
                <Col col={2}>
                  {memberDetails
                      && `${memberDetails.sex === 'M' ? 'Male' : 'Female'}`}
                </Col>
              </Row>
            </AddressLine>
            <Row>
              <Col col={12}>
                <CommunicationLine>
                  <CommunicationIcon src={mailIcon} alt="email" />
                  <CommunicationBody>
                    <div className="heading">Email</div>
                    <div className="details">
                      {memberDetails && memberDetails.homeEmail}
                    </div>
                  </CommunicationBody>
                </CommunicationLine>
              </Col>
              <Col col={12}>
                <CommunicationLine>
                  <CommunicationIcon src={phoneIcon} alt="phone" />
                  <CommunicationBody>
                    <div className="heading">Phone</div>
                    <div className="details">
                      {memberDetails && memberDetails.contactNo}
                    </div>
                  </CommunicationBody>
                </CommunicationLine>
              </Col>
            </Row>
          </MemberInformation>
        </Col>
      </Row>
      <Row>
        <Col col={3} textAlign="-webkit-center">
          <BlueDiv>
            <SocialButton
              onClick={() => (memberDetails.twitter
                ? goToProfile(memberDetails.twitter)
                : showNotification('Twitter not provided'))}
            >
              <SocialIcon src={twitter} />
            </SocialButton>
          </BlueDiv>
        </Col>
        <Col col={3} textAlign="-webkit-center">
          <BlueDiv>
            <SocialButton
              onClick={() => (memberDetails.linkedIn
                ? goToProfile(memberDetails.linkedIn)
                : showNotification('LinkedIn not provided'))}
            >
              <SocialIcon src={linkedIn} />
            </SocialButton>
          </BlueDiv>
        </Col>
        <Col col={3} textAlign="-webkit-center">
          <BlueDiv>
            <SocialButton
              onClick={() => (memberDetails.facebook
                ? goToProfile(memberDetails.facebook)
                : showNotification('Facebook not provided'))}
            >
              <SocialIcon src={facebook} />
            </SocialButton>
          </BlueDiv>

        </Col>
        <Col col={3} textAlign="-webkit-center">
          <BlueDiv>
            <SocialButton
              onClick={() => (memberDetails.instagram
                ? goToProfile(memberDetails.instagram)
                : showNotification('Instagram not provided'))}
            >
              <SocialIcon src={instagram} />
            </SocialButton>
          </BlueDiv>
        </Col>
      </Row>
    </TopSection>
  );
};

ProfileSection.propTypes = {
  showEditIcon: PropTypes.bool,
  memberDetails: PropTypes.shape,
};
export default ProfileSection;

import React, { useState } from 'react';
import Cookies from 'js-cookie';
import {
  Col,
  Row,
} from '../../../../components/styled_components/grid/Grid.style';
import {
  AccountingPageLayout,
  PageHeading,
} from '../../../../theme/GlobalStyles';
import {
  DateInput,
  JournalInputWrap,
  JournalDisplayButton,
  JournalDisplayButtonIcon,
  JournalTableHead,
  NoData,
} from './JournalVouchers.style';
import arrowRightIcon from '../../../../assets/icons/arrow-right.svg';
import JournalVoucherPanel from '../../../../components/accounting/journal_voucher_panel';
import AccountingMenu from '../../../../components/accounting/menu';
import { fetcher } from '../../../../hooks/useFetch';
import { ACCT_API } from '../../../../constants';
import Breadcrumbs from '../../../../components/breadcrumbs';
import Loader from '../../../../components/styled_components/loader/loader.style';
import ScrollDownButton from '../../../../utils/ScrollDownButton';
import ScrollUpButton from '../../../../utils/ScrollUpButton';

const JournalVouchers = () => {
  const breadcrumbs = ['Accounting', 'Transactions', 'Journal Vouchers'];
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const minDate = Cookies.get('accountant_year');

  const handleView = async () => {
    setLoading(true);
    const res = await fetcher({
      url: `${ACCT_API.GET_TRANSACTION_JOURNAL_VOUCHERS}?startDate=${startDate}&endDate=${endDate}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    setData(res);
    setLoading(false);
  };

  return (
    <>
      <AccountingMenu />
      {loading ? <Loader /> : null}
      <ScrollUpButton />
      <ScrollDownButton />
      <AccountingPageLayout>
        <Row>
          <Col>
            <PageHeading>Journal Vouchers</PageHeading>
          </Col>
        </Row>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <JournalInputWrap>
          <Row>
            <Col col={4}>
              <DateInput
                type="date"
                min={`${minDate}-01-01`}
                max={`${minDate}-12-31`}
                placeholder="Start Date"
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Col>
            <Col col={4}>
              <DateInput
                type="date"
                min={`${minDate}-01-01`}
                max={`${minDate}-12-31`}
                placeholder="End Date"
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Col>
            <Col col={4} textAlign="right">
              <JournalDisplayButton type="button" onClick={() => handleView()} disabled={!(startDate && endDate)}>
                Display
                <JournalDisplayButtonIcon>
                  <img src={arrowRightIcon} alt=">" height="16px" />
                </JournalDisplayButtonIcon>
              </JournalDisplayButton>
            </Col>
          </Row>
        </JournalInputWrap>
        {
          data && data.response.length > 0
            ? (
              <JournalTableHead>
                <Row>
                  <Col col={1}>ID</Col>
                  <Col col={4}>Account</Col>
                  <Col col={4}>Description</Col>
                  <Col col={1}>Debit/Credit</Col>
                  <Col col={2} textAlign="right">
                    Amount
                  </Col>
                </Row>
              </JournalTableHead>
            )
            : ''
        }
        {data && data.response.length > 0
          ? data.response.map((data) => <JournalVoucherPanel voucher={data} />)
          : data && data.response.length === 0 ? (
            <NoData>
              No Data
            </NoData>
          ) : ''}
      </AccountingPageLayout>
    </>
  );
};

export default JournalVouchers;

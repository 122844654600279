import styled from 'styled-components';

export const MenuWrapper = styled.div`
  box-shadow: 0 8px 6px -7px rgba(71, 78, 115, 0.08);
`;

export const Menu = styled.div`
  margin-right: ${(props) => props.marginRight};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.secondary_background};
  box-shadow: 0px 4px 4px rgb(53 61 101 / 8%);
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  padding: 10px;
  text-align: center;
  line-height: 40px;
  color: ${({ theme }) => theme.colors.primary_blue};
  height: 40px;
  cursor: pointer;

  .dropdown {
    position: relative;
  }
`;

export const DropdownContent = styled.div`
  display: ${(props) => props.display || 'none'};
  position: absolute;
  left: 10px;
  top: 61px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.secondary_background};
  width: 92%;
  z-index: 1;
  text-align: left;
  p {
    text-align: center;
    margin: 0;
    cursor: pointer;
    border-bottom: 1px solid ${({ theme }) => theme.colors.secondary_background};
  }
  p > a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary_blue};
  }
`;
export const DropDownItem = styled.div`
  color: ${({ theme }) => theme.colors.primary_blue};
  border-top: 1px solid ${({ theme }) => theme.colors.secondary_background};
  text-align: center;
  margin: 0 0 0 0;
  cursor: pointer;
  width: 100%;
`;

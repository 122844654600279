import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Col, Row } from '../styled_components/grid/Grid.style';

import defaultPic from '../../assets/icons/default-profile.png';
import editIcon from '../../assets/icons/pen.svg';
import remove from '../../assets/icons/delete.svg';

import {
  FamilyCardWrapper,
  FamilyRelation,
  FamilyName,
  FamilyProfilePic,
  EditIconDiv,
  EditIcon,
  RemoveButton,
  RemoveIcon,
} from './FamilyPanel.style';

const FamilyPanel = ({
  marginRight, member, showEditIcon,
  handleRemove, memberChange, membership,
  caller, roles,
}) => {
  const navigate = useNavigate();
  const handleClick = (link) => {
    navigate(link);
  };
  return (
    <>
      <FamilyCardWrapper key={member && member.id} marginRight={marginRight}>
        <Row>
          <Col col={2}>
            <FamilyProfilePic
              onError={(e) => { e.target.onerror = null; e.target.src = defaultPic; }}
              src={member && member.profile_pic_url ? member.profile_pic_url : defaultPic}
              alt="default pic"
              onClick={() => (membership ? memberChange(member.id) : handleClick(`/members/details/${member.ID ? member.ID : member.id}`))}
            />
          </Col>
          <Col col={9}>
            <Row>
              <Col>
                <FamilyName onClick={() => (membership ? memberChange(member.id) : handleClick(`/members/details/${member.ID ? member.ID : member.id}`))}>
                  {member && member.name}
                  (
                  {member && member.gender}
                  )
                </FamilyName>
              </Col>
            </Row>
            <Row>
              <Col col={12}>
                <FamilyRelation>{member && member.relation}</FamilyRelation>
                <FamilyRelation>{member && member.age}</FamilyRelation>
              </Col>
            </Row>
          </Col>
          {caller === 'member_details' && roles.includes('PowerUser') ? (
            <Col col={1}>
              <RemoveButton onClick={() => handleRemove(member)}>
                <RemoveIcon src={remove} />
              </RemoveButton>
            </Col>
          ) : null}
          {showEditIcon
            ? (
              <Col col={1}>
                <EditIconDiv onClick={() => memberChange(member.id)}>
                  <EditIcon src={editIcon} />
                </EditIconDiv>
              </Col>
            ) : null}
        </Row>
      </FamilyCardWrapper>
    </>
  );
};
FamilyPanel.propTypes = {
  marginRight: PropTypes.string,
  member: PropTypes.object,
  showEditIcon: PropTypes.bool,
  memberChange: PropTypes.func,
  handleRemove: PropTypes.func,
  roles: PropTypes.array,
  membership: PropTypes.bool,
  caller: PropTypes.string,
};

export default FamilyPanel;

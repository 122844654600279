import styled from 'styled-components';
import Modal from 'styled-react-modal';

export const ModalWrapper = styled.div`
  margin: 30px;
`;
export const ModalHeading = styled.h2`
  margin: 10px 0px 20px 0px;
  font-family: 'Playfair Display', sans-serif;
  color: ${({ theme }) => theme.colors.primary_blue};
`;
export const RemoveModals = Modal.styled`
  width: 500px;
  height: 200px;
  align-items: center;
  font-size: 15px;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.border.radius_m}
`;

export const SaveButton = styled.button`
  position: relative;
  background-color: ${({ theme }) => theme.colors.primary_red};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius_m};
  border: none;
  height: 45px;
  padding-right: 35px;
  margin: 15px 5px 5px 5px;
  width: 86%;
`;

import React from 'react';
import PropTypes from 'prop-types';
import tick from '../../../../assets/icons/tick-white.svg';
import close from '../../../../assets/icons/close-icon.svg';
import { Row, Col } from '../../../styled_components/grid/Grid.style';
import {
  StyledModal,
  SaveButton,
  SaveButtonIcon,
  ModalWrapper,
  ModalHeading,
  CloseButton,
  CloseIcon,
  CustomSelect,
  StyledDate,
  CheckNumber,
  CreateSelect,
} from './editModalPayment.style';
import EditTable from '../edit_table';

const options = [
  { label: 'Check', value: 'Check' },
  { label: 'Venmo', value: 'Venmo' },
  { label: 'Cash', value: 'Cash' },
  { label: 'Credit Card', value: 'Credit Card' },
];
const EditModal = ({
  isOpen,
  toggleModal,
  lineItems,
  selected,
  category,
  organization,
  selectVendor,
  createVendor,
  handleChange,
  handleLineItems,
  handleSubmit,
  formatter,
  minDate,
  getMaxDate,
}) => (
  <StyledModal isOpen={isOpen}>
    <CloseButton type="button" onClick={toggleModal}>
      <CloseIcon>
        <img src={close} alt="arrow" height="16px" />
      </CloseIcon>
    </CloseButton>
    <ModalWrapper>
      <Row>
        <Col col={12}>
          <ModalHeading>Edit Payment</ModalHeading>
        </Col>
      </Row>
      <Row>
        <Row>
          {/* <Col col={6}>
            <CustomSelect
              className="select"
              classNamePrefix="custom"
              options={category}
              value={category}
              placeholder="Category"
            />
          </Col> */}
          <Col col={6}>
            <StyledDate
              defaultValue={selected.date}
              width="95%"
              type="date"
              name="date"
              min={`${minDate}-01-01`}
              max={getMaxDate()}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) => handleChange(e)}
            />
          </Col>
          <Col col={6}>
            <CustomSelect
              className="select"
              classNamePrefix="custom"
              value={options.find((item) => item.value === selected.paymentMethod)}
              options={options}
              placeholder="Payment Methods"
              onChange={(e) => {
                handleChange({ target: { name: 'paymentMethod', value: e.value } });
              }}
            />
          </Col>
          {selected.paymentMethod === 'Check' ? (
            <>
              <Col col={6}>
                <CheckNumber
                  type="text"
                  name="checkNumber"
                  value={selected.checkNumber}
                  placeholder="Check Number"
                  onChange={(e) => handleChange(e)}
                />
              </Col>

            </>
          ) : null}
          <Col col={6}>
            <CreateSelect
              className="select"
              classNamePrefix="custom"
              onCreateOption={(value) => createVendor(value)}
              options={selectVendor}
              defaultValue={selectVendor.find((item) => item.value === selected.recipient)}
              onChange={(e) => handleChange({ target: { name: 'recipient', value: e.value } })}
            />
          </Col>
        </Row>
        {/* </Col> */}
      </Row>
      <EditTable
        formatter={formatter}
        categories={category}
        items={lineItems}
        handleLineItems={handleLineItems}
        organization={organization}
      />
      <Row>
        <Col col={8} />
        <Col col={4}>
          <SaveButton
            type="button"
            onClick={() => {
              toggleModal();
              handleSubmit();
            }}
          >
            Confirm
            <SaveButtonIcon>
              <img src={tick} alt="arrow" height="16px" />
            </SaveButtonIcon>
          </SaveButton>
        </Col>
      </Row>
    </ModalWrapper>
  </StyledModal>
);

EditModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  lineItems: PropTypes.object,
  createVendor: PropTypes.func,
  selected: PropTypes.object,
  selectVendor: PropTypes.array,
  handleSubmit: PropTypes.func,
  handleLineItems: PropTypes.func,
  handleChange: PropTypes.func,
  category: PropTypes.array,
  organization: PropTypes.array,
  formatter: PropTypes.func,
  minDate: PropTypes.number,
  getMaxDate: PropTypes.func,
};

export default EditModal;

import styled from 'styled-components';

export const GeneralLedgerDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 5px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 4%);
  border-radius: ${({ theme }) => theme.border.radius_m};
  margin: ;
`;
export const DisplayButton = styled.button`
  position: relative;
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  background-color: ${({ theme }) => theme.colors.primary_red};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius_m};
  border: none;
  height: 50px;
  padding: 0;
  margin: 5px 20px;
  width: 90%;
  float: right;
`;
export const DisplayButtonIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(12, 0, 0, 0.15);
  width: 42px;
  height: 35px;
  margin: 0;
  padding: 15px 0 0 0;
  border-radius: ${({ theme }) => theme.border.radius_m};
`;
export const StyledDate = styled.input`
  padding: 0px 10px;
  margin: 5px 7px;
  height: 48px;
  width: ${(props) => props.width};
  border-radius: ${({ theme }) => theme.border.radius_m};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary_blue};
  background-color: ${({ theme }) => theme.colors.page_background};
  border: 1px solid ${({ theme }) => theme.colors.page_background};
`;
export const TableHead = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  font-size: 18px;
  text-align: left;
  padding: 15px;
  margin: 10px 0 10px 0;
  border-radius: ${({ theme }) => theme.border.radius_m};
  color: ${({ theme }) => theme.colors.primary_blue};
  :first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  :last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;
export const TableBody = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fonts.font_weight.normal_font};
  font-size: ${({ theme }) => theme.fonts.font_size.normal_font};
  padding: 15px;
  margin: 6px 0 6px 0;
  border-radius: ${({ theme }) => theme.border.radius_m};
  color: ${({ theme }) => theme.colors.primary_blue};
  :first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  :last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;
export const BoldItems = styled.div`
  font-weight: ${({ theme }) => theme.fonts.font_weight.bold_font};
`;
export const BorderItems = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
`;
export const TableBodyHead = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  font-size: 18px;
  text-align: left;
  padding: 5px;
  margin: 2px 0 2px 0;
  border-radius: ${({ theme }) => theme.border.radius_m};
  color: ${({ theme }) => theme.colors.primary_blue};
  :first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  :last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;
export const TableSummary = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  font-size: 18px;
  text-align: left;
  padding: 25px 20px 25px 20px;
  margin: 6px 0 2px 0;
  border-radius: ${({ theme }) => theme.border.radius_m};
  color: ${({ theme }) => theme.colors.primary_blue};
  :first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  :last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Cookies from 'js-cookie';

const initalLoggedInState = !!Cookies.get('auth_token');
const AuthContext = React.createContext({
  loggedIn: initalLoggedInState,
  setJWT: () => {},
  setUserId: () => {},
  removeJWT: () => {},
});
export const AuthProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(initalLoggedInState);
  const setJWT = (jwt) => {
    if (jwt) {
      Cookies.set('auth_token', jwt);
      setLoggedIn(true);
    }
  };
  const removeJWT = () => {
    Cookies.remove('auth_token');
    setLoggedIn(false);
  };
  const setUserId = (user_id) => {
    if (user_id) {
      Cookies.set('user_id', user_id);
      setLoggedIn(true);
    }
  };
  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        setJWT,
        removeJWT,
        setUserId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export const useAuth = () => React.useContext(AuthContext);
export default AuthContext;

import styled from 'styled-components';

export const TableDiv = styled.div`
  overflow-x: auto;
  table {
    width: 100%;
    border-spacing: 0 8px;
    border-collapse: separate;
    tr {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const TableHead = styled.th`
  background-color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  font-size: 18px;
  text-align: left;
  padding: 18px;
  color: ${({ theme }) => theme.colors.primary_blue};
  :first-child {
    border-top-left-radius: ${({ theme }) => theme.border.radius_m};
    border-bottom-left-radius: ${({ theme }) => theme.border.radius_m};
  }

  :last-child {
    border-top-right-radius: ${({ theme }) => theme.border.radius_m};
    border-bottom-right-radius: ${({ theme }) => theme.border.radius_m};
  }
`;

export const TableData = styled.td`
  text-align: ${(props) => props.textAlign || 'left'};
  font-size: 16px;
  white-space: nowrap;
  padding: 18px;
  color: ${({ theme }) => theme.colors.primary_blue};
  :first-child {
    border-top-left-radius: ${({ theme }) => theme.border.radius_m};
    border-bottom-left-radius: ${({ theme }) => theme.border.radius_m};
  }

  :last-child {
    border-top-right-radius: ${({ theme }) => theme.border.radius_m};
    border-bottom-right-radius: ${({ theme }) => theme.border.radius_m};
  }
`;
export const IconDiv = styled.div`
  background: ${({ theme }) => theme.colors.page_background};;
  width: 30px;
  height: 30px;
  border-radius: 7px;
  text-align: center;
  cursor: pointer;
`;
export const Icon = styled.img`
  margin-top: 8px;
`;

export const ToolTipText = styled.span`
  visibility: "hidden",
  width: "120px",
  backgroundColor: "#000",
  color: "#fff",
  textAlign: "center",
  borderRadius: "6px",
  padding: "5px 0",
  position: "absolute",
  zIndex: 1,
  bottom: "150%",
  left: "50%",
  marginLeft: "-60px",
  ":after": {
    content: '""',
    position: "absolute",
    top: "100%",
    left: "50%",
    marginLeft: "-5px",
    borderWidth: "5px",
    borderStyle: "solid",
    borderColor: "black transparent transparent transparent"
  }
`;
export const ToolTip = styled.div`
  position: "relative",
  display: "inline-block",
  borderBottom: "1px dotted black",
  ":hover span": {
    visibility: "visible"
  }
`;

import React from 'react';
import OrganisationMenu from '../../../components/organisation/menu';
import ReportSubMenu from '../../../components/organisation/reports_submenu';
import Breadcrumbs from '../../../components/breadcrumbs';
import { AccountingPageLayout, PageHeading } from '../../../theme/GlobalStyles';

const breadcrumbs = ['Organisation', 'Reports', 'Church Reports'];
const Reports = () => (
  <>
    <OrganisationMenu />
    <AccountingPageLayout>
      <PageHeading>Reports</PageHeading>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <ReportSubMenu />
    </AccountingPageLayout>
  </>
);

export default Reports;

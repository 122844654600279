import React from 'react';
import PropTypes from 'prop-types';
import PanelHeading from '../../membership_components/panel_heading';
import { Row, Col } from '../../styled_components/grid/Grid.style';
import {
  ContactLayout,
  Panel,
  PanelBody,
  FieldLabel,
  FieldValue,
  Div,
  Required,
} from './ContactPanel.style';

const ContactPanel = ({ text, handleChange }) => (
  <>
    <ContactLayout>
      <Row>
        <Panel marginRight="20px">
          <PanelHeading label="Contact Information" />
          <PanelBody>
            <Row marginBottom="17px">
              <Col col={4}>
                <Div className="addMargin">
                  <FieldLabel>
                    Home Email
                    <Required>*</Required>
                  </FieldLabel>
                  <FieldValue
                    onChange={handleChange}
                    name="homeEmail"
                    type="text"
                    defaulfValue={text.homeEmail}
                  />
                </Div>
              </Col>
              <Col col={4}>
                <Div className="addMargin">
                  <FieldLabel>
                    Home Phone
                    <Required>*</Required>
                  </FieldLabel>
                  <FieldValue
                    onChange={handleChange}
                    name="homePhone"
                    type="text"
                    defaultValue={text.homePhone}
                    value={text.homePhone}
                  />
                </Div>
              </Col>
              <Col col={4}>
                <Div className="addMargin">
                  <FieldLabel>
                    Mobile Phone
                    <Required>*</Required>
                  </FieldLabel>
                  <FieldValue
                    onChange={handleChange}
                    name="mobilePhone"
                    type="text"
                    defaultValue={text.mobilePhone}
                    value={text.mobilePhone}
                  />
                </Div>
              </Col>
            </Row>
          </PanelBody>
        </Panel>
      </Row>
    </ContactLayout>
  </>
);

ContactPanel.propTypes = {
  handleChange: PropTypes.func,
  text: PropTypes.object,
};
export default ContactPanel;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { isMobile } from 'react-device-detect';
import { Col, Row } from '../../components/styled_components/grid/Grid.style';
import prayingHandsImage from '../../assets/img/praying_hands.png';
import logo from '../../assets/img/logo.svg';
import emailIcon from '../../assets/icons/email.svg';
import passwordIcon from '../../assets/icons/password.svg';
import arrowRightIcon from '../../assets/icons/arrow-right.svg';
import { fetcher } from '../../hooks/useFetch';
import { API } from '../../constants';
import { useAuth } from '../../providers/AuthProvider';
import MobileLogin from '../mobile_view/login';

import {
  ChurchName,
  LoginButton,
  LoginButtonIcon,
  LoginHeader,
  LoginIcon,
  LoginInput,
  LoginInputWrapper,
  LoginLabel,
  LoginPagePrayingHands,
  Logo,
  LoginLoader,
  ForgotPassword,
} from './Login.style';

const Login = () => {
  const [loginData, setLoginData] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setJWT } = useAuth();
  const { setUserId } = useAuth();
  const handleChange = (evt) => {
    const currentLoginData = { ...loginData };
    currentLoginData[evt.target.name] = evt.target.value;
    setLoginData(currentLoginData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetcher({
        url: API.LOGIN,
        method: 'POST',
        body: JSON.stringify(loginData),
      });
      setJWT(response.token);
      setUserId(response.user_id);
      if (response.user_id) {
        const getRole = await fetcher({
          url: API.GET_ROLES,
          method: 'GET',
        });
        const getMember = await fetcher({
          url: API.MEMBER_DETAILS,
          method: 'GET',
        });
        Cookies.set(
          'memberName',
          `${getMember.firstName} ${getMember.lastName}`,
        );
        Cookies.set('photoUrl', getMember.profilePic);
        const setRole = getRole.roles.map((item) => item.name);
        Cookies.set('user_roles', setRole);
        const accountantYear = getRole.roles.find((item) => (item.name.match(/accountant/i)))?.name.slice(-4);
        Cookies.set('accountant_year', accountantYear);
        const orgAccountantYear = getRole.roles.find((item) => (item.name.match(/organization/i)))?.name.slice(-4);
        Cookies.set('Org_accountant_year', orgAccountantYear);
        const organization = getRole.roles.filter((item) => (item.name.match(/organization/i))).map((items) => items.name);
        Cookies.set('organization', organization);
        Cookies.set('memberId', getMember.memberID);
        navigate('/');
        setLoading(false);
      }
    } catch (e) {
      if (e.status === 401) {
        toast.error('Error! You have entered an invalid username or password');
        setLoading(false);
      } else {
        toast.error('Error! Technical error occurred');
        setLoading(false);
      }
    }
  };
  if (isMobile) {
    return <MobileLogin />;
  }
  return (
    <>
      <LoginPagePrayingHands src={prayingHandsImage} alt="" />
      <Row justifyCenter="center">
        <Col col={4} />
        <Col col={4} textAlign="center">
          <Logo src={logo} />
        </Col>
      </Row>
      <Row>
        <Col col={4} />
        <Col col={4} textAlign="center">
          <ChurchName>Trinity Marthoma Church, Houston</ChurchName>
        </Col>
      </Row>
      <Row>
        <Col col={4} />
        <Col col={4}>
          <LoginHeader>Login</LoginHeader>
        </Col>
      </Row>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col col={4} />
          <Col col={4}>
            <LoginInputWrapper>
              <LoginLabel htmlFor="email">User Name</LoginLabel>
              <LoginInput
                id="email"
                name="email"
                onChange={handleChange}
                type="email"
                placeholder="yourmail@email.com"
                required="true"
              />
              <LoginIcon src={emailIcon} height="32px" alt="" />
            </LoginInputWrapper>
          </Col>
        </Row>
        <Row marginTop="20px" marginBottom="20px">
          <Col col={4} />
          <Col col={4}>
            <LoginInputWrapper>
              <LoginLabel htmlFor="email">Password</LoginLabel>
              <LoginInput
                id="email"
                name="password"
                onChange={handleChange}
                type="password"
                placeholder="********"
                required="true"
              />
              <LoginIcon src={passwordIcon} height="32px" alt="" />
            </LoginInputWrapper>
          </Col>
        </Row>
        <Row marginTop="30px">
          <Col col={4} />
          <Col col={4}>
            <LoginButton type="submit">
              {loading ? <LoginLoader /> : 'Login'}
              <LoginButtonIcon>
                <img src={arrowRightIcon} alt=">" height="16px" />
              </LoginButtonIcon>
            </LoginButton>
          </Col>
        </Row>
      </form>
      <Row marginTop="30px">
        <Col col={4} />
        <Col col={4}>
          <ForgotPassword onClick={() => navigate('/forgot-password')}>Forgot Password</ForgotPassword>
        </Col>
      </Row>
    </>
  );
};

export default Login;

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Cookies from 'js-cookie';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import AccountingMenu from '../../../components/accounting/menu';
import ReportSubMenu from '../../../components/accounting/report_submenu';
import Breadcrumbs from '../../../components/breadcrumbs';
import arrowRightIcon from '../../../assets/icons/arrow-right.svg';
import downloadArrow from '../../../assets/icons/download.svg';
import { Row, Col } from '../../../components/styled_components/grid/Grid.style';
import { AccountingPageLayout, PageHeading, PageSubHeading } from '../../../theme/GlobalStyles';
import {
  SummarizedDiv,
  BoldItems,
  LineItems,
  ItemTotal,
  DownloadButton,
  DownloadButtonIcon,
  DownloadImage,
  FinalTotal,
  SummarizedSubHeadDiv,
  NoData,
  StyledDate, DisplayButton, DisplayButtonIcon, SummarizedHeadDiv, SummarizedBodyDiv, ReportTitle,
} from './summarized.style';
import { fetcher, fileDownloader } from '../../../hooks/useFetch';
import { ACCT_API } from '../../../constants';
import Loader from '../../../components/styled_components/loader/loader.style';
import ScrollDownButton from '../../../utils/ScrollDownButton';
import ScrollUpButton from '../../../utils/ScrollUpButton';

const breadcrumbs = ['Accounting', 'Reports', 'Summarized Report'];
const SummarizedReport = () => {
  const [startDate, setStartDate] = useState(moment().format('YYYY-01-01'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [bankAccount, setBankAccount] = useState();
  const [receiptAccount, setReceiptAccount] = useState();
  const [paymentAccount, setPaymentAccount] = useState();
  const [accountObject, setAccountObject] = useState({});
  const [receiptTotal, setReceiptTotal] = useState();
  const [paymentTotal, setPaymentTotal] = useState();
  const [openingTotal, setOpeningTotal] = useState();
  const [closingTotal, setClosingTotal] = useState();
  const [income, setIncome] = useState();
  const [expense, setExpense] = useState();
  const [view, setView] = useState(false);
  const [loading, setLoading] = useState(false);
  const minDate = Cookies.get('accountant_year');
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const getReport = async () => {
    setLoading(true);
    const bank = await fetcher(
      {
        url: `${ACCT_API.GET_BANK_SUMMARIZED}`,
        method: 'GET',
        authTokenKey: 'auth_token',
      },
    );
    const receipts = await fetcher(
      {
        url: `${ACCT_API.GET_RECEIPTS_SUMMARIZED}?startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
        authTokenKey: 'auth_token',
      },
    );
    if (receipts) {
      setReceiptAccount(receipts.response);
    }
    const payments = await fetcher(
      {
        url: `${ACCT_API.GET_PAYMENTS_SUMMARIZED}?startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
        authTokenKey: 'auth_token',
      },
    );
    if (payments) {
      setPaymentAccount(payments.response);
    }
    const balanceSheet = await fetcher(
      {
        url: `${ACCT_API.GET_BALANCE_SHEET}?endDate=${endDate}`,
        method: 'GET',
        authTokenKey: 'auth_token',
      },
    );
    if (balanceSheet) {
      balanceSheet.forEach((item) => (() => {
        let opening = item.openingBalance;
        const closing = item.closingBalance;
        if (opening === undefined) {
          opening = 0;
        }
        const tempObject = accountObject;
        tempObject[item.acctNumber] = [opening, closing];
        setAccountObject(tempObject);
      })());
    }
    if (bank && Object.keys(accountObject).length > 0) {
      setBankAccount(
        bank.response.map((item) => ({
          ...item,
          value: item.value.map((i) => (accountObject[i.accountNumber]
            ? {
              ...i,
              openingBalance: parseFloat(accountObject[i.accountNumber][0]),
              closingBalance: parseFloat(accountObject[i.accountNumber][1]),
            }
            : {
              ...i,
              openingBalance: 0,
              closingBalance: 0,
            })),
        })),
      );
    }
  };
  const downloadReport = async () => {
    try {
      const download = await fileDownloader(
        {
          url: `${ACCT_API.DOWNLOAD_SUMMARIZED_REPORT}?startDate=${startDate}&endDate=${endDate}`,
          method: 'GET',
          authTokenKey: 'auth_token',
        },
      );
      if (download) {
        saveAs(new Blob([download], { type: 'application/vnd.ms-excel' }), 'Summarized Report.xlsx');
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };
  useEffect(() => {
    if (bankAccount && receiptAccount) {
      const receiptTotal = [];
      const openingTotal = [];
      receiptAccount.map((item) => receiptTotal.push(
        item.value.reduce(
          (acc, curVal) => acc + parseFloat(curVal.amount, 10),
          0,
        ),
      ));
      setReceiptTotal(receiptTotal.reduce((a, b) => a + b, 0));
      bankAccount.map((item) => openingTotal.push(
        item.value.reduce(
          (acc, curVal) => acc + parseFloat(curVal.openingBalance, 10),
          0,
        ),
      ));
      const merged = receiptTotal.concat(openingTotal);
      setIncome(merged.reduce((a, b) => a + b, 0));
      setOpeningTotal(openingTotal.reduce((a, b) => a + b, 0));
    }
    if (bankAccount && paymentAccount) {
      const paymentTotal = [];
      const closingTotal = [];
      paymentAccount.map((item) => paymentTotal.push(
        item.value.reduce(
          (acc, curVal) => acc + parseFloat(curVal.amount, 10),
          0,
        ),
      ));
      setPaymentTotal(paymentTotal.reduce((a, b) => a + b, 0));
      bankAccount.map((item) => closingTotal.push(
        item.value.reduce(
          (acc, curVal) => acc + parseFloat(curVal.closingBalance, 10),
          0,
        ),
      ));
      const merged = paymentTotal.concat(closingTotal);
      setExpense(merged.reduce((a, b) => a + b, 0));
      setClosingTotal(closingTotal.reduce((a, b) => a + b, 0));
      setLoading(false);
      setView(true);
    }
  }, [bankAccount]);
  return (
    <>
      <AccountingMenu />
      {loading ? <Loader /> : null}
      <ScrollUpButton />
      <ScrollDownButton />
      <AccountingPageLayout>
        <PageHeading>Reports</PageHeading>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <ReportSubMenu />
        <PageSubHeading>Summarized Report</PageSubHeading>
        <SummarizedDiv>
          <Row>
            <Col col={3}>
              <StyledDate
                width="88%"
                type="date"
                min={`${minDate}-01-01`}
                max={`${minDate}-12-31`}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Col>
            <Col col={3}>
              <StyledDate
                width="88%"
                type="date"
                min={`${minDate}-01-01`}
                max={`${minDate}-12-31`}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Col>
            <Col col={6}>
              <DownloadButton type="button" onClick={() => downloadReport()}>
                Download
                <DownloadButtonIcon>
                  <DownloadImage
                    src={downloadArrow}
                    alt=">"
                    height="13px"
                  />
                </DownloadButtonIcon>
              </DownloadButton>
              <DisplayButton type="button" onClick={() => getReport()}>
                Display
                <DisplayButtonIcon>
                  <img src={arrowRightIcon} alt=">" height="16px" />
                </DisplayButtonIcon>
              </DisplayButton>
            </Col>
          </Row>
        </SummarizedDiv>
        {view && ((bankAccount && bankAccount.length > 0)
        || (receiptAccount && receiptAccount.length > 0)
        || (paymentAccount && paymentAccount.length > 0)) ? (
          <>
            <SummarizedHeadDiv>
              <Row marginTop="18px" marginBottom="18px">
                <Col textAlign="center" col={1}>
                  Sr.
                </Col>
                <Col col={7}>
                  Description
                </Col>
                <Col col={2}>
                  Acct#
                </Col>
                <Col textAlign="right" col={2}>
                  Actual
                </Col>
              </Row>
            </SummarizedHeadDiv>
            <SummarizedBodyDiv>
              <>
                <SummarizedSubHeadDiv>
                  <Row marginTop="18px" marginBottom="18px" marginLeft="15px">
                    <Col textAlign="left" col={12}>
                      Receipts
                    </Col>
                  </Row>
                </SummarizedSubHeadDiv>
                <ReportTitle>General Fund Balance Sheet Account - Opening Balance</ReportTitle>
                {bankAccount && bankAccount.map((main) => (
                  <>
                    <Row>
                      <Col col={12}><BoldItems>{main.parent_acct_head}</BoldItems></Col>
                    </Row>
                    {main.value.map((sub, index) => (
                      <LineItems>
                        <Row>
                          <Col textAlign="center" col={1}>{index + 1}</Col>
                          <Col col={7}>{sub.acct_head_name}</Col>
                          <Col col={2}>{sub.accountNumber}</Col>
                          <Col col={2} textAlign="right">
                            $
                            {' '}
                            {formatter.format(sub.openingBalance)}
                          </Col>
                        </Row>
                      </LineItems>
                    ))}
                    <ItemTotal>
                      <Row>
                        <Col col={10} textAlign="right">
                          Total for
                          {' '}
                          {main.parent_acct_head}
                          {' '}
                          :
                        </Col>
                        <Col col={2} textAlign="right">
                          $
                          {' '}
                          {formatter
                            .format(main.value.reduce(
                              (acc, curVal) => acc + parseFloat(curVal.openingBalance, 10),
                              0,
                            ))}
                        </Col>
                      </Row>
                    </ItemTotal>
                  </>
                ))}
                <FinalTotal>
                  <Row>
                    <Col col={10} textAlign="right">Total for : General Fund Balance Sheet Account - Opening Balance</Col>
                    <Col col={2} textAlign="right">
                      $
                      {' '}
                      {formatter.format(openingTotal)}
                    </Col>
                  </Row>
                </FinalTotal>
              </>
            </SummarizedBodyDiv>
            <SummarizedBodyDiv>
              <>
                <ReportTitle>General Fund</ReportTitle>
                {receiptAccount && receiptAccount.map((main) => (
                  <>
                    <Row>
                      <Col col={12}><BoldItems>{main.parent_acct_head}</BoldItems></Col>
                    </Row>
                    {main.value.map((sub, index) => (
                      <LineItems>
                        <Row>
                          <Col textAlign="center" col={1}>{index + 1}</Col>
                          <Col col={7}>{sub.acct_head_name}</Col>
                          <Col col={2}>{sub.acct_head_description}</Col>
                          <Col col={2} textAlign="right">
                            $
                            {' '}
                            {formatter.format(sub.amount)}

                          </Col>
                        </Row>
                      </LineItems>
                    ))}
                    <ItemTotal>
                      <Row>
                        <Col col={10} textAlign="right">
                          Total for
                          {' '}
                          {main.parent_acct_head}
                          {' '}
                          :
                        </Col>
                        <Col col={2} textAlign="right">
                          $
                          {' '}
                          {formatter
                            .format(main.value.reduce(
                              (acc, curVal) => acc + parseFloat(curVal.amount, 10),
                              0,
                            ))}
                        </Col>
                      </Row>

                    </ItemTotal>
                  </>
                ))}
                <Row>
                  <Col col={10} textAlign="right"><BoldItems>Total for: General Fund</BoldItems></Col>
                  <Col col={2} textAlign="right">
                    <BoldItems>
                      $
                      {' '}
                      {formatter.format(receiptTotal)}
                    </BoldItems>
                  </Col>
                </Row>
                <FinalTotal>
                  <Row>
                    <Col col={10} textAlign="right">Total for: Receipts</Col>
                    <Col col={2} textAlign="right">
                      $
                      {' '}
                      {formatter.format(income)}
                    </Col>
                  </Row>
                </FinalTotal>
              </>
            </SummarizedBodyDiv>
            <SummarizedBodyDiv>
              <SummarizedSubHeadDiv>
                <Row marginTop="18px" marginBottom="18px" marginLeft="15px">
                  <Col textAlign="left" col={12}>
                    Payments
                  </Col>
                </Row>
              </SummarizedSubHeadDiv>
              <ReportTitle>General Fund</ReportTitle>
              {paymentAccount && paymentAccount.map((main) => (
                <>
                  <Row>
                    <Col col={12}><BoldItems>{main.parent_acct_head}</BoldItems></Col>
                  </Row>
                  {main.value.map((sub, index) => (
                    <LineItems>
                      <Row>
                        <Col textAlign="center" col={1}>{index + 1}</Col>
                        <Col col={7}>{sub.acct_head_name}</Col>
                        <Col col={2}>{sub.acct_head_description}</Col>
                        <Col col={2} textAlign="right">
                          $
                          {' '}
                          {formatter.format(sub.amount)}

                        </Col>
                      </Row>
                    </LineItems>
                  ))}
                  <ItemTotal>
                    <Row>
                      <Col col={10} textAlign="right">
                        Total for
                        {' '}
                        {main.parent_acct_head}
                        {' '}
                        :
                      </Col>
                      <Col col={2} textAlign="right">
                        $
                        {' '}
                        {formatter
                          .format(main.value.reduce(
                            (acc, curVal) => acc + parseFloat(curVal.amount, 10),
                            0,
                          ))}
                      </Col>
                    </Row>
                  </ItemTotal>

                </>
              ))}
              <Row>
                <Col col={10} textAlign="right"><BoldItems>Total for General Fund:</BoldItems></Col>
                <Col col={2} textAlign="right">
                  <BoldItems>
                    $
                    {' '}
                    {formatter.format(paymentTotal)}
                  </BoldItems>
                </Col>
              </Row>
            </SummarizedBodyDiv>
            <SummarizedBodyDiv>
              <ReportTitle>General Fund Balance Sheet Account - Closing Balance</ReportTitle>
              {bankAccount && bankAccount.map((main) => (
                <>
                  <Row>
                    <Col col={12}><BoldItems>{main.parent_acct_head}</BoldItems></Col>
                  </Row>
                  {main.value.map((sub, index) => (
                    <LineItems>
                      <Row>
                        <Col textAlign="center" col={1}>{index + 1}</Col>
                        <Col col={7}>{sub.acct_head_name}</Col>
                        <Col col={2}>{sub.accountNumber}</Col>
                        <Col col={2} textAlign="right">
                          $
                          {' '}
                          {formatter.format(sub.closingBalance)}
                        </Col>
                      </Row>
                    </LineItems>
                  ))}
                  <ItemTotal>
                    <Row>
                      <Col col={10} textAlign="right">
                        Total for
                        {' '}
                        {main.parent_acct_head}
                        {' '}
                        :
                      </Col>
                      <Col col={2} textAlign="right">
                        $
                        {' '}
                        {formatter
                          .format(main.value.reduce(
                            (acc, curVal) => acc + parseFloat(curVal.closingBalance, 10),
                            0,
                          ))}
                      </Col>
                    </Row>
                  </ItemTotal>
                </>
              ))}
              <Row>
                <Col col={10} textAlign="right"><BoldItems>Total for : General Fund Balance Sheet Account - Closing Balance:</BoldItems></Col>
                <Col col={2} textAlign="right">
                  <BoldItems>
                    $
                    {' '}
                    {formatter.format(closingTotal)}
                  </BoldItems>
                </Col>
              </Row>
              <FinalTotal>
                <Row>
                  <Col col={10} textAlign="right">Total for: Payments</Col>
                  <Col col={2} textAlign="right">
                    $
                    {' '}
                    {formatter.format(expense)}
                  </Col>
                </Row>
              </FinalTotal>
            </SummarizedBodyDiv>
          </>
          ) : view && (bankAccount && bankAccount.length > 0)
          && (receiptAccount && receiptAccount.length > 0)
          && (paymentAccount && paymentAccount.length > 0)
            ? (
              <NoData>
                No Data
              </NoData>
            )
            : ''}
      </AccountingPageLayout>

    </>
  );
};
export default SummarizedReport;

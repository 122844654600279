import styled from 'styled-components';

export const TopSection = styled.div`
  border-top-left-radius: ${({ theme }) => theme.border.radius_m};
  border-top-right-radius: ${({ theme }) => theme.border.radius_m};
  font-size: 18px;
  padding: 10px 10px 19px;
  background-color: ${({ theme }) => theme.colors.primary_blue};
`;

export const TopCol = styled.div`
  min-width: ${(props) => props.minWidth || 0};
  height: 100%;
  float: left;
  margin-right: 20px;
  margin-top: 33px;
  position: relative;
  .profileEdit {
    position: absolute;
    left: 115px;
    top: 109px;
  }
`;

export const UserPhoto = styled.img`
  height: 157px;
  border-radius: ${({ theme }) => theme.border.radius_l};
`;

export const MemberInformation = styled.div`
  color: ${({ theme }) => theme.colors.white};
  padding: 10px;
`;

export const MemberName = styled.h1`
  margin: 0 0 5px 0;
  font-family: 'Playfair Display', serif;
  font-weight: ${({ theme }) => theme.fonts.font_weight.bold_font};
  font-size: 35px;
  word-wrap: break-word;
`;

export const SeparatorIcon = styled.img`
  width: 10px;
  height: 10px;
`;

export const AddressLine = styled.div`
  margin: 5px 0;
  p {
    margin: 5px 0;
  }
`;

export const CommunicationLine = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grey_blue};
  border-radius: ${({ theme }) => theme.border.radius_m};
  .left {
    border-right: 1px solid ${({ theme }) => theme.colors.grey_blue};
  }
  margin: 5px 0;
`;

export const CommunicationIcon = styled.img`
  width: 20px;
  height: 20px;
  margin: 26px 10px;
  float: left;
`;

export const CommunicationBody = styled.div`
  padding: 5px;
  margin: 10px 0;
  .heading {
    font-family: 'Playfair Display', serif;
    color: ${({ theme }) => theme.colors.white};
    opacity: 0.5;
  }
`;

export const SocialMediaLine = styled.div`
  margin-top: 10px;
`;

export const BlueDiv = styled.div`
  height: 40px;
  width: 40px;
  border-radius: ${({ theme }) => theme.border.radius_m};
  background-color: ${({ theme }) => theme.colors.light_blue};
  text-align: center;
`;

export const SocialIcon = styled.img`
  margin-top: 8px;
  width: 24px;
  height: 24px;
`;

export const EditIconDiv = styled.div`
  background: ${({ theme }) => theme.colors.page_background};
  width: 30px;
  height: 30px;
  border-radius: 7px;
  text-align: center;
  cursor: pointer;
`;

export const EditIcon = styled.img`
  margin-top: 7px;
`;

export const SocialButton = styled.button`
  border: none;
  background-color: ${({ theme }) => theme.colors.light_blue};
  border-radius: ${({ theme }) => theme.border.radius_m}
`;

import React, { useRef } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Col, Row } from '../../styled_components/grid/Grid.style';
import {
  UserPhoto,
  MemberName,
  SeparatorIcon,
  TopSection,
  AddressLine,
  CommunicationLine,
  CommunicationIcon,
  CommunicationBody,
  SocialIcon,
  BlueDiv,
  SocialMediaLine,
  TopCol,
  MemberInformation,
  SocialButton,
  EditIconDiv,
  EditIcon,
} from './ProfileSection.style';
import defaultUserProfileImg from '../../../assets/img/default-user.png';
import diamondIcon from '../../../assets/icons/diamond.svg';
import mailIcon from '../../../assets/icons/mail.svg';
import phoneIcon from '../../../assets/icons/phone.svg';
import twitter from '../../../assets/icons/twitter.svg';
import linkedIn from '../../../assets/icons/linkedIn.svg';
import facebook from '../../../assets/icons/facebook.svg';
import instagram from '../../../assets/icons/instagram-icon.svg';
import editIcon from '../../../assets/icons/pen.svg';

const ProfileSection = ({ showEditIcon, memberDetails, uploadProfilePic }) => {
  const profileInput = useRef();
  const handleUpdate = () => {
    profileInput.current.click();
  };
  const goToProfile = (link) => {
    window.open(link);
  };
  const showNotification = (value) => {
    toast.error(value, { autoClose: 3000 });
  };

  return (
    <TopSection>
      <Row>
        <Col col={9}>
          <TopCol minWidth="150px">
            <UserPhoto
              height="150px"
              width="150px"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = defaultUserProfileImg;
              }}
              src={
                memberDetails && memberDetails && memberDetails.profilePic
                  ? memberDetails.profilePic
                  : defaultUserProfileImg
              }
              alt="User Photo"
            />
            {showEditIcon ? (
              <EditIconDiv onClick={handleUpdate} className="profileEdit">
                <EditIcon src={editIcon} alt="edit" />
                <input
                  type="file"
                  ref={profileInput}
                  accept="image/*"
                  name="image"
                  id="profileimg"
                  onChange={(event) => uploadProfilePic(event)}
                  hidden
                />
              </EditIconDiv>
            ) : null}
          </TopCol>
          <TopCol minWidth="600px">
            <MemberInformation>
              <Row>
                <Col col={12}>
                  <MemberName>
                    {memberDetails
                      && `${memberDetails.firstName} ${memberDetails.secondName} ${memberDetails.lastName}`}
                  </MemberName>
                </Col>
              </Row>
              <AddressLine>
                <Row>
                  <Col col={3}>{memberDetails && memberDetails.areaName}</Col>
                  <Col width="2%">
                    <SeparatorIcon src={diamondIcon} />
                  </Col>
                  <Col col={1}>
                    {memberDetails && memberDetails.membershipNumber}
                  </Col>
                  <Col width="2%">
                    <SeparatorIcon src={diamondIcon} />
                  </Col>
                  <Col col={1}>
                    {memberDetails
                      && `${memberDetails.sex === 'M' ? 'Male' : 'Female'}`}
                  </Col>
                </Row>
              </AddressLine>
              <Row>
                <CommunicationLine>
                  <Row>
                    <Col col={6} className="left">
                      <CommunicationIcon src={mailIcon} alt="email" />
                      <CommunicationBody>
                        <div className="heading">Email</div>
                        <div className="details">
                          {memberDetails && memberDetails.homeEmail}
                        </div>
                      </CommunicationBody>
                    </Col>
                    <Col col={4}>
                      <CommunicationIcon src={phoneIcon} alt="phone" />
                      <CommunicationBody>
                        <div className="heading">Phone</div>
                        <div className="details">
                          {memberDetails && memberDetails.contactNo}
                        </div>
                      </CommunicationBody>
                    </Col>
                  </Row>
                </CommunicationLine>
              </Row>
            </MemberInformation>
          </TopCol>
        </Col>
        <Col col={3} alignSelf="flex-end">
          <SocialMediaLine>
            <BlueDiv>
              <SocialButton
                onClick={() => (memberDetails.twitter
                  ? goToProfile(memberDetails.twitter)
                  : showNotification('Twitter not provided'))}
              >
                <SocialIcon src={twitter} />
              </SocialButton>
            </BlueDiv>
            <BlueDiv>
              <SocialButton
                onClick={() => (memberDetails.linkedIn
                  ? goToProfile(memberDetails.linkedIn)
                  : showNotification('LinkedIn not provided'))}
              >
                <SocialIcon src={linkedIn} />
              </SocialButton>
            </BlueDiv>
            <BlueDiv>
              <SocialButton
                onClick={() => (memberDetails.facebook
                  ? goToProfile(memberDetails.facebook)
                  : showNotification('Facebook not provided'))}
              >
                <SocialIcon src={facebook} />
              </SocialButton>
            </BlueDiv>
            <BlueDiv>
              <SocialButton
                onClick={() => (memberDetails.instagram
                  ? goToProfile(memberDetails.instagram)
                  : showNotification('Instagram not provided'))}
              >
                <SocialIcon src={instagram} />
              </SocialButton>
            </BlueDiv>
          </SocialMediaLine>
        </Col>
      </Row>
    </TopSection>
  );
};

ProfileSection.propTypes = {
  showEditIcon: PropTypes.bool,
  memberDetails: PropTypes.object,
  uploadProfilePic: PropTypes.func,
};
export default ProfileSection;

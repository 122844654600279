import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import Cookies from 'js-cookie';
import AccountingMenu from '../../../components/accounting/menu';
import ReportSubMenu from '../../../components/accounting/report_submenu';
import Breadcrumbs from '../../../components/breadcrumbs';
import arrowRightIcon from '../../../assets/icons/arrow-right.svg';
import { Row, Col } from '../../../components/styled_components/grid/Grid.style';
import { AccountingPageLayout, PageHeading, PageSubHeading } from '../../../theme/GlobalStyles';
import {
  GeneralLedgerDiv,
  DisplayButton,
  DisplayButtonIcon,
  TableSummary,
  StyledDate, TableHead, TableBody, BoldItems, BorderItems, TableBodyHead,
} from './generalLedger.style';
import { fetcher } from '../../../hooks/useFetch';
import { ACCT_API } from '../../../constants';
import Loader from '../../../components/styled_components/loader/loader.style';
import ScrollDownButton from '../../../utils/ScrollDownButton';
import ScrollUpButton from '../../../utils/ScrollUpButton';

const breadcrumbs = ['Accounting', 'Reports', 'General Ledger'];

const GeneralLedger = () => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [report, setReport] = useState();
  const [subs, setSubs] = useState();
  const [loading, setLoading] = useState(false);
  const minDate = Cookies.get('accountant_year');
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const generateKey = (key) => `${key}_${Math.random()}`;
  const getReport = async () => {
    setReport(null);
    setLoading(true);
    try {
      const res = await fetcher({
        url: `${ACCT_API.GET_REPORT}?type=GeneralLedger&start_date=${startDate}&end_date=${endDate}`,
        method: 'GET',
        authTokenKey: 'auth_token',
      });
      if (res.report) {
        setReport(res);
        setLoading(false);
      } else {
        toast.error('Error!', { autoClose: 3000 });
        setLoading(false);
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
      setLoading(false);
    }
  };
  useEffect(() => {
    if (report) {
      const check = report.report.Rows;
      if (Object.keys(check).length > 0) {
        const data = report.report.Rows.Row.map((item) => item.Rows);
        setSubs(data);
      }
    }
  }, [report]);

  let headers;
  let tableBody;
  if (report) {
    const columns = report.report.Columns.Column;
    const sections = report.report.Rows.Row;
    headers = (
      <>
        {!sections ? (
          <div style={{ textAlign: 'center' }}>
            <h1>No data</h1>
          </div>
        ) : (
          <Row key="header">
            {columns.map((col, index) => {
              switch (index) {
                case 0: {
                  return <Col col={1} key={generateKey(col.ColTitle)}>{col.ColTitle}</Col>;
                }
                case 1: {
                  return <Col col={2} key={generateKey(col.ColTitle)}>{col.ColTitle}</Col>;
                }
                case 2: {
                  return null;
                }
                case 3: {
                  return <Col col={1} key={generateKey(col.ColTitle)}>Id</Col>;
                }
                case 4: {
                  return <Col col={3} key={generateKey(col.ColTitle)}>{col.ColTitle}</Col>;
                }
                case 5: {
                  return <Col col={3} key={generateKey(col.ColTitle)}>Category</Col>;
                }
                case 6: {
                  return (
                    <Col col={1} key={generateKey(col.ColTitle)} style={{ textAlign: 'right' }}>
                      Amount
                    </Col>
                  );
                }
                case 7: {
                  return (
                    <Col col={1} key={generateKey(col.ColTitle)} style={{ textAlign: 'right' }}>
                      Balance
                    </Col>
                  );
                }

                default:
                  return <Col col={3} key={generateKey(col.ColTitle)}>{col.ColTitle}</Col>;
              }
            })}
          </Row>
        )}
      </>
    );
    tableBody = (
      <>
        {!sections
          ? null
          : sections.map((section, index) => {
            switch (index) {
              case 0:
                return (
                  <>
                    <TableBody>
                      <TableBodyHead>
                        <Row key={generateKey('4')}>
                          {!section.Header
                            ? null
                            : section.Header.ColData.map((cellData, cellIndex) => (cellIndex !== 2
                              ? (
                                <Col key={generateKey(cellData.value)}>{cellData.value}</Col>
                              ) : null))}
                        </Row>
                      </TableBodyHead>
                      {!subs
                        ? null
                        : subs[0].Row.map((cellData) => (
                          <>
                            <TableBody>
                              <Row key={generateKey('1')} marginTop="5px" marginBottom="10px">
                                {cellData.Header.ColData.map((i) => (
                                  <Col key={generateKey(i.value)}>
                                    <BoldItems>{i.value}</BoldItems>
                                    {' '}
                                  </Col>
                                ))}
                              </Row>

                              {cellData.Rows.Row.map((i) => (
                                <BorderItems>
                                  <Row key={generateKey('2')} marginTop="5px" marginBottom="5px">
                                    {i.ColData.map((k, colIndex) => {
                                      switch (colIndex) {
                                        case 0:
                                          return k.value
                                          !== 'Beginning Balance' ? (
                                            <Col col={1} key={generateKey(k.value)}>
                                              {moment(k.value).format(
                                                'MM-DD-YYYY',
                                              )}
                                            </Col>
                                            ) : (
                                              <Col col={1} key={generateKey(k.value)}>
                                                {k.value}
                                              </Col>
                                            );
                                        case 1:
                                          return k.value === 'Sales Receipt' ? (
                                            <Col col={2} key={generateKey(k.value)}>Receipt</Col>
                                          ) : (
                                            <Col col={2} key={generateKey(k.value)}>{k.value}</Col>
                                          );

                                        case 2:
                                          return null;
                                        case 3:
                                          return (
                                            <Col col={1} key={generateKey(k.value)}>
                                              {k.value.split('-')[0]}
                                            </Col>
                                          );
                                        case 5:
                                          return k.value === '-Split-' ? (
                                            <Col col={3} />
                                          ) : (
                                            <Col col={3} key={generateKey(k.value)}>
                                              {k.value}
                                            </Col>
                                          );
                                        case 6:
                                          return k.value === '.00' ? (
                                            <Col
                                              col={1}
                                              key={generateKey(k.value)}
                                              style={{ textAlign: 'right' }}
                                            >
                                              0.00
                                            </Col>
                                          ) : (
                                            <Col
                                              col={1}
                                              key={generateKey(k.value)}
                                              style={{ textAlign: 'right' }}
                                            >
                                              {k.value}
                                            </Col>
                                          );
                                        case 7:
                                          return k.value === '.00' ? (
                                            <Col
                                              col={1}
                                              key={generateKey(k.value)}
                                              style={{ textAlign: 'right' }}
                                            >
                                              0.00
                                            </Col>
                                          ) : (
                                            <Col
                                              col={1}
                                              key={generateKey(k.value)}
                                              style={{ textAlign: 'right' }}
                                            >
                                              {k.value}
                                            </Col>
                                          );
                                        default:
                                          return (
                                            <Col col={3} key={generateKey(k.value)} padding="0 15px">
                                              {k.value}
                                            </Col>
                                          );
                                      }
                                    })}
                                  </Row>
                                </BorderItems>
                              ))}
                              <Row
                                key={section}
                                marginTop="15px"
                                marginBottom="5px"
                              >
                                {cellData.Summary.ColData.map((i, sumIndex) => (sumIndex === 0 ? (
                                  <Col
                                    col={6}
                                    key={generateKey(i.value)}
                                    textAlign="left"
                                  >
                                    <BoldItems>
                                      {i.value}
                                    </BoldItems>
                                  </Col>
                                ) : sumIndex === 6 ? (
                                  <Col
                                    col={6}
                                    key={generateKey(i.value)}
                                    textAlign="right"
                                  >
                                    <BoldItems>
                                      $
                                      {' '}
                                      {formatter.format(i.value)}
                                    </BoldItems>
                                  </Col>
                                ) : null))}
                              </Row>
                            </TableBody>
                          </>
                        ))}
                      <TableBodyHead>
                        <Row
                          key={generateKey('5')}
                        >
                          {section.Summary.ColData.map((cellData, cellIndex) => (cellIndex === 0 ? (
                            <Col col={6} key={generateKey(cellData.value)} textAlign="left">
                              {cellData.value}
                            </Col>
                          ) : cellIndex === 6 ? (
                            <Col col={6} key={generateKey(cellData.value)} textAlign="right">
                              $
                              {' '}
                              {formatter.format(cellData.value)}
                            </Col>
                          ) : null))}
                        </Row>
                      </TableBodyHead>
                    </TableBody>
                  </>
                );
              default:
                return (
                  <>
                    {section.Header
                        && section.Rows && (
                        <TableBody>
                          <TableBodyHead>
                            <Row key={generateKey('8')} marginTop="5px" marginBottom="10px">
                              {!section.Header
                                ? null
                                : section.Header.ColData
                                  .map((cellData, cellIndex) => (cellIndex !== 2
                                    ? (
                                      <Col key={generateKey(cellData.value)}>
                                        {cellData.value}
                                      </Col>
                                    ) : null))}
                            </Row>
                          </TableBodyHead>
                          <TableBody>
                            {!section.Rows
                              ? null
                              : section.Rows.Row.map((row) => (
                                <BorderItems>
                                  <Row key={generateKey('6')} marginTop="5px" marginBottom="5px">
                                    {!row.ColData
                                      ? null
                                      : row.ColData.map((cellData, cellIndex) => {
                                        switch (cellIndex) {
                                          case 0:
                                            return cellData.value
                                          !== 'Beginning Balance' ? (
                                            <Col col={1} key={generateKey(cellData.value)}>
                                              {moment(cellData.value).format(
                                                'MM-DD-YYYY',
                                              )}
                                            </Col>
                                              ) : (
                                                <Col col={1} key={generateKey(cellData.value)}>
                                                  {cellData.value}
                                                </Col>
                                              );
                                          case 1:
                                            return (
                                              <Col col={2} key={generateKey(cellData.value)}>
                                                {cellData.value}
                                              </Col>
                                            );
                                          case 2:
                                            return null;
                                          case 3:
                                            return (
                                              <Col col={1} key={generateKey(cellData.value)}>
                                                {cellData.value.split('-')[0]}
                                              </Col>
                                            );
                                          case 5:
                                            return cellData.value === '-Split-' ? (
                                              <Col col={3} key={generateKey(cellData.value)} />
                                            ) : (
                                              <Col col={3} key={generateKey(cellData.value)}>
                                                {cellData.value}
                                              </Col>
                                            );
                                          case 6:
                                            return cellData.value === '.00' ? (
                                              <Col
                                                col={1}
                                                key={generateKey(cellData.value)}
                                                style={{ textAlign: 'right' }}
                                              >
                                                0.00
                                              </Col>
                                            ) : (
                                              <Col
                                                col={1}
                                                key={generateKey(cellData.value)}
                                                style={{ textAlign: 'right' }}
                                              >
                                                {cellData.value}
                                              </Col>
                                            );
                                          case 7:
                                            return cellData.value === '.00' ? (
                                              <Col
                                                col={1}
                                                key={generateKey(cellData.value)}
                                                style={{ textAlign: 'right' }}
                                              >
                                                0.00
                                              </Col>
                                            ) : (
                                              <Col
                                                col={1}
                                                key={generateKey(cellData.value)}
                                                style={{ textAlign: 'right' }}
                                              >
                                                {cellData.value}
                                              </Col>
                                            );
                                          default:
                                            return (
                                              <Col col={3} key={generateKey(cellData.value)} padding="0 15px">
                                                {cellData.value}
                                              </Col>
                                            );
                                        }
                                      })}
                                  </Row>
                                </BorderItems>
                              ))}
                          </TableBody>
                          <TableBodyHead>
                            <Row
                              key={generateKey('7')}
                            >
                              {(section.Summary.ColData
                                .map((cellData, cellIndex) => (cellIndex === 0 ? (
                                  <Col col={6} key={generateKey(cellData.value)} textAlign="left">
                                    {cellData.value}
                                  </Col>
                                ) : cellIndex === 6 ? (
                                  <Col col={6} key={generateKey(cellData.value)} textAlign="right">
                                    $
                                    {' '}
                                    {formatter.format(cellData.value)}
                                  </Col>
                                ) : null)))}
                            </Row>
                          </TableBodyHead>
                        </TableBody>
                    )}
                    {!section.Header && !section.Rows && (
                      <TableSummary>
                        <Row
                          key={generateKey('7')}
                        >
                          {section.Summary.ColData
                            .map((cellData, cellIndex) => (cellIndex === 0 ? (
                              <Col col={6} key={generateKey(cellData.value)} textAlign="left">
                                {cellData.value}
                              </Col>
                            ) : cellIndex === 6 ? (
                              <Col col={6} key={generateKey(cellData.value)} textAlign="right">
                                $
                                {' '}
                                {formatter.format(cellData.value)}
                              </Col>
                            ) : null))}
                        </Row>
                      </TableSummary>
                    )}
                  </>
                );
            }
          })}
      </>
    );
  }
  return (
    <>
      <AccountingMenu />
      {loading ? <Loader /> : null}
      <ScrollUpButton />
      <ScrollDownButton />
      <AccountingPageLayout>
        <PageHeading>Reports</PageHeading>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <ReportSubMenu />
        <PageSubHeading>General Ledger</PageSubHeading>
        <GeneralLedgerDiv>
          <Row>
            <Col col={3}>
              <StyledDate
                width="88%"
                type="date"
                min={`${minDate}-01-01`}
                max={`${minDate}-12-31`}
                placeholder="Start Date"
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Col>
            <Col col={3}>
              <StyledDate
                width="88%"
                type="date"
                min={`${minDate}-01-01`}
                max={`${minDate}-12-31`}
                placeholder="End Date"
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Col>
            <Col col={3} />
            <Col col={3}>
              <DisplayButton type="button" onClick={getReport} disabled={!(startDate && endDate)}>
                Display
                <DisplayButtonIcon>
                  <img src={arrowRightIcon} alt=">" height="16px" />
                </DisplayButtonIcon>
              </DisplayButton>
            </Col>
          </Row>
        </GeneralLedgerDiv>
        {subs ? (
          <>
            <TableHead>{headers}</TableHead>
            {tableBody}
          </>
        ) : null}
      </AccountingPageLayout>

    </>
  );
};
export default GeneralLedger;

import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { ModalProvider } from 'styled-react-modal';
import moment from 'moment';
import Cookies from 'js-cookie';
import {
  Col,
  Row,
} from '../../../../components/styled_components/grid/Grid.style';
import {
  AccountingPageLayout,
  PageHeading,
} from '../../../../theme/GlobalStyles';
import {
  PaymentSearchInputWrap,
  TransactionPaymentDisplayButton,
  TransactionPaymentDisplayButtonIcon,
  SearchInput,
  TotalAmtDiv,
  CustomSelect,
  Total,
  NoData,
} from './Payments.style';
import arrowRightIcon from '../../../../assets/icons/arrow-right.svg';
import Table from '../../../../components/common/table';
import OrganisationMenu from '../../../../components/organisation/menu';
import Breadcrumbs from '../../../../components/breadcrumbs';
import { useFetch, fetcher, fileDownloader } from '../../../../hooks/useFetch';
import { ACCT_API } from '../../../../constants';
import Loader from '../../../../components/styled_components/loader/loader.style';
import ScrollDownButton from '../../../../utils/ScrollDownButton';
import ScrollUpButton from '../../../../utils/ScrollUpButton';
import EditModal from '../../../../components/organisation/edit_transactions/edit_modal_payment';
import DeleteModal from '../../../../components/organisation/delete_transactions/delete_modal';

const TransactionsPayments = () => {
  const breadcrumbs = ['Organisation', 'Transactions', 'Payments'];
  const [editModal, setEditModal] = useState(false);
  const [data, setData] = useState();
  const [selectVendor, setSelectVendor] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loading, setLoading] = useState(false);
  const [lineItems, setLineItems] = useState();
  const [total, setTotalAmt] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [organization, setOrganization] = useState();
  const [deleteId, setDeleteId] = useState();
  const [selectedPayment, setSelectedPayment] = useState();
  const today = moment().format('YYYY-MM-DD');
  const minDate = Cookies.get('Org_accountant_year');
  const bankAccount = 'Organization Bank Account1';
  const accountName = 'Organization Bank Account2';
  const org = Cookies.get('organization').split(',');
  const org_values = org.map((item) => item.split('-'));
  const org_val = org_values.map((item) => item[1]);
  const values = org_val.map((item) => ({
    value: item,
    label: item,
  }));
  const getVendor = async () => {
    try {
      const res = await fetcher({
        url: `${ACCT_API.GET_VENDOR}`,
        method: 'GET',
        authTokenKey: 'auth_token',
      });
      if (res) {
        setSelectVendor(
          res.response.map((item) => ({
            value: item.displayName,
            label: item.displayName,
          })),
        );
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };
  const vendor = useFetch({
    url: `${ACCT_API.GET_VENDOR}`,
    method: 'GET',
    authTokenKey: 'auth_token',
  });
  if (vendor.data && selectVendor.length === 0) {
    setSelectVendor(
      vendor.data.response.map((item) => ({
        value: item.displayName,
        label: item.displayName,
      })),
    );
  }
  const getMaxDate = () => {
    let maxDate;
    if (today.substring(0, 4) === minDate) {
      maxDate = today;
    } else {
      maxDate = `${minDate}-12-31`;
    }
    return maxDate;
  };
  const handleView = async () => {
    setLoading(true);
    const res = await fetcher({
      url: `${ACCT_API.GET_ORG_PAYMENTS}?&startDate=${startDate}&endDate=${endDate}&organization=${organization}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    setData(res.response);
    setLoading(false);
    const rates = [];
    let amt = 0;
    res.response.map((item) => item.lines.map((i) => rates.push(parseFloat(i.amount))));
    if (rates.length > 0) {
      amt = rates.reduce((a, b) => a + b);
    }
    setTotalAmt(amt);
  };
  const handleEdit = async (id) => {
    setEditModal(true);
    setLoading(true);
    const payment = await fetcher({
      url: `${ACCT_API.GET_ORG_PAYMENT}?id=${id}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    setSelectedPayment(payment.response);
    setLineItems(payment.response.lines.map((item) => ({
      category: item.itemName,
      amount: item.amount,
      particular: item.particulars,
    })));
    setLoading(false);
  };
  const createVendor = async (value) => {
    try {
      const res = await fetcher({
        url: `${ACCT_API.CREATE_VENDOR}`,
        method: 'POST',
        authTokenKey: 'auth_token',
        body: JSON.stringify({ name: value }),
      });
      if (res.success) {
        toast.success('Successfully Created!', { autoClose: 3000 });
        getVendor();
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };
  const handleChange = (e) => {
    const data = { ...selectedPayment };
    data[e.target.name] = e.target.value;
    setSelectedPayment(data);
  };
  const handleLineItems = (items) => {
    setLineItems(items);
  };
  const handleSubmit = async () => {
    const items = lineItems.map((item) => ({
      accountName,
      particulars: item.particular,
      rate: parseFloat(item.amount),
    }));
    let payload = {
      paymentId: selectedPayment.id,
      paymentDate: selectedPayment.date,
      bankAccount,
      organization,
      paymentMethod: selectedPayment.paymentMethod,
      vendorDisplayName: selectedPayment.recipient,
      lineItems: items,
    };
    if (selectedPayment.paymentMethod === 'Check') {
      payload = {
        ...payload,
        checkNumber: selectedPayment.checkNumber,
      };
    }
    try {
      const res = await fetcher({
        url: `${ACCT_API.EDIT_ORG_PAYMENTS}`,
        method: 'POST',
        authTokenKey: 'auth_token',
        body: JSON.stringify(payload),
      });
      if (res.success) {
        toast.success('Successfully Edited!', { autoClose: 3000 });
        handleView();
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };
  const handleDeleteSubmit = async (reason) => {
    const payload = {
      paymentId: deleteId,
      confirm: 'yes',
      notes: reason,
    };
    try {
      const res = await fetcher({
        url: ACCT_API.VOID_PAYMENTS,
        method: 'POST',
        authTokenKey: 'auth_token',
        body: JSON.stringify(payload),
      });
      if (res) {
        toast.success('Successfully Deleted!', { autoClose: 3000 });
        handleView();
        setDeleteId('');
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };
  const deleteItem = async (id) => {
    setDeleteId(id);
    setDeleteModal(true);
  };
  const toggleModal = () => {
    setSelectedPayment();
    setLineItems();
    setEditModal(false);
  };
  const toggleDeleteModal = () => {
    setDeleteModal(false);
    setDeleteId('');
  };
  const tableLookup = [
    {
      heading: 'Paid To',
      field_name: 'paidTo',
      type: 'string',
    },
    {
      heading: 'Date',
      field_name: 'date',
      type: 'date',
    },
    {
      heading: 'Notes',
      field_name: 'lines',
      type: 'particulars',
    },
    {
      heading: 'Amount',
      field_name: 'lines',
      type: 'rate',
    },
    {
      heading: 'Action',
      field_name: 'actions',
      type: 'download',
    },
  ];
  const downloadPdf = async (id) => {
    const downloadReceipts = await fileDownloader({
      url: `${ACCT_API.DOWNLOAD_ORG_PAYMENT}?id=${id}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    saveAs(new Blob([downloadReceipts], { type: 'application/pdf' }), 'Transaction Payment.pdf');
  };
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const handleDisplay = (e) => {
    e.preventDefault();
    handleView();
  };
  return (
    <>
      <OrganisationMenu />
      {loading ? <Loader /> : null}
      <ScrollUpButton />
      <ScrollDownButton />
      <ModalProvider>
        {lineItems ? (
          <EditModal
            lineItems={lineItems}
            selected={selectedPayment}
            category={values}
            selectVendor={selectVendor}
            createVendor={createVendor}
            handleChange={handleChange}
            handleLineItems={handleLineItems}
            handleSubmit={handleSubmit}
            toggleModal={toggleModal}
            isOpen={editModal}
            formatter={formatter}
            minDate={minDate}
            getMaxDate={getMaxDate}
          />
        ) : null}
      </ModalProvider>
      <ModalProvider>
        <DeleteModal
          handleDeleteSubmit={handleDeleteSubmit}
          toggleDeleteModal={toggleDeleteModal}
          isOpen={deleteModal}
        />
      </ModalProvider>
      <AccountingPageLayout>
        <Row>
          <Col>
            <PageHeading>Payments</PageHeading>
          </Col>
        </Row>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <PaymentSearchInputWrap>
          <form onSubmit={handleDisplay}>
            <Row>
              <Col col={3}>
                <SearchInput
                  type="date"
                  min={`${minDate}-01-01`}
                  max={`${minDate}-12-31`}
                  placeholder="Start Date"
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Col>
              <Col col={3}>
                <SearchInput
                  type="date"
                  min={`${minDate}-01-01`}
                  max={`${minDate}-12-31`}
                  placeholder="End Date"
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Col>
              <Col col={3}>
                <CustomSelect
                  className="select"
                  classNamePrefix="custom"
                  options={values}
                  placeholder="Organization"
                  onChange={(e) => setOrganization(e.value)}
                />
              </Col>
              <Col col={3}>
                <TransactionPaymentDisplayButton
                  type="submit"
                  disabled={!(startDate && endDate)}
                >
                  Display
                  <TransactionPaymentDisplayButtonIcon>
                    <img src={arrowRightIcon} alt=">" height="16px" />
                  </TransactionPaymentDisplayButtonIcon>
                </TransactionPaymentDisplayButton>
              </Col>
            </Row>
          </form>
        </PaymentSearchInputWrap>
        {data && data.length > 0 ? (
          <>
            <Table
              data={data || []}
              tableLookup={tableLookup}
              downloadPdf={downloadPdf}
              handleEdit={handleEdit}
              deleteItem={deleteItem}
            />
            <TotalAmtDiv>
              <Total>
                {`$ ${formatter.format(total)}`}
              </Total>
            </TotalAmtDiv>
          </>
        ) : data && data.length === 0 ? (
          <NoData>
            No Data
          </NoData>
        ) : ''}
      </AccountingPageLayout>
    </>
  );
};

export default TransactionsPayments;

import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../../../providers/AuthProvider';
import Header from '../header';
import { Row, Col } from '../../styled_components/grid/Grid.style';
import { PageLayoutWrapper } from './Layout.style';
import Menu from '../menu';

const Layout = () => {
  const { loggedIn } = useAuth();
  if (!loggedIn) {
    return <Navigate to="/login" />;
  }
  return (
    <>
      <PageLayoutWrapper>
        <Header />
        <Row>
          <Col col={12}>
            <Outlet />
          </Col>
        </Row>
      </PageLayoutWrapper>
      <Menu />
    </>
  );
};

export default Layout;

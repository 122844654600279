import React from 'react';
import PropTypes from 'prop-types';
import RedDot from '../../../assets/icons/red-dot.svg';
import ArrowRight from '../../../assets/icons/arrow-right-colored.svg';
import { Col, Row } from '../../styled_components/grid/Grid.style';
import {
  Amount, ArrowSpan, CardDiv, Dollar,
  CardHeading, CardBody,
} from './SummaryPanel.style';

const SummaryPanel = ({ label, amount, marginRight }) => (
  <>
    <CardDiv marginRight={marginRight}>
      <CardHeading>
        <Row>
          <Col width="3%">
            <img src={RedDot} width={5} height={10} alt="." />
          </Col>
          <Col col={11}>{label}</Col>
        </Row>
      </CardHeading>
      <CardBody>
        <Row>
          <Col col={11}>
            <Dollar>$ </Dollar>
            <Amount>{amount}</Amount>
          </Col>
          <Col col={1}>
            <ArrowSpan>
              <img
                src={ArrowRight}
                width={48}
                height={40}
                alt="right arrow"
              />
            </ArrowSpan>
          </Col>
        </Row>
      </CardBody>
    </CardDiv>
  </>
);

SummaryPanel.propTypes = {
  label: PropTypes.string,
  amount: PropTypes.string,
  marginRight: PropTypes.string,
};

export default SummaryPanel;

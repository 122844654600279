import styled from 'styled-components';

export const FamilyCardWrapper = styled.div`
  margin-top: 20px;
  margin-right: ${(props) => props.marginRight || 0};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius_m};
  padding: 20px 20px 20px 10px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
`;

export const FamilyProfilePic = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
`;

export const FamilyName = styled.div`
  font-family: 'Playfair Display', serif;
  color: ${({ theme }) => theme.colors.primary_blue};
  font-size: 20px;
  text-align: left;
  margin: 5px 0;
  cursor: pointer;
`;

export const FamilyRelation = styled.div`
  font-size: 15px;
  color: ${({ theme }) => theme.colors.secondary_blue};
  margin-top: 5px;
`;

export const EditIconDiv = styled.div`
  background: ${({ theme }) => theme.colors.page_background};;
  width: 30px;
  height: 30px;
  border-radius: 7px;
  text-align: center;
  cursor: pointer;
`;
export const EditIcon = styled.img`
  margin-top: 7px;
`;
export const RemoveButton = styled.div`
  background: ${({ theme }) => theme.colors.page_background};;
  width: 30px;
  height: 30px;
  margin-left: 20px;
  border-radius: 7px;
  text-align: center;
  cursor: pointer;
`;
export const RemoveIcon = styled.img`
  margin-top: 7px;
`;

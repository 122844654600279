import styled from 'styled-components';

export const SideBarDiv = styled.div`
  background: #fff;
  height: 100%;
  margin-top: 37px;
`;

export const IconRow = styled.div`
  margin-bottom: 2rem;
`;

export const IconDiv = styled.div`
  padding-inline: 2rem;
  text-align: center;
  color: #767c9c;
`;

export const IconImg = styled.img`
  width: 32px;
  height: 32px;
`;

export const IconText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  text-align: center;
  color: ${(props) => props.active};
  opacity: 0.62;
`;

import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const ChartOfAccountsWrap = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 10px 20px;
  border-radius: ${({ theme }) => theme.border.radius_m};
`;

export const CreateNewButton = styled.button`
  position: relative;
  background-color: ${({ theme }) => theme.colors.primary_red};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius_m};
  border: none;
  height: 50px;
  padding: 0;
  margin: 5px 20px;
  width: 55%;
`;

export const CreateNewButtonIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(12, 0, 0, 0.15);
  width: 42px;
  height: 35px;
  margin: 0;
  padding: 15px 0 0 0;
  border-radius: ${({ theme }) => theme.border.radius_m};
`;
export const TableDiv = styled.div`
  table {
    width: 100%;
    border-spacing: 0 8px;
    border-collapse: separate;

    tr {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`;
export const TableHead = styled.th`
  width: ${(props) => props.width};
  background-color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  font-size: 18px;
  text-align: left;
  padding: 18px;
  color: ${({ theme }) => theme.colors.primary_blue};
  :first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  :last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;
export const TableData = styled.td`
  text-align: ${(props) => props.textAlign || 'left'};
  font-size: 16px;
  font-weight: ${(props) => props.fontWeight};
  white-space: nowrap;
  padding: 18px;
  color: ${({ theme }) => theme.colors.primary_blue};
  :first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  :last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;
export const IconWrapper = styled.div`
    cursor: pointer;
    margin 0 5px;
    border-radius: 5px;
    height: 30px;
    width: 30px;
    background-color: ${({ theme }) => theme.colors.page_background};
    border: 1px solid ${({ theme }) => theme.colors.page_background};
`;
export const IconWrappers = styled.div`
    cursor: pointer;
    margin-left: 50px;
    margin-top: -30px;
    border-radius: 5px;
    height: 30px;
    width: 30px;
    background-color: ${({ theme }) => theme.colors.page_background};
    border: 1px solid ${({ theme }) => theme.colors.page_background};
`;
export const ActionIcon = styled.img`
  padding-top: 8px;
  padding-left: 8px;
`;
export const TypeHead = styled(TableHead)`
  background-color: ${({ theme }) => theme.colors.page_background};
  text-align: center;
  border-radius: 10px;
`;

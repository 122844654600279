import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import { ModalProvider } from 'styled-react-modal';
import { toast } from 'react-toastify';
import moment from 'moment';
import Cookies from 'js-cookie';
import {
  Col,
  Row,
} from '../../../components/styled_components/grid/Grid.style';
import ReportSubMenu from '../../../components/accounting/report_submenu';
import AccountingMenu from '../../../components/accounting/menu';
import {
  AccountingPageLayout,
  PageHeading,
  PageSubHeading,
} from '../../../theme/GlobalStyles';
import ConfigureModal from '../../../components/accounting/configure_modal';

import {
  StatementDiv, DisplayButton, MemberCardWrapper, SearchInput, Bold,
  DisplayButtonIcon, TotalAmtDiv, Total, SearchSelect, ConfigureButtonIcon,
  DownloadButtonIcon, DownloadButton, ConfigureButton, Image, Message, NoData,
} from './IdWiseContributionLetter.style';
import Breadcrumbs from '../../../components/breadcrumbs';
import arrowRightIcon from '../../../assets/icons/arrow-right.svg';
import configure from '../../../assets/icons/configure.svg';
import Table from '../../../components/common/table';
// import emailThis from '../../../assets/icons/emailThis.svg';
import download from '../../../assets/icons/download.svg';
import { ACCT_API, API } from '../../../constants';
import { fetcher, fileDownloader } from '../../../hooks/useFetch';
import Loader from '../../../components/styled_components/loader/loader.style';
import ScrollDownButton from '../../../utils/ScrollDownButton';
import ScrollUpButton from '../../../utils/ScrollUpButton';

const IdWiseStatement = () => {
  const [data, setData] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [memberId, setMemberId] = useState();
  const [configureData, setConfigureData] = useState({});
  const [selected, setSelected] = useState([]);
  const [total, setTotalAmt] = useState(0);
  const [loading, setLoading] = useState(false);
  const minDate = Cookies.get('accountant_year');
  const breadcrumbs = ['Accounting', 'Reports', 'Member Reports', 'ID-wise Contribution Letter'];
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const getName = async (value) => {
    const res = await fetcher({
      url: `${API.GET_MEMBER_LIST}?query=${value}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    const memberNames = res.member_list.map((item) => ({
      value: item.membershipNumber,
      label: item.name,
    }));
    if (value.match(/0/i) || value.match(/non/i)) {
      return [...memberNames, { value: '0001', label: '0001 Non Member' }];
    } else {
      return memberNames;
    }
  };
  const handleView = async () => {
    setLoading(true);
    const res = await fetcher({
      url: `${ACCT_API.GET_IDWISE_CONTRIBUTION_LETTER}?membershipId=${memberId}&startDate=${startDate}&endDate=${endDate}&family=true`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    const arr = res.receipts.filter((x) => x.notes !== 'Voided');
    setData(arr);
    setLoading(false);
    const rates = [];
    let amt = 0;
    res.receipts.map((item) => item.lines.map((i) => rates.push(parseFloat(i.rate))));
    if (rates.length > 0) {
      amt = rates.reduce((a, b) => a + b);
    }
    setTotalAmt(amt);
  };
  const handleDownload = async () => {
    const res = await fileDownloader({
      url: `${ACCT_API.GET_IDWISE_CONTRIBUTION_LETTER_DOWNLOAD}?familyId=${memberId}&startDate=${startDate}&endDate=${endDate}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    saveAs(new Blob([res], { type: 'application/vnd.ms-excel' }), 'contribution-letter.pdf');
  };
  const getConfigureData = async () => {
    try {
      const response = await fetcher({
        url: ACCT_API.GET_CONFIGURATION,
        method: 'GET',
        authTokenKey: 'auth_token',
      });
      if (response) {
        setConfigureData({ data: response });
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };
  useEffect(() => {
    getConfigureData();
  }, []);

  const handleUpdate = async (value) => {
    const payload = {
      ...value,
      message: value.message.slice(0, 200) || '',
      message2: value.message.slice(200, 400) || '',
      message3: value.message.slice(400, 600) || '',
    };
    try {
      const response = await fetcher({
        url: ACCT_API.UPDATE_STATEMENT,
        method: 'POST',
        body: JSON.stringify(payload),
      });
      if (response.success) {
        toast.success('Successfully updated', { autoClose: 3000 });
        getConfigureData();
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };

  const tableLookup = [
    {
      heading: 'Recipient Number',
      field_name: 'id',
      type: 'number',
    },
    {
      heading: 'Date',
      field_name: 'date',
      type: 'date',
    },
    {
      heading: 'Payment Method',
      field_name: 'paymentMethod',
      type: 'string',
    },
    {
      heading: 'Category',
      field_name: 'lines',
      type: 'array',
    },
    {
      heading: 'Amount',
      field_name: 'lines',
      type: 'rate',
    },
  ];
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const handleDisplay = (e) => {
    e.preventDefault();
    handleView();
    setData(null);
  };

  return (
    <>
      <AccountingMenu />
      {loading ? <Loader /> : null}
      <ScrollUpButton />
      <ScrollDownButton />
      <ModalProvider>
        <ConfigureModal
          handleUpdate={handleUpdate}
          toggleModal={toggleModal}
          isOpen={isOpen}
          configuration={configureData.data}
        />
      </ModalProvider>
      <AccountingPageLayout>
        <PageHeading>Reports</PageHeading>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <ReportSubMenu />
        <PageSubHeading>ID-wise Contribution Letter</PageSubHeading>
        <StatementDiv>
          <form onSubmit={handleDisplay}>
            <Row>
              <Col width="20%" marginLeft="5px">
                <SearchSelect
                  className="select"
                  classNamePrefix="custom"
                  placeholder="Enter Family Id"
                  loadOptions={(value) => getName(value)}
                  onChange={(e) => {
                    setMemberId(e.value);
                    setSelected(e.label);
                  }}
                />
              </Col>

              <Col width="20%">
                <SearchInput
                  type="date"
                  min={`${minDate}-01-01`}
                  max={`${minDate}-12-31`}
                  placeholder="Start Date"
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Col>
              <Col width="20%">
                <SearchInput
                  type="date"
                  min={`${minDate}-01-01`}
                  max={`${minDate}-12-31`}
                  placeholder="End Date"
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Col>
              <Col width="20%">
                <ConfigureButton
                  type="button"
                  onClick={() => {
                    toggleModal();
                  }}
                >
                  Configure
                  <ConfigureButtonIcon>
                    <Image src={configure} alt=">" height="13px" marginTop="5px" />
                  </ConfigureButtonIcon>

                </ConfigureButton>
              </Col>
              <Col width="18%">
                <DisplayButton
                  type="submit"
                  disabled={!(startDate && endDate)}
                >
                  Display
                  <DisplayButtonIcon>
                    <img src={arrowRightIcon} alt=">" height="16px" />
                  </DisplayButtonIcon>
                </DisplayButton>
              </Col>
            </Row>
          </form>
        </StatementDiv>
        {data && data.length > 0 ? (
          <>
            <MemberCardWrapper>
              <Row>

                <Col col={12}>
                  <Row>
                    <Col col={10}>
                      <Bold>
                        <p>
                          Dear
                          {' '}
                          {selected}
                          ,
                        </p>
                      </Bold>
                    </Col>
                    {/* <Col col={2} textAlign="right">
                      <EmailButton>
                        Email This
                        <EmailButtonIcon>
                          <ImageEmail src={emailThis} alt=">" height="16px" />
                        </EmailButtonIcon>
                      </EmailButton>
                    </Col> */}

                  </Row>
                  <Message>

                    Greetings from the Trinity Mar Thoma Church, Houston!
                    The following are the contributions you have made to
                    Trinity Mar Thoma Church, Houston from
                    {' '}
                    {moment(startDate).format('MM-DD-yyyy')}
                    {' '}
                    to
                    {' '}
                    {moment(endDate).format('MM-DD-yyyy')}
                    .
                  </Message>

                </Col>

              </Row>
            </MemberCardWrapper>
          </>
        ) : data && data.length === 0 ? (
          <NoData>
            No Data
          </NoData>
        ) : ''}
        {data && data.length > 0 ? (
          <>
            <Table data={data || []} tableLookup={tableLookup} />
            <TotalAmtDiv>
              <Row>
                <Col col={2}>
                  <DownloadButton
                    type="button"
                    onClick={() => handleDownload()}
                  >
                    Download
                    <DownloadButtonIcon>
                      <Image
                        src={download}
                        alt=">"
                        height="13px"
                        marginTop="5px"
                      />
                    </DownloadButtonIcon>
                  </DownloadButton>
                </Col>
                <Col col={10}>
                  <Total>
                    $
                    {formatter.format(total)}
                  </Total>
                </Col>
              </Row>
            </TotalAmtDiv>
          </>
        ) : (
          ''
        )}
        {data && data.length > 0 ? (
          <>
            <MemberCardWrapper>
              <Row>
                <Col col={12}>
                  <Message>
                    {configureData.data.results && configureData.data.results.message}
                    {configureData.data.results && configureData.data.results.message2}
                    {configureData.data.results && configureData.data.results.message3}
                  </Message>
                  <Message>
                    <p>May God bless you and your family.</p>
                  </Message>

                  <Row>

                    <Col col={4}>
                      <Message>
                        <p>{configureData.data.results && configureData.data.results.vicar}</p>
                      </Message>
                    </Col>
                    <Col col={4} textAlign="center">
                      <Message>
                        <p>
                          {configureData.data.results
                        && configureData.data.results.trusteeFinanceName}
                        </p>
                      </Message>
                    </Col>
                    <Col col={4} textAlign="right">
                      <Message>
                        <p>
                          {configureData.data.results
                        && configureData.data.results.trusteeAccountsName}
                        </p>
                      </Message>
                    </Col>

                  </Row>
                  <Row>
                    <Col col={4}>
                      <Bold>
                        <p>Vicar</p>
                      </Bold>
                    </Col>
                    <Col col={4} textAlign="center">
                      <Bold>
                        <p>
                          Trustee-Finance
                        </p>
                      </Bold>
                    </Col>
                    <Col col={4} textAlign="right">
                      <Bold>
                        <p>
                          Trustee-Accounts
                        </p>
                      </Bold>
                    </Col>

                  </Row>
                </Col>
              </Row>
            </MemberCardWrapper>
          </>
        ) : ''}
      </AccountingPageLayout>
    </>
  );
};
export default IdWiseStatement;

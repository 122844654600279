import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import AccountingMenu from '../../../components/accounting/menu';
import ReportSubMenu from '../../../components/accounting/report_submenu';
import Breadcrumbs from '../../../components/breadcrumbs';
import arrowRightIcon from '../../../assets/icons/arrow-right.svg';
import Loader from '../../../components/styled_components/loader/loader.style';
import {
  Col,
  Row,
} from '../../../components/styled_components/grid/Grid.style';
import {
  AccountingPageLayout,
  PageHeading,
  PageSubHeading,
} from '../../../theme/GlobalStyles';
import {
  DisplayButton,
  DisplayButtonIcon,
  StyledDate,
  TrialBalanceDiv,
  TableHead,
  TableBody,
  BoldItems,
} from './trialBalance.style';
import { ACCT_API } from '../../../constants';
import { fetcher } from '../../../hooks/useFetch';
import ScrollDownButton from '../../../utils/ScrollDownButton';
import ScrollUpButton from '../../../utils/ScrollUpButton';

const breadcrumbs = ['Accounting', 'Reports', 'Trial Balance'];

const TrialBalance = () => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [report, setReport] = useState();
  const [loading, setLoading] = useState(false);
  const minDate = Cookies.get('accountant_year');
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const getReport = async () => {
    setLoading(true);
    try {
      const res = await fetcher({
        url: `${ACCT_API.GET_REPORT}?type=TrialBalance&${startDate}&${endDate}`,
        method: 'GET',
        authTokenKey: 'auth_token',
      });
      if (res.report) {
        setReport(res);
        setLoading(false);
      } else {
        toast.error('Error!', { autoClose: 3000 });
        setLoading(false);
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
      setLoading(false);
    }
  };
  let headers;
  let tableBody;
  if (report) {
    const columns = report.report.Columns.Column;
    const rows = report.report.Rows.Row;
    headers = (
      <>
        {!rows ? (
          <div style={{ textAlign: 'center' }}>
            <h1>No data</h1>
          </div>
        ) : (
          <Row key="header">
            <Col col={6}>Account</Col>
            {columns.map((col) => (
              <Col col={2} key={col} textAlign="right">
                {col.ColTitle}
              </Col>
            ))}
          </Row>
        )}
      </>
    );

    tableBody = (
      <>
        {!rows
          ? null
          : rows.map((row) => (
            <TableBody>
              <Row key={row}>
                {row.ColData
                  ? row.ColData.map((cellData, index) => {
                    switch (index) {
                      case 0:
                        return (
                          <Col col={8} key={cellData}>
                            {cellData.value}
                          </Col>
                        );
                      case 1:
                        return (
                          <Col col={2} key={cellData} textAlign="right">
                            {cellData.value}
                          </Col>
                        );
                      default:
                        return (
                          <Col col={2} key={cellData} textAlign="right">
                            {cellData.value}
                          </Col>
                        );
                    }
                  })
                  : row.Summary.ColData.map((cellData, cellIndex) => {
                    switch (cellIndex) {
                      case 0:
                        return (
                          <Col col={8} key={`summary_${cellData}`}>
                            <BoldItems>
                              {cellIndex >= row.Summary.ColData.length - 2
                                ? `$ ${formatter.format(cellData.value)}`
                                : cellData.value}
                            </BoldItems>
                          </Col>
                        );
                      default:
                        return (
                          <Col
                            col={2}
                            textAlign="right"
                            key={`summary_${cellData}`}
                          >
                            <BoldItems>
                              {cellIndex >= row.Summary.ColData.length - 2
                                ? `$ ${formatter.format(cellData.value)}`
                                : cellData.value}
                            </BoldItems>
                          </Col>
                        );
                    }
                  })}
              </Row>
            </TableBody>
          ))}
      </>
    );
  }
  return (
    <>
      <AccountingMenu />
      {loading ? <Loader /> : null}
      <ScrollUpButton />
      <ScrollDownButton />
      <AccountingPageLayout>
        <PageHeading>Reports</PageHeading>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <ReportSubMenu />
        <PageSubHeading>Trial Balance</PageSubHeading>
        <TrialBalanceDiv>
          <Row>
            <Col col={3}>
              <StyledDate
                width="88%"
                type="date"
                min={`${minDate}-01-01`}
                max={`${minDate}-12-31`}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Col>
            <Col col={3}>
              <StyledDate
                width="88%"
                type="date"
                min={`${minDate}-01-01`}
                max={`${minDate}-12-31`}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Col>
            <Col col={3} />
            <Col col={3}>
              <DisplayButton type="button" onClick={getReport} disabled={!(startDate && endDate)}>
                Display
                <DisplayButtonIcon>
                  <img src={arrowRightIcon} alt=">" height="16px" />
                </DisplayButtonIcon>
              </DisplayButton>
            </Col>
          </Row>
        </TrialBalanceDiv>
        {report ? <TableHead>{headers}</TableHead> : null}
        {tableBody}
      </AccountingPageLayout>
    </>
  );
};

export default TrialBalance;

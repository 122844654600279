import React from 'react';
import PropTypes from 'prop-types';
import tick from '../../../../assets/icons/tick-white.svg';
import close from '../../../../assets/icons/close-icon.svg';
import { Row, Col } from '../../../styled_components/grid/Grid.style';
import {
  StyledModal,
  SaveButton,
  SaveButtonIcon,
  ModalWrapper,
  ModalHeading,
  CloseButton,
  CloseIcon,
  CustomSelect,
  SearchSelect,
  AddressBar,
  StyledDate,
  CheckNumber,
  CreateSelect,
} from './editModal.style';
import EditTable from '../edit_table';

const options = [
  { label: 'Check', value: 'Check' },
  { label: 'Venmo', value: 'Venmo' },
  { label: 'Cash', value: 'Cash' },
  { label: 'Credit Card', value: 'Credit Card' },
];
const EditModal = ({
  isOpen,
  toggleModal,
  lineItems,
  address,
  selected,
  category,
  getAddress,
  handleChange,
  handleLineItems,
  handleSubmit,
  getName,
  formatter,
  minDate,
  getMaxDate,
  checkBank,
  createCheckBank,
}) => (
  <StyledModal isOpen={isOpen}>
    <CloseButton type="button" onClick={toggleModal}>
      <CloseIcon>
        <img src={close} alt="arrow" height="16px" />
      </CloseIcon>
    </CloseButton>
    <ModalWrapper>
      <Row>
        <Col col={12}>
          <ModalHeading>Edit Receipt</ModalHeading>
        </Col>
      </Row>
      <Row>
        <Col col={6}>
          <Row>
            <SearchSelect
              className="select"
              classNamePrefix="custom"
              loadOptions={(value) => getName(value)}
              placeholder={selected.memberName}
              onChange={(e) => {
                handleChange({ target: { name: 'membershipId', value: e.value } });
                getAddress(e.value);
              }}
            />
          </Row>
          <Row>
            {/* <Col col={6}>
              <CustomSelect
                className="select"
                classNamePrefix="custom"
                options={category}
                value={category.find((item) => item.value === selected.organization)}
                placeholder="Organization"
                onChange={(e) => {
                  handleChange({ target: { name: 'organization', value: e.value } });
                }}
              />
            </Col> */}
            <Col col={12}>
              <CustomSelect
                className="select"
                classNamePrefix="custom"
                value={options.find((item) => item.value === selected.paymentMethod)}
                options={options}
                placeholder="Payment Methods"
                onChange={(e) => {
                  handleChange({ target: { name: 'paymentMethod', value: e.value } });
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col col={6}>
          <Row>
            <AddressBar
              value={
                    address
                      ? `${address.address1} ${address.address2}\n${address.city}, ${address.state}, ${address.zipcode}`
                      : ''
                  }
              placeholder="Address"
              disabled
            />
          </Row>

          <Row>
            <Col col={4}>
              <StyledDate
                defaultValue={selected.date}
                width="91%"
                type="date"
                name="date"
                min={`${minDate}-01-01`}
                max={getMaxDate()}
                placeholder="Check Date"
                onKeyDown={(e) => e.preventDefault()}
                onChange={(e) => handleChange({ target: { name: 'date', value: e.target.value } })}
              />
            </Col>
            {/* <Col col={1} /> */}
            {selected.paymentMethod === 'Check' ? (
              <>
                <Col col={4}>
                  <CheckNumber
                    type="text"
                    name="checkNumber"
                    value={selected.checkNumber}
                    onChange={(e) => handleChange(e)}
                  />
                </Col>
                {/* <Col col={1} /> */}
                <Col col={4}>
                  <CreateSelect
                    className="select"
                    classNamePrefix="custom"
                    onCreateOption={(value) => createCheckBank(value)}
                    options={checkBank}
                    placeholder={selected.checkBank}
                    onChange={(e) => handleChange({ target: { name: 'checkBank', value: e.value } })}
                  />
                </Col>
              </>
            ) : null}
          </Row>
        </Col>
      </Row>
      <EditTable
        formatter={formatter}
        categories={category}
        items={lineItems}
        handleLineItems={handleLineItems}
      />
      <Row>
        <Col col={8} />
        <Col col={4}>
          <SaveButton
            type="button"
            onClick={() => {
              toggleModal();
              handleSubmit();
            }}
          >
            Confirm
            <SaveButtonIcon>
              <img src={tick} alt="arrow" height="16px" />
            </SaveButtonIcon>
          </SaveButton>
        </Col>
      </Row>
    </ModalWrapper>
  </StyledModal>
);
EditModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  lineItems: PropTypes.object,
  selected: PropTypes.object,
  checkBank: PropTypes.array,
  createCheckBank: PropTypes.func,
  address: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleLineItems: PropTypes.func,
  handleChange: PropTypes.func,
  category: PropTypes.array,
  getAddress: PropTypes.func,
  getName: PropTypes.func,
  formatter: PropTypes.func,
  minDate: PropTypes.number,
  getMaxDate: PropTypes.func,
};

export default EditModal;

import React from 'react';
import { isMobile } from 'react-device-detect';
import Cookies from 'js-cookie';
import { Col, Row } from '../../components/styled_components/grid/Grid.style';
import { PageHeading, PageLayout } from '../../theme/GlobalStyles';
import FamilyPanel from '../../components/family_panel';
import { useFetch } from '../../hooks/useFetch';
import { API } from '../../constants';
import MobileFamily from '../mobile_view/family';

const Family = () => {
  const roles = Cookies.get('user_roles').split(',');
  const { data } = useFetch(
    {
      url: API.GET_FAMILY,
      method: 'GET',
      authTokenKey: 'auth_token',
    },
  );
  if (isMobile) {
    return <MobileFamily />;
  }

  return (
    <>
      <PageLayout>
        <PageHeading>Family</PageHeading>
        <Row>
          {data && data.details.length > 0
            ? data.details.map((member, ind) => {
              if (ind % 2 === 0) {
                return (
                  <Col col={6}>
                    <FamilyPanel roles={roles} member={member} marginRight="20px" />
                  </Col>
                );
              }
              return (
                <Col col={6}>
                  <FamilyPanel roles={roles} member={member} />
                </Col>
              );
            })
            : null}
        </Row>
      </PageLayout>
    </>
  );
};

export default Family;

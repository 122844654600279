import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { isMobile } from 'react-device-detect';
import { ModalProvider } from 'styled-react-modal';
import { toast } from 'react-toastify';
import CreateModal from '../../components/membership_components/remove_modal';
import { PageLayout } from '../../theme/GlobalStyles';
import { Col, Row } from '../../components/styled_components/grid/Grid.style';
import ProfileSection from '../../components/membership_components/profile_section';
import PanelHeading from '../../components/membership_components/panel_heading';
import tickIcon from '../../assets/icons/tick.svg';
import editIcon from '../../assets/icons/pen.svg';
import closeIcon from '../../assets/icons/close-icon.svg';
import defaultFamilyProfileImg from '../../assets/img/default-family.png';
import { normalizeInput } from '../../utils/NormalizeUSNumber';
import {
  WhiteBodySection,
  BlueBodySection,
  OtherInfoCustomSelect,
  Panel,
  PanelBody,
  FieldLabel,
  FieldValue,
  FieldValueInput,
  EditFieldValue,
  EditIconDiv,
  EditIconDivs,
  EditIcon,
  EditIcons,
  UserPhoto,
  TopCol,
  FieldLabelEdit,
  CloseIconDiv,
  CloseIcon,
  TickIconDiv,
  TickIcon,
} from './MemberDetails.style';
import FamilyPanel from '../../components/family_panel';
import { useFetch, fetcher } from '../../hooks/useFetch';
import { API } from '../../constants';
import EditAddressModal from '../../components/membership_components/edit_modal';
import MembershipMobile from '../mobile_view/member_details';

const tShirtSize = [
  { value: 'S', label: 'S' },
  { value: 'M', label: 'M' },
  { value: 'L', label: 'L' },
  { value: 'XL', label: 'XL' },
  { value: 'XXL', label: 'XXL' },
];
const gender = [
  { value: 'F', label: 'F' },
  { value: 'M', label: 'M' },
  { value: 'Other', label: 'Other' },
];
const Membership = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [memberDetails, setMemberDetails] = useState();
  const [familyDetails, setFamilyDetails] = useState();
  const [edit, setEdit] = useState(false);
  const [item, setItem] = useState();
  const [modal, setModal] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [address, setAddress] = useState();
  const [selectedMember, setSelectedMember] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [relation, setRelation] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [familyMembers, setFamilyMembers] = useState();
  const [selectedMembers, setSelectedMembers] = useState([]);
  const roles = Cookies.get('user_roles').split(',');

  const handleClick = (link) => {
    // eslint-disable-next-line no-restricted-globals
    navigate(link);
  };
  const toggleModal = () => {
    setModal(!modal);
  };
  const familyInput = useRef();
  const handleUpdate = () => {
    familyInput.current.click();
  };
  const group = useFetch({
    url: API.GET_PRAYER_GROUP,
    method: 'GET',
    authTokenKey: 'auth_token',
  });
  if (group.data && selectedGroup.length === 0) {
    setSelectedGroup(
      group.data.area_list.map((item) => ({
        value: item.id,
        label: item.areaName,
      })),
    );
  }
  const rel = useFetch({
    url: API.GET_RELATIONS,
    method: 'GET',
    authTokenKey: 'auth_token',
  });
  if (rel.data && relation.length === 0) {
    // setNextId(rel.data.next_family_id);
    setRelation(
      rel.data.relation_list.map((item) => ({
        value: item.roleId,
        label: item.roleName,
      })),
    );
  }
  const onHandleChange = (e) => {
    let getFormated;
    if (e.target.name === 'mobilePhone' || e.target.name === 'homePhone') {
      getFormated = normalizeInput(e.target.value);
      setItem({ [e.target.name]: getFormated });
    } else {
      setItem({ [e.target.name]: e.target.value });
    }
  };
  const handleAddress = (e) => {
    setAddress({ ...address, [e.target.name]: e.target.value });
  };
  const getMemberDetails = async () => {
    const res = await fetcher({
      url: `${API.MEMBER_DATA}?userid=${id}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    setMemberDetails(res);
    setAddress({
      address_1: res.address1,
      address_2: res.address2,
      city: res.city,
      state: res.state,
      ZipCode: res.zip,
    });
  };
  const getFamilyDetails = async () => {
    const res = await fetcher({
      url: `${API.GET_FAMILY_MEMBERS}?userId=${id}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    const member = res.find((item) => item.ID === id);
    if (!member) {
      handleClick('/members');
    }
    setFamilyDetails(res);
    setFamilyMembers(res.map((item) => ({
      value: item.churchID,
      label: item.name,
    })));
  };
  const toggleDeleteModal = (member) => {
    setIsOpen(!isOpen);
    if (member) {
      setSelectedMembers([{ value: member.churchID, label: member.name }]);
    }
    getFamilyDetails();
  };
  const uploadProfilePic = async (event) => {
    const image = event.target.files[0];
    if (image.size > 1e6) {
      toast.warning('Error: Image size should be less than 1MB', {
        autoClose: 3000,
      });
    } else {
      const formData = new FormData();
      formData.append('profile_pic', image);
      formData.append('userId', id);
      try {
        const res = await fetcher({
          url: `${API.UPLOAD_MEMBER_PHOTO}`,
          method: 'POST',
          authTokenKey: 'auth_token',
          body: formData,
          multiPart: true,
        });
        if (res.success) {
          toast.success('Successfully Updated!', { autoClose: 3000 });
          const res = await fetcher({
            url: `${API.MEMBER_DETAILS}`,
            method: 'GET',
            authTokenKey: 'auth_token',
          });
          if (res) {
            Cookies.set('photoUrl', res.profilePic);
          }
          window.location.reload();
        } else {
          toast.error('Error!', { autoClose: 3000 });
        }
      } catch (e) {
        toast.error('Error!', { autoClose: 3000 });
      }
    }
  };
  const uploadFamilyPic = async (event) => {
    const image = event.target.files[0];
    if (image.size > 1e6) {
      toast.warning('Error: Image size should be less than 2MB', {
        autoClose: 3000,
      });
    } else {
      const formData = new FormData();
      formData.append('family_pic', image);
      formData.append('userId', id);
      try {
        const res = await fetcher({
          url: `${API.UPLOAD_FAMILY_PHOTO}`,
          method: 'POST',
          authTokenKey: 'auth_token',
          body: formData,
          multiPart: true,
        });
        if (res.success) {
          toast.success('Successfully Updated!', { autoClose: 3000 });
          window.location.reload();
        } else {
          toast.error('Error!', { autoClose: 3000 });
        }
      } catch (e) {
        toast.error('Error!', { autoClose: 3000 });
      }
    }
  };
  const updateContact = async (e) => {
    e.preventDefault();
    try {
      const response = await fetcher({
        url: API.UPDATE_CONTACT,
        method: 'POST',
        body: JSON.stringify({ ...item, userId: id }),
      });
      if (response.success) {
        toast.success('Successfully updated', { autoClose: 3000 });
        setEdit(null);
        setItem();
        setMemberDetails();
        getMemberDetails();
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
      setEdit(null);
    }
  };
  const updateSocial = async (e) => {
    e.preventDefault();
    try {
      const response = await fetcher({
        url: API.UPDATE_SOCIAL,
        method: 'POST',
        body: JSON.stringify({ ...item, userId: id }),
      });
      if (response.success) {
        toast.success('Successfully updated', { autoClose: 3000 });
        setEdit(null);
        setItem();
        setMemberDetails();
        getMemberDetails();
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
      setEdit(null);
    }
  };
  const updateInfo = async (e) => {
    e.preventDefault();
    try {
      const response = await fetcher({
        url: API.UPDATE_MEMBER_DETAILS,
        method: 'POST',
        body: JSON.stringify({ ...item, userId: id }),
      });
      if (response.success) {
        toast.success('Successfully updated', { autoClose: 3000 });
        setEdit(null);
        setItem();
        setMemberDetails();
        getMemberDetails();
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
      setEdit(null);
    }
  };

  const handleRemove = (value) => {
    toggleDeleteModal(value);
    setSelectedMember(value);
  };

  const handleDelete = async (value, params) => {
    try {
      const res = await fetcher({
        url: `${API.REMOVE_MEMBER}`,
        method: 'POST',
        authTokenKey: 'auth_token',
        body: JSON.stringify(value),
      });
      if (res) {
        if (res.message) {
          toast.success('Successfully removed!', { autoClose: 3000 });
          if (params?.caller !== 'transfer') {
            handleClick('/members');
          } else {
            const items = [...selectedMembers];
            items.splice(params.index, 1);
            if (items.length === 0) {
              handleClick('/members');
            } else {
              setSelectedMembers(items);
            }
          }
        } else if (res.warning) {
          toast.success(res.warning, { autoClose: 3000 });
          if (params?.caller !== 'transfer') {
            handleClick('/members');
          } else {
            const items = [...selectedMembers];
            items.splice(params.index, 1);
            if (items.length === 0) {
              handleClick('/members');
            } else {
              setSelectedMembers(items);
            }
          }
        } else {
          toast.error('Error!', { autoClose: 3000 });
        }
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };

  const handleByMistake = async (value) => {
    try {
      const res = await fetcher({
        url: `${API.DELETE_MEMBER}`,
        method: 'POST',
        authTokenKey: 'auth_token',
        body: JSON.stringify(value),
      });
      if (res) {
        if (res.message) {
          toast.success('Successfully removed!', { autoClose: 3000 });
        } else if (res.warning) {
          toast.success(res.warning, { autoClose: 3000 });
        }
      }
    } catch (error) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };

  const updateAddress = async () => {
    const payload = {
      address1: address.address_1,
      address2: address.address_2,
      city: address.city,
      state: address.state,
      zipcode: address.zipcode,
    };
    try {
      const response = await fetcher({
        url: API.UPDATE_ADDRESS_ADMIN,
        method: 'POST',
        body: JSON.stringify({ ...payload, userId: id }),
      });
      if (response.success) {
        toast.success('Successfully updated', { autoClose: 3000 });
        setEdit(null);
        setAddress();
        setMemberDetails();
        getMemberDetails();
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
      setEdit(null);
    }
  };
  const editFields = (name, field, post, value, getField) => {
    if (edit === name) {
      if (field === 'tShirtSize' || field === 'prayerGroup' || field === 'gender' || field === 'roleId') {
        return (
          <Row>
            <Col col={12} padding="0 5px">
              <Row><FieldLabelEdit>{name}</FieldLabelEdit></Row>
              <Row>
                <Col col={10} display="flex" alignItems="center">
                  <OtherInfoCustomSelect
                    className="select"
                    classNamePrefix="custom"
                    name={field}
                    options={getField}
                    defaultValue={field === 'prayerGroup' && field === 'roleId' ? getField.find((i) => i.label === value) : getField.find((i) => i.value === value)}
                    onChange={(event) => onHandleChange({
                      target: { name: field, value: event.value },
                    })}
                  />
                </Col>
                <Col col={1}>
                  <CloseIconDiv onClick={() => setEdit(null)}>
                    <CloseIcon
                      src={closeIcon}
                    />
                  </CloseIconDiv>
                </Col>
                <Col col={1}>
                  <TickIconDiv onClick={post === 'social' ? updateSocial : post === 'contact' ? updateContact : updateInfo}>
                    <TickIcon
                      src={tickIcon}
                    />
                  </TickIconDiv>
                </Col>
              </Row>
            </Col>
          </Row>
        );
      } else {
        return (
          <Row>
            <Col col={12} padding="0 5px">
              <Row><FieldLabelEdit>{name}</FieldLabelEdit></Row>
              <Row>
                <Col col={10} display="flex" marginRight="10px">
                  <FieldValueInput
                    name={field}
                    type={field === 'dateOfBaptism' || field === 'dob' || field === 'dom' ? 'date' : 'text'}
                    value={item[field]}
                    onChange={(e) => onHandleChange(e)}
                  />
                </Col>
                <Col col={1}>
                  <CloseIconDiv onClick={() => setEdit(null)}>
                    <CloseIcon
                      src={closeIcon}
                    />
                  </CloseIconDiv>
                </Col>
                <Col col={1}>
                  <TickIconDiv onClick={post === 'social' ? updateSocial : post === 'contact' ? updateContact : updateInfo}>
                    <TickIcon
                      src={tickIcon}
                    />
                  </TickIconDiv>
                </Col>
              </Row>
            </Col>
          </Row>
        );
      }
    } else {
      return (
        <Row>
          <Col col={12} padding="0 5px">
            <Row><FieldLabelEdit>{name}</FieldLabelEdit></Row>
            <Row>
              <Col col={10} display="flex" alignItems="center">
                <EditFieldValue>
                  {value}
                </EditFieldValue>
              </Col>
              <Col col={2}>
                {isAdmin ? (
                  <EditIconDivs onClick={() => {
                    setItem({ [field]: memberDetails[getField || field] });
                    setEdit(name);
                  }}
                  >
                    <EditIcons
                      src={editIcon}
                    />
                  </EditIconDivs>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }
  };
  useEffect(() => {
    getMemberDetails();
    getFamilyDetails();
  }, [id]);
  if (isMobile) {
    return <MembershipMobile />;
  }
  if (roles.includes('PowerUser') && !isAdmin) {
    setIsAdmin(true);
  }

  return (
    <>
      <PageLayout className="layout">
        <ModalProvider>
          <EditAddressModal
            toggleModal={toggleModal}
            isOpen={modal}
            memberDetails={memberDetails}
            handleAddress={handleAddress}
            handleUpdate={updateAddress}
            caller="admin"
          />
        </ModalProvider>
        <ModalProvider>
          <CreateModal
            member={selectedMember}
            handleDelete={handleDelete}
            handleByMistake={handleByMistake}
            toggleModal={toggleDeleteModal}
            isOpen={isOpen}
            familyMembers={familyMembers}
            selectedMembers={selectedMembers}
            setSelectedMembers={setSelectedMembers}
          />
        </ModalProvider>
        <ProfileSection
          memberDetails={memberDetails}
          showEditIcon={isAdmin}
          uploadProfilePic={uploadProfilePic}
          uploadFamilyPic={uploadFamilyPic}
        />
        <BlueBodySection>
          <Row>
            <Panel marginRight="20px">
              <PanelHeading label="My Households" />
              <PanelBody>
                <Row>
                  {familyDetails && familyDetails.length > 0
                    ? familyDetails.map((member) => (
                      <Col col={6}>
                        <FamilyPanel roles={roles} handleRemove={handleRemove} member={member} caller="member_details" marginRight="20px" />
                      </Col>
                    ))
                    : null}
                </Row>
              </PanelBody>
            </Panel>
          </Row>
        </BlueBodySection>
        <WhiteBodySection>
          <Row>
            <Panel marginRight="20px">
              <Row>
                <Col col={11}><PanelHeading label="Family Pic" /></Col>
                <TopCol col={12}>
                  <UserPhoto
                    height="500px"
                    width="800px"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = defaultFamilyProfileImg;
                    }}
                    src={
                memberDetails && memberDetails && memberDetails.photoUrl
                  ? memberDetails.photoUrl
                  : defaultFamilyProfileImg
              }
                    alt="User Photo"
                  />
                  {isAdmin && (memberDetails
                    && memberDetails.familyHead === memberDetails.membershipNumber) ? (
                      <EditIconDiv onClick={handleUpdate} className="profileEdit">
                        <EditIcon src={editIcon} alt="edit" />
                        <input
                          type="file"
                          ref={familyInput}
                          accept="image/*"
                          name="image"
                          id="familyimg"
                          onChange={(event) => uploadFamilyPic(event)}
                          hidden
                        />
                      </EditIconDiv>
                    ) : null}
                </TopCol>

              </Row>
            </Panel>
          </Row>
        </WhiteBodySection>
        {isAdmin ? (
          <>
            <BlueBodySection>
              <Row>
                <Panel marginRight="20px">
                  <PanelHeading label="Personal Info" />
                  <PanelBody>
                    <Row marginBottom="17px">
                      <Col col={4}>
                        {editFields('Name Prefix', 'saute', 'info', (memberDetails && memberDetails.saute))}
                      </Col>
                      <Col col={4}>
                        {editFields('First Name', 'firstName', 'info', (memberDetails && memberDetails.firstName))}
                      </Col>
                      <Col col={4}>
                        {editFields('Middle Name', 'secondName', 'info', (memberDetails && memberDetails.secondName))}
                      </Col>
                    </Row>
                    <Row marginBottom="17px">
                      <Col col={4}>
                        {editFields('Last Name', 'lastName', 'info', (memberDetails && memberDetails.lastName))}
                      </Col>
                      <Col col={4}>
                        {editFields('Nick Name', 'nickName', 'info', (memberDetails && memberDetails.nickName))}
                      </Col>
                      <Col col={4}>
                        {editFields('School', 'school', 'info', (memberDetails && memberDetails.school))}
                      </Col>
                    </Row>
                    <Row marginBottom="17px">
                      <Col col={4}>
                        {editFields('Grade', 'grade', 'info', (memberDetails && memberDetails.grade))}
                      </Col>
                      <Col col={4}>
                        {editFields('Medical Notes', 'medicalNotes', 'info', (memberDetails && memberDetails.medicalNotes))}
                      </Col>
                      <Col col={4}>
                        {editFields('T-Shirt Size', 'tShirtSize', 'info', (memberDetails && memberDetails.tShirtSize), tShirtSize)}
                      </Col>
                    </Row>
                    <Row marginBottom="17px">
                      <Col col={4}>
                        {editFields('Birthday', 'dob', 'info', (memberDetails && memberDetails.dateOfBirth))}
                      </Col>
                      <Col col={4}>
                        {editFields('Wedding Anniversary', 'dom', 'info', (memberDetails && memberDetails.dateOfMarriage))}
                      </Col>
                      <Col col={4}>
                        {editFields('Prayer Group', 'prayerGroup', 'info', (memberDetails && memberDetails.areaName), selectedGroup)}
                      </Col>
                    </Row>
                    <Row marginBottom="17px">
                      <Col col={4}>
                        {editFields('Gender', 'gender', 'info', (memberDetails && memberDetails.sex), gender)}
                      </Col>
                      <Col col={4}>
                        {editFields('Relation With Head of Family', 'roleId', 'info', (memberDetails && memberDetails.roleName), relation)}
                      </Col>
                      <Col col={4}>
                        {editFields('Date Of Baptism', 'dateOfBaptism', 'info', (memberDetails && memberDetails.dateOfBaptism))}
                      </Col>
                    </Row>
                    <Row marginBottom="17px">
                      <Col col={4}>
                        {editFields('Place Of Baptism', 'placeOfBaptism', 'info', (memberDetails && memberDetails.placeOfBaptism))}
                      </Col>
                      <Col col={4}>
                        {editFields('God Parent', 'godParent', 'info', (memberDetails && memberDetails.godParent))}
                      </Col>
                      <Col col={4}>
                        {editFields('Priest Conducted Baptism', 'priestBaptism', 'info', (memberDetails && memberDetails.priestBaptism))}
                      </Col>
                    </Row>
                    <Row marginBottom="17px">
                      <Col col={4}>
                        {editFields('Mother Parish', 'motherParish', 'info', (memberDetails && memberDetails.motherParish))}
                      </Col>
                    </Row>
                  </PanelBody>
                </Panel>
              </Row>
            </BlueBodySection>
          </>
        ) : null}
        <WhiteBodySection>
          <Row>
            <Panel marginRight="20px">
              <Row>
                <Col col={9}><PanelHeading label="Address" /></Col>
                <Col col={3} padding="20px 0 0 0">
                  {isAdmin ? (
                    <EditIconDiv onClick={() => toggleModal()}>
                      <EditIcon
                        src={editIcon}
                      />
                    </EditIconDiv>
                  ) : null}
                </Col>

              </Row>
              <PanelBody>
                <Row marginBottom="17px">
                  <Col col={4}>
                    <FieldLabel>Street Line 1</FieldLabel>
                    <FieldValue>
                      {memberDetails && memberDetails.address1}
                    </FieldValue>
                  </Col>
                  <Col col={4}>
                    <FieldLabel>Street Line 2</FieldLabel>
                    <FieldValue>
                      {memberDetails && memberDetails.address2}
                    </FieldValue>
                  </Col>
                  <Col col={4}>
                    <FieldLabel>City</FieldLabel>
                    <FieldValue>
                      {memberDetails && memberDetails.city}
                    </FieldValue>
                  </Col>
                </Row>
                <Row marginBottom="17px">
                  <Col col={4}>
                    <FieldLabel>State</FieldLabel>
                    <FieldValue>
                      {memberDetails && memberDetails.state}
                    </FieldValue>
                  </Col>
                  <Col col={4}>
                    <FieldLabel>Zip Code</FieldLabel>
                    <FieldValue>
                      {memberDetails && memberDetails.zip}
                    </FieldValue>
                  </Col>
                  <Col col={4}>
                    <FieldLabel>Address Native</FieldLabel>
                    <FieldValue>
                      {memberDetails && `${memberDetails.otherAddress1} ${memberDetails.otherAddress2} ${memberDetails.otherAddressCity} ${memberDetails.otherAddressState}`}
                    </FieldValue>
                  </Col>
                </Row>
              </PanelBody>
            </Panel>
          </Row>
        </WhiteBodySection>
        <BlueBodySection>
          <Row>
            <Panel marginRight="20px">
              <PanelHeading label="Contact Information" />
              <PanelBody>
                <Row marginBottom="17px">
                  <Col col={4}>
                    {editFields('Mobile Phone Number', 'mobilePhone', 'contact', (memberDetails && memberDetails.contactNo), 'contactNo')}
                  </Col>
                  <Col col={4}>
                    {editFields('Home Phone Number', 'homePhone', 'contact', (memberDetails && memberDetails.contactDetail), 'contactDetail')}
                  </Col>
                  <Col col={4}>
                    {editFields('Email', 'homeEmail', 'contact', (memberDetails && memberDetails.homeEmail))}
                  </Col>
                </Row>
              </PanelBody>
            </Panel>
          </Row>
        </BlueBodySection>
        {isAdmin ? (
          <>
            <WhiteBodySection>
              <Row>
                <Panel marginRight="20px">
                  <PanelHeading label="Social Media Link" />
                  <PanelBody>
                    <Row marginBottom="17px">
                      <Col col={4}>
                        {editFields('Facebook', 'facebook', 'social', (memberDetails && memberDetails.facebook))}
                      </Col>
                      <Col col={4}>
                        {editFields('Instagram', 'instagram', 'social', (memberDetails && memberDetails.instagram))}
                      </Col>
                      <Col col={4}>
                        {editFields('LinkedIn', 'linkedIn', 'social', (memberDetails && memberDetails.linkedIn))}
                      </Col>
                    </Row>
                    <Row marginBottom="17px">
                      <Col col={4}>
                        {editFields('Twitter', 'twitter', 'social', (memberDetails && memberDetails.twitter))}
                      </Col>
                    </Row>
                  </PanelBody>
                </Panel>
              </Row>
            </WhiteBodySection>
          </>
        ) : null}
      </PageLayout>
    </>
  );
};
export default Membership;

import styled from 'styled-components';
import Select from 'react-select';

// eslint-disable-next-line import/prefer-default-export
export const Menu = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 10px 20px;
  border-radius: ${({ theme }) => theme.border.radius_m};
`;

export const CustomSelect = styled(Select)`
  width: 100%;
  margin: 5px 0;
  padding: 0 5px;
  .custom__control {
    height: 50px;
    background-color: ${({ theme }) => theme.colors.page_background};
    border: 1px solid ${({ theme }) => theme.colors.page_background};
    .custom__placeholder {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.primary_blue};
    }
    .custom__single-value {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.primary_blue};
    }
    .custom__indicator-separator {
      display: none;
    }
  }
`;

export const AmountInput = styled.input`
  background-color: ${({ theme }) => theme.colors.page_background};
//   border-radius: ${({ theme }) => theme.border.radius_m};
  padding: 10px 15px;
  font-size: 14px;
  margin-top: 7px;
  border: none;
  height: 25px;
  width: 90%;
`;

export const PaymentSaveButton = styled.button`
  position: relative;
  background-color: ${({ theme }) => theme.colors.primary_red};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius_m};
  border: none;
  height: 50px;
  padding: 0;
  margin: 15px 5px 5px 5px;
  width: 96%;
`;
export const PaymentSaveButtonIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(12, 0, 0, 0.15);
  width: 60px;
  height: 35px;
  margin: 0;
  padding: 15px 0 0 0;
  border-radius: ${({ theme }) => theme.border.radius_m};
`;

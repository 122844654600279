import styled from 'styled-components';
import Select from 'react-select';

export const BlueBodySection = styled.div`
  padding: 10px 40px;
  background-color: ${({ theme }) => theme.colors.blue_background};
`;

export const WhiteBodySection = styled.div`
  padding: 10px 40px;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Panel = styled.div`
  width: 100%;
  border-radius: ${({ theme }) => theme.border.radius_m};
  margin: 20px ${(props) => props.marginRight || 0} 20px 0;
`;

export const PanelBody = styled.div`
  margin-left: 20px;
  .FieldCol {
    border: 1px solid ${({ theme }) => theme.colors.grey_blue};
    border-radius: ${({ theme }) => theme.border.radius_m};
  }
  .addMargin {
    margin-right: 17px;
    margin-left: 17px;
  }
`;

export const FieldLabel = styled.div`
  color:  ${({ theme }) => theme.colors.light_grey};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fonts.font_weight.bold_font};
  margin: 13px 22px 0px;
  opacity: 0.5;
`;
export const FieldLabelEdit = styled.div`
  color:  ${({ theme }) => theme.colors.light_grey};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fonts.font_weight.bold_font};
  margin: 10px 5px 0px;
  opacity: 0.5;
`;
export const FieldValue = styled.div`
  color:  ${({ theme }) => theme.colors.primary_blue};
  font-size: ${({ theme }) => theme.fonts.font_size.big_font};
  font-weight: ${({ theme }) => theme.fonts.font_weight.bold_font};
  margin: 0px 22px 8px;
`;
export const EditFieldValue = styled.div`
  color:  ${({ theme }) => theme.colors.primary_blue};
  font-size: ${({ theme }) => theme.fonts.font_size.big_font};
  font-weight: ${({ theme }) => theme.fonts.font_weight.bold_font};
  padding: 5px;
`;
export const EditIconDiv = styled.div`
  background: ${({ theme }) => theme.colors.page_background};
  width: 25px;
  height: 27px;
  border-radius: 7px;
  text-align: center;
  cursor: pointer;
  margin-left:300px;
`;
export const EditIconDivs = styled.div`
  background: ${({ theme }) => theme.colors.page_background};
  width: 25px;
  height: 27px;
  border-radius: 7px;
  text-align: center;
  cursor: pointer;
  margin-right:7px;
  float: right;
`;
export const CloseIconDiv = styled.div`
  background: ${({ theme }) => theme.colors.page_background};
  width: 25px;
  height: 27px;
  border-radius: 7px;
  text-align: center;
  cursor: pointer;
  margin-right:10px;
`;
export const TickIconDiv = styled.div`
  background: ${({ theme }) => theme.colors.page_background};
  width: 25px;
  height: 27px;
  border-radius: 7px;
  text-align: center;
  cursor: pointer;
  margin-right:2px;
`;
export const CloseIcon = styled.img`
  margin-top: 7px;
`;
export const TickIcon = styled.img`
  margin-top: 7px;
`;
export const EditIcons = styled.img`
  margin-top: 7px;
`;
export const EditIcon = styled.img`
  margin-top: 7px;
`;
export const FieldValueInput = styled.input`
  color:  ${({ theme }) => theme.colors.primary_blue};
  font-size: ${({ theme }) => theme.fonts.font_size.big_font};
  font-weight: 600;
  border: 1px solid ${({ theme }) => theme.colors.grey_blue};
  border-radius: 10px;
  width: 90%;
  padding: 5px 5px 5px;;
  &:disabled {
    background-color: inherit;
    border: none;
  }
`;
export const UserPhoto = styled.img`
  height: 400px;
  border-radius: ${({ theme }) => theme.border.radius_l};
  margin-left: 120px;
`;
export const CustomSelect = styled.select`
  border: 1px solid rgba(118, 124, 156, 0.2);
  background-color: ${({ theme }) => theme.colors.page_background};
  height: 25px;
  width: 90%;
  border-radius: 10px;
`;
export const TopCol = styled.div`
  min-width: ${(props) => props.minWidth || 0};
  height: 100%;
  text-align: center;
  margin-right: 20px;
  margin-top: 33px;
  position: relative;
  .profileEdit {
    position: absolute;
    left: 580px;
    top: 360px;
  }
`;
export const OtherInfoCustomSelect = styled(Select)`
  width: 90%;
  margin:  0;
  padding: 0 5px;
  .custom__control {
    // height: 1px;
    background-color: ${({ theme }) => theme.colors.page_background};
    border: 1px solid ${({ theme }) => theme.colors.white};
    border-radius: 10px;
    .custom__placeholder {
      font-size: 13px;
      color: ${({ theme }) => theme.colors.primary_blue};
    }
    .custom__single-value {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.primary_blue};
    }
    .custom__indicator-separator {
      display: none;
    }
  }
`;

import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from './providers/AuthProvider';
import { useTheme } from './theme/useTheme';
import { GlobalStyles } from './theme/GlobalStyles';
import Login from './pages/login';
import ForgotPassword from './pages/forgot_password';
import Accounting from './pages/accounting';
import Organisation from './pages/organisation';
import Activity from './pages/activity';
import Members from './pages/members';
import LayoutContainer from './components/layout';
import CreateReceipts from './pages/create/receipts';
import OrganisationReceipts from './pages/organisation_create/organisationreceipts';
import OrganisationPayments from './pages/organisation_create/organisationpayments';
import PaymentReceipts from './pages/create/payments';
import CreateJournal from './pages/create/journal';
import BankTransfer from './pages/create/bank_transfer';
import Budget from './pages/create/budget';
import ChurchReceipts from './pages/reports/church_receipts';
import './App.css';
import Contribution from './pages/contribution';
import Family from './pages/family';
import 'react-toastify/dist/ReactToastify.css';
import JournalVouchers from './pages/accounting/transactions/journal_vouchers';
import MemberDashboard from './pages/member_dashboard';
import TransactionReceipts from './pages/accounting/transactions/reciepts';
import TransactionsPayments from './pages/accounting/transactions/payments';
import OrganisationTransactionReceipts from './pages/organisation/transactions/receipts';
import OrganisationTransactionPayments from './pages/organisation/transactions/payments';
import EditBudget from './pages/accounting/transactions/edit_budget';
import Membership from './pages/membership';
import ChurchPayment from './pages/reports/churchPayment';
import TransactionsBankTransfer from './pages/accounting/transactions/bank_transfer';
import SubscriptionReport from './pages/reports/subscription_reports';
import ChartOfAccounts from './pages/accounting/chart_of_accounts';
import AddressApproval from './pages/members/address_approval';
import IdWiseStatement from './pages/reports/idwise_statement';
import IdWiseContributionLetter from './pages/reports/idWise_contribution_letter';
import ItemizedContributionList from './pages/reports/itemized-contribution-list';
import IncomeWiseStatement from './pages/reports/incomeWiseStatement';
import IncomeCombined from './pages/reports/income_combined';
import MemberDetails from './pages/member_details';
import TrialBalance from './pages/reports/trial_balance';
import CashFlow from './pages/reports/cash_flow';
import AddMember from './pages/add-member';
import ResetPassWord from './pages/reset_password';
import SummarizedReport from './pages/reports/summarized_report';
import GeneralLedger from './pages/reports/general-ledger';
import Reports from './pages/reports/reports_menu';
import GeneralBodyList from './pages/member_reports/general_body_list';
import PrayerGroup from './pages/member_reports/prayer_group';
import GeneralReports from './pages/member_reports/general_reports';
import AllMembersDetails from './pages/member_reports/label_reports/all_members_details';
import DeathReport from './pages/member_reports/general_reports/death_report';
import BudgetReport from './pages/reports/budget_report';
import OverBudgetReport from './pages/reports/over_non_budget_report';
import NewlyAddedMember from './pages/member_reports/general_reports/newly_added_member';
import MaleFemale from './pages/member_reports/general_reports/male_female';
import NewlyWeddedMembership from './pages/member_reports/general_reports/newly_wedded_membership';
import NewAddressChange from './pages/member_reports/general_reports/new_address_change';
import PrivacyPolicy from './pages/privacy_policy';
import EndUserLicenseAgreement from './pages/end_user_license_agreement';
import OrganisationReports from './pages/organisation_reports/reports_menu';
import OrganisationChurchPayments from './pages/organisation_reports/church_payment';
import OrganisationChurchReceipts from './pages/organisation_reports/church_receipts';

function App() {
  const { theme, themeLoaded } = useTheme();
  const [selectedTheme, setSelectedTheme] = useState(theme);
  useEffect(() => {
    setSelectedTheme(theme);
  }, [theme, themeLoaded]);
  return (
    <>
      {themeLoaded && (
        <ThemeProvider theme={selectedTheme}>
          <GlobalStyles />
          <ToastContainer />
          <AuthProvider>
            <BrowserRouter>
              <Routes>
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/forgot-password" element={<ForgotPassword />} />
                <Route
                  exact
                  path="/reset-password/:userid"
                  element={<ResetPassWord />}
                />
                <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route exact path="/end-user-license-agreement" element={<EndUserLicenseAgreement />} />
                <Route exact path="/" element={<LayoutContainer />}>
                  <Route exact path="/" element={<MemberDashboard />} />
                  <Route
                    exact
                    path="/contribution"
                    element={<Contribution />}
                  />
                  <Route exact path="/family" element={<Family />} />
                  <Route exact path="/activity" element={<Activity />} />
                  <Route exact path="/members" element={<Members />} />
                  <Route exact path="/membership" element={<Membership />} />
                  <Route exact path="/add-member" element={<AddMember />} />
                  <Route exact path="/member-reports/general-body-list" element={<GeneralBodyList />} />
                  <Route exact path="/member-reports/general-reports/prayer-group" element={<PrayerGroup />} />
                  {/* <Route exact path="/member-reports/birthday-reports" element={<GeneralRe
                    ports />} /> */}
                  <Route exact path="/member-reports/general-reports/death-report" element={<DeathReport />} />
                  <Route exact path="/member-reports/general-reports/male-female" element={<MaleFemale />} />
                  <Route exact path="/member-reports/general-reports/newly-added-member" element={<NewlyAddedMember />} />
                  <Route exact path="/member-reports/general-reports/new-address-change" element={<NewAddressChange />} />
                  <Route exact path="/member-reports/general-reports/newly-wedded-membership" element={<NewlyWeddedMembership />} />
                  <Route exact path="/member-reports/label-reports/birthday-report" element={<GeneralReports />} />
                  <Route exact path="/member-reports/label-reports/all-members-details" element={<AllMembersDetails />} />
                  <Route
                    exact
                    path="/accounting/transactions/journal-vouchers"
                    element={<JournalVouchers />}
                  />
                  <Route
                    exact
                    path="/accounting/transactions/bank-transfer"
                    element={<TransactionsBankTransfer />}
                  />
                  <Route exact path="/accounting" element={<Accounting />} />
                  <Route exact path="/accounting/create/receipts" element={<CreateReceipts />} />
                  <Route exact path="/accounting/create/payments" element={<PaymentReceipts />} />
                  <Route exact path="/accounting/create/journal" element={<CreateJournal />} />
                  <Route exact path="/accounting/create/bank-transfer" element={<BankTransfer />} />
                  <Route exact path="/accounting/create/budget" element={<Budget />} />
                  <Route exact path="/accounting/report" element={<Reports />} />
                  <Route exact path="/accounting/chartofaccounts" element={<ChartOfAccounts />} />
                  <Route exact path="/accounting/reports/member-idwise-report" element={<IdWiseStatement />} />
                  <Route exact path="/accounting/reports/idwise-contribution-letter-report" element={<IdWiseContributionLetter />} />
                  <Route exact path="/accounting/reports/itemized-contribution-list" element={<ItemizedContributionList />} />
                  <Route exact path="/accounting/report/incomewise-statement" element={<IncomeWiseStatement />} />
                  <Route exact path="/accounting/report/income-combined" element={<IncomeCombined />} />
                  <Route exact path="/accounting/report/churchpayments" element={<ChurchPayment />} />
                  <Route exact path="/accounting/report/churchreceipts" element={<ChurchReceipts />} />
                  <Route exact path="/accounting/report/summarizedreport" element={<SummarizedReport />} />
                  <Route exact path="/accounting/report/generalledger" element={<GeneralLedger />} />
                  <Route exact path="/accounting/report/trialbalance" element={<TrialBalance />} />
                  <Route exact path="/accounting/report/cashflow" element={<CashFlow />} />
                  <Route exact path="/accounting/report/budgetreport" element={<BudgetReport />} />
                  <Route exact path="/accounting/report/overbudgetreport" element={<OverBudgetReport />} />
                  <Route exact path="/organization" element={<Organisation />} />
                  <Route exact path="/organization/create/receipts" element={<OrganisationReceipts />} />
                  <Route exact path="/organization/create/payments" element={<OrganisationPayments />} />
                  <Route exact path="/organization/transactions/receipts" element={<OrganisationTransactionReceipts />} />
                  <Route exact path="/organization/transactions/payments" element={<OrganisationTransactionPayments />} />
                  <Route exact path="/organization/report" element={<OrganisationReports />} />
                  <Route exact path="/organization/report/church_payments" element={<OrganisationChurchPayments />} />
                  <Route exact path="/organization/report/church_receipts" element={<OrganisationChurchReceipts />} />
                  <Route exact path="/members/address-approval" element={<AddressApproval />} />
                  <Route exact path="/members/details/:id" element={<MemberDetails />} />
                  <Route
                    exact
                    path="/accounting/transactions/receipts"
                    element={<TransactionReceipts />}
                  />
                  <Route
                    exact
                    path="/accounting/transactions/payments"
                    element={<TransactionsPayments />}
                  />
                  <Route
                    exact
                    path="/accounting/transactions/edit-budget"
                    element={<EditBudget />}
                  />
                  <Route
                    exact
                    path="/accounting/reports/subscription-due"
                    element={<SubscriptionReport />}
                  />
                </Route>
              </Routes>
            </BrowserRouter>
          </AuthProvider>
        </ThemeProvider>
      )}
    </>
  );
}

export default App;

import styled from 'styled-components';
import Modal from 'styled-react-modal';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import Creatable from 'react-select/creatable';

export const ModalWrapper = styled.div`
  margin: 30px;
`;
export const StyledModal = Modal.styled`
  width: 900px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.border.radius_m}
`;
export const ModalHeading = styled.h2`
  margin: 10px 0px 20px 0px;
  font-family: 'Playfair Display', sans-serif;
  color: ${({ theme }) => theme.colors.primary_blue};
`;

export const SaveButton = styled.button`
  position: relative;
  background-color: ${({ theme }) => theme.colors.primary_red};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius_m};
  border: none;
  height: 45px;
  padding-right: 35px;
  margin: 15px 5px 5px 5px;
  width: 86%;
`;
export const SaveButtonIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(12, 0, 0, 0.15);
  width: 40px;
  height: 30px;
  margin: 0;
  padding: 15px 0 0 0;
  border-radius: ${({ theme }) => theme.border.radius_m};
`;

export const CloseIcon = styled.div`
  top: 0;
  width: 10px;
  margin: 0 0 40px 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.white}
  border-radius: ${({ theme }) => theme.border.radius_m};
`;
export const CloseButton = styled.button`
  height: 35px;
  border: none;
  float: right;
  background-color: ${({ theme }) => theme.colors.secondary_background};
  padding: 10px 20px 10px 15px;
  border-top-right-radius: ${({ theme }) => theme.border.radius_m};
  border-bottom-left-radius: ${({ theme }) => theme.border.radius_m};
  font-size: 12px;
`;

export const FieldValueInput = styled.input`
  color: ${({ theme }) => theme.colors.primary_blue};
  font-size: ${({ theme }) => theme.fonts.font_size.big_font};
  font-weight: 600;
  margin: 0px 12px 6px;
  border: none;
  border-radius: 6px;
  width: 75%;
  padding: 5px 5px 5px;
  &:disabled {
    background-color: ${({ theme }) => theme.colors.secondary_background};
    border: none;
  }
`;
export const Div = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grey_blue};
  border-radius: ${({ theme }) => theme.border.radius_m};
  min-height: 40px;
  margin: 10px;
`;
export const FieldLabel = styled.div`
  color: ${({ theme }) => theme.colors.light_grey};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fonts.font_weight.bold_font};
  margin: 8px 16px 0px;
  opacity: 0.5;
`;
export const Info = styled.div`
  margin: 0 0 0 15px;
  font-size: ${({ theme }) => theme.fonts.font_size.below_normal_font};
  color: ${({ theme }) => theme.colors.primary_red};
`;
export const CustomSelect = styled(Select)`
  width: 100%;
  margin: 5px 0;
  padding: 0 5px;
  .custom__control {
    height: 50px;
    background-color: ${({ theme }) => theme.colors.page_background};
    border: 1px solid ${({ theme }) => theme.colors.page_background};
    .custom__placeholder {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.primary_blue};
    }
    .custom__single-value {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.primary_blue};
    }
    .custom__indicator-separator {
      display: none;
    }
  }
`;
export const AddressBar = styled.textarea`
  margin: 5px 5px;
  padding: 5px 5px;
  height: 38px;
  width: 100%;
  resize: none;
  border-radius: 5px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary_blue};
  background-color: ${({ theme }) => theme.colors.page_background};
  border: 1px solid ${({ theme }) => theme.colors.page_background};
`;
export const CheckNumber = styled.input`
  margin: 5px 5px;
  height: 46px;
  width: 95%;
  border-radius: 5px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary_blue};
  background-color: ${({ theme }) => theme.colors.page_background};
  border: 1px solid ${({ theme }) => theme.colors.page_background};
`;
export const StyledDate = styled.input`
  margin: 5px 5px;
  height: 48px;
  width: ${(props) => props.width};
  padding-left: 3px;
  border-radius: 5px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary_blue};
  background-color: ${({ theme }) => theme.colors.page_background};
  border: 1px solid ${({ theme }) => theme.colors.page_background};
`;
export const SearchSelect = styled(AsyncSelect)`
  width: 100%;
  margin: 5px 0;
  padding: 0 5px;
  .custom__control {
    height: 50px;
    background-color: ${({ theme }) => theme.colors.page_background};
    border: 1px solid ${({ theme }) => theme.colors.page_background};
    .custom__placeholder {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.primary_blue};
    }
    .custom__single-value {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.primary_blue};
    }
    .custom__indicator-separator {
      display: none;
    }
  }
`;
export const CreateSelect = styled(Creatable)`
  width: 100%;
  margin: 5px 0;
  padding: 0 5px;
  .custom__control {
    height: 50px;
    background-color: ${({ theme }) => theme.colors.page_background};
    border: 1px solid ${({ theme }) => theme.colors.page_background};
    .custom__placeholder {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.primary_blue};
    }
    .custom__single-value {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.primary_blue};
    }
    .custom__indicator-separator {
      display: none;
    }
  }
`;

import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const ActivityWrapper = styled.div`
  margin-top: 25px;
`;
export const ActivityLoader = styled.h4`
  font-style: italic;
`;
export const ActivityPageLayout = styled.div`
  background-color: ${({ theme }) => theme.colors.page_background};;
  margin-top: 20px;
  height: 100%;
  padding: 15px 40px 30px 25px;
`;

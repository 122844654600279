import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import Cookies from 'js-cookie';
import {
  Col,
  Row,
} from '../../../components/styled_components/grid/Grid.style';
import ReportSubMenu from '../../../components/accounting/report_submenu';
import AccountingMenu from '../../../components/accounting/menu';
import {
  AccountingPageLayout,
  PageHeading,
} from '../../../theme/GlobalStyles';
import download from '../../../assets/icons/download.svg';
import {
  PageSubHeading, SearchInput, PaymentsDiv, DisplayButton, DownloadButton,
  DisplayButtonIcon, TotalAmtDiv, Total, DownloadButtonIcon, CustomSelect, Image, NoData,
} from './IncomeWiseStatement.style';
import Breadcrumbs from '../../../components/breadcrumbs';
import arrowRightIcon from '../../../assets/icons/arrow-right.svg';
import Table from '../../../components/common/table';
import { fetcher, useFetch, fileDownloader } from '../../../hooks/useFetch';
import { ACCT_API } from '../../../constants';
import Loader from '../../../components/styled_components/loader/loader.style';
import ScrollDownButton from '../../../utils/ScrollDownButton';
import ScrollUpButton from '../../../utils/ScrollUpButton';

const IncomeWiseStatement = () => {
  const breadcrumbs = ['Accounting', 'Reports', 'IncomeReports', 'Income-wise Statement'];
  const [data, setData] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectCategories, setSelectCategories] = useState([]);
  const [category, setCategory] = useState('');
  const [total, setTotalAmt] = useState(0);
  const [loading, setLoading] = useState(false);
  const minDate = Cookies.get('accountant_year');
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const handleView = async () => {
    setLoading(true);
    const res = await fetcher({
      url: `${ACCT_API.GET_INCOME_WISE_STATEMENT}?&name=${category}&startDate=${startDate}&endDate=${endDate}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    setData(res.response.map((item) => ({
      ...item, membershipId: item.membershipId.split('-')[0],
    })));
    setLoading(false);
    const rates = [];
    let amt = 0;
    res.response.map((item) => item.lines.map((i) => rates.push(i.rate)));
    if (rates.length > 0) {
      amt = rates.reduce((a, b) => a + b);
    }
    setTotalAmt(amt);
  };
  const categories = useFetch({
    url: ACCT_API.GET_SALES_ITEM,
    method: 'GET',
    authTokenKey: 'auth_token',
  });
  if (categories.data && selectCategories.length === 0) {
    setSelectCategories(
      categories.data.response.data
        .map((item) => ({
          value: item.accountName.replace('&', '%26'),
          label: item.accountName,
        })),
    );
  }
  const handleDownload = async () => {
    const res = await fileDownloader({
      url: `${ACCT_API.GET_INCOME_WISE_STATEMENT_DOWNLOAD}?name=${category}&startDate=${startDate}&endDate=${endDate}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    saveAs(new Blob([res], { type: 'application/vnd.ms-excel' }), 'Income statement.xlsx');
  };

  const handleDisplay = (e) => {
    e.preventDefault();
    handleView();
    setData(null);
  };

  const tableLookup = [
    {
      heading: 'Family ID',
      field_name: 'membershipId',
      type: 'number',
    },
    {
      heading: 'Member Name',
      field_name: 'memberName',
      type: 'string',
    },
    {
      heading: 'Date',
      field_name: 'date',
      type: 'date',
    },
    {
      heading: 'Payment Method',
      field_name: 'paymentMethod',
      type: 'string',
    },
    {
      heading: 'Amount',
      field_name: 'lines',
      type: 'rate',
    },
  ];

  return (
    <>
      <AccountingMenu />
      {loading ? <Loader /> : null}
      <ScrollUpButton />
      <ScrollDownButton />
      <AccountingPageLayout>
        <PageHeading>Reports</PageHeading>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <ReportSubMenu />
        <PageSubHeading>Income-wise Statement</PageSubHeading>

        <PaymentsDiv>
          <form onSubmit={handleDisplay}>
            <Row>
              <Col col={3}>
                <CustomSelect
                  className="select"
                  classNamePrefix="custom"
                  options={selectCategories}
                  onChange={(e) => setCategory(e.value)}
                  placeholder="Select Category"
                />
              </Col>

              <Col col={3}>
                <SearchInput
                  width="88%"
                  type="date"
                  min={`${minDate}-01-01`}
                  max={`${minDate}-12-31`}
                  placeholder="Start Date"
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Col>
              <Col col={3}>
                <SearchInput
                  width="88%"
                  type="date"
                  min={`${minDate}-01-01`}
                  max={`${minDate}-12-31`}
                  placeholder="End Date"
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Col>

              <Col col={3}>
                <DisplayButton
                  type="submit"
                  disabled={!(startDate && endDate)}
                >
                  Display
                  <DisplayButtonIcon>
                    <img src={arrowRightIcon} alt=">" height="16px" />
                  </DisplayButtonIcon>
                </DisplayButton>
              </Col>
            </Row>
          </form>
        </PaymentsDiv>
        {data && data.length > 0 ? (
          <>
            <Table data={data || []} tableLookup={tableLookup} />
            <TotalAmtDiv>
              <Row>
                <Col col={2}>

                  <DownloadButton
                    type="button"
                    onClick={handleDownload}
                  >
                    Download
                    <DownloadButtonIcon>
                      <Image src={download} alt=">" height="13px" marginTop="5px" />
                    </DownloadButtonIcon>
                  </DownloadButton>
                </Col>
                <Col col={10}>
                  <Total>
                    {`$ ${formatter.format(total)}`}
                  </Total>
                </Col>
              </Row>
            </TotalAmtDiv>
          </>
        ) : data && data.length === 0 ? (
          <NoData>
            No Data
          </NoData>
        ) : ''}
      </AccountingPageLayout>
    </>
  );
};

export default IncomeWiseStatement;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import moment from 'moment';
import Breadcrumbs from '../../../components/breadcrumbs';
import MemberReportsMenu from '../../../components/member_reports/menu';
import arrowRightIcon from '../../../assets/icons/arrow-right.svg';
import downloadArrow from '../../../assets/icons/download.svg';
import { AccountingPageLayout, PageHeading } from '../../../theme/GlobalStyles';
import {
  Row,
  Col,
} from '../../../components/styled_components/grid/Grid.style';
import RadioButtons from '../../../components/common/radio_buttons';
import { fetcher, fileDownloader } from '../../../hooks/useFetch';
import { API } from '../../../constants';
import Table from '../../../components/common/table';
import Loader from '../../../components/styled_components/loader/loader.style';
import {
  GeneralReportsDiv, DisplayButton, DisplayButtonIcon, StyledDate,
  DownloadButton, DownloadButtonIcon, DownloadImage, ButtonWrapper,
} from './generalReports.style';

const breadcrumbs = ['Reports', 'Birthday/Wedding'];
const GeneralReports = () => {
  const navigate = useNavigate();
  const [startMonth, setStartMonth] = useState(moment());
  const [endMonth, setEndMonth] = useState(moment());
  const [data, setData] = useState([]);
  const [select, setSelect] = useState('birthday');
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment());

  const handlestartDate = (e) => {
    setStartMonth({ value: e.target.value });
    setStartDate(e.target.value);
  };
  const handleSelectChange = (event) => {
    setSelect(event.target.value);
    setData([]);
  };
  const handleDisplay = async () => {
    if (!startMonth.value) {
      toast.error('Please select date', { autoClose: 3000 });
    } else if (!endMonth.value) {
      toast.error('Please select date', { autoClose: 3000 });
    } else {
      setLoading(true);
      const payload = (
        {
          sDate: startMonth.value.slice(5),
          eDate: endMonth.value.slice(5),
          selectValue: select,
        });
      localStorage.setItem('data', JSON.stringify(payload));
      const sDate = startMonth.value.slice(5);
      const eDate = endMonth.value.slice(5);
      const selectValue = select;
      try {
        const response = await fetcher({
          url: `${API.GET_ANNIVERSARY}?startDate=${sDate}&endDate=${eDate}&category=${selectValue}`,
          method: 'GET',
          authTokenKey: 'auth_token',
        });
        if (response) {
          setLoading(false);
          setData(response.anniversary_list);
        } else {
          setLoading(false);
          toast.error('Error!', { autoClose: 3000 });
        }
      } catch (e) {
        setLoading(false);
        toast.error('Error!', { autoClose: 3000 });
      }
    }
  };
  useEffect(async () => {
    const data = localStorage.getItem('data');
    if (data) {
      const newData = JSON.parse(data);
      setStartMonth(newData.sDate);
      setEndMonth(newData.eDate);
      setSelect(newData.selectValue);
      const sDate = `${newData.sDate}`;
      const eDate = `${newData.eDate}`;
      const select = `${newData.selectValue}`;
      try {
        const response = await fetcher({
          url: `${API.GET_ANNIVERSARY}?startDate=${sDate}&endDate=${eDate}&category=${select}`,
          method: 'GET',
          authTokenKey: 'auth_token',
        });
        if (response) {
          setLoading(false);
          setData(response.anniversary_list);
        } else {
          setLoading(false);
          toast.error('Error!', { autoClose: 3000 });
        }
      } catch (e) {
        setLoading(false);
        toast.error('Error!', { autoClose: 3000 });
      }
    }
  }, []);
  const tableLookup = [
    {
      heading: 'Membership Number',
      field_name: 'membershipId',
      type: 'id',
    },
    {
      heading: 'Name',
      field_name: 'name',
      type: 'link',
    },
    {
      heading: 'Prayer Group',
      field_name: 'prayerGroup',
      type: 'number',
    },
    {
      heading: 'Date of Birth',
      field_name: 'dob',
      type: 'date',
    },
    {
      heading: 'Phone',
      field_name: 'contactNumber',
      type: 'number',
    },
  ];
  const weddingLookup = [
    {
      heading: 'Membership Number',
      field_name: 'membershipId',
      type: 'id',
    },
    {
      heading: 'Name',
      field_name: 'name',
      type: 'link',
    },
    {
      heading: 'Prayer Group',
      field_name: 'prayerGroup',
      type: 'number',
    },
    {
      heading: 'Date of Wedding',
      field_name: 'dom',
      type: 'date',
    },
    {
      heading: 'Phone',
      field_name: 'contactNumber',
      type: 'number',
    },
  ];
  const handleClick = (id) => {
    navigate(`/members/details/${id}`);
  };

  const downloadReport = async () => {
    if (!startMonth.value) {
      toast.error('Please enter date', { autoClose: 3000 });
    } else if (!endMonth.value) {
      toast.error('Please enter a date', { autoClose: 3000 });
    } else {
      const sDate = startMonth.value.slice(5);
      const eDate = endMonth.value.slice(5);
      try {
        const download = await fileDownloader({
          url: `${API.DOWNLOAD_ANNIVERSARY_LIST}?startDate=${sDate}&endDate=${eDate}&category=${select}`,
          method: 'GET',
          authTokenKey: 'auth_token',
        });
        if (download) {
          saveAs(
            new Blob([download], { type: 'application/vnd.ms-excel' }),
            'Report.xlsx',
          );
        }
      } catch (e) {
        setLoading(false);
        toast.error('Error!', { autoClose: 3000 });
      }
    }
  };
  const labelDownloadReport = async () => {
    if (!startMonth.value) {
      toast.error('Please enter date', { autoClose: 3000 });
    } else if (!endMonth.value) {
      toast.error('Please enter a date', { autoClose: 3000 });
    } else {
      const sDate = startMonth.value.slice(5);
      const eDate = endMonth.value.slice(5);
      try {
        const download = await fileDownloader({
          url: `${API.DOWNLOAD_LABEL}?startDate=${sDate}&endDate=${eDate}&category=${select}`,
          method: 'GET',
          authTokenKey: 'auth_token',
        });
        if (download) {
          saveAs(
            new Blob([download], { type: 'application/vnd.ms-excel' }),
            'LabelReport.xlsx',
          );
        }
      } catch (e) {
        setLoading(false);
        toast.error('Error!', { autoClose: 3000 });
      }
    }
  };
  const pdfDownloadReport = async () => {
    if (!startMonth.value) {
      toast.error('Please enter date', { autoClose: 3000 });
    } else if (!endMonth.value) {
      toast.error('Please enter a date', { autoClose: 3000 });
    } else {
      const sDate = startMonth.value.slice(5);
      const eDate = endMonth.value.slice(5);
      try {
        const download = await fileDownloader({
          url: `${API.DOWNLOAD_PDF}?startDate=${sDate}&endDate=${eDate}&category=${select}`,
          method: 'GET',
          authTokenKey: 'auth_token',
        });
        if (download) {
          saveAs(
            new Blob([download], { type: 'application/vnd.pdf' }),
            'PdfReport.pdf',
          );
        }
      } catch (e) {
        setLoading(false);
        toast.error('Error!', { autoClose: 3000 });
      }
    }
  };
  return (
    <>
      <MemberReportsMenu />
      {loading ? <Loader /> : null}
      <AccountingPageLayout>
        <PageHeading>Birthday/Wedding Reports</PageHeading>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <GeneralReportsDiv>
          <Row>
            <Col col={2}>
              <RadioButtons
                label1="Birthday"
                value1="birthday"
                label2="Wedding"
                value2="wedding"
                marginTop="5px"
                handleSelectChange={handleSelectChange}
                select={select}
              />
            </Col>
            <Col col={1.5}>
              <StyledDate
                type="date"
                onChange={handlestartDate}
              />
            </Col>
            <Col col={1.5}>
              <StyledDate
                type="date"
                min={startDate}
                onChange={(e) => setEndMonth({ value: e.target.value })}
              />
            </Col>
            <Col col={2}>
              <ButtonWrapper>
                <DownloadButton type="button" onClick={() => pdfDownloadReport()}>
                  Pdf Download
                  <DownloadButtonIcon>
                    <DownloadImage src={downloadArrow} alt=">" height="10px" />
                  </DownloadButtonIcon>
                </DownloadButton>
              </ButtonWrapper>
            </Col>
            <Col col={2}>
              <ButtonWrapper>
                <DownloadButton type="button" onClick={() => labelDownloadReport()}>
                  Label Download
                  <DownloadButtonIcon>
                    <DownloadImage src={downloadArrow} alt=">" height="10px" />
                  </DownloadButtonIcon>
                </DownloadButton>
              </ButtonWrapper>
            </Col>
            <Col col={2}>
              <ButtonWrapper>
                <DownloadButton type="button" onClick={() => downloadReport()}>
                  Download
                  <DownloadButtonIcon>
                    <DownloadImage src={downloadArrow} alt=">" height="10px" />
                  </DownloadButtonIcon>
                </DownloadButton>
              </ButtonWrapper>
            </Col>
            <Col col={1}>
              <DisplayButton type="button" onClick={() => handleDisplay()}>
                Display
                <DisplayButtonIcon>
                  <img src={arrowRightIcon} alt=">" height="16px" />
                </DisplayButtonIcon>
              </DisplayButton>
            </Col>
          </Row>
        </GeneralReportsDiv>
        {data.length > 0 ? (
          <Table
            data={data}
            tableLookup={select === 'birthday' ? tableLookup : weddingLookup}
            handleClick={handleClick}
          />
        ) : null}
      </AccountingPageLayout>
    </>
  );
};
export default GeneralReports;

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import { useNavigate, useParams } from 'react-router';
import { Col, Row } from '../../components/styled_components/grid/Grid.style';
import prayingHandsImage from '../../assets/img/praying_hands.png';
import logo from '../../assets/img/logo.svg';
import passwordIcon from '../../assets/icons/password.svg';
import arrowRightIcon from '../../assets/icons/arrow-right.svg';
import MobileResetPassword from '../mobile_view/reset_password';
import {
  Logo,
  ChurchName,
  LoginInput,
  LoginIcon,
  LoginButtonIcon,
  LoginButton,
  LoginLabel,
  LoginInputWrapper,
  LoginHeader,
  LoginPagePrayingHands,
  LoginLoader,
} from './resetPassword.style';
import { fetcher } from '../../hooks/useFetch';
import { API } from '../../constants';

const ResetPassWord = () => {
  const { userid } = useParams();
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setLoading(true);
    const payload = {
      userid, newPassword, confirmPassword,
    };
    try {
      const response = await fetcher({
        url: API.RESET_PASSWORD,
        method: 'POST',
        body: JSON.stringify(payload),
      });
      if (response.success) {
        toast.success('Successfully changed', { autoClose: 3000 });
        navigate('/login');
        setLoading(false);
      }
    } catch (e) {
      if (e.status === 401) {
        toast.error('Error!');
        setLoading(false);
      } else {
        toast.error('Error! Technical error occurred');
        setLoading(false);
      }
    }
  };

  const handleValidate = (e) => {
    e.preventDefault();
    if (newPassword === confirmPassword) {
      handleSubmit();
    } else {
      toast.warning('Passwords not matching', { autoClose: 3000 });
    }
  };
  if (isMobile) {
    return <MobileResetPassword />;
  }
  return (
    <>
      <LoginPagePrayingHands src={prayingHandsImage} alt="" />
      <Row justifyCenter="center">
        <Col col={4} />
        <Col col={4} textAlign="center">
          <Logo src={logo} />
        </Col>
      </Row>
      <Row>
        <Col col={4} />
        <Col col={4} textAlign="center">
          <ChurchName>Trinity Marthoma Church, Houston</ChurchName>
        </Col>
      </Row>
      <Row>
        <Col col={4} />
        <Col col={4}>
          <LoginHeader>Reset Password</LoginHeader>
        </Col>
      </Row>
      <form onSubmit={handleValidate}>
        <Row>
          <Col col={4} />
          <Col col={4}>
            <LoginInputWrapper>
              <LoginLabel htmlFor="email">New Password</LoginLabel>
              <LoginInput
                id="email"
                name="newPassword"
                type="password"
                placeholder="********"
                required="true"
                minLength="6"
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <LoginIcon src={passwordIcon} height="32px" alt="" />
            </LoginInputWrapper>
          </Col>
        </Row>
        <Row marginTop="20px" marginBottom="20px">
          <Col col={4} />
          <Col col={4}>
            <LoginInputWrapper>
              <LoginLabel htmlFor="email">Confirm Password</LoginLabel>
              <LoginInput
                id="email"
                name="confirmPassword"
                type="password"
                placeholder="********"
                required="true"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <LoginIcon src={passwordIcon} height="32px" alt="" />
            </LoginInputWrapper>
          </Col>
        </Row>
        <Row marginTop="30px">
          <Col col={4} />
          <Col col={4}>
            <LoginButton type="submit">
              {loading ? <LoginLoader /> : 'Reset Password'}
              <LoginButtonIcon>
                <img src={arrowRightIcon} alt=">" height="16px" />
              </LoginButtonIcon>
            </LoginButton>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default ResetPassWord;

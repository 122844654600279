import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import Cookies from 'js-cookie';
import {
  Col,
  Row,
} from '../../../components/styled_components/grid/Grid.style';
import ReportSubMenu from '../../../components/accounting/report_submenu';
import AccountingMenu from '../../../components/accounting/menu';
import {
  AccountingPageLayout,
  PageHeading,
  PageSubHeading,
} from '../../../theme/GlobalStyles';
import {
  SearchInput, StatementDiv, DisplayButton,
  DisplayButtonIcon, TotalAmtDiv, Total,
  DownloadButtonIcon, DownloadButton, Image, SearchSelect, NoData,
} from './IdWiseStatement.style';
import Breadcrumbs from '../../../components/breadcrumbs';
import arrowRightIcon from '../../../assets/icons/arrow-right.svg';
import Table from '../../../components/common/table';
import RadioButtons from '../../../components/common/radio_buttons';
import download from '../../../assets/icons/download.svg';
import { ACCT_API, API } from '../../../constants';
import { fetcher, fileDownloader } from '../../../hooks/useFetch';
import Loader from '../../../components/styled_components/loader/loader.style';
import ScrollDownButton from '../../../utils/ScrollDownButton';
import ScrollUpButton from '../../../utils/ScrollUpButton';

const IdWiseStatement = () => {
  const [select, setSelect] = useState('family_wise');
  const handleSelectChange = (event) => {
    setSelect(event.target.value);
  };
  const breadcrumbs = ['Accounting', 'Reports', 'Member Reports', 'ID-wise Statement'];
  const [data, setData] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [memberId, setMemberId] = useState();
  const [total, setTotalAmt] = useState(0);
  const [loading, setLoading] = useState(false);
  const minDate = Cookies.get('accountant_year');
  const getName = async (value) => {
    const res = await fetcher({
      url: `${API.GET_MEMBER_LIST}?query=${value}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    const memberNames = res.member_list.map((item) => ({
      value: item.membershipNumber,
      label: item.name,
    }));
    if (value.match(/0/i) || value.match(/non/i)) {
      return [...memberNames, { value: '0001', label: '0001 Non Member' }];
    } else {
      return memberNames;
    }
  };
  const getHead = async (value) => {
    const res = await fetcher({
      url: `${API.GET_MEMBER_LIST}?query=${value}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    const memberNames = res.member_list.map((item) => ({
      value: item.membershipNumber.split('-')[0],
      label: item.name,
    }));

    return memberNames;
  };
  const handleView = async () => {
    setLoading(true);
    if (select === 'member_wise') {
      const res = await fetcher({
        url: `${ACCT_API.GET_IDWISE_STATEMENT}?membershipId=${memberId}&startDate=${startDate}&endDate=${endDate}&family=false`,
        method: 'GET',
        authTokenKey: 'auth_token',
      });
      const arr = res.receipts.filter((x) => x.notes !== 'Voided');
      setData(arr);
      let amt = 0;
      res.receipts.forEach((item) => {
        amt += item.amount;
      });
      setTotalAmt(amt);
    } else {
      const res = await fetcher({
        url: `${ACCT_API.GET_IDWISE_STATEMENT}?membershipId=${memberId}&startDate=${startDate}&endDate=${endDate}&family=true`,
        method: 'GET',
        authTokenKey: 'auth_token',
      });
      const arr = res.receipts.filter((x) => x.notes !== 'Voided');
      setData(arr);
      let amt = 0;
      res.receipts.forEach((item) => {
        amt += item.amount;
      });
      setTotalAmt(amt);
    }
    setLoading(false);
  };
  const handleDownload = async () => {
    if (select === 'member_wise') {
      const res = await fileDownloader({
        url: `${ACCT_API.GET_IDWISE_STATEMENT_DOWNLOAD}?membershipId=${memberId}&startDate=${startDate}&endDate=${endDate}&family=false`,
        method: 'GET',
        authTokenKey: 'auth_token',
      });
      saveAs(new Blob([res], { type: 'application/vnd.ms-excel' }), 'IDwise Statement.pdf');
    } else {
      const res = await fileDownloader({
        url: `${ACCT_API.GET_IDWISE_STATEMENT_DOWNLOAD}?membershipId=${memberId}&startDate=${startDate}&endDate=${endDate}&family=true`,
        method: 'GET',
        authTokenKey: 'auth_token',
      });
      saveAs(new Blob([res], { type: 'application/vnd.ms-excel' }), 'IDwise Statement.pdf');
    }
  };

  const handleDisplay = (e) => {
    e.preventDefault();
    handleView();
    setData();
  };

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const tableLookup = [
    {
      heading: 'Recipient Number',
      field_name: 'id',
      type: 'number',
    },
    {
      heading: 'Date',
      field_name: 'date',
      type: 'date',
    },
    {
      heading: 'Payment Method',
      field_name: 'paymentMethod',
      type: 'string',
    },
    {
      heading: 'Category',
      field_name: 'lines',
      type: 'array',
    },
    {
      heading: 'Amount',
      field_name: 'lines',
      type: 'rate',
    },
  ];

  return (
    <>
      <AccountingMenu />
      {loading ? <Loader /> : null}
      <ScrollUpButton />
      <ScrollDownButton />
      <AccountingPageLayout>
        <PageHeading>Reports</PageHeading>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <ReportSubMenu />
        <PageSubHeading>ID-wise Statement</PageSubHeading>
        <StatementDiv>
          <form onSubmit={handleDisplay}>
            <Row>
              <Col width="27%" marginLeft="5px">
                <RadioButtons
                  label1="Family Wise"
                  value1="family_wise"
                  label2="Member Wise"
                  value2="member_wise"
                  marginTop="5px"
                  handleSelectChange={handleSelectChange}
                  select={select}
                />
              </Col>
              <Col width="20%">
                {select === 'member_wise' ? (

                  <SearchSelect
                    className="select"
                    classNamePrefix="custom"
                    placeholder="Enter Name or Family Id"
                    loadOptions={(value) => getName(value)}
                    onChange={(e) => {
                      setMemberId(e.value);
                    }}
                  />
                ) : (
                  <SearchSelect
                    className="select"
                    classNamePrefix="custom"
                    placeholder="Enter Family Id"
                    loadOptions={(value) => getHead(value)}
                    onChange={(e) => {
                      setMemberId(e.value);
                    }}
                  />
                )}
              </Col>
              <Col width="20%">
                <SearchInput
                  type="date"
                  min={`${minDate}-01-01`}
                  max={`${minDate}-12-31`}
                  placeholder="Start Date"
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Col>
              <Col width="20%">
                <SearchInput
                  type="date"
                  min={`${minDate}-01-01`}
                  max={`${minDate}-12-31`}
                  placeholder="End Date"
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Col>
              <Col width="12%">
                <DisplayButton
                  type="submit"
                  disabled={!(startDate && endDate)}
                >
                  Display
                  <DisplayButtonIcon>
                    <img src={arrowRightIcon} alt=">" height="16px" />
                  </DisplayButtonIcon>
                </DisplayButton>
              </Col>
            </Row>
          </form>
        </StatementDiv>
        {data && data.length > 0 ? (
          <>
            <Table data={data || []} tableLookup={tableLookup} />
            <TotalAmtDiv>
              <Row>
                <Col col={2}>
                  <DownloadButton
                    type="button"
                    onClick={() => handleDownload()}
                  >
                    Download
                    <DownloadButtonIcon>
                      <Image
                        src={download}
                        alt=">"
                        height="13px"
                        marginTop="5px"
                      />
                    </DownloadButtonIcon>
                  </DownloadButton>
                </Col>
                <Col col={10}>
                  <Total>
                    $
                    {formatter.format(total)}
                  </Total>
                </Col>
              </Row>
            </TotalAmtDiv>
          </>
        ) : data && data.length === 0 ? (
          <NoData>
            No Data
          </NoData>
        ) : ''}
      </AccountingPageLayout>
    </>
  );
};

export default IdWiseStatement;

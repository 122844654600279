import styled from 'styled-components';
import Select from 'react-select';

export const StyledDate = styled.input`
  padding: 0px 7px;
  margin: 6px 7px;
  height: 48px;

  width: ${(props) => props.width};
  border-radius: ${({ theme }) => theme.border.radius_m};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary_blue};
  background-color: ${({ theme }) => theme.colors.page_background};
  border: 1px solid ${({ theme }) => theme.colors.page_background};
`;
export const PaymentsDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 5px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 4%);
  border-radius: ${({ theme }) => theme.border.radius_m};
`;
export const DisplayButton = styled.button`
  position: relative;
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  background-color: ${({ theme }) => theme.colors.primary_red};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius_m};
  border: none;
  height: 50px;
  padding: 0;
  margin: 5px 6px;
  width: 85%;
  float: right;
`;
export const DisplayButtonIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(12, 0, 0, 0.15);
  width: 42px;
  height: 35px;
  margin: 0;
  padding: 15px 0 0 0;
  border-radius: ${({ theme }) => theme.border.radius_m};
`;
export const TotalAmtDiv = styled.div`
  height: 50px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius_m};
`;
export const Total = styled.div`
  font-size: 22px;
  font-weight: 600;
  line-height: 50px;
  text-align: right;
  margin-right: 12px;
  color: ${({ theme }) => theme.colors.primary_blue};
`;
export const Menu = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.secondary_background};
  border-top-left-radius: ${(props) => props.topLeftBorderRadius || 0};
  font-size: 14px;
  line-height: 48px;
  color: ${({ theme }) => theme.colors.primary_blue};
  cursor: pointer;
  padding: 0px 10px;
  margin: 5px 7px;
  height: 48px;
  border-radius: ${({ theme }) => theme.border.radius_m};
  color: ${({ theme }) => theme.colors.primary_blue};
  background-color: ${({ theme }) => theme.colors.page_background};

  .dropdown {
    position: relative;
  }
`;

export const DropdownContent = styled.div`
  display: ${(props) => props.display || 'none'};
  position: absolute;
  left: 0;
  top: 80px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.secondary_background};
  width: 100%;
  z-index: 1;
  text-align: left;

  p {
    padding: 0 0 0 100px;
    margin: 0;
    cursor: pointer;
    border-bottom: 1px solid ${({ theme }) => theme.colors.secondary_background};
  }

  p > a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary_blue};
  }
`;
export const ChurchPaymentsCustomSelect = styled(Select)`
  width: 100%;
  margin: 6px 0;
  padding: 0 5px;
  .custom__control {
    height: 48px;
    background-color: ${({ theme }) => theme.colors.page_background};
    border: 1px solid ${({ theme }) => theme.colors.page_background};
    border-radius: 10px;
    .custom__placeholder {
      font-size: 13px;
      color: ${({ theme }) => theme.colors.primary_blue};
    }
    .custom__single-value {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.primary_blue};
    }
    .custom__indicator-separator {
      display: none;
    }
  }
`;

export const VendorsCustomSelect = styled(Select)`
  width: 100%;
  margin: 6px 0;
  padding: 0 5px;
  .custom__control {
    height: 48px;
    background-color: ${({ theme }) => theme.colors.page_background};
    border: 1px solid ${({ theme }) => theme.colors.page_background};
    border-radius: 10px;
    .custom__placeholder {
      font-size: 13px;
      color: ${({ theme }) => theme.colors.primary_blue};
    }
    .custom__single-value {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.primary_blue};
    }
    .custom__indicator-separator {
      display: none;
    }
  }
`;
export const SearchInput = styled.input`
  background-color: ${({ theme }) => theme.colors.page_background};
  border-radius: ${({ theme }) => theme.border.radius_m};
  padding: 10px 15px;
  margin-top: 7px;
  border: none;
  height: 25px;
  width: 80%;
  float: right;
`;
export const DownloadButton = styled.button`
  border: 1px solid rgba(118, 124, 156, 0.4);
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Archivo;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 12px;
  background: #fff;
  float: left;
  margin: 0.5rem 1rem 1rem;
  padding: 0.5rem;
`;
export const DownloadIcon = styled.span`
  float: right;
  margin-left: 1rem;
`;
export const NoData = styled.div`
  font-weight:${({ theme }) => theme.fonts.font_weight.normal_font};
  color : ${({ theme }) => theme.colors.primary_blue};
  font-size: 20px;
  margin-top: 20px;
`;

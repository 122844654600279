import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from '../../styled_components/grid/Grid.style';
import {
  RadioDiv, RadioButton, RadioButtonLabel, RadioButtonLabelDiv, VerticalLine, RadioButtonWrapper,
} from './RadioButtons.style';

const RadioButtons = ({
  label1, value1, label2, value2, marginTop, handleSelectChange, select,
}) => (
  <RadioDiv marginTop={marginTop}>
    <Row>
      <Col col={5}>
        <RadioButtonWrapper onClick={() => handleSelectChange({ target: { value: value1 } })}>
          <Row>
            <Col col={3}>
              <RadioButton
                type="radio"
                name="radio"
                value={value1}
                checked={select === value1}
              />
              <RadioButtonLabel />
            </Col>
            <Col col={9}>
              <RadioButtonLabelDiv color={select === value1 ? '#EC1C24' : '#393E5C'}>
                {label1}
              </RadioButtonLabelDiv>
            </Col>
          </Row>
        </RadioButtonWrapper>
      </Col>
      <Col col={1} textAlign="-webkit-center">
        <VerticalLine />
      </Col>
      <Col col={6}>
        <RadioButtonWrapper onClick={() => handleSelectChange({ target: { value: value2 } })}>
          <Row>
            <Col col={3}>
              <RadioButton
                type="radio"
                name="radio"
                value={value2}
                checked={select === value2}
              />
              <RadioButtonLabel />
            </Col>
            <Col col={9}>
              <RadioButtonLabelDiv color={select === value2 ? '#EC1C24' : '#393E5C'}>{label2}</RadioButtonLabelDiv>
            </Col>
          </Row>
        </RadioButtonWrapper>
      </Col>
    </Row>
  </RadioDiv>
);
RadioButtons.propTypes = {
  label1: PropTypes.string,
  value1: PropTypes.string,
  label2: PropTypes.string,
  value2: PropTypes.string,
  marginTop: PropTypes.string,
  handleSelectChange: PropTypes.func,
  select: PropTypes.string,
};
export default RadioButtons;

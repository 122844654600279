import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const LoginPagePrayingHands = styled.img`
  position: absolute;
  height: 600px;
  bottom: 0;
  left: 0;
`;

export const Logo = styled.img`
  margin-top: 92px;
  height: 157px;
  width: 157px;
`;

export const ChurchName = styled.h5`
  color: #353d65;
  font-size: 16px;
  margin-top: -10px;
`;

export const LoginHeader = styled.h1`
  font-family: 'Playfair Display', serif;
  color: ${(props) => props.theme.colors.primary_blue};
`;

export const LoginInputWrapper = styled.div`
  position: relative;
  border: 1px solid #e4e8f4;
  border-radius: 15px;
  height: 60px;
  padding: 10px;
`;

export const LoginLabel = styled.label`
  display: block;
  font-family: 'Playfair Display', serif;
  font-size: 18px;
  color: ${(props) => props.theme.colors.primary_blue};
  opacity: 0.4;
`;

export const LoginInput = styled.input`
  width: 90%;
  border: none;
  height: 40px;
  &:focus {
    outline: none;
  }
`;

export const LoginIcon = styled.img`
  position: absolute;
  right: 10px;
  height: 32px;
`;

export const LoginButton = styled.button`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary_red};
  height: 70px;
  border: none;
  border-radius: 15px;
  font-family: 'Playfair Display', serif;
  color: #ffffff;
  font-size: 24px;
  padding: 20px 0;
`;

export const LoginButtonIcon = styled.div`
  display: inline;
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(12, 0, 0, 0.15);
  width: 70px;
  height: 50px;
  padding-top: 20px;
  border-radius: 15px;
`;
export const LoginLoader = styled.div`
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 4px solid ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  border-top-color: ${({ theme }) => theme.colors.primary_red};
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  margin: -5px 0 0 0px;
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from '../../styled_components/grid/Grid.style';
import {
  MenuWrapper, Menu,
} from './ReportSubmenu.style';

const ReportSubMenu = () => {
  const navigate = useNavigate();
  const [displayCreateMenu, setDisplayCreateMenu] = useState('none');
  const showChurchReport = () => {
    if (displayCreateMenu === 'block') {
      setDisplayCreateMenu('none');
    } else {
      setDisplayCreateMenu('block');
    }
  };
  document.addEventListener('click', (e) => {
    if (document.getElementById('churchmenubox')?.contains(e.target)) {
      setDisplayCreateMenu('block');
    } else {
      setDisplayCreateMenu('none');
    }
  });
  const handleClick = (link) => {
    navigate(link);
  };
  return (
    <>
      <MenuWrapper>
        <Row>
          <Col width="50%">
            <Menu id="churchmenubox" topLeftBorderRadius="40px" marginRight="7px">
              <Row className="dropdown" onClick={showChurchReport}>
                <Col col={11} onClick={() => handleClick('/organization/report/church_receipts')}>Church Receipts</Col>
              </Row>
            </Menu>
          </Col>
          <Col width="50%">
            <Menu id="churchmenubox" topLeftBorderRadius="40px" marginRight="7px">
              <Row className="dropdown" onClick={showChurchReport}>
                <Col col={11} onClick={() => handleClick('/organization/report/church_payments')}>Church Payments</Col>
              </Row>
            </Menu>
          </Col>
        </Row>
      </MenuWrapper>
    </>
  );
};
export default ReportSubMenu;

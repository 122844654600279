/* eslint-disable no-multi-spaces */
import React from 'react';
import PropTypes from 'prop-types';
import PanelHeading from '../../membership_components/panel_heading';
import { Row, Col } from '../../styled_components/grid/Grid.style';
import {
  OtherInfoLayout,
  Panel,
  PanelBody,
  FieldLabel,
  FieldValue,
  Div,
  OtherInfoCustomSelect,
  Required,
} from './OtherInfo.style';

const OtherInfo = ({
  formType, caller, areaName, handleChange, text, type,
}) => (
  <>
    <OtherInfoLayout>
      <Row>
        <Panel marginRight="20px">
          <PanelHeading label="Other Information" />
          <PanelBody>
            <Row marginBottom="17px">
              {caller === 'baptism' ? (
                <>
                  {formType === 'new' ? (
                    <Col col={4}>
                      <Div className="addMargin">
                        <OtherInfoCustomSelect
                          className="select"
                          classNamePrefix="custom"
                          options={areaName}
                          name="areaId"
                          onChange={(event) => handleChange({
                            target: { name: 'areaId', value: event.value },
                          })}
                          placeholder={(
                            <>
                              Area Name
                              {' '}
                              <Required>*</Required>
                            </>
)}
                        />
                      </Div>
                    </Col>
                  ) : null}
                  <Col col={4}>
                    <Div className="addMargin">
                      <FieldLabel>
                        God Parent
                        <Required>{type === 'new' ? '' : '*'}</Required>
                      </FieldLabel>
                      <FieldValue
                        name="godParent"
                        onChange={handleChange}
                        type="text"
                        value={text.godParent}
                      />
                    </Div>
                  </Col>
                </>
              ) : caller === 'transfer' && formType === 'new' ? (
                <>
                  <Col col={4}>
                    <Div className="addMargin">
                      <OtherInfoCustomSelect
                        className="select"
                        classNamePrefix="custom"
                        name="areaName"
                        options={areaName}
                        onChange={(event) => handleChange({
                          target: { name: 'areaName', value: event.value },
                        })}
                        placeholder="Area Name"
                      />
                    </Div>
                  </Col>
                  <Col col={4}>
                    <Div className="addMargin">
                      <FieldLabel>Date Of Wedding</FieldLabel>
                      <FieldValue
                        type="date"
                        placeholder="Date of wedding"
                        name="dom"
                        value={text.dom}
                        onChange={handleChange}
                      />
                    </Div>
                  </Col>
                </>
              ) : (

                ''
              )}
              {formType === 'existing' ? (
                <>
                  {/* <Col col={4}>
                    <Div className="addMargin">
                      <FieldLabel>Date Of Wedding</FieldLabel>
                      <FieldValue
                        type="date"
                        placeholder="Date of wedding"
                        name="dom"
                        onChange={handleChange}
                      />
                    </Div>
                  </Col> */}
                </>
              ) : (
                ''
              )}
            </Row>
          </PanelBody>
        </Panel>
      </Row>
    </OtherInfoLayout>
  </>
);
OtherInfo.propTypes = {
  formType: PropTypes.string,
  caller: PropTypes.string,
  areaName: PropTypes.array,
  handleChange: PropTypes.func,
  text: PropTypes.object,
  type: PropTypes.string,
};
export default OtherInfo;

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import Cookies from 'js-cookie';
import {
  Col,
  Row,
} from '../../../components/styled_components/grid/Grid.style';
import ReportSubMenu from '../../../components/organisation/reports_submenu';
import OrganisationMenu from '../../../components/organisation/menu';
import {
  AccountingPageLayout,
  PageHeading,
  PageSubHeading,
} from '../../../theme/GlobalStyles';
import {
  PaymentsDiv,
  DisplayButton,
  DisplayButtonIcon,
  TotalAmtDiv,
  Total,
  SearchInput,
  DownloadButton,
  DownloadIcon,
  DownloadImage,
  NoData,
  CustomSelect,
} from './payment.style';
import { ACCT_API } from '../../../constants';
import downloadArrow from '../../../assets/icons/download.svg';
import Breadcrumbs from '../../../components/breadcrumbs';
import arrowRightIcon from '../../../assets/icons/arrow-right.svg';
import Table from '../../../components/common/table';
import { fetcher, useFetch, fileDownloader } from '../../../hooks/useFetch';
import Loader from '../../../components/styled_components/loader/loader.style';
import ScrollDownButton from '../../../utils/ScrollDownButton';
import ScrollUpButton from '../../../utils/ScrollUpButton';

const breadcrumbs = ['Accounting', 'Reports', 'ChurchPayments'];
const tableLookup = [
  {
    heading: 'Date',
    field_name: 'date',
    type: 'date',
  },
  {
    heading: 'Voucher Num',
    field_name: 'id',
    type: 'number',
  },
  {
    heading: 'Organization',
    field_name: 'organization',
    type: 'string',
  },
  {
    heading: 'Vendor',
    field_name: 'paidTo',
    type: 'string',
  },
  {
    heading: 'Amount',
    field_name: 'amount',
    type: 'amount',
  },
];

const ChurchPayment = () => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [vendors, setVendors] = useState([{ label: 'All', value: 'All' }]);
  const [total, setTotal] = useState(0);
  const [payments, setPayments] = useState();
  const [loading, setLoading] = useState(false);
  const [organization, setOrganization] = useState();
  const minDate = Cookies.get('accountant_year');
  const org = Cookies.get('organization').split(',');
  const org_values = org.map((item) => item.split('-'));
  const org_val = org_values.map((item) => item[1]);
  const values = org_val.map((item) => ({
    value: item,
    label: item,
  }));
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const vendorsData = useFetch({
    method: 'GET',
    url: `${ACCT_API.GET_VENDOR}`,
    authTokenKey: 'auth_token',
  });
  useEffect(() => {
    if (vendorsData?.data?.response) {
      setVendors([
        ...vendors,
        ...vendorsData?.data?.response.map((item) => ({
          value: item.displayName,
          label: item.displayName,
        })),
      ]);
    }
  }, [vendorsData?.data?.response]);
  const handleDisplay = async (e) => {
    e?.preventDefault();
    setLoading(true);
    try {
      const result = await fetcher({
        method: 'GET',
        url: `${ACCT_API.GET_CHURCH_ORG_PAYMENTS}?startDate=${startDate}&endDate=${endDate}&organization=${organization}`,
        authTokenKey: 'auth_token',
      });
      if (result?.response) {
        const arr = result?.response.filter((x) => x.notes !== 'Voided');
        setPayments(arr);
        setLoading(false);
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };

  const handleDownload = async () => {
    const res = await fileDownloader({
      url: `${ACCT_API.CHURCH_PAYMENTS_ORG_DOWNLOAD}?startDate=${startDate}&endDate=${endDate}&organization=${organization}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    saveAs(new Blob([res], { type: 'application/vnd.ms-excel' }), 'myExcel.xlsx');
  };
  useEffect(() => {
    if (payments) {
      let sum = 0;
      for (const i of payments) {
        sum += i.amount;
      }
      setTotal(sum);
    }
  }, [payments]);
  return (
    <>
      <OrganisationMenu />
      {loading ? <Loader /> : null}
      <ScrollUpButton />
      <ScrollDownButton />
      <AccountingPageLayout>
        <PageHeading>Reports</PageHeading>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <ReportSubMenu />
        <PageSubHeading>Church Payments</PageSubHeading>
        <PaymentsDiv>
          <form onSubmit={handleDisplay}>
            <Row>
              <Col col={3}>
                <SearchInput
                  type="date"
                  min={`${minDate}-01-01`}
                  max={`${minDate}-12-31`}
                  placeholder="Start Date"
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Col>
              <Col col={3}>
                <SearchInput
                  type="date"
                  min={`${minDate}-01-01`}
                  max={`${minDate}-12-31`}
                  placeholder="End Date"
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Col>
              <Col col={2}>
                <CustomSelect
                  className="select"
                  classNamePrefix="custom"
                  options={values}
                  placeholder="Organization"
                  onChange={(e) => setOrganization(e.value)}
                />
              </Col>
              <Col col={2}>
                <DownloadButton onClick={handleDownload}>
                  Download
                  <DownloadIcon>
                    <DownloadImage src={downloadArrow} alt=">" height="13px" />
                  </DownloadIcon>
                </DownloadButton>
              </Col>
              <Col col={2}>
                <DisplayButton type="submit" disabled={!(startDate && endDate)}>
                  Display
                  <DisplayButtonIcon>
                    <img src={arrowRightIcon} alt=">" height="16px" />
                  </DisplayButtonIcon>
                </DisplayButton>
              </Col>
            </Row>
          </form>
        </PaymentsDiv>
        { payments && payments.length > 0
          ? (
            <>
              <Table data={payments} tableLookup={tableLookup} />
              <TotalAmtDiv>
                <Total>
                  $
                  {formatter.format(total)}
                </Total>
              </TotalAmtDiv>
            </>
          ) : payments && payments.length === 0 ? (
            <NoData>
              No Data
            </NoData>
          ) : ''}
      </AccountingPageLayout>
    </>
  );
};

export default ChurchPayment;

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import Cookies from 'js-cookie';
import {
  Col,
  Row,
} from '../../../components/styled_components/grid/Grid.style';
import ReportSubMenu from '../../../components/accounting/report_submenu';
import AccountingMenu from '../../../components/accounting/menu';
import {
  AccountingPageLayout,
  PageHeading,
  PageSubHeading,
} from '../../../theme/GlobalStyles';
import {
  PaymentsDiv,
  DisplayButton,
  DisplayButtonIcon,
  TotalAmtDiv,
  Total,
  ChurchPaymentsCustomSelect,
  VendorsCustomSelect,
  SearchInput,
  DownloadButton,
  DownloadIcon,
  NoData,
} from './ChurchPayments.style';
import { ACCT_API } from '../../../constants';
import Download from '../../../assets/icons/download.svg';
import Breadcrumbs from '../../../components/breadcrumbs';
import arrowRightIcon from '../../../assets/icons/arrow-right.svg';
import Table from '../../../components/common/table';
import { fetcher, useFetch, fileDownloader } from '../../../hooks/useFetch';
import Loader from '../../../components/styled_components/loader/loader.style';
import ScrollDownButton from '../../../utils/ScrollDownButton';
import ScrollUpButton from '../../../utils/ScrollUpButton';

const breadcrumbs = ['Accounting', 'Reports', 'ChurchPayments'];
const tableLookup = [
  {
    heading: 'Date',
    field_name: 'date',
    type: 'date',
  },
  {
    heading: 'Voucher Num',
    field_name: 'paymentId',
    type: 'number',
  },
  {
    heading: 'Account Name',
    field_name: 'accountName',
    type: 'string',
  },
  {
    heading: 'Category',
    field_name: 'category',
    type: 'string',
  },
  {
    heading: 'Vendor',
    field_name: 'vendor',
    type: 'string',
  },
  {
    heading: 'Amount',
    field_name: 'amount',
    type: 'amount',
  },
];

const ChurchPayment = () => {
  // const d = new Date();
  // d.setDate(d.getDate() - 30);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [categories, setCategories] = useState([
    { label: 'All', value: 'All' },
  ]);
  const [vendors, setVendors] = useState([{ label: 'All', value: 'All' }]);
  const [data, setData] = useState();
  const [categoryValue, setCategoryValue] = useState('All');
  const [vendorsValue, setVendorsValue] = useState('All');
  const [total, setTotal] = useState(0);
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const minDate = Cookies.get('accountant_year');
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const categoriesData = useFetch({
    method: 'GET',
    url: `${ACCT_API.GET_BANK_ACCOUNTS}?type=Expense`,
    authTokenKey: 'auth_token',
  });

  useEffect(() => {
    if (categoriesData?.data?.response) {
      setCategories([
        ...categories,
        ...categoriesData?.data?.response.data.map((item) => ({
          value: item.accountName,
          label: item.accountName,
        })),
      ]);
    }
  }, [categoriesData?.data?.response]);

  const vendorsData = useFetch({
    method: 'GET',
    url: `${ACCT_API.GET_VENDOR}`,
    authTokenKey: 'auth_token',
  });
  useEffect(() => {
    if (vendorsData?.data?.response) {
      setVendors([
        ...vendors,
        ...vendorsData?.data?.response.map((item) => ({
          value: item.displayName,
          label: item.displayName,
        })),
      ]);
    }
  }, [vendorsData?.data?.response]);
  const handleDisplay = async (e) => {
    e?.preventDefault();
    setLoading(true);
    try {
      const result = await fetcher({
        method: 'GET',
        url: `${ACCT_API.GET_CHURCH_PAYMENTS}?startDate=${startDate}&endDate=${endDate}`,
        authTokenKey: 'auth_token',
      });
      setPayments(result?.response);
      setLoading(false);
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };

  const handleDownload = async () => {
    const res = await fileDownloader({
      url: `${ACCT_API.CHURCH_PAYMENTS_DOWNLOAD}?startDate=${startDate}&endDate=${endDate}&category=${categoryValue === 'All' ? '' : categoryValue}&vendor=${vendorsValue === 'All' ? '' : vendorsValue}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    saveAs(new Blob([res], { type: 'application/vnd.ms-excel' }), 'myExcel.xlsx');
  };
  useEffect(() => {
    if (payments.length > 0) {
      const temp = [];
      payments.forEach((payment) => {
        payment.lines.forEach((line, index) => {
          temp.push({
            // key: `${payment.id}_${index}`,
            id: `${payment.id}_${index}`,
            paymentId: payment.id,
            date: payment.date,
            memberId: payment.memberId,
            vendor: payment.vendor,
            accountName: payment.accountName,
            category: line.itemName,
            amount: parseFloat(line.rate, 10),
            recepient: payment.recepient,
            notes: payment.notes,
          });
        });
      });
      const arr = temp.filter((x) => x.notes !== 'Voided');
      if (categoryValue === 'All' && vendorsValue === 'All') {
        setData(arr);
      }
      if (categoryValue === 'All' && vendorsValue !== 'All') {
        setData(
          arr.filter((line) => line.vendor === vendorsValue),
        );
      }
      if (categoryValue !== 'All' && vendorsValue === 'All') {
        setData(
          arr.filter((line) => line.category === categoryValue),
        );
      }
      if (categoryValue !== 'All' && vendorsValue !== 'All') {
        setData(
          arr.filter(
            (line) => line.category === categoryValue && line.vendor === vendorsValue,
          ),
        );
      }
    } else {
      setData('');
    }
  }, [categoryValue, vendorsValue, payments]);
  useEffect(() => {
    if (data) {
      let sum = 0;
      for (const i of data) {
        sum += i.amount;
      }
      setTotal(sum);
    }
  }, [data]);
  return (
    <>
      <AccountingMenu />
      {loading ? <Loader /> : null}
      <ScrollUpButton />
      <ScrollDownButton />
      <AccountingPageLayout>
        <PageHeading>Reports</PageHeading>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <ReportSubMenu />
        <PageSubHeading>Church Payments</PageSubHeading>
        <PaymentsDiv>
          <form onSubmit={handleDisplay}>
            <Row>
              <Col col={2}>
                <ChurchPaymentsCustomSelect
                  className="select"
                  classNamePrefix="custom"
                  options={categories}
                  value={{ label: categoryValue, value: categoryValue }}
                  onChange={(e) => {
                    setCategoryValue(e.value);
                  }}
                  placeholder="Category"
                />
              </Col>
              <Col col={2}>
                <VendorsCustomSelect
                  className="select"
                  classNamePrefix="custom"
                  options={vendors}
                  value={{ label: vendorsValue, value: vendorsValue }}
                  onChange={(e) => {
                    setVendorsValue(e.value);
                  }}
                  placeholder="Vendors"
                />
              </Col>
              <Col col={3}>
                <SearchInput
                  type="date"
                  min={`${minDate}-01-01`}
                  max={`${minDate}-12-31`}
                  placeholder="Start Date"
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Col>
              <Col col={3}>
                <SearchInput
                  type="date"
                  min={`${minDate}-01-01`}
                  max={`${minDate}-12-31`}
                  placeholder="End Date"
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Col>
              <Col col={2}>
                <DisplayButton type="submit" disabled={!(startDate && endDate)}>
                  Display
                  <DisplayButtonIcon>
                    <img src={arrowRightIcon} alt=">" height="16px" />
                  </DisplayButtonIcon>
                </DisplayButton>
              </Col>
            </Row>
          </form>
        </PaymentsDiv>
        {data && data.length > 0
          ? (
            <>
              <Table data={data} tableLookup={tableLookup} />
              <TotalAmtDiv>
                <DownloadButton onClick={handleDownload}>
                  Download
                  <DownloadIcon>
                    <img src={Download} alt="download" />
                  </DownloadIcon>
                </DownloadButton>
                <Total>
                  $
                  {formatter.format(total)}
                </Total>
              </TotalAmtDiv>
            </>
          ) : data && data.length === 0 ? (
            <NoData>
              No Data
            </NoData>
          ) : ''}
      </AccountingPageLayout>
    </>
  );
};

export default ChurchPayment;

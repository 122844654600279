import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import {
  Row,
  Col,
} from '../../../components/styled_components/grid/Grid.style';
import AccountingMenu from '../../../components/accounting/menu';
import ReportSubMenu from '../../../components/accounting/report_submenu';
import Breadcrumbs from '../../../components/breadcrumbs';
import arrowRightIcon from '../../../assets/icons/arrow-right.svg';
import Loader from '../../../components/styled_components/loader/loader.style';
import {
  AccountingPageLayout,
  PageHeading,
  PageSubHeading,
} from '../../../theme/GlobalStyles';
import {
  CashFlowDiv,
  DisplayButton,
  DisplayButtonIcon,
  StyledDate,
  BoldItems,
  BorderItems,
  CashFlowBodyDiv,
  ItemDiv,
} from './cashFlow.style';
import { ACCT_API } from '../../../constants';
import { fetcher } from '../../../hooks/useFetch';
import ScrollDownButton from '../../../utils/ScrollDownButton';
import ScrollUpButton from '../../../utils/ScrollUpButton';

const breadcrumbs = ['Accounting', 'Reports', 'Cash Flow'];

const CashFlow = () => {
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const minDate = Cookies.get('accountant_year');
  const [report, setReport] = useState();
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const getReport = async () => {
    setLoading(true);
    setReport(null);
    try {
      const res = await fetcher({
        url: `${ACCT_API.GET_REPORT}?type=CashFlow&start_date=${startDate}&end_date=${endDate}`,
        method: 'GET',
        authTokenKey: 'auth_token',
      });
      if (res.report) {
        setReport(res);
        setLoading(false);
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };
  let headers;
  let tableBody;
  if (report) {
    const columns = report.report.Columns.Column;
    headers = (
      <>
        <BorderItems>
          <Row key="header">
            {columns.map((col) => (
              <>
                <Col col={10} />
                <Col col={2} key={col}>
                  <BoldItems>{col.ColTitle}</BoldItems>
                </Col>

              </>
            ))}
          </Row>
        </BorderItems>
      </>
    );
    const sections = report.report.Rows.Row;
    tableBody = (
      <>
        {sections.map((section) => (
          <ItemDiv key={section}>
            {section.Header && (
              <Row key={`header_${section}`}>
                {section.Header.ColData.map((cellData) => (
                  <Col key={cellData} margin="10px 0 10px 0"><BoldItems>{cellData.value}</BoldItems></Col>
                ))}
              </Row>
            )}
            {section.Rows
              && section.Rows.Row.map((row) => (
                <>
                  {row.ColData && (
                    <Row key={`row_${row}`}>
                      {row.ColData.map((cellData, cellIndex) => {
                        switch (cellIndex) {
                          case 0:
                            return (
                              <Col
                                col={6}
                                key={cellData}
                                padding="10px 0 10px 15px"
                              >
                                {cellData.value}
                              </Col>
                            );
                          default:
                            return (
                              <>
                                <Col col={4} />
                                <Col
                                  col={2}
                                  key={cellData}
                                  padding="10px 0 10px 0"
                                >
                                  {cellData.value}
                                </Col>
                              </>
                            );
                        }
                      })}
                    </Row>
                  )}
                  {row.Header && (
                    <Row
                      key={`row_header_${row}`}
                    >
                      {row.Header.ColData.map((cellData, cellIndex) => {
                        switch (cellIndex) {
                          case 0:
                            return (
                              <Col
                                col={6}
                                key={cellData}
                                padding="10px 0 10px 15px"
                              >
                                {cellData.value}
                              </Col>
                            );
                          default:
                            return (
                              <>
                                <Col col={4} />
                                <Col
                                  col={2}
                                  key={cellData}
                                  padding="10px 0 10px 0"
                                >
                                  {cellData.value}
                                </Col>
                              </>
                            );
                        }
                      })}
                    </Row>
                  )}
                  {row.Rows
                    && row.Rows.Row.map((innerRow) => {
                      switch (innerRow) {
                        case 0:
                          return (
                            <Col
                              col={6}
                              key={innerRow}
                              padding="10px 0 10px 15px"
                            >
                              {innerRow.value}
                            </Col>
                          );
                        default:
                          return (
                            <>
                              <Col col={4} />
                              <Col
                                col={2}
                                key={innerRow}
                                padding="10px 0 10px 0"
                              >
                                {innerRow.value}
                              </Col>
                            </>
                          );
                      }
                    })}
                  {row.Summary && (
                    <Row
                      key={`row_summary_${row}`}
                    >
                      {row.Summary.ColData.map((cellData) => (
                        <Col
                          col={6}
                          key={cellData}
                        >
                          hello
                          {cellData.value}
                        </Col>
                      ))}
                    </Row>
                  )}
                </>
              ))}
            {section.Summary && (
              <BorderItems>
                <Row
                  key={`summary_${section}`}
                >
                  {section.Summary.ColData.map((cellData, cellIndex) => {
                    switch (cellIndex) {
                      case 1:
                        return (
                          <>
                            <Col col={4} />
                            <Col
                              col={2}
                              key={cellData}
                              padding="10px 0 10px 0"
                            >
                              {`$ ${formatter.format(cellData.value)}`}
                            </Col>
                          </>
                        );
                      default:
                        return (
                          <>
                            <Col
                              col={6}
                              key={cellData}
                              padding="10px 0 10px 15px"
                            >
                              <BoldItems>{cellData.value}</BoldItems>
                            </Col>
                          </>
                        );
                    }
                  })}
                </Row>

              </BorderItems>
            )}
          </ItemDiv>
        ))}
      </>
    );
  }
  return (
    <>
      <AccountingMenu />
      {loading ? <Loader /> : null}
      <ScrollUpButton />
      <ScrollDownButton />
      <AccountingPageLayout>
        <PageHeading>Reports</PageHeading>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <ReportSubMenu />
        <PageSubHeading>Cash Flow</PageSubHeading>
        <CashFlowDiv>
          <Row>
            <Col col={3}>
              <StyledDate
                width="88%"
                type="date"
                placeholder="Start Date"
                min={`${minDate}-01-01`}
                max={`${minDate}-12-31`}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Col>
            <Col col={3}>
              <StyledDate
                width="88%"
                type="date"
                placeholder="End Date"
                min={`${minDate}-01-01`}
                max={`${minDate}-12-31`}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Col>
            <Col col={3} />
            <Col col={3}>
              <DisplayButton type="button" onClick={() => getReport()} disabled={!(startDate && endDate)}>
                Display
                <DisplayButtonIcon>
                  <img src={arrowRightIcon} alt=">" height="16px" />
                </DisplayButtonIcon>
              </DisplayButton>
            </Col>
          </Row>
        </CashFlowDiv>
        {report
          ? (
            <CashFlowBodyDiv>
              {headers}
              {tableBody}
            </CashFlowBodyDiv>
          ) : ''}
      </AccountingPageLayout>
    </>
  );
};

export default CashFlow;

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import AccountingMenu from '../../../components/accounting/menu';
import Loader from '../../../components/styled_components/loader/loader.style';
import Breadcrumbs from '../../../components/breadcrumbs';
import { fetcher } from '../../../hooks/useFetch';
import { ACCT_API } from '../../../constants';
import {
  AccountingPageLayout,
  PageHeading,
  PageSubHeading,
} from '../../../theme/GlobalStyles';
import {
  Row,
  Col,
} from '../../../components/styled_components/grid/Grid.style';
import {
  LineItems,
  SummarizedHeadDiv,
  SummarizedBodyDiv,
  AmountField,
  CreateNewButton,
  BudgetField,
} from './Budget.style';
import ScrollDownButton from '../../../utils/ScrollDownButton';
import ScrollUpButton from '../../../utils/ScrollUpButton';

const breadcrumbs = ['Accounting', 'Budget'];

const Budget = () => {
  const [loading, setLoading] = useState(false);
  const [incomeAccountData, setIncomeAccountData] = useState([]);
  const [expenseAccountData, setExpenseAccountData] = useState([]);
  const [bankOpenData, setBankOpenData] = useState([]);
  const [bankCloseData, setBankCloseData] = useState([]);
  const [bankOpenDetails, setBankOpenDetails] = useState([]);
  const [bankCloseDetails, setBankCloseDetails] = useState([]);
  const [incomeData, setIncomeData] = useState([]);
  const [expenseData, setExpenseData] = useState([]);
  const [budgetName, setBudgetName] = useState('');
  const currentYear = Cookies.get('accountant_year');

  const getIncomeAccountDetails = async () => {
    setLoading(true);
    const res = await fetcher({
      url: `${ACCT_API.GET_SALES_ITEM}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    if (res.response) {
      const date = new Date();
      setLoading(false);
      const arr = [];
      res.response.data.forEach((acc) => {
        arr.push({
          accountType: 'Income', amount: 0, accountName: acc.accountName, budgetDate: moment(date).format('YYYY-MM-DD'),
        });
      });
      setIncomeAccountData(arr);
      setIncomeData(arr);
    }
  };
  const getExpenseAccountDetails = async () => {
    setLoading(true);
    const res = await fetcher({
      url: `${ACCT_API.GET_BANK_ACCOUNTS}?type=Expense`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    if (res.response) {
      setLoading(false);
      const arr = res.response.data.filter((acc) => acc.subAccount);
      const date = new Date();
      const arr1 = [];
      arr.forEach((acc) => {
        arr1.push({
          accountType: 'Expense', amount: 0, accountName: acc.accountName, budgetDate: moment(date).format('YYYY-MM-DD'),
        });
      });
      setExpenseData(arr1);
      setExpenseAccountData(arr1);
    }
  };

  const getBankDetails = async () => {
    setLoading(true);
    const res = await fetcher({
      url: `${ACCT_API.GET_BANK_ACCOUNTS}?type=Bank`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    if (res.response) {
      setLoading(false);
      const arr = res.response.data.filter((acc) => acc.subAccount);
      const date = new Date();
      const bankOpenarr = [];
      const bankClosearr = [];
      arr.forEach((acc) => {
        bankOpenarr.push({
          accountType: 'Bank', bankType: 'Open', amount: 0, accountName: acc.accountName, budgetDate: moment(date).format('YYYY-MM-DD'),
        });
        bankClosearr.push({
          accountType: 'Bank', bankType: 'Close', amount: 0, accountName: acc.accountName, budgetDate: moment(date).format('YYYY-MM-DD'),
        });
      });
      setBankOpenData(bankOpenarr);
      setBankOpenDetails(bankOpenarr);
      setBankCloseData(bankClosearr);
      setBankCloseDetails(bankClosearr);
    }
  };

  useEffect(() => {
    getIncomeAccountDetails();
    getExpenseAccountDetails();
    getBankDetails();
  }, []);

  const handleChange = (value, name, type) => {
    const date = new Date();
    if (type === 'income') {
      const arr = [...incomeData];
      const arr1 = arr.filter((x) => x.accountName !== name);
      setIncomeData([...arr1, {
        accountType: 'Income', amount: Number(value), accountName: name, budgetDate: moment(date).format('YYYY-MM-DD'),
      }]);
    } else if (type === 'expense') {
      const arr = [...expenseData];
      const arr1 = arr.filter((x) => x.accountName !== name);
      setExpenseData([...arr1, {
        accountType: 'Expense', amount: Number(value), accountName: name, budgetDate: moment(date).format('YYYY-MM-DD'),
      }]);
    } else if (type === 'bankOpen') {
      const arr = [...bankOpenDetails];
      const arr1 = arr.filter((x) => x.accountName !== name);
      setBankOpenDetails([...arr1, {
        bankType: 'Open', accountType: 'Bank', amount: Number(value), accountName: name, budgetDate: moment(date).format('YYYY-MM-DD'),
      }]);
    } else {
      const arr = [...bankCloseDetails];
      const arr1 = arr.filter((x) => x.accountName !== name);
      setBankCloseDetails([...arr1, {
        bankType: 'Close', accountType: 'Bank', amount: Number(value), accountName: name, budgetDate: moment(date).format('YYYY-MM-DD'),
      }]);
    }
  };

  const handleCreateBudget = async () => {
    if (!budgetName) {
      toast.error('Please give a budget name', { autoClose: 3000 });
      return;
    }

    const payload = {
      startDate: `${currentYear}-01-01`,
      endDate: `${currentYear}-12-25`,
      name: budgetName,
      type: 'Yearly',
      lineItems: [...incomeData, ...expenseData, ...bankOpenDetails, ...bankCloseDetails],
    };
    const noAmountData = payload.lineItems.filter((acc) => acc.amount === '');

    if (noAmountData.length > 0) {
      toast.error('Please give a minimum value to all fields', { autoClose: 3000 });
      return;
    }

    setLoading(true);
    const response = await fetcher({
      url: `${ACCT_API.CREATE_BUDGET}`,
      method: 'POST',
      authTokenKey: 'auth_token',
      body: JSON.stringify(payload),
    });

    if (response.success) {
      setLoading(false);
      toast.success('Budget Created Successfully', { autoClose: 3000 });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  const handleBudgetNameChange = (name) => {
    setBudgetName(name);
  };

  return (
    <>
      <AccountingMenu />
      <AccountingPageLayout>
        {loading ? <Loader /> : null}
        <ScrollUpButton />
        <ScrollDownButton />
        <PageHeading>Budget</PageHeading>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <>
          <SummarizedHeadDiv>
            <Row>
              <Col col={3}>
                <BudgetField
                  type="text"
                  placeholder="Budget Name"
                  value={budgetName}
                  onChange={(e) => handleBudgetNameChange(e.target.value)}
                />
              </Col>
              <Col col={6} />
              <Col col={3}>
                <CreateNewButton type="button" onClick={() => handleCreateBudget()}>
                  Create Budget
                </CreateNewButton>
              </Col>
            </Row>
          </SummarizedHeadDiv>
          <SummarizedHeadDiv>
            <Row marginTop="18px" marginBottom="18px">
              <Col col={1} />
              <Col textAlign="left" col={7}>
                Account Name
              </Col>
              <Col col={3}>Amount</Col>
            </Row>
          </SummarizedHeadDiv>
          <SummarizedBodyDiv>
            <Row>
              <Col col={1} />
              <PageSubHeading>Bank Opening Details</PageSubHeading>
            </Row>
            {bankOpenData?.map((acc) => (
              <LineItems>
                <Row>
                  <Col col={1} />
                  <Col col={7}>{acc.accountName}</Col>
                  <Col col={2}>
                    <AmountField
                      type="number"
                      placeholder="Amount"
                      onChange={(e) => handleChange(e.target.value, acc.accountName, 'bankOpen')}
                    />
                  </Col>
                </Row>
              </LineItems>
            ))}
          </SummarizedBodyDiv>
          <SummarizedBodyDiv>
            <Row>
              <Col col={1} />
              <PageSubHeading>Income Account Details</PageSubHeading>
            </Row>
            {incomeAccountData?.map((acc) => (
              <LineItems>
                <Row>
                  <Col col={1} />
                  <Col col={7}>{acc.accountName}</Col>
                  <Col col={2}>
                    <AmountField
                      type="number"
                      placeholder="Amount"
                      onChange={(e) => handleChange(e.target.value, acc.accountName, 'income')}
                    />
                  </Col>
                </Row>
              </LineItems>
            ))}
          </SummarizedBodyDiv>
          <SummarizedBodyDiv>
            <Row>
              <Col col={1} />
              <PageSubHeading>Expense Account Details</PageSubHeading>
            </Row>
            {expenseAccountData?.map((acc) => (
              <LineItems>
                <Row>
                  <Col col={1} />
                  <Col col={7}>{acc.accountName}</Col>
                  <Col col={2}>
                    <AmountField
                      type="number"
                      placeholder="Amount"
                      onChange={(e) => handleChange(e.target.value, acc.accountName, 'expense')}
                    />
                  </Col>
                </Row>
              </LineItems>
            ))}
          </SummarizedBodyDiv>
          <SummarizedBodyDiv>
            <Row>
              <Col col={1} />
              <PageSubHeading>Bank Closing Details</PageSubHeading>
            </Row>
            {bankCloseData?.map((acc) => (
              <LineItems>
                <Row>
                  <Col col={1} />
                  <Col col={7}>{acc.accountName}</Col>
                  <Col col={2}>
                    <AmountField
                      type="number"
                      placeholder="Amount"
                      onChange={(e) => handleChange(e.target.value, acc.accountName, 'bankClose')}
                    />
                  </Col>
                </Row>
              </LineItems>
            ))}
          </SummarizedBodyDiv>
        </>
      </AccountingPageLayout>
    </>
  );
};

export default Budget;

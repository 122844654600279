import React, { useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import Cookies from 'js-cookie';
import {
  Row,
  Col,
} from '../../../components/styled_components/grid/Grid.style';
import AccountingMenu from '../../../components/accounting/menu';
import Breadcrumbs from '../../../components/breadcrumbs';
import tick from '../../../assets/icons/tick-white.svg';
import { AccountingPageLayout, PageHeading } from '../../../theme/GlobalStyles';
import {
  OptionsWrapperDiv,
  CustomSelect,
  StyledInput,
  InputWrapper,
  StyledDate,
  BankTransferSaveButton,
  BankTransferSaveButtonIcon,
} from './BankTransfer.style';
import { fetcher, useFetch } from '../../../hooks/useFetch';
import { ACCT_API } from '../../../constants';
import Loader from '../../../components/styled_components/loader/loader.style';

const breadcrumbs = ['Accounting', 'Bank Transfer'];

const BankTransfer = () => {
  const [selectBank, setSelectBank] = useState([]);
  const [amount, setAmount] = useState();
  const [description, setDescription] = useState();
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [fromAccount, setFromAccount] = useState();
  const [toAccount, setToAccount] = useState();
  const [loading, setLoading] = useState(false);
  const [button, setButton] = useState(false);
  const minDate = Cookies.get('accountant_year');
  const today = moment().format('YYYY-MM-DD');

  const bank = useFetch({
    url: `${ACCT_API.GET_BANK_ACCOUNTS}?type=Bank`,
    method: 'GET',
    authTokenKey: 'auth_token',
  });
  if (bank.data && selectBank.length === 0) {
    setSelectBank(
      bank.data.response.data
        .filter((item) => item.subAccount)
        .map((item) => ({
          value: item.accountName,
          label: item.accountName,
        })),
    );
  }
  const getMaxDate = () => {
    let maxDate;
    if (today.substring(0, 4) === minDate) {
      maxDate = today;
    } else {
      maxDate = `${minDate}-12-31`;
    }
    return maxDate;
  };
  const handleSubmit = async () => {
    const payload = {
      amount,
      date,
      description,
      fromAccount,
      toAccount,
    };
    if (fromAccount === '' || fromAccount === undefined) {
      toast.error('Error! Please Select fromAccount ', { autoClose: 3000 });
    } else if (toAccount === '' || toAccount === undefined) {
      toast.error('Error! Please Select toAccount ', { autoClose: 3000 });
    } else if (amount === '' || amount === undefined) {
      toast.error('Error! Please add amount ', { autoClose: 3000 });
    } else if (description === '' || description === undefined) {
      toast.error('Error! Please add description ', { autoClose: 3000 });
    } else {
      setLoading(true);
      setButton(true);
      try {
        const res = await fetcher({
          url: `${ACCT_API.CREATE_BANK_TRANSFER}`,
          method: 'POST',
          authTokenKey: 'auth_token',
          body: JSON.stringify(payload),
        });
        if (res.success) {
          toast.success('Successfully Created!', { autoClose: 3000 });
          setLoading(false);
          setTimeout(() => {
            setButton(false);
            window.location.reload();
          }, 2000);
        } else {
          toast.error('Error!', { autoClose: 3000 });
          setLoading(false);
          setButton(false);
        }
      } catch (e) {
        toast.error('Error!', { autoClose: 3000 });
        setLoading(false);
        setButton(false);
      }
    }
  };
  return (
    <>
      <AccountingMenu />
      <AccountingPageLayout>
        {loading ? <Loader /> : null }
        <PageHeading>Bank Transfer</PageHeading>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <OptionsWrapperDiv>
          <Row>
            <Col col={12}>
              <Row>
                <Col col={4}>
                  <InputWrapper>
                    <StyledDate
                      width="100%"
                      type="date"
                      min={`${minDate}-01-01`}
                      max={getMaxDate()}
                      onKeyDown={(e) => e.preventDefault()}
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </InputWrapper>
                </Col>
                <Col col={4}>
                  <InputWrapper>
                    <CustomSelect
                      className="select"
                      classNamePrefix="custom"
                      options={selectBank}
                      placeholder="From Account"
                      onChange={(e) => setFromAccount(e.value)}
                    />
                  </InputWrapper>
                </Col>
                <Col col={4}>
                  <InputWrapper>
                    <CustomSelect
                      className="select"
                      classNamePrefix="custom"
                      options={selectBank}
                      placeholder="To Account"
                      onChange={(e) => setToAccount(e.value)}
                    />
                  </InputWrapper>
                </Col>
              </Row>
              <Row>
                <Col col={4}>
                  <InputWrapper>
                    <StyledInput
                      type="number"
                      placeholder="Amount"
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </InputWrapper>
                </Col>
                <Col col={8}>
                  <InputWrapper>
                    <StyledInput
                      type="description"
                      placeholder="Description"
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </InputWrapper>
                </Col>
              </Row>
              <Row>
                <Col col={10} />
                <Col col={2}>
                  <BankTransferSaveButton
                    type="button"
                    disabled={button}
                    onClick={() => handleSubmit()}
                  >
                    Submit
                    <BankTransferSaveButtonIcon>
                      <img src={tick} alt="arrow" height="16px" />
                    </BankTransferSaveButtonIcon>
                  </BankTransferSaveButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </OptionsWrapperDiv>
      </AccountingPageLayout>
    </>
  );
};

export default BankTransfer;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from '../../styled_components/grid/Grid.style';
import CreateIcon from '../../../assets/icons/accounting_create.svg';
import ArrowDown from '../../../assets/icons/arrow-down.svg';
import {
  MenuWrapper, Menu, DropdownContent, DropDownItem,
} from './AccountingMenu.style';

const AccountingMenu = () => {
  const [displayCreateMenu, setDisplayCreateMenu] = useState('none');
  const [displayTransactionMenu, setDisplayTransactionMenu] = useState('none');
  const navigate = useNavigate();
  const showCreateMenu = () => {
    if (displayCreateMenu === 'block') {
      setDisplayCreateMenu('none');
    } else {
      setDisplayCreateMenu('block');
    }
  };
  const showTransactionMenu = () => {
    if (displayTransactionMenu === 'block') {
      setDisplayTransactionMenu('none');
    } else {
      setDisplayTransactionMenu('block');
    }
  };
  const handleClick = (link) => {
    navigate(link);
  };
  document.addEventListener('click', (e) => {
    if (document.getElementById('createmenubox')?.contains(e.target)) {
      setDisplayCreateMenu('block');
    } else {
      setDisplayCreateMenu('none');
    }
    if (document.getElementById('transactionmenubox')?.contains(e.target)) {
      setDisplayTransactionMenu('block');
    } else {
      setDisplayTransactionMenu('none');
    }
  });
  return (
    <>
      <MenuWrapper>
        <Row>
          <Col col={3}>
            <Menu id="createmenubox" topLeftBorderRadius="40px">
              <Row className="dropdown" onClick={showCreateMenu}>
                <Col col={1}>
                  <img src={CreateIcon} width={22} alt="create icon" />
                </Col>
                <Col col={10}>
                  Create
                </Col>
                <Col col={1}>
                  <img src={ArrowDown} width={12} height={10} alt="arrow down" />
                </Col>
              </Row>
              <DropdownContent display={displayCreateMenu}>
                <DropDownItem onClick={() => handleClick('/accounting/create/receipts')}>Receipts</DropDownItem>
                <DropDownItem onClick={() => handleClick('/accounting/create/payments')}>Payments</DropDownItem>
                <DropDownItem onClick={() => handleClick('/accounting/create/journal')}>Journal Voucher</DropDownItem>
                <DropDownItem onClick={() => handleClick('/accounting/create/bank-transfer')}>Bank Transfer</DropDownItem>
                <DropDownItem onClick={() => handleClick('/accounting/create/budget')}>Budget</DropDownItem>
              </DropdownContent>
            </Menu>
          </Col>
          <Col col={3}>
            <Menu id="transactionmenubox">
              <Row className="dropdown" onClick={showTransactionMenu}>
                <Col col={1} />
                <Col col={10}>
                  Transactions
                </Col>
                <Col col={1}>
                  <img src={ArrowDown} width={12} height={10} alt="arrow down" />
                </Col>
              </Row>
              <DropdownContent display={displayTransactionMenu}>
                <DropDownItem onClick={() => handleClick('/accounting/transactions/receipts')}>Receipts</DropDownItem>
                <DropDownItem onClick={() => handleClick('/accounting/transactions/payments')}>Payments</DropDownItem>
                <DropDownItem onClick={() => handleClick('/accounting/transactions/journal-vouchers')}>Journal Vouchers</DropDownItem>
                <DropDownItem onClick={() => handleClick('/accounting/transactions/bank-transfer')}>Bank Transfer</DropDownItem>
                <DropDownItem onClick={() => handleClick('/accounting/transactions/edit-budget')}>Edit Budget</DropDownItem>
              </DropdownContent>
            </Menu>
          </Col>
          <Col col={3}>
            <Menu>
              <Row onClick={() => handleClick('/accounting/chartofaccounts')}>
                <Col col={1} />
                <Col col={10}>
                  Chart of Accounts
                </Col>
              </Row>
            </Menu>
          </Col>
          <Col col={3}>
            <Menu>
              <Row onClick={() => handleClick('/accounting/report')}>
                <Col col={1} />
                <Col col={10}>
                  Reports
                </Col>
              </Row>
            </Menu>
          </Col>
        </Row>
      </MenuWrapper>
    </>
  );
};

export default AccountingMenu;

import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from '../../styled_components/grid/Grid.style';

import defaultPic from '../../../assets/icons/default-profile.png';
import locationIcon from '../../../assets/icons/location.svg';
import mailIcon from '../../../assets/icons/mail-icon.svg';
import mobileIcon from '../../../assets/icons/mobile-icon.svg';
// import remove from '../../../assets/icons/delete.svg';
import {
  LocationIcon,
  MemberCardWrapper,
  ContactText,
  ContactHeading,
  ContactIcon,
  MemberLocation,
  MemberName,
  MemberProfilePic,
  MemberContactContainer,
  ContactWrapper,
} from './MemberPanel.style';

const MemberPanel = ({
  marginRight, member, handleClick,
}) => (
  <MemberCardWrapper key={member.id} marginRight={marginRight}>
    <Row>
      <Col col={3} textAlign="center">
        <MemberProfilePic
          onError={(e) => { e.target.onerror = null; e.target.src = defaultPic; }}
          src={member.profilePic ? member.profilePic : defaultPic}
          alt="default pic"
        />
      </Col>
      <Col col={9} textAlign="left" marginTop="5px">
        <Row>
          <Col col={12} onClick={() => handleClick(`/members/details/${member.id}`)}>
            <MemberName>
              {member.name}
            </MemberName>
          </Col>
          <Col col={12}>
            <MemberLocation>
              <LocationIcon src={locationIcon} alt="loc" />
              {member.prayerGroup}
            </MemberLocation>
          </Col>
        </Row>
      </Col>
    </Row>
    <Row>
      <Col col={12}>
        <MemberContactContainer>
          <Row>
            <Col col={6}>
              <ContactWrapper borderRight="1px solid #e4e8f4;">
                <Row>
                  <Col col={2} textAlign="center">
                    <ContactIcon src={mailIcon} alt="mail" />
                  </Col>
                  <Col col={10} padding="0 0 0 10px">
                    <ContactHeading>Email</ContactHeading>
                    <ContactText>
                      {member.homeEmail}
                    </ContactText>
                  </Col>
                </Row>
              </ContactWrapper>
            </Col>
            <Col col={6}>
              <ContactWrapper>
                <Row>
                  <Col col={2} textAlign="center">
                    <ContactIcon src={mobileIcon} alt="phone" />
                  </Col>
                  <Col col={10} padding="0 0 0 10px">
                    <ContactHeading>Phone</ContactHeading>
                    <ContactText>
                      {member.contactDetail}
                    </ContactText>
                  </Col>
                </Row>
              </ContactWrapper>
            </Col>
          </Row>
        </MemberContactContainer>
      </Col>
    </Row>
  </MemberCardWrapper>
);

MemberPanel.propTypes = {
  marginRight: PropTypes.string,
  member: PropTypes.object,
  handleClick: PropTypes.func,
};

export default MemberPanel;

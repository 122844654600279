import styled from 'styled-components';

export const VerticalLine = styled.div` 
  height: 130%;
  width: 0;
  border: 1px solid #E4E8F4;
`;

export const RadioDiv = styled.div` 
  height: 50px;
  border: 1px solid #E4E8F4;
  box-sizing: border-box;
  border-radius: 14px;
  margin-top:${(props) => props.marginTop};
`;

export const RadioButtonWrapper = styled.div`
  margin: 5px 0 0 5px;
  cursor: pointer;
`;

export const RadioButtonLabel = styled.label`
    box-shadow: 0 0 0 5px #393e5c;
    position: absolute;
    top: 18px;
    left: 10px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #f0f0f5;
    border: 1px solid #bebebe;
`;
export const RadioButtonLabelDiv = styled.div` 
  font-family: Archivo, sans-serif;
  font-weight: 400;
  white-space: nowrap;
  margin-top: 12px;
  color: ${(props) => props.color};
`;
export const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  ${(props) => props.checked
    && ` 
    &:checked + ${RadioButtonLabel} {
      background: ${({ theme }) => theme.colors.white};
      border: 1px solid #db7290;
      color: #EC1C24;
      box-shadow: 0px 0px 0px 5px #ec1c24;
    }
  `}
`;

import styled from 'styled-components';
import Modal from 'styled-react-modal';
import Select from 'react-select';

export const ModalWrapper = styled.div`
  margin: 30px;
`;
export const StyledModal = Modal.styled`
  width: 800px;
  height: 500px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.border.radius_m}
`;
export const ModalHeading = styled.h2`
  margin: 10px 0px 20px 0px;
  font-family: 'Playfair Display', sans-serif;
  color: ${({ theme }) => theme.colors.primary_blue};
`;

export const SaveButton = styled.button`
  position: relative;
  background-color: ${({ theme }) => theme.colors.primary_red};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius_m};
  border: none;
  height: 45px;
  padding-right: 35px;
  margin: 15px 5px 5px 5px;
  width: 86%;
`;
export const SaveButtonIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(12, 0, 0, 0.15);
  width: 40px;
  height: 30px;
  margin: 0;
  padding: 15px 0 0 0;
  border-radius: ${({ theme }) => theme.border.radius_m};
`;

export const CloseIcon = styled.div`
  top: 0;
  width: 10px;
  margin: 0 0 40px 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius_m};
`;
export const CloseButton = styled.button`
  height: 35px;
  border: none;
  float: right;
  background-color: ${({ theme }) => theme.colors.secondary_background};
  padding: 10px 20px 10px 15px;
  border-top-right-radius: ${({ theme }) => theme.border.radius_m};
  border-bottom-left-radius: ${({ theme }) => theme.border.radius_m};
  font-size: 12px;
`;

export const FieldValueInput = styled.input`
  color: ${({ theme }) => theme.colors.primary_blue};
  font-size: ${({ theme }) => theme.fonts.font_size.big_font};
  font-weight: 600;
  margin: 0px 12px 6px;
  border: none;
  border-radius: 6px;
  width: 85%;
  padding: 5px 5px 5px;
  &:disabled {
    background-color: ${({ theme }) => theme.colors.white};
    border: none;
  }
`;
export const Div = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grey_blue};
  border-radius: ${({ theme }) => theme.border.radius_m};
  min-height: 40px;
  margin: 5px;
`;
export const FieldLabel = styled.div`
  color: ${({ theme }) => theme.colors.light_grey};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fonts.font_weight.bold_font};
  margin: 8px 16px 0px;
  opacity: 0.5;
`;
export const CustomSelect = styled(Select)`
  width: 100%;
  margin: 5px 0;
  padding: 0 5px;
  .custom__control {
    height: 50px;
    background-color: ${({ theme }) => theme.colors.page_background};
    border: 1px solid ${({ theme }) => theme.colors.page_background};
    .custom__placeholder {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.primary_blue};
    }
    .custom__single-value {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.primary_blue};
    }
    .custom__indicator-separator {
      display: none;
    }
  }
`;
export const MembersInput = styled.input`
  background-color: ${({ theme }) => theme.colors.page_background};
  border: 1px solid ${({ theme }) => theme.colors.page_background};
  font-family: Archivo, sans-serif;
  padding:5px 20px;
  margin-top:15px ;
  height: 38px;
  width: 88%;
  border-radius: 3px;
`;
export const TransferButton = styled.div`
  background: ${({ theme }) => theme.colors.page_background};;
  width: 30px;
  height: 30px;
  margin-right:20px;
  border-radius: 7px;
  text-align: center;
  cursor: pointer;
  float:right;
`;
export const TransferIcon = styled.img`
  margin-top: 4px;
  width:25px;
  height:25px;

`;
export const SelectedContainer = styled.div`
  max-height: 190px;
  overflow: auto;
  flex-wrap: nowrap;
`;

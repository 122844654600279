import styled from 'styled-components';
import Select from 'react-select';

export const PageSubHeading = styled.h3`
  
  color: ${({ theme }) => theme.colors.primary_blue};
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 12px;
`;

export const PaymentsDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 5px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 4%);
  border-radius: ${({ theme }) => theme.border.radius_m};
`;
export const DisplayButton = styled.button`
  position: relative;
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  background-color: ${({ theme }) => theme.colors.primary_red};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius_m};
  border: none;
  height: 50px;
  padding: 0;
  margin: 5px 20px;
  width: 90%;
  float: right;
`;
export const DisplayButtonIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(12, 0, 0, 0.15);
  width: 42px;
  height: 35px;
  margin: 0;
  padding: 15px 0 0 0;
  border-radius: ${({ theme }) => theme.border.radius_m};
`;
export const TotalAmtDiv = styled.div`
  height: 50px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius_m};
`;
export const Total = styled.div`
  font-size: 22px;
  font-weight: 600;
  line-height: 50px;
  text-align: right;
  margin-right: 12px;
  color: ${({ theme }) => theme.colors.primary_blue};
`;

export const DownloadIcon = styled.div`
  border: 1px solid  ${({ theme }) => theme.colors.secondary_background};
  border-top-left-radius: ${(props) => props.topLeftBorderRadius || 0};
  font-size: 14px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  padding: 0px 0px;
  margin: 5px 7px;
  height: 35px;
  width: 180px;
  border-radius: 10px;
  
`;
export const DownloadButton = styled.button`
position: relative;
font-size: ${({ theme }) => theme.fonts.font_size.big_font};
font-weight: ${({ theme }) => theme.fonts.font_weight.normal_font};
background-color: ${({ theme }) => theme.colors.white};
color: ${({ theme }) => theme.colors.text};
border-radius: ${({ theme }) => theme.border.radius_m};
border: 1px solid  ${({ theme }) => theme.colors.secondary_background};
height: 40px;
padding: 0;
margin: 5px 20px;
width: 100%;
  
`;
export const DownloadButtonIcon = styled.div`
position: absolute;
right: 0;
top: 0;
border: 1px solid ${({ theme }) => theme.colors.secondary_background};
border-radius: 50px;
height: 20px;
width: 20px;
margin: 8px;
`;
export const SearchInput = styled.input`
  background-color: ${({ theme }) => theme.colors.page_background};
  border-radius: ${({ theme }) => theme.border.radius_m};
  padding: 10px 15px;
  margin-top: 7px;
  border: none;
  height: 28px;
  width: 87%;
`;
export const CustomSelect = styled(Select)`
  width: 95%;
  margin: 6px 0;
  padding: 0 5px;
  .custom__control {
    height: 48px;
    background-color: ${({ theme }) => theme.colors.page_background};
    border: 1px solid ${({ theme }) => theme.colors.page_background};
    border-radius: 10px;
    .custom__placeholder {
      font-size: 13px;
      color: ${({ theme }) => theme.colors.primary_blue};
    }
    .custom__single-value {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.primary_blue};
    }
    .custom__indicator-separator {
      display: none;
    }
  }
`;
export const Image = styled.img`
  margin-top: 3px;
`;
export const NoData = styled.div`
  font-weight:${({ theme }) => theme.fonts.font_weight.normal_font};
  color : ${({ theme }) => theme.colors.primary_blue};
  font-size: 20px;
  margin-top: 20px;
`;

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import Cookies from 'js-cookie';
import {
  Row,
  Col,
} from '../../../components/styled_components/grid/Grid.style';
import AccountingMenu from '../../../components/accounting/menu';
import Breadcrumbs from '../../../components/breadcrumbs';
import { AccountingPageLayout, PageHeading } from '../../../theme/GlobalStyles';
import CreateTable from '../../../components/accounting/create_table';
import tick from '../../../assets/icons/tick-white.svg';
import editIcon from '../../../assets/icons/pen.svg';
import tickIcon from '../../../assets/icons/tick.svg';
import closeIcon from '../../../assets/icons/close-icon.svg';
import {
  OptionsWrapperDiv,
  CustomSelect,
  AddressBar,
  CheckNumber,
  StyledDate,
  SearchSelect,
  ReceiptSaveButton,
  ReceiptSaveButtonIcon,
  CreateSelect,
  EditButton,
  EditCheckBank,
} from './Receipts.style';
import { fetcher, useFetch } from '../../../hooks/useFetch';
import { API, ACCT_API } from '../../../constants';
import Loader from '../../../components/styled_components/loader/loader.style';

const breadcrumbs = ['Accounting', 'Church Receipts'];
const options = [
  { label: 'Check', value: 'Check' },
  { label: 'Venmo', value: 'Venmo' },
  { label: 'Cash', value: 'Cash' },
  { label: 'Credit Card', value: 'Credit Card' },
  { label: 'Zelle', value: 'Zelle' },
];

const CreateReceipts = () => {
  const [address, setAddress] = useState();
  const [memberId, setMemberId] = useState();
  const [selectBank, setSelectBank] = useState([]);
  const [category, setCategory] = useState([]);
  const [checkBank, setCheckBank] = useState([]);
  const [items, setItems] = useState();
  const [bankAccount, setBankAccount] = useState(
    'Gen. Funds A/C - Checking - XXXXXX7994',
  );
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [checkNumber, setCheckNumber] = useState();
  const [selectedCheckbank, setSelectedCheckbank] = useState();
  const [paymentMethod, setPaymentMethod] = useState('Check');
  const [editedCheckBank, setEditedCheckBank] = useState();
  const [checkEdit, setCheckEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [button, setButton] = useState(false);
  const minDate = Cookies.get('accountant_year');
  const today = moment().format('YYYY-MM-DD');
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const getName = async (value) => {
    const res = await fetcher({
      url: `${API.GET_MEMBER_LIST}?query=${value}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    const memberNames = res.member_list.map((item) => ({
      value: item.membershipNumber,
      label: ` ${item.membershipNumber.split('-')[0]} ${item.name}  (${item.prayerGroup})`,
    }));
    if (value.match(/0/i) || value.match(/non/i)) {
      return [...memberNames, { value: '0001', label: '0001 Non Member' }];
    } else {
      return memberNames;
    }
  };
  const getAddress = async (memberId) => {
    const res = await fetcher({
      url: `${API.GET_MEMBER_ADDRESS}?family_id=${memberId}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    setAddress(res);
  };
  const getMaxDate = () => {
    let maxDate;
    if (today.substring(0, 4) === minDate) {
      maxDate = today;
    } else {
      maxDate = `${minDate}-12-31`;
    }
    return maxDate;
  };
  const bank = useFetch({
    url: `${ACCT_API.GET_BANK_ACCOUNTS}?type=Bank`,
    method: 'GET',
    authTokenKey: 'auth_token',
  });
  if (bank.data && selectBank.length === 0) {
    setSelectBank(
      bank.data.response.data
        .filter((item) => item.subAccount)
        .map((item) => ({
          value: item.accountName,
          label: item.accountName,
        })),
    );
  }

  const check = useFetch({
    url: `${ACCT_API.GET_CHECKBANKS}`,
    method: 'GET',
    authTokenKey: 'auth_token',
  });
  if (check.data && checkBank.length === 0) {
    setCheckBank(
      check.data.response.filter((i) => i.checkBank !== '').map((item) => ({
        value: item.checkBank,
        label: item.checkBank,
        editIcon: <EditButton type="button" onClick={() => { setEditedCheckBank(item); setCheckEdit(true); }}><img src={editIcon} alt="edit" /></EditButton>,
      })),
    );
  }

  const categories = useFetch({
    url: `${ACCT_API.GET_CATEGORY}`,
    method: 'GET',
    authTokenKey: 'auth_token',
  });
  if (categories.data && category.length === 0) {
    setCategory(
      categories.data.response.data.map((item) => ({
        value: item.accountName,
        label: item.accountName,
      })),
    );
  }

  const getCheckBank = async () => {
    try {
      const res = await fetcher({
        url: `${ACCT_API.GET_CHECKBANKS}`,
        method: 'GET',
        authTokenKey: 'auth_token',
      });
      if (res) {
        setCheckBank(
          res.response
            .map((item) => ({
              value: item.checkBank,
              label: item.checkBank,
              editIcon: <EditButton type="button" onClick={() => { setEditedCheckBank(item); setCheckEdit(true); }}><img src={editIcon} alt="edit" /></EditButton>,
            })),
        );
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };

  const handleLineItems = (items) => {
    setItems(
      items.map((item) => ({
        item_name: item.category,
        rate: item.amount,
        particulars: item.particular,
      })),
    );
  };

  const handleSubmit = async () => {
    let payload = {
      membershipId: memberId,
      receiptDate: date,
      bankAccount,
      paymentMethod,
      lineItems: items,
    };
    if (paymentMethod === 'Check') {
      payload = {
        ...payload,
        checkDate: date,
        checkBank: selectedCheckbank,
        checkNumber,
      };
    }
    if (memberId === '' || memberId === undefined) {
      toast.error('Error! Please Select a member ', { autoClose: 3000 });
    } else if (items[0].item_name === '') {
      toast.error('Error! Please add category ', { autoClose: 3000 });
    } else if
    (paymentMethod === 'Check' && (selectedCheckbank === '')) {
      toast.error('Error! Please add Check Bank ', { autoClose: 3000 });
    } else if
    (paymentMethod === 'Check' && (checkNumber === '' || checkNumber === undefined)) {
      toast.error('Error! Please add Check Number ', { autoClose: 3000 });
    } else if
    (items[0].rate === '') {
      toast.error('Error! Please add an amount ', { autoClose: 3000 });
    } else {
      setLoading(true);
      setButton(true);
      try {
        const res = await fetcher({
          url: `${ACCT_API.CREATE_RECEIPT}`,
          method: 'POST',
          authTokenKey: 'auth_token',
          body: JSON.stringify(payload),
        });
        if (res.success) {
          toast.success('Successfully Created!', { autoClose: 3000 });
          setLoading(false);
          setTimeout(() => {
            setButton(false);
            window.location.reload();
          }, 2000);
        } else {
          toast.error('Error!', { autoClose: 3000 });
          setLoading(false);
          setButton(false);
        }
      } catch (e) {
        toast.error('Error!', { autoClose: 3000 });
        setLoading(false);
        setButton(false);
      }
    }
  };
  const createCheckBank = async (value) => {
    try {
      const res = await fetcher({
        url: `${ACCT_API.CREATE_CHECK_BANK}`,
        method: 'POST',
        authTokenKey: 'auth_token',
        body: JSON.stringify({ name: value }),
      });
      if (res.success) {
        toast.success('Successfully Created!', { autoClose: 3000 });
        getCheckBank();
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };
  const editCheckBank = async () => {
    const payload = {
      id: editedCheckBank.id,
      name: editedCheckBank.checkBank,
    };
    try {
      const res = await fetcher({
        url: `${ACCT_API.EDIT_CHECKBANK}`,
        method: 'POST',
        authTokenKey: 'auth_token',
        body: JSON.stringify(payload),
      });
      if (res.success) {
        toast.success('Successfully Edited!', { autoClose: 3000 });
        setCheckEdit(false);
        getCheckBank();
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };
  return (
    <>
      <AccountingMenu />
      <AccountingPageLayout>
        {loading ? <Loader /> : null }
        <PageHeading>Church Receipts</PageHeading>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <OptionsWrapperDiv>
          <Row>
            <Col col={6}>
              <Row>
                <SearchSelect
                  className="select"
                  classNamePrefix="custom"
                  placeholder="Enter name or Family Id"
                  loadOptions={(value) => getName(value)}
                  onChange={(e) => {
                    setMemberId(e.value);
                    getAddress(e.value);
                  }}
                />
              </Row>
              <Row>
                <Col col={8}>
                  <CustomSelect
                    defaultValue={{
                      label: 'Gen. Funds A/C - Checking - XXXXXX7994',
                      value: 'Gen. Funds A/C - Checking - XXXXXX7994',
                    }}
                    className="select"
                    classNamePrefix="custom"
                    options={selectBank}
                    onChange={(e) => setBankAccount(e.value)}
                    // placeholder="Bank Accounts"
                  />
                </Col>
                <Col col={4}>
                  <CustomSelect
                    className="select"
                    classNamePrefix="custom"
                    defaultValue={{ label: 'Check', value: 'Check' }}
                    options={options}
                    placeholder="Payment Methods"
                    onChange={(e) => setPaymentMethod(e.value)}
                  />
                </Col>
              </Row>
            </Col>
            <Col col={6}>
              <Row>
                <AddressBar
                  value={
                    address && memberId !== '0001'
                      ? `${address.address1} ${address.address2}\n${address.city}, ${address.state}, ${address.zipcode}`
                      : ''
                  }
                  placeholder="Address"
                  disabled
                />
              </Row>

              <Row>
                <Col col={4}>
                  <StyledDate
                    width="95%"
                    type="date"
                    min={`${minDate}-01-01`}
                    max={getMaxDate()}
                    placeholder="Check Number"
                    onKeyDown={(e) => e.preventDefault()}
                    onChange={(e) => setDate(e.target.value)}
                  />
                </Col>
                {paymentMethod === 'Check' ? (
                  <>
                    <Col col={4}>
                      <CheckNumber
                        type="text"
                        placeholder="Check Number"
                        onChange={(e) => setCheckNumber(e.target.value)}
                      />
                    </Col>
                    <Col col={4}>
                      {checkEdit ? (
                        <Row>
                          <Col col={10}>
                            <EditCheckBank type="text" value={editedCheckBank.checkBank} onChange={(e) => setEditedCheckBank({ ...editedCheckBank, checkBank: e.target.value })} />
                          </Col>
                          <Col col={2} padding="4px 0 0 0">
                            <EditButton margin="3px 0px" type="button" onClick={() => editCheckBank()}><img src={tickIcon} alt="save" /></EditButton>
                            <EditButton margin="3px 0px" type="button" onClick={() => { setCheckEdit(false); setEditedCheckBank(); }}><img src={closeIcon} alt="cancel" /></EditButton>
                          </Col>
                        </Row>
                      )
                        : (
                          <CreateSelect
                            className="select"
                            classNamePrefix="custom"
                            getOptionLabel={(e) => (
                              <Row>
                                <Col col={10}>{e.label}</Col>
                                <Col col={2}>{e.editIcon}</Col>
                              </Row>
                            )}
                            onCreateOption={(value) => createCheckBank(value)}
                            options={checkBank}
                            placeholder="Check Bank"
                            onChange={(e) => setSelectedCheckbank(e.value)}
                          />
                        )}
                    </Col>
                  </>
                ) : null}
              </Row>
            </Col>
          </Row>
        </OptionsWrapperDiv>
        <CreateTable
          categories={category}
          handleLineItems={handleLineItems}
          formatter={formatter}
        />
        <Row>
          <Col col={10} />
          <Col col={2}>
            <ReceiptSaveButton type="button" disabled={button} onClick={() => handleSubmit()}>
              Submit
              <ReceiptSaveButtonIcon>
                <img src={tick} alt="arrow" height="16px" />
              </ReceiptSaveButtonIcon>
            </ReceiptSaveButton>
          </Col>
        </Row>
      </AccountingPageLayout>
    </>
  );
};

export default CreateReceipts;

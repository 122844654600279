/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const WrapperDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  display: flex;
  border-radius: ${({ theme }) => theme.border.radius_m};
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.primary_blue};
  font-weight: 900;
  font-size: 1.3em;
  margin: 17px 0 10px 20px;
  border-radius: ${({ theme }) => theme.border.radius_m};
`;

export const DownloadButton = styled.button`
  position: relative;
  font-size: ${({ theme }) => theme.fonts.font_size.big_font};
  font-weight: ${({ theme }) => theme.fonts.font_weight.normal_font};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary_blue};
  border-radius: ${({ theme }) => theme.border.radius_s};
  border: 2px solid ${({ theme }) => theme.colors.secondary_background};
  height: 40px;
  padding: 0;
  margin: 10px 0 10px 20px;
  width: 55%;
`;

export const DownloadButtonIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  border: 1px solid #000;
  border-radius: 50px;
  height: 20px;
  width: 20px;
  margin: 8px;
`;

export const DownloadImage = styled.img`
  margin-top: 3px;
`;

import styled from 'styled-components';
import Select from 'react-select';

/* eslint-disable import/prefer-default-export */
export const TableDiv = styled.div`
  table {
    width: 100%;
    border-spacing: 0 8px;
    border-collapse: separate;

    tr {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const TableHead = styled.th`
  width: 60%;
  background-color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  font-size: 18px;
  text-align: left;
  padding: 18px;
  color: ${({ theme }) => theme.colors.primary_blue};
  :first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  :last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;
export const CustomSelect = styled(Select)`
  .custom__control {
    height: 50px;
    background-color: ${({ theme }) => theme.colors.page_background};
    border: 1px solid ${({ theme }) => theme.colors.page_background};
    .custom__placeholder {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.primary_blue};
    }
    .custom__single-value {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.primary_blue};
    }
    .custom__indicator-separator {
      display: none;
    }
  }
`;
export const TableInput = styled.td`
  text-align: ${(props) => props.textAlign || 'left'};
  font-size: 16px;
  white-space: nowrap;
  padding: 10px;
  color: ${({ theme }) => theme.colors.primary_blue};
  :first-child {
    border-top-left-radius: ${({ theme }) => theme.border.radius_m};
    border-bottom-left-radius: ${({ theme }) => theme.border.radius_m};
  }

  :last-child {
    border-top-right-radius: ${({ theme }) => theme.border.radius_m};
    border-bottom-right-radius: ${({ theme }) => theme.border.radius_m};
  }
`;
export const TableData = styled.td`
  text-align: ${(props) => props.textAlign || 'left'};
  font-size: 18px;
  font-weight: 600;
  white-space: nowrap;
  padding: 18px;
  color: ${({ theme }) => theme.colors.primary_blue};
  :first-child {
    border-top-left-radius: ${({ theme }) => theme.border.radius_m};
    border-bottom-left-radius: ${({ theme }) => theme.border.radius_m};
  }

  :last-child {
    border-top-right-radius: ${({ theme }) => theme.border.radius_m};
    border-bottom-right-radius: ${({ theme }) => theme.border.radius_m};
  }
`;
export const StyledInput = styled.input`
  text-align: ${(props) => props.textAlign || 'left'};
  padding-left: 5%;
  width: 88%;
  height: 48px;
  border-radius: 5px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary_blue};
  background-color: ${({ theme }) => theme.colors.page_background};
  border: 1px solid ${({ theme }) => theme.colors.page_background};

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
export const IconWrapper = styled.div`
    cursor: pointer;
    margin: 0 5px;
    border-radius: 5px;
    height: 30px;
    width: 22px;
    background-color: ${({ theme }) => theme.colors.page_background};
    border: 1px solid ${({ theme }) => theme.colors.page_background};
`;
export const ActionIcon = styled.img`
  padding-top: 8px;
`;

import React from 'react';
import { isMobile } from 'react-device-detect';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';
import Header from '../header';
import Sidebar from '../sidebar';
import { Row, Col } from '../styled_components/grid/Grid.style';
import { PageLayoutWrapper } from './Layout.style';
import MobileLayout from '../mobile_views/layout';

const Layout = () => {
  const { loggedIn } = useAuth();
  if (!loggedIn) {
    return <Navigate to="/login" />;
  }
  if (isMobile) {
    return <MobileLayout />;
  }
  return (
    <PageLayoutWrapper>
      <Header />
      <Row>
        <Col col={1}>
          <Sidebar />
        </Col>
        <Col col={11}>
          <Outlet />
        </Col>
      </Row>
    </PageLayoutWrapper>
  );
};

export default Layout;

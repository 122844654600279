import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { ModalProvider } from 'styled-react-modal';
import moment from 'moment';
import Cookies from 'js-cookie';
import {
  Col,
  Row,
} from '../../../../components/styled_components/grid/Grid.style';
import {
  AccountingPageLayout,
  PageHeading,
} from '../../../../theme/GlobalStyles';
import {
  ReceiptSearchInputWrap,
  TransactionReceiptDisplayButton,
  TransactionReceiptDisplayButtonIcon,
  SearchInput,
  TotalAmtDiv,
  Total,
  TransactionReceiptsCustomSelect,
  SearchSelect,
  NoData,
} from './TransactionsReceipts.style';
import arrowRightIcon from '../../../../assets/icons/arrow-right.svg';
import RadioButtons from '../../../../components/common/radio_buttons';
import Table from '../../../../components/common/table';
import AccountingMenu from '../../../../components/accounting/menu';
import Breadcrumbs from '../../../../components/breadcrumbs';
import { ACCT_API, API } from '../../../../constants';
import Loader from '../../../../components/styled_components/loader/loader.style';
import { fetcher, useFetch, fileDownloader } from '../../../../hooks/useFetch';
import ScrollDownButton from '../../../../utils/ScrollDownButton';
import ScrollUpButton from '../../../../utils/ScrollUpButton';
import EditModal from '../../../../components/accounting/edit_transactions/edit_modal';
import DeleteModal from '../../../../components/accounting/delete_transactions/delete_modal';

const TransactionReceipts = () => {
  const breadcrumbs = ['Accounting', 'Transactions', 'Receipts'];
  const [data, setData] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [memberId, setMemberId] = useState();
  const [selectedGroup, setSelectedGroup] = useState([{ value: '', label: 'All' }]);
  const [select, setSelect] = useState('prayer_group');
  const [total, setTotalAmt] = useState(0);
  const [prayerGroup, setPrayerGroup] = useState('');
  const [loading, setLoading] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [lineItems, setLineItems] = useState();
  const [selectedReceipt, setSelectedReceipt] = useState();
  const [address, setAddress] = useState();
  const [selectBank, setSelectBank] = useState([]);
  const [checkBank, setCheckBank] = useState([]);
  const [category, setCategory] = useState([]);
  const [deleteId, setDeleteId] = useState();
  const today = moment().format('YYYY-MM-DD');
  const minDate = Cookies.get('accountant_year');

  const handleSelectChange = (event) => {
    setSelect(event.target.value);
    setData();
  };
  const getName = async (value) => {
    const res = await fetcher({
      url: `${API.GET_MEMBER_LIST}?query=${value}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    const memberNames = res.member_list.map((item) => ({
      value: item.membershipNumber,
      label: item.name,
    }));
    if (value.match(/0/i) || value.match(/non/i)) {
      return [...memberNames, { value: '0001', label: '0001 Non Member' }];
    } else {
      return memberNames;
    }
  };

  const handleView = async () => {
    setLoading(true);
    if (select === 'member_wise') {
      const res = await fetcher({
        url: `${ACCT_API.GET_MEMBER_RECEIPTS}?membershipId=${memberId}&startDate=${startDate}&endDate=${endDate}`,
        method: 'GET',
        authTokenKey: 'auth_token',
      });
      setData(res.receipts);
      setLoading(false);
      let amt = 0;
      res.receipts.forEach((item) => {
        amt += item.amount;
      });
      setTotalAmt(amt);
    } else {
      const res = await fetcher({
        url: `${ACCT_API.GET_PRAYER_GROUP_RECEIPTS}?startDate=${startDate}&endDate=${endDate}&prayerGroup=${prayerGroup}`,
        method: 'GET',
        authTokenKey: 'auth_token',
      });
      setData(res.response.map((item) => ({
        ...item, membershipId: item.membershipId.split('-')[0],
      })));
      setLoading(false);
      const rates = [];
      let amt = 0;
      res.response.map((item) => item.lines.map((i) => rates.push(Number(i.rate))));
      if (rates.length > 0) {
        amt = rates.reduce((a, b) => a + b);
      }
      setTotalAmt(amt);
    }
  };
  const group = useFetch({
    url: API.GET_PRAYER_GROUP,
    method: 'GET',
    authTokenKey: 'auth_token',
  });
  if (group.data && selectedGroup.length === 1) {
    setSelectedGroup([...selectedGroup, ...group.data.area_list.filter((item) => item.areaName !== '').map((item) => ({
      value: item.areaName.replace('&', '%26'),
      label: item.areaName,
    }))]);
  }

  const tableLookup = [
    {
      heading: 'Family ID',
      field_name: 'membershipId',
      type: 'string',
    },
    {
      heading: 'Member Name',
      field_name: 'memberName',
      type: 'string',
    },
    {
      heading: 'Date',
      field_name: 'date',
      type: 'date',
    },
    {
      heading: 'Category',
      field_name: 'lines',
      type: 'array',
    },
    {
      heading: 'Notes',
      field_name: 'lines',
      type: 'particulars',
    },
    {
      heading: 'Payment Method',
      field_name: 'paymentMethod',
      type: 'string',
    },
    {
      heading: 'Amount',
      field_name: 'lines',
      type: 'rate',
    },
    {
      heading: 'Action',
      field_name: 'actions',
      type: 'download',
    },
  ];
  const memberTableLookup = [
    {
      heading: 'Date',
      field_name: 'date',
      type: 'date',
    },
    {
      heading: 'Category',
      field_name: 'lines',
      type: 'array',
    },
    {
      heading: 'Notes',
      field_name: 'lines',
      type: 'particulars',
    },
    {
      heading: 'Payment Method',
      field_name: 'paymentMethod',
      type: 'string',
    },
    {
      heading: 'Amount',
      field_name: 'lines',
      type: 'rate',
    },
    {
      heading: 'Action',
      field_name: 'actions',
      type: 'download',
    },
  ];
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const getTableLookup = () => {
    if (select === 'member_wise') {
      return memberTableLookup;
    }
    return tableLookup;
  };
  const downloadPdf = async (id) => {
    const downloadReceipts = await fileDownloader({
      url: `${ACCT_API.DOWNLOAD_RECEIPTS}?id=${id}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    saveAs(new Blob([downloadReceipts], { type: 'application/pdf' }), 'Transaction Receipts.pdf');
  };
  const getAddress = async (memberId) => {
    const res = await fetcher({
      url: `${API.GET_MEMBER_ADDRESS}?family_id=${memberId}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    setAddress(res);
  };
  const getCheckBank = async () => {
    try {
      const res = await fetcher({
        url: `${ACCT_API.GET_CHECKBANKS}`,
        method: 'GET',
        authTokenKey: 'auth_token',
      });
      if (res) {
        setCheckBank(
          res.response
            .map((item) => ({
              value: item.checkBank,
              label: item.checkBank,
            })),
        );
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };

  const handleEdit = async (id) => {
    setEditModal(true);
    setLoading(true);
    const receipt = await fetcher({
      url: `${ACCT_API.GET_RECEIPTS}?id=${id}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    getAddress(receipt.response.membershipId);
    setSelectedReceipt(receipt.response);
    setLineItems(receipt.response.lines.map((item) => ({
      category: item.itemName,
      amount: item.rate,
      particular: item.particulars,
    })));
    setLoading(false);
  };
  const bank = useFetch({
    url: `${ACCT_API.GET_BANK_ACCOUNTS}?type=Bank`,
    method: 'GET',
    authTokenKey: 'auth_token',
  });
  if (bank.data && selectBank.length === 0) {
    setSelectBank(
      bank.data.response.data
        .filter((item) => item.subAccount)
        .map((item) => ({
          value: item.accountName,
          label: item.accountName,
        })),
    );
  }

  const check = useFetch({
    url: `${ACCT_API.GET_CHECKBANKS}`,
    method: 'GET',
    authTokenKey: 'auth_token',
  });
  if (check.data && checkBank.length === 0) {
    setCheckBank(
      check.data.response.filter((i) => i.checkBank !== '').map((item) => ({
        value: item.checkBank,
        label: item.checkBank,
      })),
    );
  }

  const categories = useFetch({
    url: `${ACCT_API.GET_CATEGORY}`,
    method: 'GET',
    authTokenKey: 'auth_token',
  });
  if (categories.data && category.length === 0) {
    setCategory(
      categories.data.response.data.map((item) => ({
        value: item.accountName,
        label: item.accountName,
      })),
    );
  }
  const getMaxDate = () => {
    let maxDate;
    if (today.substring(0, 4) === minDate) {
      maxDate = today;
    } else {
      maxDate = `${minDate}-12-31`;
    }
    return maxDate;
  };
  const createCheckBank = async (value) => {
    try {
      const res = await fetcher({
        url: `${ACCT_API.CREATE_CHECK_BANK}`,
        method: 'POST',
        authTokenKey: 'auth_token',
        body: JSON.stringify({ name: value }),
      });
      if (res.success) {
        toast.success('Successfully Created!', { autoClose: 3000 });
        getCheckBank();
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };
  const handleChange = (e) => {
    const data = { ...selectedReceipt };
    data[e.target.name] = e.target.value;
    setSelectedReceipt(data);
  };
  const handleLineItems = (items) => {
    setLineItems(items);
  };
  const handleSubmit = async () => {
    const items = lineItems.map((item) => ({
      item_name: item.category,
      particulars: item.particular,
      rate: parseFloat(item.amount),
    }));
    let payload = {
      receiptId: selectedReceipt.id,
      receiptDate: selectedReceipt.date,
      membershipId: selectedReceipt.membershipId,
      bankAccount: selectedReceipt.bankAccount,
      paymentMethod: selectedReceipt.paymentMethod,
      lineItems: items,
    };
    if (selectedReceipt.paymentMethod === 'Check') {
      payload = {
        ...payload,
        checkBank: selectedReceipt.checkBank,
        checkNumber: selectedReceipt.checkNumber,
      };
    }
    try {
      const res = await fetcher({
        url: `${ACCT_API.EDIT_RECEIPTS}`,
        method: 'POST',
        authTokenKey: 'auth_token',
        body: JSON.stringify(payload),
      });
      if (res.success) {
        toast.success('Successfully Edited!', { autoClose: 3000 });
        handleView();
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };

  const handleDeleteSubmit = async (reason) => {
    const payload = {
      receiptId: deleteId,
      confirm: 'yes',
      notes: reason,
    };
    try {
      const res = await fetcher({
        url: ACCT_API.VOID_RECEIPTS,
        method: 'POST',
        authTokenKey: 'auth_token',
        body: JSON.stringify(payload),
      });
      if (res) {
        toast.success('Successfully Deleted!', { autoClose: 3000 });
        handleView();
        setDeleteId('');
      } else {
        toast.error('Error!', { autoClose: 3000 });
      }
    } catch (e) {
      toast.error('Error!', { autoClose: 3000 });
    }
  };

  const deleteItem = async (id) => {
    setDeleteId(id);
    setDeleteModal(true);
  };
  const toggleModal = () => {
    setSelectedReceipt();
    setLineItems();
    setEditModal(false);
  };

  const toggleDeleteModal = () => {
    setDeleteModal(false);
    setDeleteId('');
  };

  const handleDisplay = (e) => {
    e.preventDefault();
    handleView();
    setData();
  };

  return (
    <>
      <AccountingMenu />
      {loading ? <Loader /> : null}
      <ScrollUpButton />
      <ScrollDownButton />
      <ModalProvider>
        {lineItems ? (
          <EditModal
            lineItems={lineItems}
            selected={selectedReceipt}
            selectBank={selectBank}
            checkBank={checkBank}
            category={category}
            address={address}
            createCheckBank={createCheckBank}
            getAddress={getAddress}
            getName={getName}
            handleChange={handleChange}
            handleLineItems={handleLineItems}
            handleSubmit={handleSubmit}
            toggleModal={toggleModal}
            isOpen={editModal}
            formatter={formatter}
            minDate={minDate}
            getMaxDate={getMaxDate}
          />
        ) : null}
      </ModalProvider>
      <ModalProvider>
        <DeleteModal
          handleDeleteSubmit={handleDeleteSubmit}
          toggleDeleteModal={toggleDeleteModal}
          isOpen={deleteModal}
        />
      </ModalProvider>
      <AccountingPageLayout>
        <Row>
          <Col>
            <PageHeading>Receipts</PageHeading>
          </Col>
        </Row>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <ReceiptSearchInputWrap>
          <form onSubmit={handleDisplay}>
            <Row>
              <Col col={4}>
                <RadioButtons
                  label1="Prayer Group"
                  value1="prayer_group"
                  label2="Member Wise"
                  value2="member_wise"
                  marginTop="5px"
                  handleSelectChange={handleSelectChange}
                  select={select}
                />
              </Col>
              <Col col={2}>
                {select === 'member_wise' ? (
                  <SearchSelect
                    className="select"
                    classNamePrefix="custom"
                    placeholder="Enter Name or Family Id"
                    loadOptions={(value) => getName(value)}
                    onChange={(e) => {
                      setMemberId(e.value); setData();
                    }}
                  />
                ) : (
                  <TransactionReceiptsCustomSelect
                    className="select"
                    classNamePrefix="custom"
                    options={selectedGroup}
                    onChange={(e) => { setPrayerGroup(e.value); }}
                    placeholder="Select Prayer Group"
                  />
                )}
              </Col>
              <Col col={2}>
                <SearchInput
                  type="date"
                  min={`${minDate}-01-01`}
                  max={`${minDate}-12-31`}
                  placeholder="Start Date"
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Col>
              <Col col={2}>
                <SearchInput
                  type="date"
                  min={`${minDate}-01-01`}
                  max={`${minDate}-12-31`}
                  placeholder="End Date"
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Col>
              <Col col={2}>
                <TransactionReceiptDisplayButton
                  type="submit"
                  disabled={!(startDate && endDate)}
                >
                  Display
                  <TransactionReceiptDisplayButtonIcon>
                    <img src={arrowRightIcon} alt=">" height="16px" />
                  </TransactionReceiptDisplayButtonIcon>
                </TransactionReceiptDisplayButton>
              </Col>
            </Row>
          </form>
        </ReceiptSearchInputWrap>
        {data && data.length > 0 ? (
          <>
            <Table
              data={data || []}
              tableLookup={getTableLookup()}
              downloadPdf={downloadPdf}
              handleEdit={handleEdit}
              deleteItem={deleteItem}
            />
            <TotalAmtDiv>
              <Total>
                {`$ ${formatter.format(total)}`}
              </Total>
            </TotalAmtDiv>
          </>
        ) : data && data.length === 0 ? (
          <NoData>
            No Data
          </NoData>
        ) : ''}
      </AccountingPageLayout>
    </>
  );
};

export default TransactionReceipts;

import React from 'react';
import PropTypes from 'prop-types';
import PanelHeading from '../../membership_components/panel_heading';
import { Row, Col } from '../../styled_components/grid/Grid.style';
import {
  AddressLayout,
  Panel,
  PanelBody,
  FieldLabel,
  FieldValue,
  Div,
  AddressCustomSelect,
  Required,
} from './AddressPanel.style';
import { statesUS } from '../../../constants';

const AddressPanel = ({
  formType, address, handleChange, text,
}) => (
  <>
    <AddressLayout>
      <Row>
        <Panel marginRight="20px">
          <PanelHeading label="Address" />
          <PanelBody>
            <Row marginBottom="17px">
              <Col col={4}>
                <Div className="addMargin">
                  <FieldLabel>
                    Address Line 1
                    <Required>*</Required>
                  </FieldLabel>
                  <FieldValue
                    disabled={formType === 'existing'}
                    type="text"
                    name="Address1"
                    onChange={handleChange}
                    value={formType === 'existing' ? address.address1 : text.Address1}
                  />
                </Div>
              </Col>
              <Col col={4}>
                <Div className="addMargin">
                  <FieldLabel>
                    Address Line 2
                    <Required>*</Required>
                  </FieldLabel>
                  <FieldValue
                    disabled={formType === 'existing'}
                    type="text"
                    name="Address2"
                    onChange={formType === 'new' ? handleChange : ''}
                    value={formType === 'existing' ? address.address2 : text.Address2}
                  />
                </Div>
              </Col>
              <Col col={4}>
                <Div className="addMargin">
                  <FieldLabel>
                    City
                    <Required>*</Required>
                  </FieldLabel>
                  <FieldValue
                    disabled={formType === 'existing'}
                    type="text"
                    name="City"
                    onChange={formType === 'new' ? handleChange : ''}
                    value={formType === 'existing' ? address.city : text.City}
                  />
                </Div>
              </Col>
            </Row>
            <Row marginBottom="17px">
              <Col col={4}>
                <Div className="addMargin">
                  {formType === 'existing' ? (
                    <>
                      <FieldLabel>
                        State
                        <Required>*</Required>
                      </FieldLabel>
                      <FieldValue
                        disabled={formType === 'existing'}
                        type="text"
                        name="State"
                        value={address.state}
                        onChange={formType === 'new' ? handleChange : ''}
                      />
                    </>
                  ) : (
                    <AddressCustomSelect
                      className="select"
                      classNamePrefix="custom"
                      options={statesUS}
                      name="State"
                      onChange={(event) => handleChange({
                        target: { name: 'State', value: event.abbreviation },
                      })}
                      placeholder="State"
                    />
                  )}
                </Div>
              </Col>
              <Col col={4}>
                <Div className="addMargin">
                  <FieldLabel>
                    Zip Code
                    <Required>*</Required>
                  </FieldLabel>
                  <FieldValue
                    disabled={formType === 'existing'}
                    value={formType === 'existing' ? address.zipcode : text.Zip}
                    type="number"
                    name="Zip"
                    onChange={formType === 'new' ? handleChange : ''}
                    placeholder={
                      formType === 'new'
                        ? 'Please enter 5 digit numberic zip code'
                        : ''
                    }
                  />
                </Div>
              </Col>
              <Col col={4}>
                <Div className="addMargin">
                  <FieldLabel>
                    Mother Parish
                    <Required>*</Required>
                  </FieldLabel>
                  <FieldValue
                    disabled={formType === 'existing'}
                    type="text"
                    name="motherParish"
                    onChange={formType === 'new' ? handleChange : ''}
                    value={formType === 'existing' ? address.motherParish : text.motherParish}
                  />
                </Div>
              </Col>
            </Row>
          </PanelBody>
        </Panel>
      </Row>
    </AddressLayout>
  </>
);
AddressPanel.propTypes = {
  formType: PropTypes.string,
  address: PropTypes.object,
  handleChange: PropTypes.func,
  text: PropTypes.object,
};
export default AddressPanel;

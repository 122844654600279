import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Breadcrumbs from '../../../../components/breadcrumbs';
import MemberReportsMenu from '../../../../components/member_reports/menu';
import { API } from '../../../../constants';
import Loader from '../../../../components/styled_components/loader/loader.style';
import { fetcher } from '../../../../hooks/useFetch';
import {
  AccountingPageLayout,
  PageHeading,
  PageSubHeading,
} from '../../../../theme/GlobalStyles';
import Table from '../../../../components/common/table';
import {
  Col,
  Row,
} from '../../../../components/styled_components/grid/Grid.style';
import ScrollUpButton from '../../../../utils/ScrollUpButton';
import ScrollDownButton from '../../../../utils/ScrollDownButton';

function AllMembersDetails() {
  const breadcrumbs = ['Reports', 'Label Reports', 'All Head Of The House'];
  const [loading, setLoading] = useState(false);
  const [headOfHouseList, setHeadOfHouseList] = useState([]);
  const [text, setText] = useState('');

  const getList = async () => {
    setLoading(true);
    setText('');
    const res = await fetcher({
      url: `${API.GET_ALL_HEAD_OF_THE_HOUSE}?category=HeadOfFamily`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    if (res.head_of_house) {
      setLoading(false);
      setHeadOfHouseList(res.head_of_house);
    } else {
      setLoading(false);
      setText('No Data');
      toast.warn('No Data', { autoClose: 3000 });
    }
  };

  const headers = [
    {
      heading: 'Membership Number',
      field_name: 'membership_number',
      type: 'id',
    },
    {
      heading: 'Name',
      field_name: 'name',
      type: 'name',
    },
  ];

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <MemberReportsMenu />
      {loading ? <Loader /> : null}
      <ScrollUpButton />
      <ScrollDownButton />
      <AccountingPageLayout>
        <PageHeading>All Head Of The House</PageHeading>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Row>
          <Col col={1}>
            <PageSubHeading>{text}</PageSubHeading>
          </Col>
        </Row>
        {headOfHouseList.length > 0 && (
          <>
            <Table data={headOfHouseList} tableLookup={headers} />
          </>
        )}
      </AccountingPageLayout>
    </>
  );
}

export default AllMembersDetails;

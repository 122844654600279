import React from 'react';
import AccountingMenu from '../../../components/accounting/menu';
import ReportSubMenu from '../../../components/accounting/report_submenu';
import Breadcrumbs from '../../../components/breadcrumbs';
import { AccountingPageLayout, PageHeading } from '../../../theme/GlobalStyles';

const breadcrumbs = ['Accounting', 'Reports', 'Church Reports'];
const Reports = () => (
  <>
    <AccountingMenu />
    <AccountingPageLayout>
      <PageHeading>Reports</PageHeading>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <ReportSubMenu />
    </AccountingPageLayout>
  </>
);

export default Reports;

import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from '../../styled_components/grid/Grid.style';

import defaultPic from '../../../assets/icons/default-profile.png';
import editIcon from '../../../assets/icons/pen.svg';

import {
  FamilyCardWrapper,
  FamilyRelation,
  FamilyName,
  FamilyProfilePic,
  EditIconDiv,
  EditIcon,
} from './FamilyPanel.style';

const FamilyPanel = ({
  marginRight, member,
  memberChange, showEditIcon,
}) => {
  const navigate = useNavigate();
  const handleClick = (link) => {
    navigate(link);
  };
  return (
    <>
      <FamilyCardWrapper key={member && member.id} marginRight={marginRight}>
        <Row>
          <Col col={2}>
            <FamilyProfilePic
              onError={(e) => { e.target.onerror = null; e.target.src = defaultPic; }}
              src={member && member.profile_pic_url ? member.profile_pic_url : defaultPic}
              alt="default pic"
              onClick={() => handleClick(`/members/details/${member.ID ? member.ID : member.id}`)}
            />
          </Col>
          <Col col={9}>
            <Row>
              <Col>
                <FamilyName onClick={() => handleClick(`/members/details/${member.ID ? member.ID : member.id}`)}>
                  {member && member.name}
                  (
                  {member && member.gender}
                  )
                </FamilyName>
              </Col>
            </Row>
            <Row>
              <Col col={12}>
                <FamilyRelation>{member && member.relation}</FamilyRelation>
                <FamilyRelation>{member && member.age}</FamilyRelation>
              </Col>
            </Row>
          </Col>
          {showEditIcon
            ? (
              <Col col={1}>
                <EditIconDiv onClick={() => memberChange(member.id)}>
                  <EditIcon src={editIcon} />
                </EditIconDiv>
              </Col>
            ) : null}
        </Row>
      </FamilyCardWrapper>
    </>
  );
};
FamilyPanel.propTypes = {
  marginRight: PropTypes.string,
  member: PropTypes.object,
  showEditIcon: PropTypes.bool,
  memberChange: PropTypes.func,
};

export default FamilyPanel;

import styled from 'styled-components';
import Select from 'react-select';

export const GeneralReportsDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 5px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 4%);
  border-radius: ${({ theme }) => theme.border.radius_m};
  margin: ;
`;
export const DisplayButton = styled.button`
  position: relative;
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  background-color: ${({ theme }) => theme.colors.primary_red};
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius_m};
  border: none;
  height: 50px;
  padding: 0;
  margin-left: 100px;
  width: 150px;
  float: right;
`;
export const DisplayButtonIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(12, 0, 0, 0.15);
  width: 42px;
  height: 35px;
  margin: 0;
  padding: 15px 0 0 0;
  border-radius: ${({ theme }) => theme.border.radius_m};
`;
export const StyledDate = styled.input`
  padding: 0px 5px;
  margin: 5px 5px 0px 5px;
  height: 48px;
  width: 70%;
  border-radius: ${({ theme }) => theme.border.radius_m};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary_blue};
  background-color: ${({ theme }) => theme.colors.page_background};
  border: 1px solid ${({ theme }) => theme.colors.page_background};
`;
export const CustomSelect = styled(Select)`
  width: 100%;
  margin: 6px 0;
  padding: 0 5px;
  .custom__control {
    height: 48px;
    background-color: ${({ theme }) => theme.colors.page_background};
    border: 1px solid ${({ theme }) => theme.colors.page_background};
    border-radius: 10px;
    .custom__placeholder {
      font-size: 13px;
      color: ${({ theme }) => theme.colors.primary_blue};
    }
    .custom__single-value {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.primary_blue};
    }
    .custom__indicator-separator {
      display: none;
    }
  }
`;
export const DownloadButton = styled.button`
  position: relative;
  font-size: ${({ theme }) => theme.fonts.font_size.normal_font};
  font-weight: ${({ theme }) => theme.fonts.font_weight.normal_font};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary_blue};
  border-radius: ${({ theme }) => theme.border.radius_s};
  border: 2px solid ${({ theme }) => theme.colors.secondary_background};
  height: 40px;
  padding: 1rem;
  //margin-left: 10px;
  margin:10px 60px 10px 0px;
  width: 80%;
  float: right;
`;
export const DownloadButtonIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  border: 1px solid #000;
  border-radius: 50px;
  height: 15px;
  width: 20px;
  margin: 11px;
`;
export const DownloadImage = styled.img`
  margin-top: 3px;
`;
export const ButtonWrapper = styled.div`
  margin-left: 5px;
`;

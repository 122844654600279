import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import {
  CloseIcon,
  SaveButton,
  SaveButtonIcon,
  AccountNumber,
  AccountName,
  CustomSelect,
  ModalHeading,
  ModalWrapper,
  CloseButton,
  StyledModal,
} from './editModal.style';
import tick from '../../../../assets/icons/tick-white.svg';
import { Row, Col } from '../../../styled_components/grid/Grid.style';
import close from '../../../../assets/icons/close-icon.svg';

const EditModal = ({
  isEditOpen,
  toggleEditModal,
  editType,
  selectedData,
  editAccount,
  parentAccoutData,
}) => {
  const [state, setState] = useState(selectedData);
  const [parentAccounts, setParentAccounts] = useState([]);
  useEffect(() => {
    setState(selectedData);
  }, [selectedData]);
  useEffect(() => {
    if (editType && editType.subType === 'sub') {
      if (editType && editType.type === 'bank') {
        setParentAccounts(
          parentAccoutData.filter((item) => !item.subAccount)
            .map((i) => ({ value: i.accountName, label: i.accountName })),
        );
      } else {
        setParentAccounts(
          parentAccoutData.map((item) => ({
            value: item.parent_acct_head,
            label: item.parent_acct_head,
          })),
        );
      }
    }
  }, [editType]);

  const handleClickEditAccount = () => {
    if (state.accountName.match(/delete/gi)) {
      toast.error('Entered value is not a valid account name!', { autoClose: 3000 });
    } else {
      editAccount(state);
    }
  };
  return (
    <StyledModal isOpen={isEditOpen}>
      <CloseButton type="button" onClick={toggleEditModal}>
        <CloseIcon>
          <img src={close} alt="arrow" height="16px" />
        </CloseIcon>
      </CloseButton>
      <ModalWrapper>
        <Row>
          <Col col={12}>
            <ModalHeading> Edit Account</ModalHeading>
          </Col>
        </Row>
        <Row>
          {editType && editType.subType === 'head' ? null : (
            <Col col={12}>
              <CustomSelect
                className="select"
                classNamePrefix="custom"
                placeholder="Parent Account"
                options={parentAccounts}
                value={{
                  value: state && state.parentAccount,
                  label: state && state.parentAccount,
                }}
                onChange={(e) => {
                  setState({
                    ...state,
                    parentAccount: e.value,
                  });
                }}
              />
            </Col>
          )}
          <Col col={12}>
            <AccountName
              className="select"
              classNamePrefix="custom"
              placeholder="Account Name"
              value={state && state.accountName}
              onChange={(e) => {
                setState({
                  ...state,
                  accountName: e.target.value,
                });
              }}
            />
          </Col>
          {editType && editType.subType === 'head' ? null : (
            <Col col={12}>
              <AccountNumber
                className="select"
                classNamePrefix="custom"
                placeholder="Account Number"
                value={state && state.accountNumber}
                onChange={(e) => {
                  setState({
                    ...state,
                    accountNumber: e.target.value,
                  });
                }}
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col col={8} />
          <Col col={4}>
            <SaveButton type="button" onClick={handleClickEditAccount}>
              Save
              <SaveButtonIcon>
                <img src={tick} alt="arrow" height="16px" />
              </SaveButtonIcon>
            </SaveButton>
          </Col>
        </Row>
      </ModalWrapper>
    </StyledModal>
  );
};
EditModal.propTypes = {
  isEditOpen: PropTypes.bool,
  toggleEditModal: PropTypes.func,
  editType: PropTypes.object,
  selectedData: PropTypes.object,
  editAccount: PropTypes.func,
  parentAccoutData: PropTypes.array,
};
export default EditModal;

import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const JournalVoucherPanelWrapper = styled.div`
  margin: 10px 0;
  padding: 15px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary_blue};
  border-radius: ${({ theme }) => theme.border.radius_m};
`;

export const JournalVoucherPanelBody = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.page_background};
  padding: 10px 0px;
  margin-top: 10px;
`;

export const JournalVoucherPanelDateDiv = styled.div`
  width: 95px;
  color: ${({ theme }) => theme.colors.primary_blue};
  padding: 10px;
  font-family: 'Archivo', serif;
  font-weight: bold;
  background-color: rgba(57, 62, 92, 0.13);
  // background-color: ${({ theme }) => theme.colors.secondary_blue};
  // opacity: 13%;
  border-radius: 30px;
`;

export const JournalVoucherPanelTotal = styled.div`
  width: 100%;
  padding: 10px 0 0 0px;
  font-weight: bold;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.primary_blue};
`;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import {
  FamilyPhoto,
  LocationIcon,
  MemberInformation,
  MemberName,
  SeparatorIcon,
  TopSection,
  AddressLine,
  CommunicationLine,
  CommunicationIcon,
  CommunicationBody,
  SocialIcon,
  BlueDiv,
  SocialMediaLine,
  TopCol,
  ClearSection,
  BodySection,
  Panel,
  PanelHeading,
  PanelBody,
  ChartLegend,
  ChartLegendLabel,
  ChartLegendItem,
  PersonalInformation,
  UserProfile,
  UserName,
  BirthdayUserName,
  UserRelation,
  HouseMember,
  HouseholdWrapper,
  DashboardLayout,
  BirthdayWrapper,
  BirthdayProfile,
  WeddingUserName,
  Birthday, BirthdayHatIcon, UserPrayerGroup, UserPhone,
  EventDiv, NoContribution,
} from './MemberDashboard.style';
import MobileMemberDashboard from '../mobile_view/member_dashboard';
import { Col, Row } from '../../components/styled_components/grid/Grid.style';
import defaultFamilyProfileImg from '../../assets/img/default-family.png';
import defaultUserProfileImg from '../../assets/img/default-user.png';
import diamondIcon from '../../assets/icons/diamond.svg';
import locationIcon from '../../assets/icons/location.svg';
import mailIcon from '../../assets/icons/mail.svg';
import phoneIcon from '../../assets/icons/phone.svg';
import twitter from '../../assets/icons/twitter.svg';
import linkedIn from '../../assets/icons/linkedIn.svg';
import facebook from '../../assets/icons/facebook.svg';
import instagram from '../../assets/icons/instagram-icon.svg';
import RedDot from '../../assets/icons/red-dot.svg';
import birthdayHat from '../../assets/icons/birthday-hat.svg';
import Contribution from '../../components/dashboard_contribution/contribution';
import { useFetch, fetcher } from '../../hooks/useFetch';
import { API, ACCT_API } from '../../constants';

const MemberDashboard = () => {
  const navigate = useNavigate();
  const userId = Cookies.get('user_id');
  const today = moment(new Date()).format('DD-MM');
  const endDate = moment().format('YYYY-12-31');
  const [contributionData, setContributionData] = useState(null);
  const [event, setEvent] = useState('birthday');
  const [eventData, setEventData] = useState([]);
  const [contributionSum, setContributionSum] = useState();
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const { data } = useFetch({
    method: 'GET',
    url: API.MEMBER_DETAILS,
    authTokenKey: 'auth_token',
  });
  const houseDetails = useFetch({
    method: 'GET',
    url: API.GET_FAMILY,
    authTokenKey: 'auth_token',
  });
  const memberDetails = useFetch({
    method: 'GET',
    url: `${API.MEMBER_DATA}?userid=${userId}`,
    authTokenKey: 'auth_token',
  });
  // method to fetch birthdays
  const birthday = async () => {
    const bData = await fetcher({
      method: 'GET',
      url: `${API.BIRTHDAY}?date=${today}`,
      authTokenKey: 'auth_token',
    });
    if (bData && bData.birthday_list) {
      setEventData(bData.birthday_list);
    }
  };
  // method to fetch anniversary
  const anniversary = async () => {
    const anniversaryData = await fetcher({
      method: 'GET',
      url: `${API.Wedding}?date=${today}`,
      authTokenKey: 'auth_token',
    });
    if (anniversaryData && anniversaryData.wedding_list) {
      setEventData(anniversaryData.wedding_list);
    }
  };
  useEffect(() => {
    birthday();
  }, []);
  useEffect(async () => {
    if (data && data.memberID) {
      Cookies.set('memberId', data.memberID);
      Cookies.set('photoUrl', data.profilePic);
      const cData = await fetcher({
        method: 'GET',
        url: `${ACCT_API.CONTRIBUTION}?endDate=${endDate}&familyId=${data.memberID.split('-')[0]}`,
        authTokenKey: 'auth_token',
      });
      const allZero = Object.values(cData).every((item) => item === 0);
      if (allZero) {
        setContributionData('allZero');
      } else {
        setContributionData(cData);
        const getSum = Object.values(cData).reduce((a, b) => a + b, 0);
        setContributionSum(getSum);
      }
    }
  }, [data]);

  const handleClick = (link) => {
    // eslint-disable-next-line no-restricted-globals
    navigate(link);
  };

  const changeEvent = (event) => {
    setEvent(event);
    if (event === 'anniversary') {
      anniversary();
    } else {
      birthday();
    }
  };

  if (isMobile) {
    return <MobileMemberDashboard />;
  }
  return (
    <DashboardLayout>
      <TopSection>
        <TopCol minWidth="200px">
          <FamilyPhoto
            onError={(e) => { e.target.onerror = null; e.target.src = defaultFamilyProfileImg; }}
            src={data && data.familyPic ? data.familyPic : defaultFamilyProfileImg}
            alt="default pic"
          />
        </TopCol>
        <TopCol minWidth="600px">
          <MemberInformation>
            <Row>
              <Col col={12}>
                <MemberName>{data && `${data.firstName} ${data.lastName}`}</MemberName>
              </Col>
            </Row>
            <AddressLine>
              <Row>
                <Col col={5}>
                  {data && `${data.address1} ${data.address2} ${data.city} ${data.state}`}
                </Col>
                <Col width="2%"><SeparatorIcon src={diamondIcon} /></Col>
                <Col col={1}>{data && data.memberID}</Col>
                <Col width="2%"><SeparatorIcon src={diamondIcon} /></Col>
                <Col col={1}>{data && data.gender === 'M' ? 'Male' : 'Female'}</Col>
              </Row>
            </AddressLine>
            <AddressLine>
              <Row>
                <Col width="5%">
                  <LocationIcon src={locationIcon} alt="location" />
                </Col>
                <Col col={10}>
                  <p>
                    {data && data.prayerGroup.name}
                  </p>
                </Col>
              </Row>
            </AddressLine>
            <Row>
              <Col col={8}>
                <CommunicationLine>
                  <Row>
                    <Col col={7} className="left">
                      <CommunicationIcon src={mailIcon} alt="email" />
                      <CommunicationBody>
                        <div className="heading">Email</div>
                        <div className="details">{data && data.email}</div>
                      </CommunicationBody>
                    </Col>
                    <Col col={5}>
                      <CommunicationIcon src={phoneIcon} alt="phone" />
                      <CommunicationBody>
                        <div className="heading">Phone</div>
                        <div className="details">{data && data.cellPhone}</div>
                      </CommunicationBody>
                    </Col>
                  </Row>
                </CommunicationLine>
                <SocialMediaLine>
                  <BlueDiv>
                    <a
                      href={memberDetails && memberDetails.data
                       && memberDetails.data.twitter}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <SocialIcon src={twitter} />
                    </a>
                  </BlueDiv>
                  <BlueDiv>
                    <a
                      href={memberDetails && memberDetails.data
                       && memberDetails.data.linkedIn}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <SocialIcon src={linkedIn} />
                    </a>
                  </BlueDiv>
                  <BlueDiv>
                    <a
                      href={memberDetails && memberDetails.data
                       && memberDetails.data.facebook}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <SocialIcon src={facebook} />
                    </a>
                  </BlueDiv>
                  <BlueDiv>
                    <a
                      href={memberDetails && memberDetails.data
                       && memberDetails.data.instagram}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <SocialIcon src={instagram} />
                    </a>
                  </BlueDiv>
                </SocialMediaLine>
              </Col>
            </Row>
          </MemberInformation>
        </TopCol>
        <ClearSection />
      </TopSection>
      <BodySection>
        <Row className="first">
          <Col col={6}>
            <Panel marginRight="20px">
              <PanelHeading>
                <Row>
                  <Col width="3%">
                    <img src={RedDot} width={5} height={10} alt="." />
                  </Col>
                  <Col col={11}>My Households</Col>
                </Row>
              </PanelHeading>
              <PanelBody>
                <HouseholdWrapper>
                  {houseDetails && houseDetails.data
                  && houseDetails.data.details.map((houseMate) => (
                    <HouseMember key={houseMate.id}>
                      <UserProfile
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = defaultUserProfileImg;
                        }}
                        src={houseMate.profile_pic_url
                          ? houseMate.profile_pic_url
                          : defaultUserProfileImg}
                        alt="profile"
                        onClick={() => handleClick(`/members/details/${houseMate.id}`)}
                      />
                      <UserName onClick={() => handleClick(`/members/details/${houseMate.id}`)}>
                        {houseMate.name}
                      </UserName>
                      <UserRelation>{houseMate.relation}</UserRelation>
                    </HouseMember>
                  ))}
                  <ClearSection />
                </HouseholdWrapper>
              </PanelBody>
            </Panel>
          </Col>
          <Col col={6}>
            <Panel>
              <PanelHeading>
                <Row>
                  <Col width="3%">
                    <img src={RedDot} width={5} height={10} alt="." />
                  </Col>
                  <Col col={11}>Personal Information</Col>
                </Row>
              </PanelHeading>
              <PanelBody>
                <Row>
                  <Col col={6}>
                    <PersonalInformation>
                      <Row>
                        <Col width="3%">
                          <img src={RedDot} width={5} height={10} alt="." />
                        </Col>
                        <Col col={11}>Birthday</Col>
                      </Row>
                      <Row>
                        <Col col={12} className="value">
                          {data && moment(data.birthday, 'M/D/YY').format('MMM, DD')}
                        </Col>
                      </Row>
                    </PersonalInformation>
                  </Col>
                  <Col col={6}>
                    <PersonalInformation>
                      <Row>
                        <Col width="3%">
                          <img src={RedDot} width={5} height={10} alt="." />
                        </Col>
                        <Col col={11}>City</Col>
                      </Row>
                      <Row>
                        <Col col={12} className="value">
                          {memberDetails
                         && memberDetails.data && memberDetails.data.city}
                        </Col>
                      </Row>
                    </PersonalInformation>
                  </Col>
                </Row>
                <Row>
                  <Col col={6}>
                    <PersonalInformation>
                      <Row>
                        <Col width="3%">
                          <img src={RedDot} width={5} height={10} alt="." />
                        </Col>
                        <Col col={11}>Marital Status</Col>
                      </Row>
                      <Row>
                        <Col col={12} className="value">{data && data.maritalStatus}</Col>
                      </Row>
                    </PersonalInformation>
                  </Col>
                  <Col col={6}>
                    <PersonalInformation>
                      <Row>
                        <Col width="3%">
                          <img src={RedDot} width={5} height={10} alt="." />
                        </Col>
                        <Col col={11}>Status</Col>
                      </Row>
                      <Row>
                        <Col col={12} className="value">
                          {memberDetails
                         && memberDetails.data && memberDetails.data.status}
                        </Col>
                      </Row>
                    </PersonalInformation>
                  </Col>
                </Row>
              </PanelBody>
            </Panel>
          </Col>
        </Row>
        <Row>
          <Col col={6}>
            <Panel marginRight="20px">
              <PanelHeading>
                <Row>
                  <Col width="3%">
                    <img src={RedDot} width={5} height={10} alt="." />
                  </Col>
                  <Col col={11}>Contribution</Col>
                </Row>
              </PanelHeading>
              <PanelBody>
                <Row className="second">
                  <Col col={8}>
                    {contributionData === 'allZero' ? <NoContribution>No Contributions</NoContribution>
                      : contributionData ? (
                        <>
                          <NoContribution>
                            Total Contributions:
                            $
                            {' '}
                            {formatter.format(contributionSum)}
                          </NoContribution>
                          <Contribution
                            cData={contributionData}
                          />
                        </>
                      ) : null}
                  </Col>
                  <Col col={4} alignSelf="center">
                    <Row>
                      <Col col={12}>
                        <ChartLegendItem>
                          <ChartLegend backgroundColor="#6ED3DF" />
                          <ChartLegendLabel>
                            Birthday
                            <br />
                            {' '}
                            $
                            {' '}
                            {formatter.format((contributionData
                              && contributionData.totalBirthday) || 0.00)}
                          </ChartLegendLabel>
                        </ChartLegendItem>
                      </Col>
                      <Col col={12}>
                        <ChartLegendItem>
                          <ChartLegend backgroundColor="#FFE1E2" />
                          <ChartLegendLabel>
                            Wedding
                            <br />
                            {' '}
                            $
                            {' '}
                            {formatter.format((contributionData
                              && contributionData.totalWedding) || 0.00)}
                          </ChartLegendLabel>
                        </ChartLegendItem>
                      </Col>
                    </Row>
                    <Row>
                      <Col col={12}>
                        <ChartLegendItem>
                          <ChartLegend backgroundColor="#EC1C24" />
                          <ChartLegendLabel>
                            Subscription
                            <br />
                            {' '}
                            $
                            {' '}
                            {formatter.format((contributionData
                              && contributionData.totalSubscription) || 0.00)}
                          </ChartLegendLabel>
                        </ChartLegendItem>
                      </Col>
                      <Col col={12}>
                        <ChartLegendItem>
                          <ChartLegend backgroundColor="#E4E8F4" />
                          <ChartLegendLabel>
                            Other
                            <br />
                            {' '}
                            $
                            {' '}
                            {formatter.format((contributionData
                              && contributionData.totalOthers) || 0.00)}
                          </ChartLegendLabel>
                        </ChartLegendItem>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </PanelBody>
            </Panel>
          </Col>
          <Col col={6}>
            <Panel>
              <PanelHeading>
                <Row>
                  <Col width="3%">
                    <img src={RedDot} width={5} height={10} alt="." />
                  </Col>
                  <Col col={2}>
                    <EventDiv
                      color={event === 'birthday' ? '' : ({ theme }) => theme.colors.grey_blue}
                      onClick={() => { setEventData([]); changeEvent('birthday'); }}
                    >
                      Birthdays
                    </EventDiv>
                  </Col>
                  <Col col={8}>
                    <EventDiv
                      color={event === 'anniversary' ? '' : ({ theme }) => theme.colors.grey_blue}
                      onClick={() => { setEventData([]); changeEvent('anniversary'); }}
                    >
                      Wedding Anniversaries
                    </EventDiv>
                  </Col>
                </Row>
              </PanelHeading>
              <PanelBody>
                <BirthdayWrapper>
                  {eventData.length > 0 && eventData.map((bday) => (
                    <Birthday key={bday.id}>
                      <Row>
                        <Col col={4}>
                          <BirthdayProfile src={defaultUserProfileImg} alt="profile" />
                          <BirthdayHatIcon>
                            <img src={birthdayHat} alt="hat" />
                          </BirthdayHatIcon>
                        </Col>
                        <Col col={8}>
                          {event === 'birthday' ? (
                            <BirthdayUserName onClick={() => handleClick(`/members/details/${bday.id}`)}>
                              {bday.name}
                            </BirthdayUserName>
                          ) : (
                            <>
                              <WeddingUserName onClick={() => handleClick(`/members/details/${bday.partnerOne.id}`)}>
                                {bday?.partnerOne?.name}
                              </WeddingUserName>
                              <WeddingUserName onClick={() => handleClick(`/members/details/${bday.partnerTwo.id}`)}>
                                {bday?.partnerTwo?.name}
                              </WeddingUserName>
                            </>
                          )}
                          <UserPrayerGroup>
                            {bday?.prayerGroup
                            || bday.partnerOne?.prayerGroup}
                          </UserPrayerGroup>
                          <UserPhone>
                            <img src={phoneIcon} alt="phone" />
                            <div>{bday.contactNumber || bday.partnerOne.contactNumber}</div>
                          </UserPhone>
                        </Col>
                      </Row>
                    </Birthday>
                  ))}
                  <ClearSection />
                </BirthdayWrapper>
              </PanelBody>
            </Panel>
          </Col>
        </Row>
      </BodySection>
    </DashboardLayout>
  );
};

export default MemberDashboard;

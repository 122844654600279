import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import Cookies from 'js-cookie';
import Select from 'react-select';
import moment from 'moment';
import { Col, Row } from '../../../components/styled_components/grid/Grid.style';
import arrowRightIcon from '../../../assets/icons/arrow-right.svg';
import download from '../../../assets/icons/download.svg';
import {
  ContributionDisplayButton,
  ContributionInputWrap,
  ContributionDisplayButtonIcon,
  DownloadButton,
  DownloadButtonIcon,
  Image,
  ListHeading,
  TotalAmtDiv,
  Total,
} from './Contribution.style';
import { MobilePageHeading, MobilePageLayout } from '../../../theme/GlobalStyles';
import RadioButtons from '../../../components/common/radio_buttons';
import Loader from '../../../components/styled_components/loader/loader.style';
import { API, ACCT_API } from '../../../constants';
import { useFetch, fetcher, fileDownloader } from '../../../hooks/useFetch';
import ContributionGrid from '../../../components/mobile_views/contribution_grid';

const customStyles = {
  control: (provided) => ({
    ...provided,
    width: '100%',
    height: 50,
    background: '#ECF2F8',
    borderRadius: 11,
    marginTop: 10,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    background: 'none',
  }),
};

const Contribution = () => {
  const memberId = Cookies.get('memberId');
  const [options, setOptions] = useState([]);
  const [data, setData] = useState([]);
  const [memberID, setMemberID] = useState(memberId);
  const [select, setSelect] = useState('family_wise');
  const [total, setTotalAmt] = useState(0);
  const [startDate, setStartDate] = useState(moment().startOf('year').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [loading, setLoading] = useState(false);
  const current = parseInt(moment().format('YYYY'), 10);
  const getYear = () => {
    const year = [];
    for (let i = 2020; i <= current + 1; i += 1) {
      year.push({ value: i, label: i });
    }
    return year;
  };
  // method to fetch table data
  const getDetails = async () => {
    setLoading(true);
    const familyStatus = (select === 'family_wise');
    const res = await fetcher({
      url: `${API.MEMBER_RECEIPTS}?membershipId=${memberID}&startDate=${startDate}&endDate=${endDate}&family=${familyStatus}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    setData(res.receipts);
    setLoading(false);
    let amt = 0;
    res.receipts.forEach((item) => {
      amt += item.amount;
    });
    setTotalAmt(amt);
  };
  // API for fetching member details
  const members = useFetch({
    method: 'GET',
    url: `${API.HOUSE}?membershipId=${memberID}`,
  });
  useEffect(() => {
    getDetails();
  }, []);
  useEffect(() => {
    if (members.data) {
      const memberOption = [];
      members.data.details.map((member) => {
        memberOption.push({ value: member.churchID, label: member.name });
        return true;
      });
      setOptions(memberOption);
    }
  }, [members.data]);
  const handleChange = (e) => {
    setMemberID(e.value);
  };
  const handleSelectChange = (event) => {
    const { value } = event.target;
    setSelect(value);
  };
  const handleDownload = async () => {
    if (select === 'member_wise') {
      const res = await fileDownloader({
        url: `${ACCT_API.GET_CONTRIBUTION_DOWNLOAD}?membershipId=${memberId}&startDate=${startDate}&endDate=${endDate}&family=false`,
        method: 'GET',
        authTokenKey: 'auth_token',
      });
      saveAs(new Blob([res], { type: 'application/vnd.ms-excel' }), 'Contribution.pdf');
    } else {
      const res = await fileDownloader({
        url: `${ACCT_API.GET_CONTRIBUTION_DOWNLOAD}?membershipId=${memberId}&startDate=${startDate}&endDate=${endDate}&family=true`,
        method: 'GET',
        authTokenKey: 'auth_token',
      });
      saveAs(new Blob([res], { type: 'application/vnd.ms-excel' }), 'Contribution.pdf');
    }
  };
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return (
    <>
      <MobilePageLayout>
        {loading ? <Loader /> : null}
        <Row>
          <Col>
            <MobilePageHeading>Contribution</MobilePageHeading>
          </Col>
        </Row>
        <ContributionInputWrap>
          <Row>
            <Col col={12}>
              <RadioButtons
                label1="Family Wise"
                value1="family_wise"
                label2="Member Wise"
                value2="member_wise"
                marginTop="5px"
                handleSelectChange={handleSelectChange}
                select={select}
              />
            </Col>
          </Row>
          <Row>
            <Col col={12}>
              {select === 'member_wise'
                ? (
                  <Select
                    styles={customStyles}
                    options={options}
                    onChange={handleChange}
                    placeholder="Member name"
                  />
                ) : ''}
            </Col>
            <Col col={12}>
              <Select
                styles={customStyles}
                defaultValue={getYear().find((i) => i.value === current)}
                options={getYear()}
                onChange={(e) => {
                  setStartDate(moment().format(`${e.value}-01-01`));
                  setEndDate(moment().format(`${e.value}-12-31`));
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col col={12}>
              <ContributionDisplayButton type="button" onClick={() => { getDetails(); setData(); }}>
                Display
                <ContributionDisplayButtonIcon>
                  <img src={arrowRightIcon} alt="arrow" height="16px" />
                </ContributionDisplayButtonIcon>
              </ContributionDisplayButton>
            </Col>
          </Row>
        </ContributionInputWrap>
        <ListHeading>Trinity &apos;s Contribution List</ListHeading>
        {data && data.length > 0
          ? data.map((cData, index) => (
            <ContributionGrid cData={cData} index={index} />
          ))
          : ''}
        {data && data.length > 0
          ? (
            <TotalAmtDiv>
              <Row>
                <Col col={6}>
                  <DownloadButton type="button" onClick={() => handleDownload()}>
                    Download
                    <DownloadButtonIcon>
                      <Image src={download} alt=">" height="13px" marginTop="5px" />
                    </DownloadButtonIcon>
                  </DownloadButton>
                </Col>
                <Col col={6}>
                  <Total>
                    {`$ ${formatter.format(total)}`}
                  </Total>
                </Col>
              </Row>
            </TotalAmtDiv>
          )
          : ''}
      </MobilePageLayout>
    </>
  );
};

export default Contribution;

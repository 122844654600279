import config from './config';

export const MEMBER_API = config.servicePath;
export const ACCOUNTING_API = config.accountingPath;
export const API = {
  LOGIN: `${MEMBER_API}/login`,
  FORGOT_PASSWORD: `${MEMBER_API}/forgot_password`,
  RESET_PASSWORD: `${MEMBER_API}/reset_password`,
  HOUSE: `${MEMBER_API}/house`,
  LIST_MEMBERS: `${MEMBER_API}/list_members`,
  GET_FAMILY: `${MEMBER_API}/house`,
  GET_MEMBER_LIST: `${MEMBER_API}/list_members`,
  GET_MEMBER_ADDRESS: `${MEMBER_API}/get_family_address_api`,
  GET_ACTIVITY: `${MEMBER_API}/fetch_activities`,
  MEMBER_DETAILS: `${MEMBER_API}/member`,
  UPDATE_ADDRESS: `${MEMBER_API}/update_address`,
  UPDATE_SOCIAL: `${MEMBER_API}/social`,
  UPDATE_CONTACT: `${MEMBER_API}/update_contact`,
  UPDATE_MEMBER_DETAILS: `${MEMBER_API}/update_member_details`,
  MEMBER_DATA: `${MEMBER_API}/member_details`,
  BIRTHDAY: `${MEMBER_API}/birthday`,
  Wedding: `${MEMBER_API}/wedding`,
  MEMBER_RECEIPTS: `${ACCOUNTING_API}/get_member_receipts`,
  GET_FAMILY_MEMBERS: `${MEMBER_API}/family_members`,
  GET_ROLES: `${MEMBER_API}/roles`,
  UPLOAD_MEMBER_PHOTO: `${MEMBER_API}/upload_member_photo`,
  UPLOAD_FAMILY_PHOTO: `${MEMBER_API}/upload_family_photo`,
  PENDING_ADDRESS: `${MEMBER_API}/list_pending_requests`,
  APPROVE_ADDRESS: `${MEMBER_API}/approve_address`,
  TRANSFER_MEMBER: `${MEMBER_API}/transfer_member`,
  REMOVE_MEMBER: `${MEMBER_API}/remove_member`,
  DELETE_MEMBER: `${MEMBER_API}/delete_member`,
  GET_PRAYER_GROUP: `${MEMBER_API}/list_prayer_area`,
  GET_RELATIONS: `${MEMBER_API}/list_existing_relations`,
  ADD_MEMBER: `${MEMBER_API}/add_member`,
  UPLOAD_TC: `${MEMBER_API}/upload_transfer_certificate`,
  UPDATE_ADDRESS_ADMIN: `${MEMBER_API}/update_address_admin`,
  GET_ANNIVERSARY: `${MEMBER_API}/anniversary`,
  GENERAL_BODY_LIST: `${MEMBER_API}/general_body`,
  GENERAL_AGE_LIST: `${MEMBER_API}/get_member_age`,
  PRAYER_GROUP_LIST: `${MEMBER_API}/prayer_group`,
  NEW_MEMBER_EMAIL: `${MEMBER_API}/member_email`,
  GET_DEATH_REPORT: `${MEMBER_API}/death_report`,
  GET_NEWLY_ADDED_MEMBER_LIST: `${MEMBER_API}/list_new_member`,
  GET_MALE_FEMALE_LIST: `${MEMBER_API}/list_male_female`,
  GET_NEWLY_WEDDED_MEMBERSHIP: `${MEMBER_API}/list_newly_wedded_membership`,
  GET_NEW_ADDRESS_CHANGE: `${MEMBER_API}/new_address_change`,
  GET_ALL_HEAD_OF_THE_HOUSE: `${MEMBER_API}/all_members_head_of_the_house`,
  DOWNLOAD_MALE_FEMALE_REPORT: `${MEMBER_API}/male_female_report_download`,
  DOWNLOAD_NEWLY_ADDED_MEMBER_LIST: `${MEMBER_API}/list_new_member_report_download`,
  DOWNLOAD_DEATH_REPORT: `${MEMBER_API}/death_report_download`,
  DOWNLOAD_NEW_ADDRESS_CHANGE: `${MEMBER_API}/new_address_change_download`,
  DOWNLOAD_PRAYER_GROUP_LIST: `${MEMBER_API}/download_prayer_group_list`,
  DOWNLOAD_NEWLY_WEDDED_MEMBERSHIP: `${MEMBER_API}/newly_wedded_membership_download`,
  DOWNLOAD_GENERAL_BODY_LIST: `${MEMBER_API}/general_body_download`,
  DOWNLOAD_GENERAL_AGE_LIST: `${MEMBER_API}/list_member_age_download`,
  DOWNLOAD_ANNIVERSARY_LIST: `${MEMBER_API}/download_anniversary`,
  DOWNLOAD_LABEL: `${MEMBER_API}/download_birthday_wedding`,
  DOWNLOAD_PDF: `${MEMBER_API}/birthday_anniversary_label`,
};
export const ACCT_API = {
  GET_BANK_ACCOUNTS: `${ACCOUNTING_API}/get_account`,
  GET_CHECKBANKS: `${ACCOUNTING_API}/list_checkbank`,
  GET_CATEGORY: `${ACCOUNTING_API}/get_sales_items`,
  CONTRIBUTION: `${ACCOUNTING_API}/contribution_pie`,
  CREATE_RECEIPT: `${ACCOUNTING_API}/create_receipt`,
  CREATE_ORG_RECEIPT: `${ACCOUNTING_API}/create_org_receipt`,
  GET_TRANSACTION_JOURNAL_VOUCHERS: `${ACCOUNTING_API}/list_journal_voucher`,
  CREATE_BANK_TRANSFER: `${ACCOUNTING_API}/create_transfer`,
  CREATE_JOURNAL_ENTRY: `${ACCOUNTING_API}/create_journal_entry`,
  GET_TRANSACTION_BANK_TRANSFER: `${ACCOUNTING_API}/list_transfer`,
  GET_VENDOR: `${ACCOUNTING_API}/list_vendor`,
  CREATE_PAYMENT: `${ACCOUNTING_API}/create_payment`,
  CREATE_ORG_PAYMENT: `${ACCOUNTING_API}/create_org_payment`,
  GET_CHURCH_RECEIPTS: `${ACCOUNTING_API}/church_receipts`,
  GET_CHURCH_ORG_RECEIPTS: `${ACCOUNTING_API}/organization_receipts`,
  CHURCH_RECEIPTS_ORG_DOWNLOAD: `${ACCOUNTING_API}/church_receipt_org_download`,
  CHURCH_RECEIPTS_DOWNLOAD: `${ACCOUNTING_API}/church_receipt_download`,
  GET_MEMBER_RECEIPTS: `${ACCOUNTING_API}/get_member_receipts`,
  GET_SALES_ITEM: `${ACCOUNTING_API}/get_sales_items`,
  GET_INCOME_WISE_STATEMENT: `${ACCOUNTING_API}/receipts_category`,
  GET_INCOME_COMBINED: `${ACCOUNTING_API}/get_income_combined_receipts`,
  GET_INCOME_WISE_STATEMENT_DOWNLOAD: `${ACCOUNTING_API}/receipt_category_download`,
  GET_PRAYER_GROUP_RECEIPTS: `${ACCOUNTING_API}/church_receipts`,
  GET_TRANSACTION_PAYMENTS: `${ACCOUNTING_API}/get_payment_category`,
  GET_ORG_PAYMENTS: `${ACCOUNTING_API}/organization_payments`,
  GET_ORG_RECEIPTS: `${ACCOUNTING_API}/organization_receipts`,
  GET_BANK_SUMMARIZED: `${ACCOUNTING_API}/get_bank_summarized`,
  GET_RECEIPTS_SUMMARIZED: `${ACCOUNTING_API}/receipts_summarized`,
  GET_PAYMENTS_SUMMARIZED: `${ACCOUNTING_API}/get_payments_summarized`,
  GET_BALANCE_SHEET: `${ACCOUNTING_API}//balance_sheet`,
  DOWNLOAD_SUMMARIZED_REPORT: `${ACCOUNTING_API}//summarized_report_download`,
  GET_CHURCH_PAYMENTS: `${ACCOUNTING_API}/church_payments`,
  GET_CHURCH_ORG_PAYMENTS: `${ACCOUNTING_API}/organization_payments`,
  CHURCH_PAYMENTS_ORG_DOWNLOAD: `${ACCOUNTING_API}/church_payment_org_download`,
  CHURCH_PAYMENTS_DOWNLOAD: `${ACCOUNTING_API}/church_payments_download`,
  GET_BANK_DETAILS: `${ACCOUNTING_API}/get_account`,
  GET_INCOME_DETAILS: `${ACCOUNTING_API}/receipts_summarized`,
  GET_EXPENSE_DETAILS: `${ACCOUNTING_API}/get_payments_summarized`,
  CREATE_INCOME_SUB_ACCOUNT: `${ACCOUNTING_API}/create_items`,
  CREATE_SUB_ACCOUNT: `${ACCOUNTING_API}/create_sub_account`,
  CREATE_ACCOUNT_HEAD: `${ACCOUNTING_API}/create_account_head`,
  EDIT_BANK_ACCOUNT: `${ACCOUNTING_API}/edit_bank_account`,
  EDIT_INCOME_ACCOUNT: `${ACCOUNTING_API}/update_receipt_heads`,
  EDIT_EXPENSE_ACCOUNT: `${ACCOUNTING_API}/edit_expense_account`,
  GET_CONTRIBUTION_DOWNLOAD: `${ACCOUNTING_API}/receipts_member_download`,
  GET_SUBSCRIPTION_DUE: `${ACCOUNTING_API}/subscription_dues`,
  GET_SUBSCRIPTION_DUE_DOWNLOAD: `${ACCOUNTING_API}/download_subscription_dues`,
  CREATE_CHECK_BANK: `${ACCOUNTING_API}/create_check_bank`,
  CREATE_VENDOR: `${ACCOUNTING_API}/create_vendor`,
  GET_IDWISE_STATEMENT: `${ACCOUNTING_API}/get_member_receipts`,
  GET_IDWISE_STATEMENT_DOWNLOAD: `${ACCOUNTING_API}/receipts_member_download`,
  GET_IDWISE_CONTRIBUTION_LETTER: `${ACCOUNTING_API}/get_member_receipts`,
  GET_IDWISE_CONTRIBUTION_LETTER_DOWNLOAD: `${ACCOUNTING_API}/download_contribution_list`,
  GET_CONFIGURATION: `${ACCOUNTING_API}/get_configuration`,
  UPDATE_STATEMENT: `${ACCOUNTING_API}/update_statement`,
  GET_REPORT: `${ACCOUNTING_API}/get_report`,
  GET_ITEMIZED_LIST: `${ACCOUNTING_API}/get_itemized_list`,
  GET_ITEMIZED_GENERAL: `${ACCOUNTING_API}/get_itemized_general`,
  DOWNLOAD_ITEMIZED_LIST: `${ACCOUNTING_API}/download_itemized_list`,
  DOWNLOAD_ITEMIZED_GENERAL: `${ACCOUNTING_API}/download_itemized_general`,
  DOWNLOAD_RECEIPTS: `${ACCOUNTING_API}/download_receipt`,
  DOWNLOAD_PAYMENT: `${ACCOUNTING_API}/download_payment`,
  DOWNLOAD_ORG_PAYMENT: `${ACCOUNTING_API}/download_org_payment`,
  DOWNLOAD_ORG_RECEIPT: `${ACCOUNTING_API}/download_org_receipt`,
  ACCOUNTING_DASHBOARD: `${ACCOUNTING_API}/accounting_dashboard`,
  DASHBOARD_MONTHLY: `${ACCOUNTING_API}/dashboard_monthly`,
  ACCOUNTING_DASHBOARD_LAST_FIVE: `${ACCOUNTING_API}/dashboard_last_five`,
  GET_RECEIPTS: `${ACCOUNTING_API}/get_receipt`,
  EDIT_RECEIPTS: `${ACCOUNTING_API}/edit_receipt`,
  EDIT_CHECKBANK: `${ACCOUNTING_API}/edit_checkbank`,
  EDIT_CUSTOMER: `${ACCOUNTING_API}/edit_customer`,
  EDIT_VENDOR: `${ACCOUNTING_API}/edit_vendor`,
  GET_PAYMENTS: `${ACCOUNTING_API}/get_payment`,
  GET_ORG_PAYMENT: `${ACCOUNTING_API}/get_org_payment`,
  GET_ORG_RECEIPT: `${ACCOUNTING_API}/get_org_receipt`,
  EDIT_PAYMENTS: `${ACCOUNTING_API}/edit_payment`,
  EDIT_ORG_PAYMENTS: `${ACCOUNTING_API}/edit_org_payment`,
  EDIT_ORG_RECEIPTS: `${ACCOUNTING_API}/edit_org_receipt`,
  VOID_RECEIPTS: `${ACCOUNTING_API}/void_receipt`,
  VOID_PAYMENTS: `${ACCOUNTING_API}/void_payment`,
  ADD_MEMBER_ACCOUNTING: `${ACCOUNTING_API}/map_customer`,
  GET_ACCOUNT_NO: `${ACCOUNTING_API}/get_account_no`,
  GET_BUDGET: `${ACCOUNTING_API}/get_budget`,
  GET_BUDGET_LIST: `${ACCOUNTING_API}/list_budget`,
  CREATE_BUDGET: `${ACCOUNTING_API}/create_budget`,
  DOWNLOAD_BUDGET_REPORT: `${ACCOUNTING_API}/budget_report_download`,
  EDIT_BUDGET: `${ACCOUNTING_API}/edit_budget`,
  GET_NON_BUDGET: `${ACCOUNTING_API}/get_non_budget`,
  GET_OVER_BUDGET: `${ACCOUNTING_API}/get_over_budget`,
  DOWNLOAD_NON_BUDGET: `${ACCOUNTING_API}/non_budget_download`,
  DOWNLOAD_OVER_BUDGET: `${ACCOUNTING_API}/over_budget_download`,
  INCOME_COMBINED_DOWNLOAD: `${ACCOUNTING_API}/income_combined_pdf_download`,
  DELETE_BANK_ACCOUNT: `${ACCOUNTING_API}/delete_bank_account`,
};

export const statesUS = [
  {
    label: 'Alabama',
    value: 'Alabama',
    abbreviation: 'AL',
  },
  {
    label: 'Alaska',
    value: 'Alaska',
    abbreviation: 'AK',
  },
  {
    label: 'American Samoa',
    value: 'American Samoa',
    abbreviation: 'AS',
  },
  {
    label: 'Arizona',
    value: 'Arizona',
    abbreviation: 'AZ',
  },
  {
    label: 'Arkansas',
    value: 'Arkansas',
    abbreviation: 'AR',
  },
  {
    label: 'California',
    value: 'California',
    abbreviation: 'CA',
  },
  {
    label: 'Colorado',
    value: 'Colorado',
    abbreviation: 'CO',
  },
  {
    label: 'Connecticut',
    value: 'Connecticut',
    abbreviation: 'CT',
  },
  {
    label: 'Delaware',
    value: 'Delaware',
    abbreviation: 'DE',
  },
  {
    label: 'District Of Columbia',
    value: 'District Of Columbia',
    abbreviation: 'DC',
  },
  {
    label: 'Federated States Of Micronesia',
    value: 'Federated States Of Micronesia',
    abbreviation: 'FM',
  },
  {
    label: 'Florida',
    value: 'Florida',
    abbreviation: 'FL',
  },
  {
    label: 'Georgia',
    value: 'Georgia',
    abbreviation: 'GA',
  },
  {
    label: 'Guam',
    value: 'Guam',
    abbreviation: 'GU',
  },
  {
    label: 'Hawaii',
    value: 'Hawaii',
    abbreviation: 'HI',
  },
  {
    label: 'Idaho',
    value: 'Idaho',
    abbreviation: 'ID',
  },
  {
    label: 'Illinois',
    value: 'Illinois',
    abbreviation: 'IL',
  },
  {
    label: 'Indiana',
    value: 'Indiana',
    abbreviation: 'IN',
  },
  {
    label: 'Iowa',
    value: 'Iowa',
    abbreviation: 'IA',
  },
  {
    label: 'Kansas',
    value: 'Kansas',
    abbreviation: 'KS',
  },
  {
    label: 'Kentucky',
    value: 'Kentucky',
    abbreviation: 'KY',
  },
  {
    label: 'Louisiana',
    value: 'Louisiana',
    abbreviation: 'LA',
  },
  {
    label: 'Maine',
    value: 'Maine',
    abbreviation: 'ME',
  },
  {
    label: 'Marshall Islands',
    value: 'Marshall Islands',
    abbreviation: 'MH',
  },
  {
    label: 'Maryland',
    value: 'Maryland',
    abbreviation: 'MD',
  },
  {
    label: 'Massachusetts',
    value: 'Massachusetts',
    abbreviation: 'MA',
  },
  {
    label: 'Michigan',
    value: 'Michigan',
    abbreviation: 'MI',
  },
  {
    label: 'Minnesota',
    value: 'Minnesota',
    abbreviation: 'MN',
  },
  {
    label: 'Mississippi',
    value: 'Mississippi',
    abbreviation: 'MS',
  },
  {
    label: 'Missouri',
    value: 'Missouri',
    abbreviation: 'MO',
  },
  {
    label: 'Montana',
    value: 'Montana',
    abbreviation: 'MT',
  },
  {
    label: 'Nebraska',
    value: 'Nebraska',
    abbreviation: 'NE',
  },
  {
    label: 'Nevada',
    value: 'Nevada',
    abbreviation: 'NV',
  },
  {
    label: 'New Hampshire',
    value: 'New Hampshire',
    abbreviation: 'NH',
  },
  {
    label: 'New Jersey',
    value: 'New Jersey',
    abbreviation: 'NJ',
  },
  {
    label: 'New Mexico',
    value: 'New Mexico',
    abbreviation: 'NM',
  },
  {
    label: 'New York',
    value: 'New York',
    abbreviation: 'NY',
  },
  {
    label: 'North Carolina',
    value: 'North Carolina',
    abbreviation: 'NC',
  },
  {
    label: 'North Dakota',
    value: 'North Dakota',
    abbreviation: 'ND',
  },
  {
    label: 'Northern Mariana Islands',
    value: 'Northern Mariana Islands',
    abbreviation: 'MP',
  },
  {
    label: 'Ohio',
    value: 'Ohio',
    abbreviation: 'OH',
  },
  {
    label: 'Oklahoma',
    value: 'Oklahoma',
    abbreviation: 'OK',
  },
  {
    label: 'Oregon',
    value: 'Oregon',
    abbreviation: 'OR',
  },
  {
    label: 'Palau',
    value: 'Palau',
    abbreviation: 'PW',
  },
  {
    label: 'Pennsylvania',
    value: 'Pennsylvania',
    abbreviation: 'PA',
  },
  {
    label: 'Puerto Rico',
    value: 'Puerto Rico',
    abbreviation: 'PR',
  },
  {
    label: 'Rhode Island',
    value: 'Rhode Island',
    abbreviation: 'RI',
  },
  {
    label: 'South Carolina',
    value: 'South Carolina',
    abbreviation: 'SC',
  },
  {
    label: 'South Dakota',
    value: 'South Dakota',
    abbreviation: 'SD',
  },
  {
    label: 'Tennessee',
    value: 'Tennessee',
    abbreviation: 'TN',
  },
  {
    label: 'Texas',
    value: 'Texas',
    abbreviation: 'TX',
  },
  {
    label: 'Utah',
    value: 'Utah',
    abbreviation: 'UT',
  },
  {
    label: 'Vermont',
    value: 'Vermont',
    abbreviation: 'VT',
  },
  {
    label: 'Virgin Islands',
    value: 'Virgin Islands',
    abbreviation: 'VI',
  },
  {
    label: 'Virginia',
    value: 'Virginia',
    abbreviation: 'VA',
  },
  {
    label: 'Washington',
    value: 'Washington',
    abbreviation: 'WA',
  },
  {
    label: 'West Virginia',
    value: 'West Virginia',
    abbreviation: 'WV',
  },
  {
    label: 'Wisconsin',
    value: 'Wisconsin',
    abbreviation: 'WI',
  },
  {
    label: 'Wyoming',
    value: 'Wyoming',
    abbreviation: 'WY',
  },
];

import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { saveAs } from 'file-saver';
import Cookies from 'js-cookie';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Col, Row } from '../../components/styled_components/grid/Grid.style';
import arrowRightIcon from '../../assets/icons/arrow-right.svg';
import {
  ContributionDisplayButton,
  ContributionInputWrap,
  ContributionDisplayButtonIcon,
  DownloadButton,
  DownloadIcon,
  DownloadImage,
  ListHeading,
  TotalAmtDiv,
  Total,
  CustomSelect,
  NoData,
} from './Contribution.style';
import { PageHeading, PageLayout } from '../../theme/GlobalStyles';
import RadioButtons from '../../components/common/radio_buttons';
import downloadArrow from '../../assets/icons/download.svg';
import Table from '../../components/common/table';
import Loader from '../../components/styled_components/loader/loader.style';
import { API, ACCT_API } from '../../constants';
import { useFetch, fetcher, fileDownloader } from '../../hooks/useFetch';
import MobileContribution from '../mobile_view/contribution';
import ScrollUpButton from '../../utils/ScrollUpButton';
import ScrollDownButton from '../../utils/ScrollDownButton';

const tableLookup = [{
  heading: 'Recipient Number',
  field_name: 'id',
  type: 'number',
}, {
  heading: 'Date',
  field_name: 'date',
  type: 'date',
}, {
  heading: 'Payment Method',
  field_name: 'paymentMethod',
  type: 'string',
}, {
  heading: 'Category',
  field_name: 'lines',
  type: 'array',
}, {
  heading: 'Organization',
  field_name: 'organization',
  type: 'string',
}, {
  heading: 'Amount',
  field_name: 'amount',
  type: 'amount',
}];

const Contribution = () => {
  const memberId = Cookies.get('memberId');
  const [options, setOptions] = useState([]);
  const [data, setData] = useState();
  const [memberID, setMemberID] = useState(memberId);
  const [select, setSelect] = useState('family_wise');
  const [total, setTotalAmt] = useState(0);
  const [startDate, setStartDate] = useState(moment().startOf('year').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [loading, setLoading] = useState(false);
  const current = parseInt(moment().format('YYYY'), 10);
  const getYear = () => {
    const year = [];
    for (let i = 2020; i <= current + 1; i += 1) {
      year.push({ value: i, label: i });
    }
    return year;
  };
  // method to fetch table data
  const getDetails = async () => {
    setLoading(true);
    const familyStatus = (select === 'family_wise');
    const res = await fetcher({
      url: `${ACCT_API.GET_INCOME_COMBINED}?membershipId=${memberID}&startDate=${startDate}&endDate=${endDate}&family=${familyStatus}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    const arr = res.receipts.filter((x) => x.particular !== 'Voided');
    setData(arr);
    setLoading(false);
    let amt = 0;
    res.receipts.forEach((item) => {
      amt += item.amount;
    });
    setTotalAmt(amt);
  };
  // API for fetching member details
  const members = useFetch({
    method: 'GET',
    url: `${API.HOUSE}?membershipId=${memberID}`,
  });
  useEffect(() => {
    getDetails();
  }, []);
  useEffect(() => {
    if (members.data) {
      const memberOption = [];
      members.data.details.map((member) => {
        memberOption.push({ value: member.churchID, label: member.name });
        return true;
      });
      setOptions(memberOption);
    }
  }, [members.data]);
  const handleChange = (e) => {
    setMemberID(e.value);
  };
  const handleSelectChange = (event) => {
    const { value } = event.target;
    setSelect(value);
  };
  const handleDownload = async () => {
    if (select === 'member_wise') {
      try {
        const res = await fileDownloader({
          url: `${ACCT_API.GET_CONTRIBUTION_DOWNLOAD}?membershipId=${memberID}&startDate=${startDate}&endDate=${endDate}&family=false`,
          method: 'GET',
          authTokenKey: 'auth_token',
        });
        saveAs(new Blob([res], { type: 'application/vnd.ms-excel' }), 'Contribution.pdf');
      } catch (e) {
        toast.error('Error!', { autoClose: 3000 });
      }
    } else {
      try {
        const res = await fileDownloader({
          url: `${ACCT_API.GET_CONTRIBUTION_DOWNLOAD}?membershipId=${memberId}&startDate=${startDate}&endDate=${endDate}&family=true`,
          method: 'GET',
          authTokenKey: 'auth_token',
        });
        saveAs(new Blob([res], { type: 'application/vnd.ms-excel' }), 'Contribution.pdf');
      } catch (e) {
        toast.error('Error!', { autoClose: 3000 });
      }
    }
  };
  if (isMobile) {
    return <MobileContribution />;
  }
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <>
      <PageLayout>
        {loading ? <Loader /> : null}
        <ScrollUpButton />
        <ScrollDownButton />
        <Row>
          <Col>
            <PageHeading>Contribution</PageHeading>
          </Col>
        </Row>
        <ContributionInputWrap>
          <Row>
            <Col col={3}>
              <RadioButtons
                label1="Family Wise"
                value1="family_wise"
                label2="Member Wise"
                value2="member_wise"
                marginTop="5px"
                handleSelectChange={handleSelectChange}
                select={select}
              />
            </Col>
            <Col col={3}>
              {select === 'member_wise'
                ? (
                  <CustomSelect
                    width="18%"
                    className="select"
                    classNamePrefix="custom"
                    options={options}
                    onChange={handleChange}
                    placeholder="Member name"
                  />
                ) : ''}
            </Col>
            <Col col={2}>
              <CustomSelect
                className="select"
                classNamePrefix="custom"
                defaultValue={getYear().find((i) => i.value === current)}
                options={getYear()}
                onChange={(e) => {
                  setStartDate(moment().format(`${e.value}-01-01`));
                  setEndDate(moment().format(`${e.value}-12-31`));
                }}
              />
            </Col>
            <Col col={2}>
              <DownloadButton onClick={handleDownload}>
                Download
                <DownloadIcon>
                  <DownloadImage src={downloadArrow} alt=">" height="13px" />
                </DownloadIcon>
              </DownloadButton>
            </Col>
            <Col col={2}>
              <ContributionDisplayButton type="button" onClick={() => { getDetails(); setData(); }}>
                Display
                <ContributionDisplayButtonIcon>
                  <img src={arrowRightIcon} alt="arrow" height="16px" />
                </ContributionDisplayButtonIcon>
              </ContributionDisplayButton>
            </Col>
          </Row>
        </ContributionInputWrap>
        {data && data.length > 0 ? (
          <>
            <ListHeading>Trinity &apos;s Contribution List</ListHeading>

            <Table data={data || []} tableLookup={tableLookup} />
            <TotalAmtDiv>
              <Row>
                <Col col={12} textAlign="right">
                  <Total>
                    $
                    {' '}
                    {formatter.format(total)}
                  </Total>
                </Col>
              </Row>
            </TotalAmtDiv>
          </>
        ) : data && data.length === 0 ? (
          <NoData>
            No Data
          </NoData>
        ) : ''}
      </PageLayout>
    </>
  );
};

export default Contribution;

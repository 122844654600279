import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Cookies from 'js-cookie';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import AccountingMenu from '../../../components/accounting/menu';
import ReportSubMenu from '../../../components/accounting/report_submenu';
import Breadcrumbs from '../../../components/breadcrumbs';
import arrowRightIcon from '../../../assets/icons/arrow-right.svg';
import downloadArrow from '../../../assets/icons/download.svg';
import {
  Row,
  Col,
} from '../../../components/styled_components/grid/Grid.style';
import {
  AccountingPageLayout,
  PageHeading,
  PageSubHeading,
} from '../../../theme/GlobalStyles';
import {
  BudgetReportDiv,
  DownloadButton,
  DownloadButtonIcon,
  DownloadImage,
  StyledDate,
  CustomSelect,
  DisplayButton,
  DisplayButtonIcon,
  TableDiv,
  TableHead,
  TableData,
} from './OverBudgetReport.style';
import { fetcher, fileDownloader } from '../../../hooks/useFetch';
import { ACCT_API } from '../../../constants';
import Loader from '../../../components/styled_components/loader/loader.style';
import ScrollDownButton from '../../../utils/ScrollDownButton';
import ScrollUpButton from '../../../utils/ScrollUpButton';

const breadcrumbs = ['Accounting', 'Reports', 'Over & Non Budget Report'];

const OverBudgetReport = () => {
  const [type, setType] = useState();
  const [startDate, setStartDate] = useState(moment().format('YYYY-01-01'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [budgetName, setBudgetName] = useState();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [budgetList, setBudgetList] = useState([]);
  const minDate = Cookies.get('accountant_year');
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const reportType = [
    {
      value: 'Over Budget',
      label: 'Over Budget',
    },
    {
      value: 'Non Budget',
      label: 'Non Budget',
    },
  ];

  const getBudgetList = async () => {
    setLoading(true);
    const res = await fetcher({
      url: `${ACCT_API.GET_BUDGET_LIST}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });
    if (res.budget_list) {
      const arr = [];
      res.budget_list.forEach((x) => {
        arr.push({ label: x.name, value: x.name });
      });
      setBudgetList(arr);
      setLoading(false);
    }
  };

  const downloadReport = async () => {
    if (!budgetName || !type) {
      toast.error('Please select a budget name and report type', { autoClose: 3000 });
      return;
    }
    setLoading(true);
    let apiUrl;
    if (type === 'Over Budget') {
      apiUrl = 'DOWNLOAD_OVER_BUDGET';
    } else {
      apiUrl = 'DOWNLOAD_NON_BUDGET';
    }
    try {
      const download = await fileDownloader(
        {
          url: `${ACCT_API[apiUrl]}?startDate=${startDate}&endDate=${endDate}&name=${budgetName}`,
          method: 'GET',
          authTokenKey: 'auth_token',
        },
      );
      if (download) {
        setLoading(false);
        saveAs(new Blob([download], { type: 'application/vnd.ms-excel' }), 'Budget Report.xlsx');
      }
    } catch (e) {
      setLoading(false);
      toast.error('Error!', { autoClose: 3000 });
    }
  };

  const getReport = async () => {
    if (!budgetName || !type) {
      toast.error('Please select a budget name and report type', { autoClose: 3000 });
      return;
    }
    setLoading(true);
    let apiUrl;
    if (type === 'Over Budget') {
      apiUrl = 'GET_OVER_BUDGET';
    } else {
      apiUrl = 'GET_NON_BUDGET';
    }
    const res = await fetcher({
      url: `${ACCT_API[apiUrl]}?name=${budgetName}&startDate=${startDate}&endDate=${endDate}`,
      method: 'GET',
      authTokenKey: 'auth_token',
    });

    if (res.response) {
      setLoading(false);
      const arr = res.response.filter((x) => x.Description !== 'Parsonage Maintenance');
      setData(arr);
    }
  };

  useEffect(() => {
    getBudgetList();
  }, []);

  return (
    <>
      <AccountingMenu />
      {loading ? <Loader /> : null}
      <ScrollUpButton />
      <ScrollDownButton />
      <AccountingPageLayout>
        <PageHeading>Reports</PageHeading>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <ReportSubMenu />
        <PageSubHeading>Over & Non Budget Report</PageSubHeading>
        <BudgetReportDiv>
          <Row>
            <Col col={2}>
              <CustomSelect
                className="select"
                classNamePrefix="custom"
                options={budgetList}
                placeholder="Budget List"
                name="type"
                onChange={(e) => {
                  setBudgetName(e.value);
                }}
              />
            </Col>
            <Col col={2}>
              <CustomSelect
                className="select"
                classNamePrefix="custom"
                options={reportType}
                placeholder="Report Type"
                name="type"
                onChange={(e) => {
                  setType(e.value);
                }}
              />
            </Col>
            <Col col={2}>
              <StyledDate
                width="88%"
                type="date"
                min={`${minDate}-01-01`}
                max={`${minDate}-12-31`}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Col>
            <Col col={2}>
              <StyledDate
                width="88%"
                type="date"
                min={`${minDate}-01-01`}
                max={`${minDate}-12-31`}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Col>
            <Col col={4}>
              <DownloadButton type="button" onClick={() => downloadReport()}>
                Download
                <DownloadButtonIcon>
                  <DownloadImage src={downloadArrow} alt=">" height="13px" />
                </DownloadButtonIcon>
              </DownloadButton>
              <DisplayButton type="button" onClick={() => getReport()}>
                Display
                <DisplayButtonIcon>
                  <img src={arrowRightIcon} alt=">" height="16px" />
                </DisplayButtonIcon>
              </DisplayButton>
            </Col>
          </Row>
        </BudgetReportDiv>
        <TableDiv>
          <table>
            <tbody>
              <tr>
                <TableHead>Description</TableHead>
                <TableHead>Account Number</TableHead>
                <TableHead>Budget</TableHead>
                <TableHead>Actual</TableHead>
                <TableHead>Difference</TableHead>
              </tr>
            </tbody>
            <tbody>
              {data
              && data.map((expense) => (
                <>
                  <tr>
                    <TableData>{expense.Description}</TableData>
                    <TableData>{expense['Acct. #']}</TableData>
                    <TableData>
                      $
                      {' '}
                      {formatter.format(expense.Budget)}
                    </TableData>
                    <TableData>
                      $
                      {' '}
                      {formatter.format(expense.Actual)}
                    </TableData>
                    <TableData>
                      $
                      {' '}
                      {formatter.format(expense.Difference)}
                    </TableData>
                  </tr>
                </>
              ))}
              {data && (
                <>
                  <tr>
                    <TableHead>Total</TableHead>
                    <TableData> </TableData>
                    <TableData>
                      $
                      {' '}
                      {formatter.format(data.reduce((a, b) => a + parseFloat(b.Budget, 10), 0))}
                    </TableData>
                    <TableData>
                      $
                      {' '}
                      {formatter.format(data.reduce((a, b) => a + parseFloat(b.Actual, 10), 0))}
                    </TableData>
                    <TableData>
                      $
                      {' '}
                      {formatter.format(data.reduce((a, b) => a + parseFloat(b.Difference, 10), 0))}
                    </TableData>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </TableDiv>
      </AccountingPageLayout>
    </>
  );
};
export default OverBudgetReport;

import styled from 'styled-components';

export const CardDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.border.radius_m};
  padding: 20px;
  margin-right: ${(props) => props.marginRight || 0};
`;

export const CardHeading = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.tertiary_blue};
`;

export const CardBody = styled.div`
  margin: 20px 0 0 0;
`;

export const Dollar = styled.span`
  font-weight: 600;
  font-size: 28px;
  color: ${({ theme }) => theme.colors.grey_blue};
`;

export const Amount = styled.span`
  font-size: 28px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.tertiary_blue};
`;

export const ArrowSpan = styled.span`
  float: right;
`;
